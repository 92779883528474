import React from 'react';

import { useParams } from 'react-router-dom';

import { Overlay } from '@liscio/common';
import { Stack } from '@liscio/ui';

import { AccountBankAccountsListMobile } from './components';
import { NewBankAccountButton } from './components/AccountBankAccountsListMobile/components';
import CreateBankAccountForm from './components/AccountBankAccountsListMobile/components/BankAccountFormMobile/CreateBankAccountForm';
import { useBankAccountOverlays } from './view-hooks';
import { EmptyListPlaceholder, Loading, TopLoader } from 'components';
import { useToastError } from 'custom-hooks';
import {
  useAccountBankAccounts,
  useAccountOverview,
} from 'fetch-utils/accounts/accounts-hooks';

interface AccountBankAccountsProps {}

export default function AccountBankAccounts(props: AccountBankAccountsProps) {
  const { id } = useParams();
  const { data: account } = useAccountOverview(id!);
  const { setShowCreateOverlay, showCreateOverlay } = useBankAccountOverlays();

  const {
    data: bankAccounts,
    isLoading,
    error,
    isRefetching,
  } = useAccountBankAccounts(id!);

  useToastError(error, 'Encountered problem with bank account data.');

  function handleCreateGoBack() {
    setShowCreateOverlay(false);
  }

  return (
    <Stack gap="24px">
      <TopLoader open={isRefetching} />
      {isLoading && <Loading />}
      {!isLoading && bankAccounts?.length! > 0 && (
        <AccountBankAccountsListMobile bankAccounts={bankAccounts} />
      )}
      {!isLoading && bankAccounts?.length === 0 && (
        <EmptyListPlaceholder label="No bank accounts found." />
      )}
      {account?.is_account_owner && <NewBankAccountButton />}
      <Overlay open={showCreateOverlay}>
        <CreateBankAccountForm goBack={handleCreateGoBack} />
      </Overlay>
    </Stack>
  );
}
