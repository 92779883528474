import React, { useState } from 'react';

import { Divider, styled, Box, Tabs, Typography } from '@liscio/ui';
import { useNavigate } from 'react-router-dom';

import { getDefaultTab } from './helpers';
import { PROFILE_DOMAIN, PROFILE_PATHS } from 'modules/profile/route-constants';

export interface ProfileViewDesktopProps {
  title?: React.ReactNode;
  children: React.ReactNode;
}

const tabs = [
  {
    label: 'User Information',
    value: 'user_information',
    url: `/${PROFILE_DOMAIN}${PROFILE_PATHS.details}`,
    dataTestId: 'filter__user_information',
  },
  {
    label: 'Security Settings',
    value: 'security_settings',
    url: `/${PROFILE_DOMAIN}${PROFILE_PATHS.securitySettings}`,
    dataTestId: 'filter__security_settings',
  },
];

const TabWrapper = styled(Box)({
  padding: '0 24px',
});
const ContentWrapper = styled(Box)({
  padding: '24px',
});

export const ProfileViewDesktop = ({
  title = 'My Profile Settings',
  children,
}: ProfileViewDesktopProps) => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(getDefaultTab());

  const onNavigationClick = (newTabValue: string) => {
    setSelectedTab(newTabValue);
    const newUrl = tabs.find((elem) => elem.value === newTabValue)?.url;
    if (newUrl) {
      navigate(newUrl);
    }
  };

  return (
    <>
      <Typography variant="h1">{title}</Typography>
      <Divider />
      <TabWrapper>
        <Tabs
          options={tabs}
          selectedOptionValue={selectedTab}
          onNavigationClick={onNavigationClick}
        />
      </TabWrapper>
      <Divider />
      <ContentWrapper>{children}</ContentWrapper>
    </>
  );
};

export default ProfileViewDesktop;
