import { useMemo } from 'react';

import { Capacitor } from '@capacitor/core';

import { getCpaSiteUrl } from './cpa-utils';
import { useGetCpaTheme } from 'fetch-utils/cpa/cpa-hooks';
import { useHomeData } from 'fetch-utils/users/user-hooks';

/**
 * Load cpa custom branding object
 */
export const useCpaCustomBranding = () => {
  const locationCpaSiteUrl = getCpaSiteUrl();
  const { data: homeData, isLoading: isLoadingHomeData } = useHomeData();
  const isNativePlatform = Capacitor.isNativePlatform();

  const cpaSiteUrl = useMemo(() => {
    // Use authenticated home data company url in native apps
    if (isNativePlatform) {
      return homeData?.data?.company_url;
    }

    // Always use site URL in web
    return locationCpaSiteUrl;
  }, [locationCpaSiteUrl, isNativePlatform, homeData]);

  const {
    isLoading: isLoadingTheme,
    data,
    error: themeError,
  } = useGetCpaTheme(
    cpaSiteUrl ? { custom_liscio_url: cpaSiteUrl } : undefined
  );

  return {
    isEnabled: Boolean(cpaSiteUrl),
    isLoading: isLoadingTheme || (isNativePlatform && isLoadingHomeData),
    error: themeError,
    data,
  };
};
