import React from 'react';

import { Helmet } from 'react-helmet-async';
import { Route, Routes } from 'react-router-dom';

import { ResponsiveView } from '@liscio/ui';

import { TASKS_PATHS, TASKS_PAGE_TITLES } from './route-constants';
import { LazyWrapper } from 'components';
import { CatchAllRedirect } from 'modules/CatchAllRedirect/CatchAllRedirect';

const TasksListViewMobile = React.lazy(
  () => import('./views/TasksListView/TasksListViewMobile/TasksListViewMobile')
);
const TasksListViewDesktop = React.lazy(
  () =>
    import('./views/TasksListView/TasksListViewDesktop/TasksListViewDesktop')
);
const TaskDetailsView = React.lazy(
  () => import('./views/TaskDetailsView/ClientTaskDetailsView')
);
const EDocView = React.lazy(() => import('./views/EDocView/EDocView'));
const GetSignatureView = React.lazy(
  () => import('./views/GetSignatureView/GetSignatureView')
);

export const ModuleRoutes = () => (
  <>
    <Helmet title={TASKS_PAGE_TITLES.list} />
    <Routes>
      <Route
        path={TASKS_PATHS.list}
        element={
          <ResponsiveView
            SmallView={<TasksListViewMobile />}
            LargeView={<TasksListViewDesktop />}
          />
        }
      />
      <Route
        path={`${TASKS_PATHS.details}/:id`}
        element={
          <LazyWrapper>
            <TaskDetailsView />
          </LazyWrapper>
        }
      />
      <Route
        path={`${TASKS_PATHS.eDoc}/:documentUrl/:taskId`}
        element={
          <LazyWrapper>
            <EDocView />
          </LazyWrapper>
        }
      />
      <Route
        path={`${TASKS_PATHS.getSignature}/:taskId`}
        element={
          <LazyWrapper>
            <GetSignatureView />
          </LazyWrapper>
        }
      />
      <Route path="*" element={<CatchAllRedirect />} />
    </Routes>
  </>
);

export default ModuleRoutes;
