// import { Capacitor } from '@capacitor/core';
import { createPortal } from 'react-dom';

import { Box, GlobalLoader } from '@liscio/ui';

import { PDFViewer } from 'components/FilePreview/components';
// import PSPDFKitViewer from 'components/PSPDFKit/PSPDFKitViewer';
// import { clientTaxDeliveryToolbarItems } from 'components/PSPDFKit/toolbarItems';
import { useTaxDeliveryStore } from 'stores/workflow/useTaxDeliveryStore';

export const ReviewableDocumentFileViewer = ({
  url,
  loading = false,
  hasError = false,
  mode = 'viewer',
}: {
  url: string;
  loading: boolean;
  hasError?: boolean;
  mode?: 'signable' | 'viewer';
}) => {
  // TODO: Finish PSPDFKit integration for native apps (if we get native licenses)
  // const isNativePlatform = Capacitor.isNativePlatform();

  const { mainContentPortalRef } = useTaxDeliveryStore();

  let content = null;

  if (hasError) {
    content = <div>there was an error loading the document</div>;
  }

  if (loading) {
    content = <GlobalLoader open={true} />;
  }

  if (url) {
    if (mode === 'signable') {
      content = (
        <iframe
          src={url}
          width="100%"
          height="100%"
          style={{ border: 'none' }}
        />
      );
    }
    if (mode === 'viewer' || !mode) {
      content = (
        <Box height="100%">
          <PDFViewer url={url} height="100%" />
        </Box>
      );
      // use this if/when we get PSPDFKit licenses figured out
      //   <Box height="100%" width="100%">
      //     <PSPDFKitViewer
      //       url={url}
      //       toolbarItems={clientTaxDeliveryToolbarItems}
      //     />
      //   </Box>
    }
  }

  if (mainContentPortalRef && mainContentPortalRef.current) {
    return createPortal(content, mainContentPortalRef.current as Element);
  } else {
    return null;
  }
};
