import { Tooltip } from '@mui/material';
import { createPortal } from 'react-dom';

import { Button, Box } from '@liscio/ui';

import { useTaxDeliveryStore } from 'stores/workflow/useTaxDeliveryStore';

type ESignItemActionButtonProps = {
  onClick: () => void;
  disabled?: boolean;
};

export const ESignItemActionButton = ({
  onClick,
  disabled,
}: ESignItemActionButtonProps) => {
  const { actionButtonPortalRef, lastStepActive } = useTaxDeliveryStore();

  const handleClick = () => {
    onClick();
  };

  const button = (
    <Button
      variant="contained"
      fullWidth
      onClick={handleClick}
      disabled={disabled}
    >
      {lastStepActive ? 'Submit' : 'Next'}
    </Button>
  );

  const content = disabled ? (
    <Tooltip title="Awaiting confirmation of successful signature. If you have not already finished signing, please do so now.">
      {/* https://mui.com/material-ui/react-tooltip/#disabled-elements */}
      <Box sx={{ width: '100%' }}>{button}</Box>
    </Tooltip>
  ) : (
    button
  );

  if (actionButtonPortalRef && actionButtonPortalRef.current) {
    return createPortal(content, actionButtonPortalRef.current as Element);
  }
};
