import React from 'react';

import { useTheme, styled, Box, Stack, Typography } from '@liscio/ui';

import {
  GoogleOAuthLoginV5,
  OutlookOauthLoginV5,
} from 'modules/authenticate/components/OAuth';

const Line = styled(Box)(({ theme }) => ({
  height: '0px',
  flex: 1,
  borderBottom: `solid 1px ${theme.palette.common.neutrals[300]}`,
}));

export default function OAuthButtons() {
  const theme = useTheme();
  return (
    <>
      <Typography
        variant="body1"
        textAlign="center"
        color={(theme) => theme.palette.common.neutrals[400]}
      >
        Login to your account with
      </Typography>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        gap={2}
      >
        <Box flex={1}>
          <GoogleOAuthLoginV5 />
        </Box>
        <Box flex={1}>
          <OutlookOauthLoginV5 />
        </Box>
      </Stack>
      <Stack direction="row" alignItems="center">
        <Line />
        <Typography px="25px" color={theme.palette.common.neutrals[400]}>
          Or continue with
        </Typography>
        <Line />
      </Stack>
    </>
  );
}
