import { useState } from 'react';

import { CircularProgress } from '@mui/material';

import { Icon, IconButton, Stack, Typography } from '@liscio/ui';

import { useEncryptedField } from 'fetch-utils/accounts/accounts-hooks';

export interface EncryptedFieldProps {
  recordType: string;
  recordId: string | number;
  fieldName: string;
  hasEncryptedValue?: boolean;
}

const EMPTY_VALUE_LABEL = '-';
const MASK = '********';

export const EncryptedFieldPreview = ({
  recordType,
  recordId,
  fieldName,
  hasEncryptedValue,
}: EncryptedFieldProps) => {
  const [isVisible, setIsVisible] = useState(false);

  const { data, isFetching, remove } = useEncryptedField({
    recordType,
    recordId,
    fieldName,
    enabled: isVisible,
  });

  const decryptedValue = data?.enc_value;
  const IconToDisplay = isVisible ? <Icon icon="show" /> : <Icon icon="hide" />;

  if (!hasEncryptedValue) {
    return <Typography>{EMPTY_VALUE_LABEL}</Typography>;
  }

  return (
    <Stack direction="row" alignItems="center" gap="5px">
      <Typography>
        {isVisible && !isFetching ? decryptedValue : MASK}
      </Typography>
      <IconButton
        size="small"
        data-testid={fieldName}
        onClick={(e) => {
          e.stopPropagation();
          setIsVisible((currentIsVisible) => {
            const newIsVisible = !currentIsVisible;
            if (!newIsVisible) {
              remove();
            }
            return newIsVisible;
          });
        }}
      >
        {isFetching ? <CircularProgress size={24} /> : IconToDisplay}
      </IconButton>
    </Stack>
  );
};

export default EncryptedFieldPreview;
