import React from 'react';

import { Stack } from '@liscio/ui';

export interface ImagePreviewProps {
  src: string;
}

export const ImagePreview = ({ src }: ImagePreviewProps) => {
  return (
    <Stack
      sx={{ height: '100%', position: 'relative' }}
      justifyContent="center"
      alignItems="center"
    >
      <img src={src} alt="file to upload" width="100%" />
    </Stack>
  );
};

export default ImagePreview;
