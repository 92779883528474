import { useMemo } from 'react';

import { Navigate, useLocation } from 'react-router-dom';

import { resolveLegacyRedirect } from './resolveLegacyRedirect';
import { resolveRedirectByDomain } from './resolveRedirectByDomain';
import { DEFAULT_VIEW_URL } from 'constants/settings';

/**
 * Component that handles routes from the legacy client app since
 * we decided to use different route paths in the new app...
 * This component also enforces sensbile default routes for each domain
 * for non-matching routes (i.e., /dashboard/whatever -> dashboard/home)
 */
export const CatchAllRedirect = () => {
  const location = useLocation();
  const redirectUri = useMemo(() => {
    const legacyRedirect = resolveLegacyRedirect({
      pathname: location.pathname,
    });
    const domainDefaultRedirect = resolveRedirectByDomain({
      pathname: location.pathname,
    });

    return legacyRedirect || domainDefaultRedirect || DEFAULT_VIEW_URL;
  }, [location]);

  return <Navigate to={redirectUri} replace />;
};
