export const AUTHENTICATE_DOMAIN = 'authenticate';
export const AUTHENTICATE_DOMAIN_URL = `/${AUTHENTICATE_DOMAIN}/*`;

export const AUTHENTICATE_PATHS = {
  login: '/login',
  logout: '/logout',
  oauthRedirect: 'oauth/:provider',
  legacyOauthRedirect: 'oauth/:provider/:state',
  cpaSelection: '/cpa-selection',
  forgotPassword: '/forgot-password',
  forgotPasswordReset: '/cp/:token',
  magicLink: '/ml/:link',
};

export const AUTHENTICATE_PAGE_TITLES = {
  login: 'Login',
  forgotPassword: 'Forgot Password',
  forgotPasswordReset: 'Forgot Password Reset',
};
