import React from 'react';

import { ResponsiveView } from '@liscio/ui';

import AccountViewDesktopLayout from './components/AccountViewDesktopLayout/AccountViewDesktopLayout';
import AccountDetailsMobileView from 'modules/accounts/views/AccountDetailsMobileView/AccountDetailsMobileView/AccountDetailsMobileView';

export interface AccountViewLayoutProps {}

export const AccountViewLayout: React.FC<AccountViewLayoutProps> = () => {
  return (
    <ResponsiveView
      SmallView={<AccountDetailsMobileView />}
      LargeView={<AccountViewDesktopLayout />}
    />
  );
};

export default AccountViewLayout;
