import React, { useCallback } from 'react';

import { Helmet } from 'react-helmet-async';

import { UploadFormType } from '@liscio/api';
import { AppBar } from '@liscio/common';
import { GlobalLoader } from '@liscio/ui';

import UploadDocumentFormProviderSwitchMobile from './UploadDocumentFormProviderSwitchMobile';
import { UploadDocumentFormData } from './useUploadDocumentForm';
import { ScreenWrapper } from 'components/screenComposition';
import { useAccountsOfContact } from 'fetch-utils/accounts/accounts-hooks';
import { useHomeData } from 'fetch-utils/users/user-hooks';
import { MessageState } from 'modules/messages/views/ThreadDetailsViewMobile/components';

export type OnFileUpload = (
  docIds?: UploadDocumentFormData['doc_ids'],
  formData?: UploadDocumentFormData
) => void | Promise<any>;

export type OnWorkflowFileUpload = (
  docInfo?: UploadDocumentFormData['doc_info']
) => void | Promise<any>;

export interface UploadDocumentFormViewMobileProps {
  fileId?: string;
  taskId?: string;
  type: UploadFormType;
  fixedToAccountId?: string;
  fixedToContactId?: string;
  onFileUpload?: OnFileUpload | OnWorkflowFileUpload;
  formData?: Partial<UploadDocumentFormData>;
  goBack?: () => void;
}

const TITLE_MAP: { [key in UploadFormType]: string } = {
  [UploadFormType.NewMessage]: 'Attach File to New Message',
  [UploadFormType.MessageThread]: 'Attach File to Message Thread',
  [UploadFormType.Task]: 'Attach File to Task',
  [UploadFormType.Invoice]: 'Attach File to Invoice',
  [UploadFormType.Edit]: 'Edit File',
  [UploadFormType.NewFile]: 'Upload New File',
  [UploadFormType.Workflow]: 'Upload File',
};

export default function UploadDocumentFormViewMobile({
  fileId,
  taskId,
  goBack,
  type,
  formData,
  onFileUpload,
  fixedToAccountId,
  fixedToContactId,
}: UploadDocumentFormViewMobileProps) {
  const { data: homeData, isLoading: homeDataLoading } = useHomeData();
  const { isLoading: accountsLoading } = useAccountsOfContact(
    String(homeData?.data?.contact_id || '')
  );

  const onNavigationClick = useCallback(
    (key?: string) => {
      if (key === 'go:back') {
        if (goBack) goBack();
      }
    },
    [goBack]
  );

  const title = TITLE_MAP[type];

  const isANewFile =
    type === UploadFormType.NewFile || type === UploadFormType.Workflow;

  const loadingRequisiteData = accountsLoading || homeDataLoading;
  const showForm = !loadingRequisiteData || !isANewFile;

  return (
    <>
      <Helmet title={title} />
      <ScreenWrapper dataTestId="UploadForm">
        <AppBar
          onNavigationClick={onNavigationClick}
          title={title}
          hideSecondaryActionButton
          DefaultStateElement={MessageState}
        />
        <GlobalLoader open={loadingRequisiteData} />
        {showForm && (
          <UploadDocumentFormProviderSwitchMobile
            onNavigationClick={onNavigationClick}
            type={type}
            formData={formData}
            onFileUpload={onFileUpload}
            fixedToAccountId={fixedToAccountId}
            fixedToContactId={fixedToContactId}
            fileId={fileId}
            taskId={taskId}
          />
        )}
      </ScreenWrapper>
    </>
  );
}
