import { Interweave } from 'interweave';

import { ThreadResponse } from '@liscio/api';
import { Divider, liscioLogoSplash, Box, Stack, Typography } from '@liscio/ui';

export type PrintMessageThreadViewProps = {
  thread: ThreadResponse;
};

/**
 * Print view for message thread details
 */
export const PrintMessageThreadView = ({
  thread,
}: PrintMessageThreadViewProps) => (
  <Box>
    <Box>
      <Box component="img" src={liscioLogoSplash} width={120} />
    </Box>
    <Typography variant="h6" mt={5} mb={2}>
      {thread.message[0].title}
    </Typography>
    <Divider />
    <Stack divider={<Divider />} gap={4} mt={2} mb={4}>
      {thread.message.map((message) => (
        <Box key={message.id}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography variant="h6">{message.sender}</Typography>
            <Box>{message.tym}</Box>
          </Box>
          <Box mt={1}>
            <Box>
              <Typography>To: {message.is_recipient?.join(', ')}</Typography>
            </Box>
          </Box>
          <Box mt={4}>
            <Interweave content={message.content} />
          </Box>
          {message.documents && message.documents.length > 0 && (
            <Box mt={4}>
              <Typography>
                Attachments:{' '}
                {message.documents.map(({ doc_name }) => doc_name).join(', ')}
              </Typography>
            </Box>
          )}
        </Box>
      ))}
    </Stack>
    <Divider />
  </Box>
);
