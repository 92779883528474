import { create } from 'zustand';

import { AccountBankAccountEntity } from '@liscio/api';

export interface IBankAccountOverlays {
  showCreateOverlay: boolean;
  showEditOverlay: boolean;
  showDetailOverlay: boolean;
  selectedBankAccount?: Partial<AccountBankAccountEntity>;
  setSelectedBankAccount: (
    bankAccount?: Partial<AccountBankAccountEntity>
  ) => void;
  setShowEditOverlay: (show: boolean) => void;
  setShowCreateOverlay: (show: boolean) => void;
  setShowDetailOverlay: (show: boolean) => void;
}

const useBankAccountOverlays = create<IBankAccountOverlays>((set) => ({
  showCreateOverlay: false,
  showEditOverlay: false,
  showDetailOverlay: false,
  selectedBankAccount: undefined,
  setSelectedBankAccount: (bankAccount?: Partial<AccountBankAccountEntity>) =>
    set(() => ({ selectedBankAccount: bankAccount })),
  setShowEditOverlay: (show: boolean) => set(() => ({ showEditOverlay: show })),
  setShowCreateOverlay: (show: boolean) =>
    set(() => ({ showCreateOverlay: show })),
  setShowDetailOverlay: (show: boolean) =>
    set(() => ({ showDetailOverlay: show })),
}));

export default useBankAccountOverlays;
