import { Icon, Typography } from '@liscio/ui';

import { FileUploadsBatchType } from 'stores/fileUploads';

export type FileUploadsProgressHeadingProps = {
  uploadBatch: FileUploadsBatchType;
};

/**
 * Simple heading for global file uploads
 */
export const FileUploadsProgressHeading = ({
  uploadBatch,
}: FileUploadsProgressHeadingProps) => {
  if (uploadBatch.error) {
    return (
      <Typography
        color={(theme) => theme.palette.error.main}
        variant="body1"
        display="flex"
        alignItems="center"
        gap={1}
      >
        <Icon icon="error" /> Uploads Not Completed
      </Typography>
    );
  }

  if (uploadBatch.isSuccess) {
    return (
      <Typography
        color={(theme) => theme.palette.success.main}
        variant="body1"
        display="flex"
        alignItems="center"
        gap={1}
      >
        <Icon icon="success" /> Upload complete!
      </Typography>
    );
  }

  return <Typography variant="body1">Files uploading...</Typography>;
};
