import { UploadFileNavigationMenuItemDesktop } from './UploadFileNavigationMenuItemDesktop';
import { UploadFileNavigationMenuItemMobile } from './UploadFileNavigationMenuItemMobile';
import { useIsDesktopView } from 'custom-hooks';

export const ResponsiveUploadFileNavigationMenuItem = () => {
  const isDesktop = useIsDesktopView();

  if (isDesktop) {
    return <UploadFileNavigationMenuItemDesktop />;
  }

  return <UploadFileNavigationMenuItemMobile />;
};
