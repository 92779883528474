import { useEffect, useState } from 'react';

import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
  BasicDocument,
  CancelControllers,
  FilePercentsMap,
  UploadFormType,
  UploadType,
  DocumentsTypes,
} from '@liscio/api';
import { makeDefaultFileSharingMessage } from '@liscio/common';

import { UploadFormProps } from './UploadForm';
import { UploadDocumentFormData } from '../../useUploadDocumentForm';
import { parseBrokenDocumentsToast } from 'components/FileAttachmentsField/parse-broken-documents';
import {
  useUpdateDocument,
  useAttachDocument,
  useSendNewDocument,
  useUploadDocuments,
} from 'fetch-utils/documents/documents-hooks';
import { makeFileId } from 'fetch-utils/documents/documentsCallsUtils';
import { useHomeData } from 'fetch-utils/users/user-hooks';
import { FILES_DOMAIN, FILES_PATHS } from 'modules/files/route-constants';
import { useRudderTrack } from 'utils/analytics';

type TypesWithoutEdit = Exclude<UploadFormType, UploadFormType.Edit>;

const FORM_TYPE_TO_UPLOAD_TYPE: { [Key in TypesWithoutEdit]: UploadType } = {
  [UploadFormType.NewFile]: UploadType.NewFile,
  [UploadFormType.MessageThread]: UploadType.Message,
  [UploadFormType.NewMessage]: UploadType.Message,
  [UploadFormType.Task]: UploadType.Task,
  [UploadFormType.Invoice]: UploadType.Invoice,
  [UploadFormType.Workflow]: UploadType.Workflow,
};

export type UseUploadFormHelperProps = UploadFormProps & {
  fileId?: string;
  taskId?: string;
  isDraft?: boolean;
};

export default function useSubmitUploadForm({
  onNavigationClick,
  type,
  onFileUpload,
  taskId,
  isDraft,
}: UseUploadFormHelperProps) {
  const rudderTrack = useRudderTrack();
  const { data } = useHomeData();
  const navigate = useNavigate();
  const { mutateAsync: attachDocument, isLoading: filesAreAttaching } =
    useAttachDocument({ taskId, isDraft });
  const { mutateAsync: sendNewDocument, isLoading: filesAreSending } =
    useSendNewDocument();
  const { mutateAsync: uploadDocuments, isLoading: filesAreUploading } =
    useUploadDocuments();
  const { mutateAsync: editFile, isLoading: fileUpdating } =
    useUpdateDocument();
  const { getValues, watch, setValue, formState } =
    useFormContext<UploadDocumentFormData>();
  const [cancelControllersMap, setCancelControllersMap] =
    useState<CancelControllers>({});
  const [uploadProgressPercentMap, setUploadProgressPercentMap] =
    useState<FilePercentsMap>({});
  const isEditView = type === UploadFormType.Edit;
  const isSendNewFileView =
    type === UploadFormType.NewFile || type === UploadFormType.Workflow;

  async function handleEditDocument() {
    const values = getValues();
    await editFile(
      {
        ...getValues(),
        year: Number(values.year),
        multi_tags: values?.tags.map((tag: { label: string }) => tag.label),
        document_ids: Number(values?.doc_ids?.[0]),
        file_name: [values.file_name, values.file_extension]
          .filter(Boolean)
          .join('.'),
      },
      {
        onSuccess: () => {
          if (onNavigationClick) {
            onNavigationClick('go:back');
          } else {
            const docId = values?.doc_ids?.[0];
            navigate(`/${FILES_DOMAIN}${FILES_PATHS.details}/${docId}`);
          }
        },
      }
    );
  }

  function cancelUpload(file?: File) {
    if (!file) return;

    const fileId = makeFileId(file);
    cancelControllersMap[fileId].abort();

    delete cancelControllersMap[fileId];
    delete uploadProgressPercentMap[fileId];

    setUploadProgressPercentMap({ ...uploadProgressPercentMap });
    setCancelControllersMap({ ...cancelControllersMap });

    const { files: fileList } = getValues();
    const files = Array.from(fileList) as File[];
    const newFiles = files.filter(
      (oldFile: File) => makeFileId(oldFile) !== fileId
    );

    setValue('files', newFiles);
  }

  async function handleUploadDocument(formValues: UploadDocumentFormData) {
    const uploadType = FORM_TYPE_TO_UPLOAD_TYPE[type as TypesWithoutEdit];

    const uploadedDocsResponse = await uploadDocuments({
      documents: formValues.files,
      taskId,
      uploadType,
      setCancelController: setCancelControllersMap,
      onUploadProgress: setUploadProgressPercentMap,
    });

    const indiciesOfFailedUploads = parseBrokenDocumentsToast(
      uploadedDocsResponse,
      formValues.files
    );

    const doc_info =
      uploadedDocsResponse
        ?.filter(({ data }) => Boolean(data.data))
        .map(
          ({ data }: DocumentsTypes.DocumentUploadResponseEntity) => data.data
        ) || [];

    const doc_ids = doc_info.map((doc) => doc.id);

    const filteredFormFiles = formValues.files.filter(
      (file, index) => !indiciesOfFailedUploads?.includes(index)
    );

    const filteredFormValues = {
      ...formValues,
      files: filteredFormFiles,
    };
    const params = {
      doc_ids,
      formValues: {
        ...filteredFormValues,
        id: taskId,
      } as UploadDocumentFormData & {
        id?: string;
      },
      isMessageAltered: Boolean(formState?.touchedFields?.message),
    };

    const res = isSendNewFileView
      ? await sendNewDocument(params)
      : await attachDocument(params);

    // for some reason, cancellation registers as a success with react-query
    // so interrupt the success script on cancellation (status 503)
    if (res?.doc_ids?.length === 0) return;

    if (onFileUpload) {
      onFileUpload(
        // TODO: fix this typing, outside the scope of this ticket. No idea why this was working before.
        // https://liscio.atlassian.net/browse/DEV-3565
        // eslint-disable-next-line
        // @ts-ignore
        type === UploadFormType.Workflow ? doc_info || [] : doc_ids || [],
        filteredFormValues as UploadDocumentFormData
      );
    }
    onNavigationClick('go:back');

    setUploadProgressPercentMap({});

    rudderTrack('Uploading File(s)', {
      recipientRoles: formValues?.participants?.map(
        (accountRecipient) => accountRecipient.recipient?.type
      ),
    });
  }

  async function onSubmit(formValues: UploadDocumentFormData) {
    try {
      if (isEditView) handleEditDocument();
      else handleUploadDocument(formValues);
    } catch (err) {
      console.error(err);
    }
  }

  const disableSubmit = isEditView
    ? false
    : filesAreAttaching || filesAreSending || watch('files')?.length === 0;

  useEffect(() => {
    const { message, files } = getValues();
    if (files.length && (!formState.touchedFields.message || !message)) {
      const mungedFiles = files.map((file) => ({
        ...file,
        doc_name: file.name,
      }));
      setValue(
        'message',
        makeDefaultFileSharingMessage(
          mungedFiles as Partial<BasicDocument>[],
          data?.data?.uname
        )
      );
    }
  }, [setValue, data, formState, getValues]);

  return {
    disableSubmit,
    filesAreUploading:
      filesAreAttaching || filesAreSending || filesAreUploading,
    isEditView,
    fileUpdating,
    onSubmit,
    uploadProgressPercentMap,
    cancelUpload,
  };
}
