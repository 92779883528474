import React from 'react';

import { ResponsiveView } from '@liscio/ui';

export interface AccountLoginFormViewProps {}

// Lazy load desktop views
const AccountLoginFormMobileView = React.lazy(
  () => import('./AccountLoginFormMobileView/AccountLoginFormMobileView')
);
const AccountLoginFormDesktopView = React.lazy(
  () => import('./AccountLoginFormDesktopView/AccountLoginFormDesktopView')
);

export const AccountLoginFormView: React.FC<AccountLoginFormViewProps> = () => {
  return (
    <ResponsiveView
      SmallView={<AccountLoginFormMobileView />}
      LargeView={<AccountLoginFormDesktopView />}
    />
  );
};

export default AccountLoginFormView;
