import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { styled, Stack, GlobalLoader } from '@liscio/ui';
// @ts-expect-error module
// eslint-disable-next-line import/no-extraneous-dependencies
import worker from 'pdfjs-dist/build/pdf.worker';
import { toast } from 'react-hot-toast';
import { pdfjs, Document, Page } from 'react-pdf';

import { Separator } from './components';
import { TopLoader } from 'components';

export interface PDFViewerProps {
  url: string;
  width?: number;
  height?: string;
  maxHeight?: string;
}

const PDFContainer = styled(Stack)(({ theme }) => ({
  '.pdf-preview-container': {
    width: '100%',
    overflow: 'scroll',
    maxHeight: 'calc(100vh - 100px)',
    backgroundColor: theme.palette.common.white,
  },
  '@media (max-width: 900px)': {
    '.pdf-preview-container': {
      width: '100%',
      overflow: 'scroll',
      backgroundColor: '#f1f2f3',
    },

    '.pdf-preview-page': {
      width: '100%',
    },
  },
}));

export const PDFViewer: React.FC<PDFViewerProps> = ({
  url,
  width,
  height,
  maxHeight,
}) => {
  const containerRef = useRef(null);
  const [numPages, setNumPages] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const [isLoadingArray, setIsLoadingArray] = useState([true]);

  function handleLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  function handleError(error: Error) {
    setIsLoadingArray([false]);
    console.error('Could not load preview.'); //this should create a sentry breadcrumb.
    console.error(error);
    toast(`Could not load preview. Please download file to view details.`, {
      icon: '💾',
    });
  }

  function handlePageRenderSuccess(index: number) {
    setIsLoadingArray((prevArr) => {
      prevArr[index] = false;
      return [...prevArr];
    });
  }

  useLayoutEffect(() => {
    if (containerRef.current) {
      setContainerWidth((containerRef.current as HTMLDivElement)!.offsetWidth);
    }
  }, []);

  useEffect(() => {
    if (!pdfjs.GlobalWorkerOptions?.workerSrc) {
      pdfjs.GlobalWorkerOptions.workerSrc = worker;
    }
  }, []);

  useEffect(() => {
    if (typeof numPages === 'number') {
      setIsLoadingArray(Array(numPages).fill(true));
    }
  }, [numPages]);

  const allPagesLoaded = isLoadingArray.every((stillLoading) => !stillLoading);
  return (
    <PDFContainer
      width="100%"
      height={height}
      ref={containerRef}
      maxHeight={maxHeight}
    >
      <TopLoader open={!allPagesLoaded} />
      <Document
        file={url}
        className="pdf-preview-container"
        loading={<GlobalLoader open />}
        onLoadError={handleError}
        onLoadSuccess={handleLoadSuccess}
      >
        {numPages > 0 &&
          [...Array(numPages)].map((_, i) => (
            <div key={i}>
              <Page
                pageNumber={i + 1}
                width={width || containerWidth}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                onRenderSuccess={() => handlePageRenderSuccess(i)}
                onRenderError={() => {
                  console.error(`render page ${i + 1} error`);
                }}
                className="pdf-preview-page"
              />
              <Separator />
            </div>
          ))}
      </Document>
    </PDFContainer>
  );
};

export default PDFViewer;
