import { create } from 'zustand';

import { UploadType } from '@liscio/api';

import {
  FileUploadsStoreActions,
  FileUploadsStoreState,
} from './fileUploads.types';
import {
  calculateUploadBatchSize,
  calculateUploadBatchProgress,
  updateFileItemInUploadsList,
  updateUploadItemInUploadsList,
} from './fileUploads.utils';

/**
 * File uploads store
 * Maintains global state for staging files and creating and manging upload btaches.
 * Should not be consumed directly. Please use the folloiwing utility hooks from the app:
 * - useUploadFiles
 * - useFileUploader
 * - useClearFileUploadsOnRouteChange
 *
 * NOTE: This store could be further abstracted to handle all general file upload behavior.
 * To do so, we'd simply need to abstract the global file upload specific behaviors into
 * state data and handlers associated with each upload batch.
 */
export const useFileUploadsStore = create<
  FileUploadsStoreState & FileUploadsStoreActions
>()((set, get) => ({
  // state
  files: [],
  uploads: [],
  uploadBatchIdx: 0,

  // Actions
  addFiles: (files: File[], uploadType) => {
    set((state) => ({
      files: [...state.files, ...files.map((file) => ({ file, progress: 0 }))],
      uploadType,
    }));
  },
  setReferer: (referer) => set({ referer }),
  deleteFile: (idx: number) => {
    set((state) => ({
      files: [...state.files.slice(0, idx), ...state.files.slice(idx + 1)],
    }));
  },
  clearFiles: () => {
    set(() => ({ files: [] }));
  },
  createBatchFromFiles: <T = unknown>(
    data: T,
    {
      uploadBatchKey,
      uploadType,
    }: { uploadBatchKey?: string; uploadType?: UploadType } = {}
  ) => {
    const state = get();
    if (state.files.length === 0) {
      throw new Error('No upload files for batch');
    }
    const uploadBatch = {
      id: state.uploadBatchIdx,
      uploadBatchKey,
      uploadType,
      files: state.files,
      batchSize: calculateUploadBatchSize(state.files),
      data,
      isUploadingBatch: false,
      isUploadingFiles: false,
      isSendingFiles: false,
      isSuccess: false,
      progress: 0,
    };
    set(() => ({
      files: [],
      uploadBatchIdx: state.uploadBatchIdx + 1,
      uploads: [...state.uploads, uploadBatch],
    }));
    return uploadBatch;
  },
  startUploadBatch: (batchId) => {
    set(({ uploads }) => ({
      uploads: updateUploadItemInUploadsList(batchId, uploads, (upload) => ({
        ...upload,
        isUploadingBatch: true,
        isUploadingFiles: true,
      })),
    }));
  },
  retryUploadBatch: (batchId) => {
    set(({ uploads }) => ({
      uploads: updateUploadItemInUploadsList(batchId, uploads, (upload) => {
        // Clear any previously errored files
        const files = upload.files.map((itm) => {
          if (itm.error) {
            return {
              ...itm,
              error: undefined,
              progress: 0,
            };
          }
          return itm;
        });
        // re-calcualte batch progress
        const progress = calculateUploadBatchSize(files);

        return {
          ...upload,
          isUploadingBatch: true,
          isUploadingFiles: true,
          // Clear files, errors and progress and increment retries
          error: undefined,
          files,
          progress,
          retryAttempts: (upload.retryAttempts || 0) + 1,
        };
      }),
    }));
  },
  fileUploadStart: ({ batchId, idx, controller, uploadPromise }) => {
    set(({ uploads }) => ({
      uploads: updateFileItemInUploadsList(
        batchId,
        idx,
        uploads,
        (fileItm) => ({
          ...fileItm,
          progress: 0,
          isUploading: true,
          controller,
          uploadPromise,
          error: false,
        })
      ),
    }));
  },
  fileUploadProgress: ({ batchId, idx, progress }) => {
    set(({ uploads }) => ({
      uploads: updateUploadItemInUploadsList(batchId, uploads, (upload) => {
        // Calculate total batch progress
        const files = upload.files.map((fileItm, fileIdx) => ({
          ...fileItm,
          progress: idx === fileIdx ? progress : fileItm.progress,
        }));
        const batchProgress = calculateUploadBatchProgress(files);

        return {
          ...upload,
          files,
          progress: batchProgress,
        };
      }),
    }));
  },
  fileUploadComplete: ({ batchId, idx, document }) => {
    set(({ uploads }) => ({
      uploads: updateFileItemInUploadsList(
        batchId,
        idx,
        uploads,
        (fileItm) => ({
          ...fileItm,
          isUploading: false,
          progress: 1,
          isSuccess: true,
          document,
        })
      ),
    }));
  },
  // NOTE: It would be nice to move these async status states to react-query where they belongs...
  fileUploadError: ({ batchId, idx, error }) => {
    set(({ uploads }) => ({
      uploads: updateFileItemInUploadsList(
        batchId,
        idx,
        uploads,
        (fileItm) => ({
          ...fileItm,
          isUploading: false,
          error,
        })
      ),
    }));
  },
  allFileUploadsComplete: (batchId, controller) => {
    set(({ uploads }) => ({
      uploads: updateUploadItemInUploadsList(batchId, uploads, (upload) => ({
        ...upload,
        isUploadingFiles: false,
        isSendingFiles: true,
        controller,
      })),
    }));
  },
  sendFilesComplete: (batchId) => {
    set(({ uploads }) => ({
      uploads: updateUploadItemInUploadsList(batchId, uploads, (upload) => ({
        ...upload,
        progress: 1,
        isSuccess: true,
        isUploadingBatch: false,
        isUploadingFiles: false,
        isSendingFiles: false,
      })),
    }));
  },
  batchUploadError: ({ batchId, error }) => {
    set(({ uploads }) => ({
      uploads: updateUploadItemInUploadsList(batchId, uploads, (upload) => ({
        ...upload,
        isSuccess: false,
        isUploadingFiles: false,
        isUploadingBatch: false,
        isSendingFiles: false,
        error,
      })),
    }));
  },
  deleteUploadBatch: (batch) => {
    set(({ uploads }) => ({
      uploads: uploads.filter((upload) => upload.id !== batch),
    }));
  },
}));
