import { MouseEventHandler, useEffect, useState } from 'react';

import toast from 'react-hot-toast';

import { DocumentDetail } from '@liscio/api';
import { Box, Button, Icon, Stack, Typography, useTheme } from '@liscio/ui';

import { ReviewableDocumentActionButton } from './ReviewableDocumentActionButton';
import { ReviewableDocumentFileViewer } from './ReviewableDocumentFileViewer';
import ReviewedConfirmationDialog from './ReviewedConfirmationDialog';
import { useDocumentDetails } from 'fetch-utils/documents/documents-hooks';
import { useUpdateResponseItemBool } from 'fetch-utils/workflow/workflow-hooks';
import { Item, useTaxDeliveryStore } from 'stores/workflow/useTaxDeliveryStore';

type ReviewableDocumentItemProps = {
  onItemClick: () => void;
  onComplete: () => void;
  itemData: Item;
};
export const ReviewableDocumentItem = ({
  onItemClick,
  onComplete,
  itemData,
}: ReviewableDocumentItemProps) => {
  const { responseItemId, prompt, itemIndex, stepIndex, complete, dependency } =
    itemData;

  const { activeItem, activeStep } = useTaxDeliveryStore();

  const active = itemIndex === activeItem && stepIndex === activeStep;

  const theme = useTheme();
  const [open, setOpen] = useState(active);
  const [actionButtonDisabled, setActionButtonDisabled] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const [filePreviewId, setFilePreviewId] = useState<string | null>(null);

  // set the open state when the active prop changes
  useEffect(() => {
    if (complete) {
      setOpen(false);
    } else {
      setOpen(active);
    }
  }, [active, complete]);

  // reset the action button disabled, etc state when the item is closed
  useEffect(() => {
    if (!open) {
      setActionButtonDisabled(false);
      setFilePreviewId(null);
    }
  }, [open]);

  const {
    data: docData,
    isLoading,
    fetchStatus,
    isError,
  } = useDocumentDetails(filePreviewId as string, filePreviewId !== null);

  const { mutate: booleanMutation, isLoading: isMutating } =
    useUpdateResponseItemBool();

  const isDocLoading = isLoading && fetchStatus !== 'idle';

  const handleReviewButtonClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    setActionButtonDisabled(true);

    // Make TS happy
    let fileId = null;
    if ('documentId' in dependency) {
      fileId = dependency.documentId as string;
    }

    setFilePreviewId(fileId);
    onItemClick();
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleConfirmDialogConfirm = () => {
    booleanMutation(
      {
        workflowResponseItemId: responseItemId,
        booleanValue: true,
      },
      {
        onSuccess: () => {
          onComplete();
        },
        onError: () => {
          const errorMessage = 'An error occured while updating.';
          toast.error(errorMessage);
        },
        onSettled: () => {
          setConfirmDialogOpen(false);
        },
      }
    );
  };

  const isComplete = complete ? 'done' : 'to-do';

  const fontColor = active
    ? theme.palette.text.primary
    : theme.palette.text.secondary;

  const labelFontColor = complete
    ? theme.palette.success.main
    : active
    ? theme.palette.primary.main
    : theme.palette.divider;

  return (
    <>
      <Box
        sx={{
          width: '100%',
          padding: '12px',
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: '7px',
          marginBottom: '16px',
          color: fontColor,
          cursor: 'pointer',
        }}
        onClick={() => setOpen(!open)}
      >
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Box>
            <Stack direction="row" spacing={1} alignItems="center">
              {open ? (
                <Icon icon="chevronDown" />
              ) : (
                <Icon icon="chevronRight" />
              )}
              <Typography variant="body1" fontWeight={'normal'}>
                {itemIndex + 1}. {prompt}
              </Typography>
            </Stack>
          </Box>
          <Box
            sx={{
              borderRadius: '5px',
              border: `1px solid ${labelFontColor}`,
              padding: '2px 8px',
              color: labelFontColor,
              textTransform: 'uppercase',
            }}
          >
            <Typography variant="subtitle2" fontWeight={'bold'}>
              {isComplete}
            </Typography>
          </Box>
        </Stack>
        {open && (
          <Stack mt={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleReviewButtonClick}
              disabled={actionButtonDisabled}
            >
              Review
            </Button>
          </Stack>
        )}
      </Box>
      {/* component for main-content-portal-container */}
      {open && filePreviewId && (
        <>
          <ReviewableDocumentFileViewer
            loading={isDocLoading || isMutating}
            hasError={isError}
            url={(docData as DocumentDetail)?.aws_url_original}
          />
        </>
      )}
      {/* component for action-button-portal-container */}
      {active && (
        <ReviewableDocumentActionButton
          disabled={!docData || isDocLoading || isMutating}
          onClick={() => setConfirmDialogOpen(true)}
        />
      )}
      {/* confirmation dialog */}
      <ReviewedConfirmationDialog
        open={confirmDialogOpen}
        onCancel={handleConfirmDialogClose}
        onConfirm={handleConfirmDialogConfirm}
      />
    </>
  );
};
