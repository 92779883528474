import { AccountsOfContactOnTheBasisOfUserIdResponse } from '@liscio/api';

import { Recipient } from 'components/ContactSelector/ContactSelector';



interface Args {
  fixedToContactId?: string;
  selectedRecipient?: Recipient | null;
  accountSearchString: string;
  loggedUserAccounts: void | AccountsOfContactOnTheBasisOfUserIdResponse;
}

const createAccountFilter =
  (searchString: string) => (account: { label: string }) => {
    const normalizedLabel = account.label.toLowerCase();
    const normalizedSearchString = searchString.toLowerCase();
    return normalizedLabel.includes(normalizedSearchString);
  };

export const mungeAccounts: (args: Args) => any = ({
  fixedToContactId,
  selectedRecipient,
  accountSearchString,
  loggedUserAccounts,
}) => {
  if (fixedToContactId) return selectedRecipient?.accounts;

  if (selectedRecipient && selectedRecipient.type === 'contact') {
    return accountSearchString
      ? selectedRecipient.accounts?.filter(
          createAccountFilter(accountSearchString)
        ) || []
      : selectedRecipient.accounts || [];
  }

  const accounts = loggedUserAccounts?.data || [];
  return accountSearchString
    ? accounts.filter(createAccountFilter(accountSearchString))
    : accounts;
};

export default mungeAccounts;
