import { ListItemButton as MUIListItemButton } from '@mui/material';

import { styled } from '@liscio/ui';

export const ListItemButton = styled(MUIListItemButton)(({ theme }) => ({
  alignItems: 'start',
  borderBottom: `solid 1px ${
    theme.palette.common.blueGray?.[200] || theme.palette.common.neutrals?.[200]
  }`,
}));

export default ListItemButton;
