import { useCallback } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { useMagicLinkAuthV5Process } from '@liscio/common';
import {
  CenteredLayout,
  GlobalLoader,
  Box,
  styled,
  ThemedLogo,
} from '@liscio/ui';

import { ErrorDialog } from 'components/ErrorDialog';
import { CpaLoginV5Selector } from 'modules/authenticate/components/CpaLoginV5Selector/CpaLoginV5Selector';
import { useSetLoggedInUserSession } from 'modules/authenticate/hooks';
import { getCpaSiteUrl } from 'stores/cpa/cpa-utils';

const Wrapper = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  paddingTop: '48px',
  paddingBottom: '16px',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: theme.breakpoints.values.sm,
  [theme.breakpoints.up('md')]: {
    color: theme.palette.text.primary,
  },
}));

export const MagicLinkLoginV5View = () => {
  const setLoggedUserSession = useSetLoggedInUserSession();

  const navigate = useNavigate();
  const backToLogin = useCallback(
    () => navigate('/authenticate/login', { replace: true }),
    [navigate]
  );
  const { link } = useParams<{ link: string }>();

  const { isLoading, processState, selectCpa, signInCpas, error } =
    useMagicLinkAuthV5Process({
      token: link || '',
      onProcessFinished: async (loggedInUser) => {
        await setLoggedUserSession(loggedInUser);
      },
      desiredCpaUrl: getCpaSiteUrl(),
    });

  // Cpa selection
  if (processState === 'cpaSelector') {
    return (
      <CpaLoginV5Selector
        onCancel={backToLogin}
        cpas={signInCpas}
        onCpaSelected={selectCpa}
      />
    );
  }

  return (
    <>
      <CenteredLayout>
        <Wrapper>
          <ThemedLogo width="240px" mb="48px" variant="logoSplash" />
          <GlobalLoader open={isLoading} />
        </Wrapper>
      </CenteredLayout>
      <ErrorDialog error={error} onClose={backToLogin} />
    </>
  );
};
