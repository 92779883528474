import { Role } from '@liscio/api';

import { RecipientAccount } from 'components/ContactSelector/ContactSelector';

export function makeAccountFromRecipientAccount(
  recipientAccount?: RecipientAccount
) {
  if (!recipientAccount) return {};

  const { account, recipient } = recipientAccount;

  return {
    account_id: account?.value,
    account_name: account?.label,
    assignee_type: Role.User,
    contact_name: recipient?.label,
    id: recipient?.value,
    type: Role.User,
  };
}
