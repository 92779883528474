import React, { Fragment } from 'react';

import { styled, Divider, Box, Stack, Typography } from '@liscio/ui';
import { Interweave } from 'interweave';

import { Message } from '@liscio/api';

import { PreviewFileButton } from 'components';

interface OpenedThreadDetailProps {
  message: Message;
  handleFilePreviewClick: (id: number) => void;
}

const ToSection = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.blueGray[500],
}));

const MessageSection = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.blueGray[50],
  padding: '6px',
  maxWidth: '74vw',
  overflow: 'scroll',
}));

const AttachmentsStack = styled(Stack)(() => ({
  width: '100%',
  justifyContent: 'center',
  margin: '25px 0px 25px 0px',
}));

export default function OpenedThreadDetail({
  message,
  handleFilePreviewClick,
}: OpenedThreadDetailProps) {
  return (
    <Box mt={1}>
      <ToSection>To: {message.is_recipient?.join(', ')}</ToSection>
      <MessageSection mt={2}>
        <Interweave
          content={
            message.content !== null
              ? message.content
                  .replace(/html/gi, 'div')
                  .replace(/!doctype/gi, 'div></div')
              : ''
          }
        />
      </MessageSection>
      {Boolean(message.documents?.length) && (
        <AttachmentsStack spacing={5}>
          {message.documents?.map((doc) => (
            <Fragment key={doc.id}>
              <PreviewFileButton
                label={doc.doc_name}
                onClick={() => handleFilePreviewClick(doc.id)}
              />
              <Divider />
            </Fragment>
          ))}
        </AttachmentsStack>
      )}
    </Box>
  );
}
