import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';

import { Badge, Tooltip } from '@mui/material';
import {
  Divider,
  Icon,
  IconButton,
  Stack,
  styled,
  Typography,
} from '@liscio/ui';
import toast from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { PrintMessageThreadButton } from './PrintMessageThread/PrintMessageThreadButton';
import {
  useMessageThread,
  useMessages,
  useUpdateMessageUnreadStatus,
} from 'fetch-utils/messages/messages-hooks';
import { useProfileData } from 'fetch-utils/users/user-hooks';
import { MessagesTabs } from 'modules/messages/views/ListViewDesktop/components/Messages/components';
import { TabType } from 'modules/messages/views/ListViewDesktop/components/Messages/Messages';

export type ThreadDetailHeaderProps = {
  setIsArchiveConfirmationVisible: Dispatch<SetStateAction<boolean>>;
  title: string;
  threadId: string;
};

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: '60px',
  height: '60px',
  position: 'relative',
  justifyContent: 'center',
  '.MuiBadge-root': {
    position: 'relative',
    left: '5px',
  },
  '.MuiBadge-dot': {
    position: 'relative',
    right: '17px',
    top: '4px',
    width: '10px',
    height: '10px',
    borderRadius: '100%',
  },
}));

export const MARK_UNREAD_TOASTS = {
  success: 'Thread successfully marked as unread.',
  error: 'Could not mark thread as unread.',
};

export default function ThreadDetailHeader({
  setIsArchiveConfirmationVisible,
  title,
  threadId,
}: ThreadDetailHeaderProps) {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const rawTab = queryParams.get('tab') as TabType;

  const tab = useMemo(() => {
    const allowedTabs = ['inbox', 'sent', 'draft', 'archive', 'pinned'];
    return allowedTabs.includes(rawTab) ? rawTab : undefined;
  }, [rawTab]) as TabType | undefined;

  const { cpa_user_id } = useProfileData();
  const { data: allMessages } = useMessages({
    type: 'inbox',
  });
  const { mutateAsync: markUnread } = useUpdateMessageUnreadStatus();

  const { data: threadDetail } = useMessageThread(threadId);

  const navigateToListPage = useCallback(
    () => navigate(`/messages/list?type=${tab || ('inbox' as TabType)}`),
    [tab, navigate]
  );

  const handleMarkAsUnread = useCallback(async () => {
    const messageId = threadDetail?.message?.[0].id;

    try {
      if (messageId && cpa_user_id) {
        await markUnread({
          cpa_user_id,
          message_id: messageId,
        });

        navigateToListPage();
      } else {
        toast.error(MARK_UNREAD_TOASTS.error);
      }
    } catch (e) {
      toast.error(MARK_UNREAD_TOASTS.error);
    }
  }, [cpa_user_id, markUnread, threadDetail, navigateToListPage]);

  return (
    <>
      <MessagesTabs
        inboxNumberOfMessages={allMessages?.inbox_count}
        sentNumberOfMessages={allMessages?.sent_count}
        draftsNumberOfMessages={allMessages?.draft_count}
        archivedNumberOfMessages={allMessages?.archive_count}
        pinnedNumberOfMessages={allMessages?.pinned_count}
        onNavigationClick={(newTab) =>
          navigate(`/messages/list?type=${newTab}`)
        }
        sx={{ height: '72px' }}
        selectedOptionValue={tab}
      />
      <Divider />
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h2" sx={{ padding: '16px 0px' }}>
          <IconButton onClick={navigateToListPage}>
            <Icon icon="chevronLeft" fontSize="large" />
          </IconButton>
          {title}
        </Typography>
        {tab === 'inbox' && (
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Tooltip title="Mark as Unread">
              <StyledIconButton
                onClick={handleMarkAsUnread}
                data-testid="mark_as_unread"
              >
                <Badge color="primary" variant="dot" overlap="circular">
                  <Icon icon="messageLines" fontSize="large" />
                </Badge>
              </StyledIconButton>
            </Tooltip>
            <Tooltip title="Archive Message">
              <IconButton
                onClick={() => setIsArchiveConfirmationVisible(true)}
                sx={{ width: '60px', height: '60px' }}
                data-testid="archive_message"
              >
                <Icon icon="archive" fontSize="large" />
              </IconButton>
            </Tooltip>
            <PrintMessageThreadButton thread={threadDetail} />
          </Stack>
        )}
      </Stack>
    </>
  );
}
