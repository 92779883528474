import React, { useCallback } from 'react';

import { styled, useTheme, Stack, Typography } from '@liscio/ui';
import { useNavigate } from 'react-router-dom';

import {
  SignInForm,
  ConfirmMfaForm,
  CopyrightFooter,
  LoginLogoDesktop,
} from './components';
import { FOOTER_HEIGHT } from './components/CopyrightFooter/CopyrightFooter';
import PoweredByLiscio from './components/PoweredByLiscio/PoweredByLiscio';
import { useAuth } from 'fetch-utils/users/use-auth';
import { AUTHENTICATE_PATHS } from 'modules/authenticate/route-constants';

const FormWrapper = styled(Stack)({
  width: '516px',
  margin: '0 auto',
});

const SplashWrapper = styled(Stack)({
  width: '100%',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
});

const LoginViewDesktop = () => {
  // Single hook instance for maintaining local auth state across flows
  const auth = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  const heroUrl = theme?.assets?.loginHero;

  const handleBackToLogin = useCallback(() => {
    navigate(AUTHENTICATE_PATHS.login);
    auth.clearMfaCredentials();
  }, [navigate, auth]);

  return (
    <Stack>
      <Stack
        display="grid"
        gridTemplateColumns="1fr 1fr"
        height={`calc(100vh - ${FOOTER_HEIGHT})`}
        overflow="auto"
      >
        <FormWrapper textAlign="center" justifyContent="center">
          <Stack pb={7} pt={7}>
            <LoginLogoDesktop />
            <Typography variant="h1">Welcome back</Typography>
            <Typography
              variant="body1"
              pt="5px"
              color={(theme) => theme.palette.common.neutrals[400]}
            >
              Login to your account with
            </Typography>
          </Stack>
          {auth.mfaRequired && auth.mfaEmail ? (
            <ConfirmMfaForm
              email={auth.mfaEmail}
              loginWithPasscode={auth.loginWithMfaCode}
              onBackToLogin={handleBackToLogin}
              error={auth.loginError}
            />
          ) : (
            <SignInForm auth={auth} />
          )}
          <PoweredByLiscio />
        </FormWrapper>
        <SplashWrapper
          sx={{
            backgroundImage: `url(${heroUrl})`,
            backgroundSize: 'cover',
          }}
        />
      </Stack>
      <CopyrightFooter />
    </Stack>
  );
};

export default LoginViewDesktop;
