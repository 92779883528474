import {
  InvoiceFieldsFragment,
  InvoiceStatus,
  TaskFieldsFragment,
  TaskStatus,
  TodoFeedsQuery,
  WorkflowRequestFragment,
  WorkflowRequestStatus,
} from '@liscio/api/graphql';

import { Unpacked } from 'types/utilityTypes';

const getStatus: (
  todoFeed: Unpacked<TodoFeedsQuery['todoFeeds']>
) => TaskStatus | InvoiceStatus | WorkflowRequestStatus | number | '' = (
  todoFeed
) =>
  (todoFeed?.item as WorkflowRequestFragment)?.percentComplete ||
  (todoFeed?.item as WorkflowRequestFragment)?.status ||
  (todoFeed?.item as InvoiceFieldsFragment)?.invoiceStatus ||
  (todoFeed?.item as TaskFieldsFragment)?.taskStatus ||
  '';

export default getStatus;
