import React from 'react';

import { useParams } from 'react-router-dom';

import { Box } from '@liscio/ui';

import { useAccountFileFilters } from '../../../../AccountFilesView/components/AccountFileFiltersProvider';
import { FileFilters, FilesListTabs } from 'components';
import { useAccountDocuments } from 'fetch-utils/documents/documents-hooks';
import { extractFilesFromPages } from 'fetch-utils/documents/documentsCallsUtils';
import { AccountParamsType } from 'modules/accounts/route-constants';

export const AccountFileFiltersSecondaryNavigation = () => {
  const { filters, setFilters, setTab, resetFilters, hasFiltersSelected } =
    useAccountFileFilters();
  const { id } = useParams<AccountParamsType>();
  const { data } = useAccountDocuments(id!, filters);
  const { total_active, total_archive } = extractFilesFromPages(data?.pages!);

  return (
    <Box borderTop={(theme) => `1px solid ${theme.palette.grey}`}>
      <FilesListTabs
        activeNumberOfFiles={total_active}
        archivedNumberOfFiles={total_archive}
        isCountVisible
        selectedOptionValue={filters.archive ? 'archived' : 'active'}
        onNavigationClick={(value) => setTab(value || 'active')}
      />
      <FileFilters
        filters={filters}
        onChange={setFilters}
        onClear={resetFilters}
        hasFiltersSelected={hasFiltersSelected}
      />
    </Box>
  );
};
