import React, { MouseEventHandler, useEffect, useState } from 'react';

import { IconButton, Menu, MenuItem } from '@mui/material';

import { BasicDocument, UploadFormType } from '@liscio/api';
import { downloadDocumentDetailFile, Overlay } from '@liscio/common';
import { GlobalLoader, Icon } from '@liscio/ui';

import { ArchiveFileConfirmationDialog } from 'components';
import { useDocumentDetails } from 'fetch-utils/documents/documents-hooks';
import { useProfileData } from 'fetch-utils/users/user-hooks';
import { makeFormValuesFromDocumentDetails } from 'modules/forms/documents-form/components/UploadForm/uploadDocumentFormUtils';
import UploadDocumentFormView from 'modules/forms/documents-form/UploadDocumentFormViewMobile';
import { fileStatusToasts } from 'utils/toasts';

export interface FileListEllipsesMenuProps {
  file: BasicDocument;
  onArchiveSuccess?: () => void;
}

export default function FileListEllipsesMenu({
  file,
  onArchiveSuccess,
}: FileListEllipsesMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [editIsOpen, setEditIsOpen] = useState(false);
  const [archiveDialogIsOpen, setArchiveDialogIsOpen] = useState(false);
  const enableDetailFetch = isDownloading || editIsOpen;
  const { data: fileDetails, isFetching } = useDocumentDetails(
    String(file?.id),
    enableDetailFetch
  );
  const { isLoading: isProfileLoading } = useProfileData();

  useEffect(() => {
    async function download() {
      const { onSuccess, onError } = fileStatusToasts(
        fileDetails?.doc_name || 'Your file'
      );

      if (isDownloading && fileDetails) {
        await downloadDocumentDetailFile(fileDetails)
          .then(onSuccess)
          .catch(onError);
        setIsDownloading(false);
      }
    }

    download();
  }, [isDownloading, fileDetails]);

  function handleClose(
    e: React.MouseEvent,
    callback?: (arg?: any) => Promise<void> | void
  ) {
    if (callback && typeof callback === 'function') callback();
    setOpen(false);
  }

  function handleDownloadClick() {
    setIsDownloading(true);
  }

  function handleEditClick() {
    setEditIsOpen(true);
  }

  function handleEllipsesClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpen((isOpen) => !isOpen);
  }

  const editFormData =
    fileDetails && editIsOpen
      ? makeFormValuesFromDocumentDetails(file.id, fileDetails)
      : undefined;

  const isFileArchived = file.is_file_archived_by_contact;

  return (
    <>
      <GlobalLoader open={isDownloading || isFetching || isProfileLoading} />
      <IconButton
        sx={{ padding: 0, margin: 0 }}
        aria-controls={open ? 'ellipse-menu-open' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleEllipsesClick}
      >
        <Icon
          icon="ellipsisVertical"
          sx={{ color: (theme) => theme.palette.grey[600] }}
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose as MouseEventHandler}
      >
        <MenuItem onClick={(e) => handleClose(e, handleEditClick)}>
          Edit
        </MenuItem>
        <MenuItem onClick={(e) => handleClose(e, handleDownloadClick)}>
          Download
        </MenuItem>
        <MenuItem
          onClick={(e) => handleClose(e, () => setArchiveDialogIsOpen(true))}
        >
          {isFileArchived ? 'Unarchive' : 'Archive'}
        </MenuItem>
      </Menu>
      <ArchiveFileConfirmationDialog
        id={file.id}
        open={archiveDialogIsOpen}
        closeFunction={() => setArchiveDialogIsOpen(false)}
        onSuccess={onArchiveSuccess}
        isFileArchived={isFileArchived}
      />
      <Overlay open={Boolean(editIsOpen && fileDetails)}>
        <UploadDocumentFormView
          fileId={file?.id ? String(file?.id) : undefined}
          formData={editFormData}
          goBack={() => setEditIsOpen(false)}
          type={UploadFormType.Edit}
        />
      </Overlay>
    </>
  );
}
