import { useMemo } from 'react';

import { useController } from 'react-hook-form';

import { AutocompleteField, AutocompleteFieldProps } from '@liscio/ui';

import { useHomeData } from 'fetch-utils/users/user-hooks';

export type DocumentYearFieldProps = Omit<
  AutocompleteFieldProps<string, false>,
  'multiple' | 'options' | 'loading'
> & {
  placeholder?: string;
};

/**
 * Upload Document Year Select Field
 */
export const DocumentYearField = ({
  label = 'Year',
  placeholder,
  ...rest
}: DocumentYearFieldProps) => {
  const { isLoading, data } = useHomeData();
  const yearOptions = useMemo(() => {
    if (data?.data?.pref_type_options?.upload_file_year) {
      return data.data.pref_type_options.upload_file_year.map((opt) =>
        String(opt.value)
      );
    }
    return [];
  }, [data?.data?.pref_type_options?.upload_file_year]);

  return (
    <AutocompleteField
      placeholder={placeholder}
      label={label}
      loading={isLoading}
      options={yearOptions}
      {...rest}
    />
  );
};

export type FormDocumentYearFieldProps = Omit<
  DocumentYearFieldProps,
  'onChange' | 'name'
> & {
  name: string;
};

export const FormDocumentYearField = (props: FormDocumentYearFieldProps) => {
  const { field, fieldState } = useController({ name: props.name });

  return (
    <DocumentYearField
      {...props}
      value={field.value || null}
      onChange={(_e, value) => field.onChange(value)}
      error={fieldState.error?.message}
    />
  );
};
