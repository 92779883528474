import React, { useState, ReactNode } from 'react';

import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import { styled, Grid, Icon } from '@liscio/ui';
import { preventBubbling } from '@liscio/utils';

interface KeyValueProps {
  itemKey: string;
  value: string | number | void | ReactNode;
  hideable?: boolean;
}

const KeyStack = styled(Stack)(({ theme }) => ({
  color: theme.palette.grey[500],
  justifyContent: 'flex-start',
  textTransform: 'uppercase',
}));

const ValueStack = styled(Stack)(({ theme }) => ({
  justifyContent: 'flex-end',
}));

const ValueBox = styled(Stack)(({ theme }) => ({
  maxWidth: '100%',
  overflowWrap: 'break-word',
  textAlign: 'right',
}));

export default function KeyValue({
  itemKey,
  value,
  hideable = false,
}: KeyValueProps) {
  const [hidden, setHidden] = useState(hideable);

  function handleEyeClick(e: React.MouseEvent) {
    preventBubbling(e);
    setHidden((isHidden) => !isHidden);
  }

  return (
    <Grid
      display="grid"
      width="100%"
      gridTemplateColumns="50% 50%"
      sx={{ minHeight: '35px' }}
    >
      <KeyStack direction="row" alignItems="center">
        {itemKey}
      </KeyStack>
      <ValueStack direction="row" justifyContent="flex-end" alignItems="center">
        <ValueBox>{!hidden && (value || '-')}</ValueBox>
        {hideable && (
          <IconButton onClick={handleEyeClick}>
            {hidden ? <Icon icon="show" /> : <Icon icon="hide" />}
          </IconButton>
        )}
      </ValueStack>
    </Grid>
  );
}
