import { create } from 'zustand';

import { SignatoryType } from '@liscio/api';
import { LiscioObject, WorkflowRequestQuery } from '@liscio/api/graphql';

type ItemTypes =
  | 'WfiReviewableDocument'
  | 'WfiSignableDocument'
  | 'WfiPayable'
  | 'WfiDynamicItem';

export type ESignDependency = {
  startSigningUrl: string;
  signingAvailableUrl: string;
  currentUserSignatory: SignatoryType;
  sameDeviceSignatories: SignatoryType[];
  id: string;
};

export type Item = {
  id: string;
  responseItemId: string;
  prompt: string;
  itemIndex: number;
  stepIndex: number;
  complete: boolean;
  type: ItemTypes;
  dependency: LiscioObject | ESignDependency;
};

type Step = {
  label: string;
  itemsCount: number;
  items: Item[] | never[];
  stepIndex: number;
  disabled?: boolean;
};

export type TaxDeliveryStoreState = {
  activeStep: number;
  activeItem: number | null;
  submitted: boolean;
  allStepsComplete: boolean;
  lastStepActive?: boolean;
  taxDeliveryData: {
    title: string;
    workflowRequestId: string | null;
    assignedAccount: string;
    stepsCount: number;
    steps: Step[];
  };
  mainContentPortalRef: React.RefObject<HTMLDivElement> | null;
  actionButtonPortalRef: React.RefObject<HTMLDivElement> | null;
};

type TaxDeliveryStoreActions = {
  setActiveStep: (activeStep: TaxDeliveryStoreState['activeStep']) => void;
  setActiveItem: (activeItem: TaxDeliveryStoreState['activeItem']) => void;
  setAllStepsComplete: (allStepsComplete: boolean) => void;
  setTaxDeliveryData: (taxDeliveryData: WorkflowRequestQuery) => void;
  setActiveItemComplete: () => void;
  goToNextItem: () => void;
  checkAllItemsComplete: () => boolean;
  setMainContentPortalRef: (
    ref: React.RefObject<HTMLDivElement> | null
  ) => void;
  setActionButtonPortalRef: (ref: React.RefObject<HTMLDivElement>) => void;
  checkAllStepsComplete: () => boolean;
  setSubmitted: (submitted: boolean) => void;
};

const initialState: Omit<
  TaxDeliveryStoreState,
  'mainContentPortalRef' | 'actionButtonPortalRef'
> = {
  activeStep: 0,
  activeItem: 0,
  allStepsComplete: false,
  submitted: false,
  taxDeliveryData: {
    title: '',
    workflowRequestId: null,
    assignedAccount: '',
    stepsCount: 0,
    steps: [],
  },
};

export const useTaxDeliveryStore = create<
  TaxDeliveryStoreState & TaxDeliveryStoreActions
>((set, get) => ({
  ...initialState,
  mainContentPortalRef: null,
  actionButtonPortalRef: null,
  setMainContentPortalRef: (ref) => set({ mainContentPortalRef: ref }),
  setActionButtonPortalRef: (ref) => set({ actionButtonPortalRef: ref }),
  setActiveStep: (activeStep) => {
    if (activeStep === get().taxDeliveryData.stepsCount - 1) {
      set({ lastStepActive: true });
    } else if (get().lastStepActive) {
      set({ lastStepActive: false });
    }

    set({ activeStep });
  },
  setActiveItem: (activeItem) => set({ activeItem }),
  setAllStepsComplete: (allStepsComplete) => set({ allStepsComplete }),
  setActiveItemComplete: () => {
    const {
      taxDeliveryData,
      activeItem,
      activeStep,
      checkAllStepsComplete,
      checkAllItemsComplete,
    } = get();
    const steps = taxDeliveryData.steps;
    const step = steps[activeStep];
    const item = step.items[activeItem as number];
    item.complete = true;
    if (checkAllStepsComplete()) {
      set({ allStepsComplete: true });
    }
    let newData = { ...taxDeliveryData, steps: [...steps] };
    // if all items in a step are complete then enable the next step
    if (checkAllItemsComplete()) {
      const nextStep = steps[activeStep + 1];
      if (nextStep) {
        nextStep.disabled = false;
        const newSteps = [...steps];
        newSteps[activeStep + 1] = nextStep;
        newData = { ...newData, steps: newSteps };
      }
    }
    set({ taxDeliveryData: newData });
  },
  checkAllItemsComplete: () => {
    const { taxDeliveryData, activeStep } = get();
    const step = taxDeliveryData.steps[activeStep];

    return step.items.every((item) => item.complete);
  },
  setTaxDeliveryData: (workflowData) => {
    // reset state to initial
    set({ ...initialState });
    let normalizedData: TaxDeliveryStoreState['taxDeliveryData'] = {
      title: '',
      stepsCount: 0,
      steps: [],
      workflowRequestId: null,
      assignedAccount: '',
    };
    const workflowRequest = workflowData?.workflowRequest;
    const workflow = workflowRequest?.workflow;

    normalizedData = {
      title: workflowRequest?.title || '',
      workflowRequestId: workflowRequest?.id || null,
      assignedAccount: workflowRequest?.assignedAccount?.id,
      stepsCount: workflowRequest?.requestSections?.length || 0,
      steps:
        workflowRequest?.requestSections?.map((section, i) => ({
          label: workflow?.sections?.[i].title || '',
          itemsCount: section.requestItems?.length || 0,
          stepIndex: i,
          items:
            section.requestItems?.map((item, j) => ({
              id: item.id,
              responseItemId: item.responseItem?.id,
              prompt: item.prompt || '',
              // TODO: figure out how to make activeItem changes update this field
              // active: get().activeItem === j,
              itemIndex: j,
              stepIndex: i,
              complete:
                item.responseItem?.booleanValue === true ||
                // esign items types are different than the others
                (item.responseItem?.liscioObjects?.length as number) > 0,
              type: item.responseItem?.workflowItem?.type as ItemTypes,
              dependency:
                item.dependency ||
                // @ts-expect-error esignAgreement does exist on workflowItem
                item?.responseItem?.workflowItem?.esignAgreement ||
                {},
            })) || [],
        })) || [],
    };

    normalizedData.steps.forEach((step, i) => {
      if (i == 0) {
        step.disabled = false;
        return;
      }
      step.disabled = !normalizedData.steps[i - 1].items.every(
        (item) => item.complete
      );
    });

    set({ taxDeliveryData: normalizedData });
  },
  goToNextItem: () => {
    const { activeItem, activeStep, taxDeliveryData } = get();

    // last item in list so go to next step and reset active item
    if (activeItem === taxDeliveryData.steps[activeStep].itemsCount - 1) {
      // if all items aren't complete loop back to an incomplete item
      const incompleteItem = taxDeliveryData.steps[activeStep].items.findIndex(
        (item) => !item.complete
      );
      if (incompleteItem > -1) {
        get().setActiveItem(incompleteItem);
        return;
      }
      // no next step so set all steps complete flag
      if (
        activeStep === taxDeliveryData.stepsCount - 1 &&
        get().checkAllStepsComplete()
      ) {
        set({ allStepsComplete: true });
        return;
      }
      get().setActiveStep(activeStep + 1);
      set({ activeItem: 0 });
    } else {
      // go to next item in list
      set({ activeItem: (activeItem as number) + 1 });
    }
  },
  checkAllStepsComplete: () => {
    const { taxDeliveryData } = get();
    return taxDeliveryData.steps.every((step) =>
      step.items.every((item) => item.complete)
    );
  },
  setSubmitted: (submitted) => set({ submitted }),
}));
