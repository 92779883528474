import React, { useMemo, useCallback } from 'react';

import { Skeleton } from '@mui/material';
import { Divider, styled, Box, BackButton, Icon, Tabs } from '@liscio/ui';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import { AccountAlert } from './components';
import {
  NON_OWNER_ACCOUNT_TABS_OPTIONS,
  ACCOUNT_OWNER_TABS_OPTIONS,
} from '../../constants/availableDesktopTabs';
import { useAccountOverview } from 'fetch-utils/accounts/accounts-hooks';
import {
  ACCOUNTS_DOMAIN,
  ACCOUNTS_PATHS,
} from 'modules/accounts/route-constants';

export interface AccountViewDesktopLayoutProps {}

const SectionWrapper = styled(Box)({
  padding: '10px 24px',
});

const TabWrapper = styled(Box)({
  padding: '0px 24px',
});

export const AccountViewDesktopLayout: React.FC<
  AccountViewDesktopLayoutProps
> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const tab = location.pathname.split('/').pop();
  const { data: accountData, isLoading } = useAccountOverview(id!);

  const onNavigationClick = useCallback(
    (keyOrTab: string) => {
      if (keyOrTab === 'go:back') {
        navigate(`/${ACCOUNTS_DOMAIN}${ACCOUNTS_PATHS.list}`);
      } else {
        navigate(
          `/${ACCOUNTS_DOMAIN}${ACCOUNTS_PATHS.details.root}/${id}/${keyOrTab}`
        );
      }
    },
    [navigate, id]
  );

  const availableUserTabs = useMemo(() => {
    const tabs = accountData?.is_account_owner
      ? ACCOUNT_OWNER_TABS_OPTIONS
      : NON_OWNER_ACCOUNT_TABS_OPTIONS;

    // Hide temporarily
    // if (accountData?.is_billing_contact) {
    //   tabs = [...tabs, BILLING_TAB];
    // }

    return tabs;
  }, [accountData?.is_account_owner, accountData?.is_billing_contact]);

  const selectedTab = useMemo(() => {
    if (tab && availableUserTabs.find((t) => t.value === tab)) {
      return tab;
    }
  }, [tab, availableUserTabs]);

  return (
    <>
      <SectionWrapper>
        <BackButton
          startIcon={<Icon icon="arrowLeft" />}
          fallback={`/${ACCOUNTS_DOMAIN}${ACCOUNTS_PATHS.list}`}
        >
          Back
        </BackButton>
      </SectionWrapper>
      <Divider />
      {isLoading && !accountData && (
        <SectionWrapper>
          <Skeleton width="120px" />
        </SectionWrapper>
      )}
      {accountData && (
        <>
          <AccountAlert
            hasFailedInvoices={accountData?.has_failed_invoices}
            hasOverdueInvoices={accountData?.has_overdue_invoices}
            hasPaymentAuthorizationRequests={
              accountData?.has_payment_authorization_requests
            }
            isAccountOwner={accountData?.is_account_owner}
          />
          <TabWrapper>
            <Tabs
              options={availableUserTabs}
              selectedOptionValue={selectedTab}
              onNavigationClick={onNavigationClick}
            />
          </TabWrapper>
        </>
      )}
      <Divider />
      <Outlet />
    </>
  );
};

export default AccountViewDesktopLayout;
