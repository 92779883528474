import React, { useRef } from 'react';

import { Tooltip } from '@mui/material';
import { useReactToPrint } from 'react-to-print';

import { ThreadResponse } from '@liscio/api';
import { Icon, IconButton, PrintContainer } from '@liscio/ui';

import { PrintMessageThreadView } from './PrintMessageThreadView';

export type PrintMessageThreadButtonProps = {
  thread?: ThreadResponse;
};

export const PrintMessageThreadButton = ({
  thread,
}: PrintMessageThreadButtonProps) => {
  const printContainerRef = useRef(null);
  const printMessageThread = useReactToPrint({
    content: () => printContainerRef.current,
    // NOTE: We may need to defer print component rendering until the button
    // is clicked if we ever run into any performance issues
  });

  return (
    <>
      <Tooltip title="Print Message Thread">
        <IconButton
          onClick={printMessageThread}
          disabled={!thread}
          // TODO: DEV-3502 Extract IconButton to @liscio/ui
          sx={{ width: 60, height: 60 }}
        >
          <Icon icon="print" fontSize="large" />
        </IconButton>
      </Tooltip>
      {thread && (
        <PrintContainer ref={printContainerRef}>
          <PrintMessageThreadView thread={thread} />
        </PrintContainer>
      )}
    </>
  );
};
