import { DocumentDetail } from '@liscio/api';
import { FileIcon, Stack, Typography, Chip } from '@liscio/ui';

export default function AttachmentsCell({
  documents,
}: {
  documents?: DocumentDetail[];
}) {
  if (!documents) return null;

  const firstDoc = documents?.[0];
  const isPdf = firstDoc?.aws_url_content_type?.includes('application/pdf');
  const isImage = firstDoc?.aws_url_content_type?.includes('image/');

  return firstDoc ? (
    <Stack
      mr={2}
      justifyContent="center"
      alignItems="center"
      flexDirection="row"
      width="90%"
      maxWidth="160px"
    >
      <Chip
        label={
          <Stack direction="row" alignItems="center" overflow="hidden">
            <FileIcon isPdf={isPdf} isImage={isImage} />
            <Typography ml={1}>{firstDoc?.display_name}</Typography>
          </Stack>
        }
      />
      {documents.length > 1 && <Chip label={`+ ${documents?.length - 1}`} />}
    </Stack>
  ) : null;
}
