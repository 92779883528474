import React from 'react';

import { styled, useTheme, Box, Icon } from '@liscio/ui';

export interface CheckProps {
  isChecked: boolean;
}

const Wrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const Check: React.FC<CheckProps> = ({ isChecked }) => {
  const theme = useTheme();

  if (!isChecked) {
    return null;
  }
  return (
    <Wrapper>
      <Icon icon="checkbox" htmlColor={theme.palette.common.neutrals[400]} />
    </Wrapper>
  );
};
