import React from 'react';

import { styled, Box, Stack, Typography } from '@liscio/ui';

import {
  GoogleOAuthLoginMobile,
  OutlookOAuthLoginMobile,
} from 'modules/authenticate/components/OAuth';

const Line = styled(Box)(({ theme }) => ({
  height: '0px',
  flex: 1,
  borderBottom: `solid 1px ${theme.palette.common.neutrals[200]}`,
}));

export default function OAuthButtons() {
  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <GoogleOAuthLoginMobile />
        <OutlookOAuthLoginMobile />
      </Stack>
      <Stack direction="row" alignItems="center" p="16px 0px">
        <Line />
        <Typography
          color={(theme) => theme.palette.text.secondary}
          m="0px 20px"
          variant="caption"
        >
          or continue with
        </Typography>
        <Line />
      </Stack>
    </Stack>
  );
}
