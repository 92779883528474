/* eslint-disable import/no-unresolved */
import { MouseEventHandler, useEffect, useState } from 'react';

import toast from 'react-hot-toast';

import { DocumentDetail } from '@liscio/api';
import { Document, Payable } from '@liscio/api/graphql';
import { Box, Button, Icon, Stack, Typography, useTheme } from '@liscio/ui';

import { PayableItemActionButtons } from './PayableItemActionButtons';
import PayableItemConfirmPaymentDialog from './PayableItemConfirmPaymentDialog';
import PayableItemConfirmRedirectDialog from './PayableItemConfirmRedirectDialog';
import { ReviewableDocumentFileViewer } from '../ReviewableDocument/ReviewableDocumentFileViewer';
import { useDocumentDetails } from 'fetch-utils/documents/documents-hooks';
import { useUpdateResponseItemBool } from 'fetch-utils/workflow/workflow-hooks';
import { Item, useTaxDeliveryStore } from 'stores/workflow/useTaxDeliveryStore';

type PayableItemProps = {
  onItemClick: () => void;
  onComplete: () => void;
  itemData: Item;
};
export const PayableItem = ({
  onItemClick,
  onComplete,
  itemData,
}: PayableItemProps) => {
  const { responseItemId, prompt, itemIndex, stepIndex, complete, dependency } =
    itemData;

  const { activeItem, activeStep } = useTaxDeliveryStore();
  const active = itemIndex === activeItem && stepIndex === activeStep;

  const theme = useTheme();
  const [open, setOpen] = useState(active);
  const [actionButtonDisabled, setActionButtonDisabled] = useState(false);
  const [confirmPaymentDialogOpen, setConfirmPaymentDialogOpen] =
    useState(false);
  const [confirmRedirectDialogOpen, setConfirmRedirectDialogOpen] =
    useState(false);
  const [filePreviewId, setFilePreviewId] = useState<string | null>(null);

  // set the open state when the active prop changes
  useEffect(() => {
    if (complete) {
      setOpen(false);
    } else {
      setOpen(active);
    }
  }, [active, complete]);

  // reset the action button disabled, etc state when the item is closed
  useEffect(() => {
    if (!open) {
      setActionButtonDisabled(false);
      setFilePreviewId(null);
    }
  }, [open]);

  const {
    data: docData,
    isLoading,
    fetchStatus,
    isError,
  } = useDocumentDetails(filePreviewId as string, filePreviewId !== null);

  const { mutate: booleanMutation, isLoading: isMutating } =
    useUpdateResponseItemBool();

  const isDocLoading = isLoading && fetchStatus !== 'idle';
  const { url: payOnlineURL } = dependency as unknown as Payable;

  const handleReviewButtonClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    setActionButtonDisabled(true);

    // Make TS happy
    let fileId = null;
    const dependency = itemData?.dependency || {};
    if ('document' in dependency) {
      const document = dependency.document as Document;
      if ('legacyId' in document) {
        fileId = document.legacyId as string;
      }
    }

    setFilePreviewId(fileId);
    onItemClick();
  };

  const handleConfirmRedirectDialogClose = () => {
    setConfirmRedirectDialogOpen(false);
  };

  const handleConfirmPaymentDialogClose = () => {
    setConfirmPaymentDialogOpen(false);
  };

  const handleConfirmRedirectDialogConfirm = () => {
    if (payOnlineURL) {
      window.open(payOnlineURL, '_blank');
    }
    setConfirmRedirectDialogOpen(false);
    setConfirmPaymentDialogOpen(true);
  };

  const handleConfirmPaymentDialogConfirm = () => {
    booleanMutation(
      {
        workflowResponseItemId: responseItemId,
        booleanValue: true,
      },
      {
        onSuccess: () => {
          onComplete();
        },
        onError: () => {
          const errorMessage = 'An error occured while updating.';
          toast.error(errorMessage);
        },
        onSettled: () => {
          setConfirmPaymentDialogOpen(false);
        },
      }
    );
  };

  const handlePayOnlineButtonClick = () => {
    setConfirmRedirectDialogOpen(true);
  };

  const isComplete = complete ? 'done' : 'to-do';

  const fontColor = active
    ? theme.palette.text.primary
    : theme.palette.text.secondary;

  const labelFontColor = complete
    ? theme.palette.success.main
    : active
    ? theme.palette.primary.main
    : theme.palette.divider;

  return (
    <>
      <Box
        sx={{
          width: '100%',
          padding: '12px',
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: '7px',
          marginBottom: '16px',
          color: fontColor,
          cursor: 'pointer',
        }}
        onClick={() => setOpen(!open)}
      >
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Box>
            <Stack direction="row" spacing={1} alignItems="center">
              {open ? (
                <Icon icon="chevronDown" />
              ) : (
                <Icon icon="chevronRight" />
              )}
              <Typography variant="body1" fontWeight={'normal'}>
                {itemIndex + 1}. {prompt}
              </Typography>
            </Stack>
          </Box>
          <Box
            sx={{
              borderRadius: '5px',
              border: `1px solid ${labelFontColor}`,
              padding: '2px 8px',
              color: labelFontColor,
              textTransform: 'uppercase',
            }}
          >
            <Typography variant="subtitle2" fontWeight={'bold'}>
              {isComplete}
            </Typography>
          </Box>
        </Stack>
        {open && (
          <Stack mt={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleReviewButtonClick}
              disabled={actionButtonDisabled}
            >
              Review
            </Button>
          </Stack>
        )}
      </Box>
      {/* component for main-content-portal-container */}
      {open && (
        <>
          <ReviewableDocumentFileViewer
            loading={isDocLoading || isMutating}
            hasError={isError}
            url={(docData as DocumentDetail)?.aws_url_original}
          />
        </>
      )}
      {active && (
        <PayableItemActionButtons
          disabled={!docData || isDocLoading || isMutating}
          printUrl={(docData as DocumentDetail)?.aws_url_original}
          onPayOnlineClick={handlePayOnlineButtonClick}
          onSubmit={onComplete}
        />
      )}
      {/* confirmation redirect dialog */}
      <PayableItemConfirmRedirectDialog
        open={confirmRedirectDialogOpen}
        onCancel={handleConfirmRedirectDialogClose}
        onConfirm={handleConfirmRedirectDialogConfirm}
        redirectUrl={payOnlineURL as string}
      />
      {/* confirmation payment dialog */}
      <PayableItemConfirmPaymentDialog
        open={confirmPaymentDialogOpen}
        onCancel={handleConfirmPaymentDialogClose}
        onConfirm={handleConfirmPaymentDialogConfirm}
      />
    </>
  );
};
