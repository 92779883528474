import { PasswordField } from '@liscio/ui';

export interface MaskProps {
  name: string;
  onFocus: () => void;
  onVisible: (isShowing: boolean) => void;
  disabled?: boolean;
}

const MASK = '********';

export const Mask = ({ name, onFocus, onVisible, disabled }: MaskProps) => {
  return (
    <PasswordField
      name={name}
      value={MASK}
      onFocus={onFocus}
      visible={false}
      onVisible={(isVisible) => {
        onVisible(isVisible);
        onFocus();
      }}
      disabled={disabled}
      inputProps={{
        autoComplete: 'new-password',
      }}
    />
  );
};

export default Mask;
