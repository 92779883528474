import { useLayoutEffect } from 'react';

import {
  useInfiniteQuery,
  useQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import {
  DraftOrThreadResponse,
  MessagePostBody,
  MessagesSearchArgs,
  ThreadResponse,
  UpdateMessageUnreadStatusPostBody,
} from '@liscio/api';

import { hasUnreadMessage } from './helpers';
import apiClient from 'fetch-utils/api-client';

const {
  getMessages,
  getMessagesQueryKeyWrapper,
  getMessagesOfContact,
  getMessageThread,
  sendMessage,
  archiveThread,
  bulkArchiveThreads,
  updateMessageUnreadStatus,
  toggleMessageIsPinned,
  toggleThreadIsPinned,
} = apiClient.messages;

export function useInfinitiveMessagesOfContact(contactId: string) {
  return useInfiniteQuery({
    queryKey: ['messagesOfContact', contactId],
    queryFn: getMessagesOfContact,
    getNextPageParam: (lastPage, allPages) => {
      const pageSize = 15;
      const allElementsCount = lastPage?.inbox_count || 0;
      if (allPages.length * pageSize < allElementsCount) {
        return allPages.length + 1;
      } else {
        return undefined;
      }
    },
    getPreviousPageParam: (firstPage, allPages) => {
      return allPages.length - 1;
    },
  });
}

export function useMessages({
  type,
  searchString = '',
  pageParam = 1,
  perPage = 15,
  accountId,
}: MessagesSearchArgs) {
  return useQuery({
    queryKey: ['messages', pageParam, type, searchString, perPage, accountId],
    queryFn: async () =>
      getMessages({
        type,
        searchString,
        pageParam,
        perPage,
        accountId,
      }),
  });
}

export function useInfinitiveMessages(type: string, searchString: string) {
  return useInfiniteQuery({
    queryKey: ['messages', type, searchString],
    queryFn: getMessagesQueryKeyWrapper,
    getNextPageParam: (lastPage, allPages) => {
      if (
        lastPage?.inbox.length === 0 &&
        lastPage?.sent.length === 0 &&
        lastPage?.draft.length === 0 &&
        lastPage?.archive.length === 0 &&
        lastPage?.all_messages.length === 0
      ) {
        return undefined;
      }

      return allPages.length + 1;
    },
    getPreviousPageParam: (firstPage, allPages) => {
      return allPages.length - 1;
    },
  });
}

export function useMessageThread(threadId: string, tab?: string) {
  const client = useQueryClient();

  const queryObject = useQuery({
    queryKey: ['messageThread', threadId],
    queryFn: getMessageThread,
  });

  const { data: threadDetails } = queryObject;

  useLayoutEffect(() => {
    if (threadDetails && hasUnreadMessage(threadDetails)) {
      tab
        ? client.refetchQueries(['messages', tab])
        : client.refetchQueries(['messages']);
      client.refetchQueries(['messageThread', threadId]);
    }
  }, [threadDetails, client, tab, threadId]);

  return queryObject;
}

export function useArchiveMessageThread(props?: DefaultMutationHookInterface) {
  const client = useQueryClient();
  return useMutation({
    mutationFn: ({ threadId, tab }: { threadId: string; tab?: string }) =>
      archiveThread(threadId),
    onSuccess: (_, variables) => {
      const threadId = variables?.threadId;
      const tab = variables?.tab;
      if (threadId) {
        client.invalidateQueries(['messageThread', threadId]);
      }
      if (tab) {
        client.invalidateQueries(['messages', tab]);
      }
      props?.onSuccess && props.onSuccess();
    },
  });
}

export function useSendMessage(props?: DefaultMutationHookInterface) {
  const client = useQueryClient();
  return useMutation({
    mutationKey: ['sending-message'],
    mutationFn: (message: MessagePostBody) => sendMessage(message),
    onMutate: async (newValues) => {
      const threadId = newValues.messages?.message_thread_id;

      // Cancel any outgoing refetches
      await client.cancelQueries({ queryKey: ['messageThread', threadId] });
      // Snapshot the previous value
      const previousMessage = client.getQueryData([
        'messageThread',
        threadId,
      ]) as ThreadResponse;
      // Optimistically update to the new value - remove the warning message and add a flag
      client.setQueryData(['messageThread', threadId], () => ({
        ...previousMessage,
        warning_msg: '',
        isOptimistic: true,
      }));
    },
    onSuccess: (_, variables) => {
      client.invalidateQueries(['messages']);

      const threadId = variables?.messages?.message_thread_id;

      if (threadId) {
        client.invalidateQueries(['messageThread', threadId]);
      }
      props?.onSuccess && props.onSuccess();
    },
  });
}

export const useSaveDraft = () => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (message: MessagePostBody) => sendMessage(message),
    onSuccess: (result) => {
      // make sure to invalidate drafts list
      client.invalidateQueries(['messages', undefined, undefined, 'archive']);
      client.invalidateQueries([
        'messageThread',
        (result as DraftOrThreadResponse)?.message_thread_id,
      ]);
    },
  });
};

export const useDiscardMessageDraft = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (draftId: string) =>
      apiClient.messages.discardMessageDraft(draftId),
    onSuccess: () => {
      queryClient.invalidateQueries(['messages']);
    },
  });
};

export function useBulkArchiveMessageThreads(
  props?: DefaultMutationHookInterface
) {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (threadIds: string[]) => bulkArchiveThreads(threadIds),
    onSuccess: (_, threadIds) => {
      if (threadIds) {
        threadIds.forEach((threadId) => {
          client.invalidateQueries(['messageThread', threadId]);
        });
      }

      client.invalidateQueries(['messages']);
      props?.onSuccess && props.onSuccess();
    },
  });
}

export function useUpdateMessageUnreadStatus() {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (postBody: UpdateMessageUnreadStatusPostBody) =>
      updateMessageUnreadStatus(postBody),
    onSuccess: () => {
      client.invalidateQueries(['messages']);
    },
  });
}

export function useToggleMessageIsPinned(
  props?: DefaultMutationHookInterface & { threadId: string }
) {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (messageId: string) => toggleMessageIsPinned(messageId),
    onSuccess: () => {
      client.invalidateQueries(['messageThread', props?.threadId]);
    },
  });
}

export function useToggleThreadIsPinned(
  props?: DefaultMutationHookInterface & { threadId: string }
) {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (messageId: string) => toggleThreadIsPinned(messageId),
    onSuccess: () => {
      client.invalidateQueries(['messages']);
    },
  });
}
