import { createPortal } from 'react-dom';

import { Box, Typography } from '@liscio/ui';

import taxDeliveryImageSrc from '../../../../assets/tax-delivery-image.svg';
import { HelperContentStyled } from '../HelperContent/HelperContent';
import { useTaxDeliveryStore } from 'stores/workflow/useTaxDeliveryStore';

export interface ESignAdditionalSignerProps {
  open: boolean;
}

export const ESignAdditionalSigner: React.FC<ESignAdditionalSignerProps> = ({
  open,
}) => {
  const { mainContentPortalRef } = useTaxDeliveryStore();

  const content = (
    <HelperContentStyled>
      <img
        src={taxDeliveryImageSrc}
        alt="Tax delivery image"
        style={{ width: '70%', maxHeight: '400px', marginBottom: '32px' }}
      />
      <Box textAlign="center">
        <Typography variant="h3">Additional signer required</Typography>
        <Typography variant="body1">
          The document you just signed requires an additional signer. Please
          provide the next signer with access to your device, then click eSign
          to proceed with signing.
        </Typography>
      </Box>
    </HelperContentStyled>
  );

  if (open && mainContentPortalRef && mainContentPortalRef.current) {
    return createPortal(content, mainContentPortalRef.current as Element);
  } else {
    return null;
  }
};

export default ESignAdditionalSigner;
