import React, { useMemo } from 'react';

import { List as MUIList, ListItem, ListItemText } from '@mui/material';

import { AccountBankAccountEntity } from '@liscio/api';
import { Overlay } from '@liscio/common';

import { AccountBankAccountDetailViewMobile, Primary } from './components';
import EditBankAccountForm from './components/BankAccountFormMobile/EditBankAccountForm';
import { useBankAccountOverlays } from '../../view-hooks';
import { ListItemButton } from 'components';

export interface AccountBankAccountsListMobileProps {
  bankAccounts: Array<AccountBankAccountEntity> | void;
}

export const AccountBankAccountsListMobile: React.FC<
  AccountBankAccountsListMobileProps
> = ({ bankAccounts }) => {
  const {
    selectedBankAccount,
    showEditOverlay,
    showDetailOverlay,
    setShowDetailOverlay,
    setShowEditOverlay,
    setSelectedBankAccount,
  } = useBankAccountOverlays();

  const bankAccountInData = useMemo(
    // @TODO Refactor child components to fetch bank accounts individually
    // So we don't have to find the data in the array like so
    // https://liscio.atlassian.net/browse/DEV-3639
    () =>
      bankAccounts?.find(
        (accountBankAccount: AccountBankAccountEntity) =>
          accountBankAccount?.id === selectedBankAccount?.id
      ),
    [selectedBankAccount?.id, bankAccounts]
  );

  if (!bankAccounts) return null;

  function handleBankAccountClick(bankAccount: AccountBankAccountEntity) {
    setSelectedBankAccount(bankAccount);
    setShowDetailOverlay(true);
  }

  function handleEditGoBack() {
    setShowEditOverlay(false);
  }

  function handleDetailGoBack() {
    setSelectedBankAccount(undefined);
    setShowDetailOverlay(false);
  }

  return (
    <>
      <MUIList>
        {bankAccounts.map((bankAccount) => {
          return (
            <ListItem key={bankAccount.id} disablePadding>
              <ListItemButton
                onClick={() => handleBankAccountClick(bankAccount)}
              >
                <ListItemText>
                  <Primary bankAccount={bankAccount} />
                </ListItemText>
              </ListItemButton>
            </ListItem>
          );
        })}
      </MUIList>
      <Overlay open={showDetailOverlay}>
        <AccountBankAccountDetailViewMobile
          bankAccount={bankAccountInData}
          goBack={handleDetailGoBack}
        />
      </Overlay>
      <Overlay open={showEditOverlay}>
        <EditBankAccountForm
          setSelectedBankAccount={setSelectedBankAccount}
          bankAccount={bankAccountInData!}
          goBack={handleEditGoBack}
        />
      </Overlay>
    </>
  );
};

export default AccountBankAccountsListMobile;
