import { TaskActivity, TaskDetails } from '@liscio/api';

const THRESHOLD_IN_MS = 5_000;

interface ActivityData {
  title: string;
  comment: string;
  fileName: string | null;
  fileUrl: string | null;
  date: string;
}

export const getFileName: (
  rawComment: string,
  isAutoGenerated: boolean
) => string = (rawComment, isAutoGenerated) => {
  if (!isAutoGenerated) {
    return '';
  }

  if (
    rawComment.includes('<p>attached file') ||
    rawComment.includes('<p>deleted file')
  ) {
    return rawComment
      .replace('<p>attached file', '')
      .replace('<p>deleted file', '')
      .replace('</p>', '')
      .trim();
  }
  return '';
};

export const getFileUrl: (
  fileName: string,
  isAutoGenerated: boolean,
  activityCreatedAt: number,
  documents?: TaskDetails['documents']
) => string = (
  fileName,
  isAutoGenerated,
  activityCreatedAt,
  documents = []
) => {
  if (!isAutoGenerated) {
    return '';
  }

  const document = documents.find((documentElement) => {
    const timeDeltaInMs = Math.abs(documentElement.created - activityCreatedAt);
    return (
      documentElement.file_name === fileName && timeDeltaInMs < THRESHOLD_IN_MS
    );
  });

  return document ? document.file_download_url : '';
};

export const getTitleAction: (
  activity: TaskActivity,
  fileName?: string
) => string = (activity, fileName = '') => {
  if (!activity.is_auto_generated) {
    return 'added a comment';
  }
  return activity.comment_for_web_display
    .replace('<p>', '')
    .replace('</p>', '')
    .replace(fileName, '')
    .trim();
};

export const getComment: (
  activity: TaskActivity,
  fileName?: string
) => string = (activity) => {
  if (!activity.is_auto_generated) {
    return activity.comment_for_web_display;
  }
  return '';
};

export const extractData: (
  activity: TaskActivity,
  documents?: TaskDetails['documents']
) => ActivityData = (activity, documents) => {
  const fileName = getFileName(
    activity.comment_for_web_display,
    activity.is_auto_generated
  );
  const fileUrl = getFileUrl(
    fileName,
    activity.is_auto_generated,
    activity.created,
    documents
  );
  const titleAuthor = activity.comment_by;
  const titleAction = getTitleAction(activity, fileName);
  const comment = getComment(activity);

  return {
    title: `${titleAuthor} ${titleAction}`,
    comment,
    fileName,
    fileUrl,
    date: activity.tym,
  };
};

export default extractData;
