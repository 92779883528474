import { useCallback } from 'react';

import {
  useDropzone,
  DropzoneOptions,
  DropEvent,
  FileRejection,
} from 'react-dropzone';
import { toast } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';

import { useFileUploadsStore } from './useFileUploadsStore';
import { defaultAllowedUploadMimetypes } from 'components/FileAttachmentsField/accepted-file-types';

export type UseFileUploaderDropZoneProps = {
  /**
   * URL to navigate to on file drop
   */
  url?: string;
  /**
   * Dropzone options
   */
  options?: DropzoneOptions;
};

/**
 * Helper hook for staging files into our global file uploads store
 * via dropzone. Pass an optional url prop to navigate to that url
 * on file drop.
 */
export const useFileUploaderDropZone = ({
  url,
  options,
}: UseFileUploaderDropZoneProps = {}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const addFiles = useFileUploadsStore((store) => store.addFiles);
  const referer = location.pathname !== url ? location.pathname : undefined;

  const onDropAcceptedOption = options?.onDropAccepted;
  const onDropAccepted = useCallback(
    (files: File[], event: DropEvent) => {
      addFiles(files, referer);
      if (onDropAcceptedOption) {
        onDropAcceptedOption(files, event);
      }
      if (url && location.pathname !== url) {
        navigate(url);
      }
    },
    [addFiles, onDropAcceptedOption, navigate, location.pathname, url, referer]
  );

  const onDropRejected = useCallback((fileRejections: FileRejection[]) => {
    const unsupportedFiles = fileRejections.map(
      (rejection) => rejection?.file?.name || ''
    );
    toast.error(`Unsupported Files: ${unsupportedFiles.join(', ')}`);
  }, []);

  const fileUploader = useDropzone({
    accept: defaultAllowedUploadMimetypes,
    ...options,
    onDropAccepted,
    onDropRejected,
  });
  return fileUploader;
};
