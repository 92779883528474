import React from 'react';

import { styled, Stack, Container as BaseContainer } from '@liscio/ui';
import { Outlet } from 'react-router-dom';

import { FOOTER_HEIGHT } from 'modules/authenticate/views/LoginViewDesktop/components/CopyrightFooter/CopyrightFooter';

const Container = styled(BaseContainer)(({ theme }) => ({
  height: '100vh',
  width: '100vw',
}));

const Wrapper = styled(Stack)(() => ({
  overflow: 'auto',
}));

const AuthenticateLayoutDesktop = () => (
  <Container maxWidth={false} disableGutters id="app-scroll-wrapper">
    <Wrapper>
      <Outlet />
    </Wrapper>
  </Container>
);

export default AuthenticateLayoutDesktop;
