import { LiscioAppLayout } from '@liscio/common';

import { PrimaryNavigationMenu } from './PrimaryNavigationMenu';
import { SecondaryNavigationMenu } from './SecondaryNavigationMenu';
import { AppFooter } from 'components/AppFooter/AppFooter';

/**
 * Application layout for authenticated users
 */
export const AppLayout = () => {
  return (
    <LiscioAppLayout
      primaryNav={<PrimaryNavigationMenu />}
      secondaryNav={<SecondaryNavigationMenu />}
      footer={<AppFooter />}
    />
  );
};
