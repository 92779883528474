import { TextField, Theme } from '@mui/material';
import { styled } from '@liscio/ui';

import { PasswordInput } from 'components';

// Custom styled input for auth module views
export const authInputStyle = ({ theme }: { theme: Theme }) => ({
  '& .MuiInputBase-root': {
    color: theme.palette.common.white,

    [theme.breakpoints.up('md')]: {
      color: theme.palette.text.secondary,
    },
  },
});

export const AuthTextField = styled(TextField)(authInputStyle);
export const AuthPasswordInput = styled(PasswordInput)(authInputStyle);
