import React from 'react';

import { useNavigate } from 'react-router-dom';

import { getScrollValue } from '@liscio/utils';

import { SpeedDial as SpeedDialComponent } from 'components';
import {
  MESSAGES_DOMAIN,
  MESSAGES_PATHS,
} from 'modules/messages/route-constants';

export interface NewMessageSpeedDialProps {
  // Optional state to pass to navigate options
  navigateState?: Record<string, any>;
}

const options = [
  {
    label: 'Add',
    value: 'add',
  },
];

export const NewMessageSpeedDial: React.FC<NewMessageSpeedDialProps> = ({
  navigateState,
}) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/${MESSAGES_DOMAIN}${MESSAGES_PATHS.new}`, {
      state: {
        scroll: getScrollValue(),
        ...navigateState,
      },
    });
  };
  return (
    <SpeedDialComponent options={options} onNavigationClick={handleClick} />
  );
};

export default NewMessageSpeedDial;
