import { useFlags } from 'launchdarkly-react-client-sdk';
import { DropzoneOptions } from 'react-dropzone';

import { Box, BoxProps, styled } from '@liscio/ui';

import { useFileUploaderDropZone } from './useFileUploaderDropZone';

export type FileUploadsDropWrapperProps = BoxProps & {
  url?: string;
  options?: DropzoneOptions;
  activeDragComponent?: React.ReactNode;
};

export const ActiveDragOverlay = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
  opacity: 0.5,
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: theme.zIndex.tooltip,
}));

/**
 * Basic global file uploads dropzone wrapper component.
 * - By default, "click to browse" is disabled. Set in options prop to enable.
 * - Only renders when flag enabled
 */
export const FileUploadsDropWrapper = ({
  url,
  options,
  children,
  activeDragComponent = <ActiveDragOverlay />,
  ...boxProps
}: FileUploadsDropWrapperProps) => {
  const { clientFileUploadRedesign } = useFlags();

  const { getRootProps, getInputProps, isDragActive } = useFileUploaderDropZone(
    {
      url,
      options: {
        noClick: true,
        ...options,
      },
    }
  );

  if (clientFileUploadRedesign) {
    return (
      <Box
        data-testid="global-file-drop"
        position="relative"
        display="flex"
        flex={1}
        {...boxProps}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {children}
        {isDragActive && activeDragComponent}
      </Box>
    );
  }

  return children;
};
