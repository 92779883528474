import React from 'react';

import { Stack, Typography, Divider, SearchInput } from '@liscio/ui';

import { Messages } from './components';
import { useMessagesFilters } from 'fetch-utils/messages/messages-filters';

export interface ListViewDesktopProps {}

export const ListViewDesktop: React.FC<ListViewDesktopProps> = () => {
  const { state: filters, updateState: setFilters } = useMessagesFilters();

  return (
    <Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h1">Messages</Typography>
        <SearchInput
          onChange={(e) => {
            setFilters({ searchString: e.target.value });
          }}
        />
      </Stack>
      <Divider />
      <Messages filters={filters} setFilters={setFilters} />
    </Stack>
  );
};

export default ListViewDesktop;
