import { useUrlState } from '@liscio/ui';

export interface MessageSearchURLParams {
  type?: string;
  pageParam?: string;
  searchString?: string;
  perPage?: string;
  accountId?: string;
}

export const defaultMessagesFilters: MessageSearchURLParams = {
  type: 'inbox',
  pageParam: '1',
  searchString: '',
  perPage: '50',
  accountId: '',
};

export const useMessagesFilters = (initialFilters = defaultMessagesFilters) =>
  useUrlState(defaultMessagesFilters);
