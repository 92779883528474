import { useEffect, useState } from 'react';

import { FormControlLabel } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { UploadType } from '@liscio/api';
import { makeDefaultFileSharingMessage } from '@liscio/common';
import { Box, Stack, styled, SubmitButton, useViewportSize } from '@liscio/ui';

import { UploadFilesFormAccordion } from './components';
import {
  UploadFilesFormType,
  validateUploadFilesForm,
} from './UploadFilesForm.types';
import { useFileUploadsAndShare } from './useFileUploadsAndShare';
import { FileUploadContactSelector } from '../ResponsiveSendDocumentFormView/SendDocumentFormViewDesktop/components/SendDocumentFormDesktop/components/FileMessageFormSection/components';
import { MobileFileUploadDrawer } from 'components';
import { FileUploadsList } from 'components/FileUploads';
import {
  useDefaultFileRecipient,
  useHomeData,
} from 'fetch-utils/users/user-hooks';
import { routes } from 'routes';
import { useFileUploaderDropZone } from 'stores/fileUploads';

const StyledForm = styled('form')({
  display: 'flex',
});

const StyledFormControlLabel = styled(FormControlLabel)({
  width: '100%',
  fontWeight: '400',
});

/**
 * Change this back to way it was previously...
 */
export const UploadFilesFormAndShare = () => {
  const { clientFileUploadRedesign } = useFlags();
  const { open: openFileBrowser } = useFileUploaderDropZone();
  const [showMobileFileDrawer, setShowMobileFileDrawer] = useState(false);
  const { isMobile } = useViewportSize();
  const navigate = useNavigate();
  const { files, referer, uploadFiles } = useFileUploadsAndShare();
  const formCtx = useForm<UploadFilesFormType>({
    resolver: validateUploadFilesForm,
  });
  const {
    setValue,
    getValues,
    formState: { touchedFields },
  } = formCtx;
  const defaultParticipant = useDefaultFileRecipient();

  // Sync files to form ctx. Not great, but I think better than managing file validation separately
  useEffect(() => {
    setValue('files', files, { shouldValidate: files.length > 0 });
  }, [files, setValue]);

  // Default participant
  useEffect(() => {
    formCtx.resetField('participants', { defaultValue: defaultParticipant });
  }, [defaultParticipant, formCtx]);

  // Default file uploads message
  const { data } = useHomeData();
  const uname = data?.data.uname;
  useEffect(() => {
    const { message } = getValues();
    if (files.length && (!touchedFields.message || !message)) {
      setValue(
        'message',
        makeDefaultFileSharingMessage(
          files.map(({ file }) => ({ doc_name: file.name })),
          uname
        )
      );
    }
  }, [getValues, setValue, touchedFields, files, uname]);

  const onSubmit = formCtx.handleSubmit((data) => {
    const dataWithMessageAltered = {
      ...data,
      isMessageAltered: touchedFields.message || false,
      // TODO: Is this getting passed along correctly?
      uploadType: UploadType.NewFile,
    };
    uploadFiles(dataWithMessageAltered);
    navigate(referer || routes.filesList.link());
  });

  const handleBrowseClick = () => {
    if (isMobile && clientFileUploadRedesign) {
      setShowMobileFileDrawer(true);
    } else {
      openFileBrowser();
    }
  };

  return (
    <FormProvider {...formCtx}>
      <MobileFileUploadDrawer
        open={showMobileFileDrawer}
        handleClose={() => setShowMobileFileDrawer(false)}
      />
      <StyledForm onSubmit={onSubmit} sx={{ flex: isMobile ? 1 : 0 }}>
        <Stack gap={2} justifyContent="space-between" flex={1} minWidth={0}>
          <Stack gap={2}>
            <StyledFormControlLabel
              data-testid="contact-selector"
              control={<FileUploadContactSelector />}
              label="Share files with:"
              labelPlacement="top"
            />
            <UploadFilesFormAccordion />
            <FileUploadsList onBrowse={handleBrowseClick} />
          </Stack>
          <Box display="flex" justifyContent="flex-end">
            <SubmitButton fullWidth={isMobile}>Upload Files</SubmitButton>
          </Box>
        </Stack>
      </StyledForm>
    </FormProvider>
  );
};
