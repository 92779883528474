import React, { useCallback } from 'react';

import { useFormContext } from 'react-hook-form';

import { useTheme, Stack, Typography, Button } from '@liscio/ui';

import { BiometricsToggle } from './BiometricsToggle/BiometricsToggle';
import EmailAndPasswordFields from './EmailAndPasswordFields/EmailAndPasswordFields';
import OAuthButtonsDesktop from './OAuthButtonsDesktop/OAuthButtonsDesktop';
import { RememberMeToggle } from './RememberMe';
import { Link } from 'components';
import { ErrorDialog } from 'components/ErrorDialog';
import { useForgotPassword } from 'fetch-utils/users/auth-hooks';
import { routes } from 'routes';

export interface SignInFormProps {
  isLoading?: boolean;
}

export const SignInForm = ({ isLoading = false }: SignInFormProps) => {
  const theme = useTheme();
  const {
    control,
    register,
    getValues,
    reset,
    formState: { errors, isSubmitting },
  } = useFormContext();
  const { setForgotPasswordEmail } = useForgotPassword();

  // Update/reset email field when remember me loads
  const updateRememberMeEmailValue = useCallback(
    (email: string) => reset({ email }),
    [reset]
  );

  // Forgot Password Capture
  const captureForgotPasswordEmail = useCallback(() => {
    const email = getValues('email');
    setForgotPasswordEmail(email);
  }, [getValues, setForgotPasswordEmail]);

  return (
    <Stack gap={3} useFlexGap>
      <OAuthButtonsDesktop />
      <Stack gap={5} useFlexGap>
        <EmailAndPasswordFields
          register={register}
          errors={errors}
          isLoggingIn={isSubmitting || isLoading}
        />
        <Stack
          direction="row"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <RememberMeToggle
            name="enableRememberMe"
            resetEmailField={updateRememberMeEmailValue}
            control={control}
          />
          <Link
            onClick={captureForgotPasswordEmail}
            to={routes.forgotPasswordForm.link()}
            underline="none"
            color={theme.palette.primary.main}
          >
            <Typography>Forgot your password?</Typography>
          </Link>
          <BiometricsToggle name="enableBiometrics" control={control} />
        </Stack>
        <Button
          variant="contained"
          type="submit"
          disabled={isSubmitting || isLoading}
          size="large"
        >
          Login
        </Button>
      </Stack>
      <ErrorDialog title="Sign In Error" error={errors.root?.serverError} />
    </Stack>
  );
};
