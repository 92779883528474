import { ErrorResponse } from '@liscio/common';
import { Typography } from '@liscio/ui';

import { ERROR_CODES } from 'constants/errorCodes';
import {
  DASHBOARD_DOMAIN,
  DASHBOARD_PATHS,
} from 'modules/dashboard/route-constants';

export const parseWorkflowRequestError = (errors?: ErrorResponse[] | null) => {
  const error: ErrorResponse | null = errors?.length ? errors[0] : null;
  if (error) {
    const code = error.code;

    switch (code) {
      case ERROR_CODES.WORKFLOW_REQUESTS.REQUEST_RECALLED: {
        return {
          title: 'Request Error',
          message: (
            <>
              <Typography variant="body1" mb={2}>
                The request you attempted to access has been deleted and
                requires no further action.
              </Typography>
              <Typography variant="body1" mb={2}>
                Consider deleting the e-mail you used to access this request to
                avoid potential confusion in the future.
              </Typography>
              <Typography variant="body1" mb={2}>
                Click the button below to return to your homepage and review any
                other outstanding tasks you may have to complete
              </Typography>
            </>
          ),
          navigateTo: `/${DASHBOARD_DOMAIN}${DASHBOARD_PATHS.home}`,
          buttonText: 'RETURN HOME',
        };
      }
      case ERROR_CODES.WORKFLOW_REQUESTS.REQUEST_NOT_FOUND: {
        return {
          title: 'Request Error',
          message: <Typography variant="body1">Request not found</Typography>,
          navigateTo: `/${DASHBOARD_DOMAIN}${DASHBOARD_PATHS.home}`,
          buttonText: 'RETURN HOME',
        };
      }
      case ERROR_CODES.WORKFLOW_REQUESTS.UNAUTHORIZED: {
        return {
          title: 'Request Error',
          message: (
            <Typography variant="body1">
              You are attempting to access a Request for a Restricted Account to
              which you do not have access. If you believe this is in error,
              please contact your Liscio Administrator and request that they add
              you to this Account’s Client Service Team.
            </Typography>
          ),
          navigateTo: `/${DASHBOARD_DOMAIN}${DASHBOARD_PATHS.home}`,
          buttonText: 'RETURN HOME',
        };
      }
      default: {
        return {
          title: 'Request Error',
          message: (
            <Typography variant="body1">
              Sorry, something went wrong with this request
            </Typography>
          ),
          navigateTo: null,
          buttonText: 'CLOSE',
        };
      }
    }
  }

  return {
    title: null,
    message: null,
    navigateTo: null,
    buttonText: null,
  };
};
