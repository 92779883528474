import React from 'react';

import { Skeleton } from '@mui/material';
import { styled, Stack, Typography, Chip } from '@liscio/ui';

export interface StatusBarProps {
  taskTypeKey?: string;
  taskTypeValue?: string;
  status?: string;
  isLoading?: boolean;
}

const StyledStack = styled(Stack)({
  padding: '0 16px',
  height: '38px',
});

const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
}));

const taskColorMapper: { [key: string]: string } = {
  request_info: '#5352ee',
  get_signature: '#62deb4',
  to_do: '#d9c06d',
  virtual_meeting: '#ff7094',
  manage_to_go_items: '#d170ff',
  edoc: '#ff7094',
  default: 'black',
};

export const StatusBar: React.FC<StatusBarProps> = ({
  taskTypeKey,
  taskTypeValue,
  status,
  isLoading,
}) => {
  const taskColor =
    taskColorMapper[taskTypeKey || 'default'] || taskColorMapper['default'];
  return (
    <StyledStack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <>
        <Stack direction="row" gap="6px">
          <Label variant="button">Status:</Label>
          {isLoading && <Skeleton width="50px" />}
          {!isLoading && <Typography variant="button">{status}</Typography>}
        </Stack>
        {isLoading && <Skeleton width="100px" />}
        {!isLoading && (
          <Chip
            sx={{ color: taskColor, borderColor: taskColor }}
            label={taskTypeValue}
            variant="outlined"
          />
        )}
      </>
    </StyledStack>
  );
};

export default StatusBar;
