import React from 'react';

import { AccountBankAccountEntity } from '@liscio/api';
import { styled, Icon, Stack, Typography } from '@liscio/ui';

export interface PrimaryProps {
  bankAccount: AccountBankAccountEntity;
}

const InfoStack = styled(Stack)({
  padding: '0px 0px 0px 10px',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
});

const GreyIcon = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
}));

export const Primary: React.FC<PrimaryProps> = ({ bankAccount }) => {
  return (
    <Stack
      width="100%"
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack direction="row" alignItems="center" flex={1}>
        <InfoStack>
          <Typography>{bankAccount.bank_name}</Typography>
          <Typography color={(theme) => theme.palette.common.neutrals[400]}>
            {bankAccount.account_type}
          </Typography>
        </InfoStack>
      </Stack>
      <GreyIcon>
        <Icon icon="chevronRight" />
      </GreyIcon>
    </Stack>
  );
};

export default Primary;
