import { useState } from 'react';

import {
  AppBar,
  BodyWrapper,
  DetailsState,
  ScreenWrapper,
} from '@liscio/common';
import { MobileConfirmationDrawer, Stack, useTheme } from '@liscio/ui';

import { FileUploadsProgressBatchMeta } from './FileUploadsProgressBatchMeta';
import { FileUploadsProgressFilesList } from './FileUploadsProgressFilesList';
import { useFileUploads } from 'stores/fileUploads/useFileUploads';

export type MobileFileUploadsProgressProps = {
  /**
   * Pass uploadBatchKey to filter uploads by
   */
  uploadBatchKey?: string;
};

/**
 * NOTE: This is a temporary component that will likely undergo
 * additional style and UX changes once designs re completed.
 */
export const MobileFileUploadsProgressOverlay = ({
  uploadBatchKey,
}: MobileFileUploadsProgressProps) => {
  const theme = useTheme();
  const { uploads, deleteUploadBatch, retryUpload, cancelAndDeleteUpload } =
    useFileUploads({ uploadBatchKey });

  // Note: We only allow for a single upload batch at a time in mobile
  const uploadBatch = uploads[0];

  const [showCancelConfirm, setShowCancelConfirm] = useState(false);
  const onNavigateAway = () => setShowCancelConfirm(true);
  const confirmCancelUpload = (batchId: number) => {
    setShowCancelConfirm(false);
    cancelAndDeleteUpload(batchId);
  };

  const onSuccessDone = (batchId: number) => {
    deleteUploadBatch(batchId);
    setShowCancelConfirm(false);
  };

  const onFailureCancel = (batchId: number) => {
    cancelAndDeleteUpload(batchId);
    setShowCancelConfirm(false);
  };
  const onFailureRetry = (batchId: number) => {
    retryUpload(batchId);
    setShowCancelConfirm(false);
  };

  // Render overlay when uploads are present
  if (uploadBatch) {
    return (
      <>
        {/*
         * TODO: There seems to be a conflict when the request question swipe
         * overlay is present when this overlay is open. Perhaps there is a more graceful fix?
         */}
        <ScreenWrapper sx={{ zIndex: theme.zIndex.appBar + 1 }}>
          <AppBar
            title="Files Uploading..."
            DefaultStateElement={DetailsState}
            onNavigationClick={onNavigateAway}
          />
          <BodyWrapper>
            <Stack p={2} gap={2}>
              <FileUploadsProgressBatchMeta uploadBatch={uploadBatch} />
              <FileUploadsProgressFilesList files={uploadBatch.files} />
            </Stack>
          </BodyWrapper>
        </ScreenWrapper>
        {/* Upload Success */}
        <MobileConfirmationDrawer
          type="success"
          title="Upload Completed Successfully!"
          open={uploadBatch.isSuccess}
          onConfirm={() => onSuccessDone(uploadBatch.id)}
          confirmLabel="Done"
        />
        {/* Upload Failure */}
        <MobileConfirmationDrawer
          type="error"
          title="Upload Not Completed"
          open={!!uploadBatch.error && !uploadBatch.isUploadingBatch}
          onCancel={() => onFailureCancel(uploadBatch.id)}
          onConfirm={() => onFailureRetry(uploadBatch.id)}
          confirmLabel="Retry"
        >
          An error occurred and your action couldn't be completed. Please try
          again, and if this keeps happening, contact our support team for help.
        </MobileConfirmationDrawer>
        {/* Cancel Active Upload */}
        <MobileConfirmationDrawer
          type="warning"
          title="Active Uploads in Progress"
          open={showCancelConfirm && uploadBatch.isUploadingBatch}
          cancelLabel="Stay"
          onCancel={() => setShowCancelConfirm(false)}
          confirmLabel="Yes, Cancel"
          onConfirm={() => confirmCancelUpload(uploadBatch.id)}
        >
          There are active uploads in progress. Navigating away from this screen
          will cancel them.
        </MobileConfirmationDrawer>
      </>
    );
  }

  return null;
};
