import React from 'react';

import { ResponsiveView } from '@liscio/ui';

const ThreadDetailsViewMobile = React.lazy(
  () => import('./ThreadDetailsViewMobile/ThreadDetailsViewMobile')
);
const ThreadDetailsViewDesktop = React.lazy(
  () => import('./ThreadDetailsViewDesktop/ThreadDetailsViewDesktopWrapper')
);

export const ResponsiveThreadDetailView = () => (
  <ResponsiveView
    SmallView={<ThreadDetailsViewMobile />}
    LargeView={<ThreadDetailsViewDesktop />}
  />
);

export default ResponsiveThreadDetailView;
