import React, { useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { workflowRequestDocument } from '@liscio/api';
import { ErrorResponse, AppBar, useGraphQLClient } from '@liscio/common';
import { GlobalLoader, ResponsiveView } from '@liscio/ui';

import { parseWorkflowRequestError } from './helpers';
import { TaxDeliveryDesktopView } from '../TaxDeliveryView/TaxDeliveryDesktopView';
import { TaxDeliveryMobileView } from '../TaxDeliveryView/TaxDeliveryMobileView';
import { ErrorDialog } from 'components';
import { useGetWorkflowRequest } from 'fetch-utils/workflow/workflow-hooks';
import { useTaxDeliveryStore } from 'stores/workflow/useTaxDeliveryStore';

const WorkflowViewDesktopView = React.lazy(
  () => import('./WorkflowViewDesktop/WorkflowViewDesktop')
);
const RequestViewMobileFlagSwitch = React.lazy(
  () => import('./RequestViewMobileFlagSwitch')
);

export const WorkflowView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const gqlClient = useGraphQLClient();
  const { setTaxDeliveryData } = useTaxDeliveryStore();

  const {
    data: workflowData,
    error: workflowError,
    isLoading: isWorkflowLoading,
    fetchStatus,
    refetch: refetchWorkflowData,
  } = useGetWorkflowRequest(id);
  gqlClient.useGraphQLWithOptions(
    workflowRequestDocument,
    {
      enabled: !!id,
    },
    {
      id,
    }
  );

  useEffect(() => {
    if (
      workflowData &&
      workflowData?.workflowRequest?.workflow.type === 'TaxDeliveryWorkflow'
    ) {
      setTaxDeliveryData(workflowData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowData]);

  if (isWorkflowLoading && fetchStatus !== 'idle')
    return (
      <>
        <AppBar
          hideSecondaryActionButton
          title="Requests"
          position="relative"
          isLoading
        />
        <GlobalLoader open />
      </>
    );

  if (workflowError) {
    const { title, message, navigateTo, buttonText } =
      parseWorkflowRequestError(workflowError as ErrorResponse[]);

    const handleErrorNavigationClick = () => {
      if (navigateTo) {
        navigate(navigateTo);
      } else {
        return null;
      }
    };
    return (
      <ErrorDialog
        title={title}
        error={workflowError}
        errorContent={message}
        closeLabel={buttonText}
        onClose={handleErrorNavigationClick}
      />
    );
  }

  if (id && workflowData) {
    if (
      workflowData?.workflowRequest?.workflow.type === 'TaxDeliveryWorkflow'
    ) {
      return (
        <ResponsiveView
          SmallView={<TaxDeliveryMobileView />}
          LargeView={<TaxDeliveryDesktopView />}
        />
      );
    } else {
      return (
        <ResponsiveView
          SmallView={
            <RequestViewMobileFlagSwitch
              id={id}
              workflowData={workflowData}
              refetchWorkflowData={refetchWorkflowData}
            />
          }
          LargeView={
            <WorkflowViewDesktopView
              workflowData={workflowData}
              refetchWorkflowData={refetchWorkflowData}
            />
          }
        />
      );
    }
  }
};

export default WorkflowView;
