import React from 'react';

import { Navigate, useParams } from 'react-router-dom';

/**
 * This componet handles redirecting all routes from `/workflows*` to `/requests*`
 */
export const DeprecatedWorkflowsToRequestsRedirect = () => {
  const { '*': splat } = useParams();
  return <Navigate to={`/requests/${splat}`} replace />;
};
