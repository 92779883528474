import { PaymentEstimateObject } from '@liscio/api';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  styled,
} from '@liscio/ui';
import { formatStringDate, parseCentsToDollars } from '@liscio/utils';

const StickyTableCell = styled(TableCell)(({ theme }) => ({
  position: 'sticky',
  right: 0,
  background: `linear-gradient(to right, ${theme.palette.common.white}00 0%, ${theme.palette.common.white} 20%);`,
}));

type TaxDetailsTableProps = {
  data: PaymentEstimateObject[];
};

export const TaxDetailsTable = ({ data = [] }: TaxDetailsTableProps) => {
  const handlePaymentButtonClick = (paymentUrl: string | null | undefined) => {
    if (!paymentUrl) {
      return;
    }
    window.open(paymentUrl, '_blank');
  };

  return (
    <TableContainer sx={{ maxWidth: '80vw' }}>
      <Table aria-label="estimate payments table">
        <TableHead>
          <TableRow>
            <TableCell rowSpan={2} align="center">
              JDX
            </TableCell>
            <TableCell rowSpan={2} align="center">
              Year
            </TableCell>
            <TableCell colSpan={2} align="center">
              Q1
            </TableCell>
            <TableCell colSpan={2} align="center">
              Q2
            </TableCell>
            <TableCell colSpan={2} align="center">
              Q3
            </TableCell>
            <TableCell colSpan={2} align="center">
              Q4
            </TableCell>
            <StickyTableCell rowSpan={2} align="center" />
          </TableRow>
          <TableRow>
            <TableCell align="left">Date</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell align="left">Date</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell align="left">Date</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell align="left">Date</TableCell>
            <TableCell align="right">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((paymentEstimateRow) => (
            <TableRow key={paymentEstimateRow.uuid}>
              <TableCell align="center">
                {paymentEstimateRow.jurisdiction}
              </TableCell>
              <TableCell align="center">
                {paymentEstimateRow.tax_year}
              </TableCell>
              <TableCell align="left">
                {formatStringDate({
                  dateString: paymentEstimateRow?.q1_due_date,
                  defaultPlaceholder: '-',
                  outputFormat: 'MM/dd',
                })}
              </TableCell>
              <TableCell align="right">
                {parseCentsToDollars(
                  paymentEstimateRow.q1_payment_estimate,
                  '-'
                )}
              </TableCell>
              <TableCell align="left">
                {formatStringDate({
                  dateString: paymentEstimateRow?.q2_due_date,
                  defaultPlaceholder: '-',
                  outputFormat: 'MM/dd',
                })}
              </TableCell>
              <TableCell align="right">
                {parseCentsToDollars(
                  paymentEstimateRow.q2_payment_estimate,
                  '-'
                )}
              </TableCell>
              <TableCell align="left">
                {formatStringDate({
                  dateString: paymentEstimateRow?.q3_due_date,
                  defaultPlaceholder: '-',
                  outputFormat: 'MM/dd',
                })}
              </TableCell>
              <TableCell align="right">
                {parseCentsToDollars(
                  paymentEstimateRow.q3_payment_estimate,
                  '-'
                )}
              </TableCell>
              <TableCell align="left">
                {formatStringDate({
                  dateString: paymentEstimateRow?.q4_due_date,
                  defaultPlaceholder: '-',
                  outputFormat: 'MM/dd',
                })}
              </TableCell>
              <TableCell align="right">
                {parseCentsToDollars(
                  paymentEstimateRow.q4_payment_estimate,
                  '-'
                )}
              </TableCell>
              <StickyTableCell>
                {paymentEstimateRow.payment_url && (
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => {
                      handlePaymentButtonClick(paymentEstimateRow?.payment_url);
                    }}
                  >
                    Pay
                  </Button>
                )}
              </StickyTableCell>
            </TableRow>
          ))}
          {data.length === 0 && (
            <TableRow>
              <TableCell colSpan={11} align="center">
                The list is empty
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
