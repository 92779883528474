import request from 'graphql-request';

import { workflowRequestsQueryDocument } from '@liscio/api';
import { WorkflowRequestsQueryQueryVariables } from '@liscio/api/graphql';
import { getAuthSession } from '@liscio/common';

import { config } from 'config';

export const getWorkflowRequests = async (
  params: WorkflowRequestsQueryQueryVariables
) => {
  const session = await getAuthSession();
  const requestHeaders = session?.auth_token
    ? {
        Authorization: session.auth_token,
      }
    : undefined;

  return request({
    url: `${config.api.url}/graphql`,
    document: workflowRequestsQueryDocument,
    variables: {
      statuses: params.statuses,
      page: Number(params.page),
      perPage: Number(params.perPage),
    },
    requestHeaders,
  });
};
