import React, { useEffect } from 'react';

import { Capacitor } from '@capacitor/core';
import { StatusBar } from '@capacitor/status-bar';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';

import {
  ApiClientProvider,
  initGraphQLClient,
  requestMiddleware,
  ScannerProvider,
} from '@liscio/common';

import AppRoutes from './AppRoutes';
import { Userpilot, CustomThemeProvider } from './components';
import { PushNotifications } from 'components/PushNotifications/PushNotifications';
import { config } from 'config';
import apiClient from 'fetch-utils/api-client';
import { GlobalUploadAndShareFileProgress } from 'modules/files/views/UploadFilesPage/components/GlobalUploadAndShareFileProgress';
import LaunchDarklyProvider from 'providers/LaunchDarklyProvider';

import './assets/fonts/fonts.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 15 * 1000,
      staleTime: 10 * 1000,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  useEffect(() => {
    if (Capacitor.getPlatform() === 'android') {
      StatusBar.setBackgroundColor({ color: '#434566' });
      //eslint-disable-next-line
      console.info('Liscio app');
    }
  }, []);

  const graphQLClient = initGraphQLClient(config.api.url, requestMiddleware);

  return (
    <HelmetProvider>
      <LaunchDarklyProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <QueryClientProvider client={queryClient}>
            <ApiClientProvider
              apiClient={apiClient}
              graphQLClient={graphQLClient}
            >
              <CustomThemeProvider>
                <ScannerProvider>
                  <BrowserRouter>
                    <CssBaseline />
                    <Helmet title="Dashboard" />
                    {config.userpilot.isEnabled && <Userpilot />}
                    <PushNotifications />
                    <Toaster
                      position="top-center"
                      containerStyle={{
                        marginTop: 'env(safe-area-inset-top)',
                      }}
                    />
                    <AppRoutes />
                    <GlobalUploadAndShareFileProgress />
                  </BrowserRouter>
                </ScannerProvider>
              </CustomThemeProvider>
            </ApiClientProvider>
          </QueryClientProvider>
        </LocalizationProvider>
      </LaunchDarklyProvider>
    </HelmetProvider>
  );
}

export default App;
