import {
  Box,
  ListItem,
  ProgressBarWithLabel,
  Stack,
  Typography,
  styled,
  FileTypeIcon,
} from '@liscio/ui';
import { readableBytes } from '@liscio/utils';

import { FileUploadItem } from 'stores/fileUploads';

const StyledListItem = styled(ListItem)({
  padding: 0,
});

export type UploadFilesProgressItemProps = {
  fileItem: FileUploadItem;
};
/**
 * File upload progress list item
 */
export const FileUploadsProgressFileListItem = ({
  fileItem,
}: UploadFilesProgressItemProps) => {
  const isError = fileItem.error;

  return (
    <StyledListItem>
      <Stack
        flex={1}
        width="100%"
        padding={(theme) => theme.spacing(1)}
        color={(theme) => (isError ? theme.palette.error.main : undefined)}
        bgcolor={(theme) =>
          isError ? theme.palette.common.error[50] : undefined
        }
        borderRadius={1}
      >
        <Box display="flex" gap={2} alignItems="center" width="100%">
          <FileTypeIcon mimeType={fileItem.file.type} />
          <Typography noWrap overflow="hidden">
            {fileItem.file.name}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1">
            {readableBytes(fileItem.file.size)}
          </Typography>
        </Box>
        <Box flex={1}>
          <ProgressBarWithLabel
            value={Math.round(fileItem.progress * 100)}
            color={isError ? 'error' : 'secondary'}
          />
        </Box>
      </Stack>
    </StyledListItem>
  );
};
