import * as yup from 'yup';

const editBankAccountValidationSchema = yup
  .object({
    bank_name: yup.string().required('Bank Name is a required field.'),
    routing_number: yup
      .string()
      .matches(/^\d{9}$/, {
        message: 'Must be a 9 digit number',
        excludeEmptyString: true,
      })
      .notRequired(),
    encryptedAccountNumber: yup
      .string()
      .matches(/^\d*$/, {
        message: 'Must be a number',
        excludeEmptyString: true,
      })
      .notRequired(),
    account_type: yup.string().notRequired(),
    web_site: yup.string().notRequired(),
    notes: yup.string().nullable().notRequired(),
    is_ach_autopay: yup.boolean().required('Required'),
  })
  .test('oneOfRequired', (values, context) => {
    // eslint-disable-next-line
    const { is_ach_autopay, ...valuesWithoutAchAutopay } = values;

    const hasAtLeastOneAttributeFilled = Object.values(
      valuesWithoutAchAutopay
    ).some(Boolean);

    if (!hasAtLeastOneAttributeFilled) {
      return context.createError({
        path: '',
        message: 'Fill at least one field',
      });
    }
    return true;
  });

export default editBankAccountValidationSchema;
