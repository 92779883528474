import { MouseEventHandler, useEffect, useState } from 'react';

import { useGetApiV5AccountsAccountIdPaymentEstimates } from '@liscio/api';
import { Box, Button, Icon, Stack, Typography, useTheme } from '@liscio/ui';

import { TaxDeliveryEstimateTable } from './TaxDeliveryEstimateTable';
import { ReviewableDocumentActionButton } from '../ReviewableDocument/ReviewableDocumentActionButton';
import { Item, useTaxDeliveryStore } from 'stores/workflow/useTaxDeliveryStore';

type TaxDeliveryEstimateProps = {
  onItemClick: () => void;
  onComplete: () => void;
  itemData: Item;
  assignedAccount: string;
};

export const TaxDeliveryEstimate = ({
  onItemClick,
  onComplete,
  itemData,
  assignedAccount,
}: TaxDeliveryEstimateProps) => {
  const theme = useTheme();

  const { prompt, itemIndex, stepIndex, complete } = itemData;

  const { activeItem, activeStep } = useTaxDeliveryStore();
  const active = itemIndex === activeItem && stepIndex === activeStep;
  const [open, setOpen] = useState(active);
  const [actionButtonDisabled, setActionButtonDisabled] = useState(false);

  const { data, isLoading } =
    useGetApiV5AccountsAccountIdPaymentEstimates(assignedAccount);

  // set the open state when the active prop changes
  useEffect(() => {
    if (complete) {
      setOpen(false);
    } else {
      setOpen(active);
    }
  }, [active, complete]);

  // reset the action button disabled, etc state when the item is closed
  useEffect(() => {
    if (!open) {
      setActionButtonDisabled(false);
    }
  }, [open]);

  const handleReviewButtonClick: MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    event.stopPropagation();
    setActionButtonDisabled(true);
    onItemClick();
  };

  const fontColor = active
    ? theme.palette.text.primary
    : theme.palette.text.secondary;

  return (
    <>
      <Box
        sx={{
          width: '100%',
          padding: '12px',
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: '7px',
          marginBottom: '16px',
          color: fontColor,
          cursor: 'pointer',
        }}
        onClick={() => setOpen(!open)}
      >
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Box>
            <Stack direction="row" spacing={1} alignItems="center">
              {open ? (
                <Icon icon="chevronDown" />
              ) : (
                <Icon icon="chevronRight" />
              )}
              <Typography variant="body1" fontWeight={'normal'}>
                {itemIndex + 1}. {prompt}
              </Typography>
            </Stack>
          </Box>
        </Stack>
        {open && (
          <Stack mt={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleReviewButtonClick}
              disabled={actionButtonDisabled}
            >
              Review
            </Button>
          </Stack>
        )}
      </Box>
      {/* component for main-content-portal-container */}
      {active && assignedAccount && (
        <TaxDeliveryEstimateTable data={data} isLoading={isLoading} />
      )}
      {/* component for action-button-portal-container */}
      {active && (
        <ReviewableDocumentActionButton onClick={() => onComplete()} />
      )}
    </>
  );
};
