import React from 'react';

import { LDProvider } from 'launchdarkly-react-client-sdk';

import { config } from 'config';
import { getCpaSiteUrl } from 'stores/cpa/cpa-utils';

interface LaunchDarklyProviderProps {
  children: React.ReactNode;
}

export default function LaunchDarklyProvider({
  children,
}: LaunchDarklyProviderProps) {
  if (!config.launchDarkly.isEnabled) {
    return <>{children}</>;
  }
  const siteCpaUrl = getCpaSiteUrl();
  const baseContext = {
    kind: 'multi',
    app: {
      key: '@liscio/client',
    },
    platform: {
      key: config.platform,
    },
  };
  const siteCpaContext = siteCpaUrl
    ? {
        firm: {
          key: siteCpaUrl,
          url: siteCpaUrl,
        },
      }
    : {};

  const initialContext = {
    ...baseContext,
    ...siteCpaContext,
  };

  return (
    <LDProvider
      clientSideID={config.launchDarkly.clientId}
      options={{ streaming: true }}
      context={initialContext}
    >
      {children}
    </LDProvider>
  );
}
