import {
  useMutation,
  useInfiniteQuery,
  useQuery,
  useQueryClient,
  useQueries,
} from '@tanstack/react-query';

import {
  CancelControllers,
  FileFiltersType,
  FilePercentsMap,
  SearchDocumentsArgs,
  UploadDocumentConfig,
  UploadNewDocumentsDirectlyPostBody,
} from '@liscio/api';

import {
  calculateDocumentPageStats,
  mungeTags,
  mungeToRecipientTuples,
} from './documentsCallsUtils';
import apiClient from 'fetch-utils/api-client';
import { UploadDocumentFormData } from 'modules/forms/documents-form/useUploadDocumentForm';

const {
  getDocumentDetails,
  getDocumentInfo,
  archiveDocuments,
  updateDocument,
  updateNewDocument,
  sendDocsToS3,
  shareDocumentsByDocId,
  getAccountDocuments,
  deleteDocument,
  getContactDocuments,
  getLiscioVaultDocuments,
  getDocumentDownloadUrls,
} = apiClient.documents;

export interface DefaultUploadHookInterface
  extends DefaultMutationHookInterface {
  taskId?: string;
  fileId?: string;
  isDraft?: boolean;
  onUploadProgress?: (percentMap: FilePercentsMap) => void;
  setCancelController?: (controller: CancelControllers) => void;
}

export function useInfiniteContactDocuments(
  contactId: string,
  filters: SearchDocumentsArgs
) {
  return useInfiniteQuery({
    queryKey: ['files', contactId, filters],
    queryFn: async ({ pageParam = 1 }) => {
      return await getContactDocuments(contactId!, {
        page: pageParam,
        ...filters,
      });
    },
    getNextPageParam: (lastPage, allPages) => {
      const { currentPage, totalPages } = calculateDocumentPageStats(
        lastPage!.response_hash
      );
      if (currentPage === totalPages) return undefined;

      return allPages.length + 1;
    },
    getPreviousPageParam: (firstPage, allPages) => {
      const { currentPage } = calculateDocumentPageStats(
        firstPage!.response_hash
      );

      if (currentPage === 1) {
        return undefined;
      }
      return allPages.length - 1;
    },
  });
}

export function useContactDocuments({
  contactId,
  filters,
}: {
  contactId: string;
  filters: SearchDocumentsArgs & { page: number; perPage?: number };
}) {
  return useQuery({
    queryKey: ['files', contactId, filters],
    queryFn: () => getContactDocuments(contactId!, filters),
  });
}

export function useDocumentUrls(ids: string[]) {
  return useQuery({
    queryKey: ['documents-to-download', ids],
    queryFn: () => getDocumentDownloadUrls(ids),
    enabled: false, // we only want to use this for refetches
  });
}

export function useInfinitiveLiscioVaultDocuments(params: SearchDocumentsArgs) {
  return useInfiniteQuery({
    queryKey: ['files', params],
    queryFn: async ({ pageParam = 1 }) => {
      return await getLiscioVaultDocuments({
        page: pageParam,
        ...params,
      });
    },
    getNextPageParam: (lastPage, allPages) => {
      const { currentPage, totalPages } = calculateDocumentPageStats(
        lastPage!.response_hash
      );
      if (currentPage === totalPages) return undefined;

      return allPages.length + 1;
    },
    getPreviousPageParam: (firstPage, allPages) => {
      const { currentPage } = calculateDocumentPageStats(
        firstPage!.response_hash
      );

      if (currentPage === 1) {
        return undefined;
      }
      return allPages.length - 1;
    },
  });
}

export function useDocumentDetails(id: string, enabled = true) {
  return useQuery({
    queryKey: ['fileDetails', id],
    queryFn: getDocumentDetails,
    enabled: enabled && Boolean(id),
  });
}

export function useMultipleDocumentDetails(ids: string[] | null | undefined) {
  const createQueries =
    Array.isArray(ids) && ids?.length
      ? ids.map((id) => ({
          queryKey: ['workflow-document-details', id],
          queryFn: getDocumentDetails,
          enabled: !!id,
        }))
      : [];

  const rawDocDetails = useQueries({ queries: createQueries });

  const data = rawDocDetails.reduce(
    (acc, doc) => {
      if (doc.isFetched) {
        // if no doc.data then it's an error
        // we want to include an object with error data so the consumer can decide what to do with it
        acc.documents.push(doc.data || { data: { success: false } });
      }
      return acc;
    },
    { documents: [] } as { documents: any[] }
  );

  const isLoading = rawDocDetails.some((doc) => doc.isLoading);
  const isError = rawDocDetails.some((doc) => doc.isError);
  const fetchStatus = isLoading ? 'loading' : 'idle';
  // this is react-query telling us it completed a request(s)
  // whether the actual request returned document details is determined by the doc.data.success property
  const isFetched = rawDocDetails.every((doc) => doc.isFetched);

  return {
    data,
    isLoading,
    isError,
    isFetched,
    fetchStatus,
  };
}

export function useDocumentInfo(ids: string[]) {
  return useQuery({
    queryKey: ['document-info', ...ids],
    queryFn: () => getDocumentInfo(ids),
    enabled: ids.length > 0,
  });
}

export const useAccountDocuments = (
  accountId: string,
  filters?: FileFiltersType
) => {
  return useInfiniteQuery({
    queryKey: ['files', accountId, filters],
    queryFn: async ({ pageParam = 1 }) => {
      const result = await getAccountDocuments(accountId, {
        page: pageParam,
        ...filters,
      });

      return result;
    },
    getNextPageParam: (lastPage, allPages) => {
      const { currentPage, totalPages } = calculateDocumentPageStats(
        lastPage!.response_hash
      );
      if (currentPage === totalPages) return undefined;

      return allPages.length + 1;
    },
    getPreviousPageParam: (firstPage, allPages) => {
      const { currentPage } = calculateDocumentPageStats(
        firstPage!.response_hash
      );

      if (currentPage === 1) {
        return undefined;
      }
      return allPages.length - 1;
    },
  });
};

export function useArchiveDocument(props?: DefaultMutationHookInterface) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: archiveDocuments,
    onSuccess: (res, variables) => {
      queryClient.invalidateQueries({ queryKey: ['files'] });
      queryClient.invalidateQueries([
        'fileDetails',
        String(variables.documentId),
      ]);
      props?.onSuccess && props.onSuccess(res);
    },
  });
}

export function useDeleteDocument(props?: DefaultMutationHookInterface) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteDocument,
    onSuccess: (res, variables) => {
      queryClient.removeQueries({ queryKey: ['files'] });
      queryClient.removeQueries(['fileDetails', String(variables.documentId)]);
      props?.onSuccess && props.onSuccess(res);
    },
  });
}

export function useUpdateDocument(props?: DefaultMutationHookInterface) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateDocument,
    onSuccess: (res) => {
      queryClient.invalidateQueries(['files']);
      queryClient.invalidateQueries(['messageThread']);
      queryClient.invalidateQueries(['fileDetails', String(res.data.id)]);
      props?.onSuccess && props.onSuccess();
    },
  });
}

export function useShareNewDocumentDesktop(props?: DefaultUploadHookInterface) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: UploadNewDocumentsDirectlyPostBody) =>
      shareDocumentsByDocId(params),
    onSuccess: () => {
      queryClient.invalidateQueries(['files']);
      props?.onSuccess && props.onSuccess();
    },
  });
}

export type DocumentFormAndConfig = {
  formValues: UploadDocumentFormData;
  isMessageAltered?: boolean;
};

export function useAttachDocument(props?: DefaultUploadHookInterface) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      doc_ids,
      formValues: { tags, year, month },
    }: DocumentFormAndConfig & { doc_ids: number[] }) =>
      updateNewDocument({
        doc_ids,
        tags: mungeTags(tags),
        is_draft: props?.isDraft !== undefined ? props.isDraft : true,
        year,
        month,
        task_id: props?.taskId ? String(props?.taskId) : '',
      }),
    onSuccess: (res) => {
      queryClient.invalidateQueries(['taskDetails', String(props?.taskId)]);
      props?.onSuccess && props.onSuccess();
    },
  });
}

export function useSendNewDocument(props?: DefaultUploadHookInterface) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      doc_ids,
      formValues: { tags, message, participants },
      isMessageAltered,
    }: DocumentFormAndConfig & { doc_ids: number[] }) => {
      return await shareDocumentsByDocId({
        doc_ids,
        tags: mungeTags(tags),
        message,
        recipients: mungeToRecipientTuples(participants!),
        is_msg_alter: isMessageAltered,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['files']);
      props?.onSuccess && props.onSuccess();
    },
  });
}

export function useUploadDocuments(props?: DefaultUploadHookInterface) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: UploadDocumentConfig) => sendDocsToS3(params),
    onSuccess: () => {
      queryClient.invalidateQueries(['files']);
      props?.onSuccess && props.onSuccess();
    },
  });
}
