import {
  INVOICES_DOMAIN,
  INVOICES_PATHS,
} from 'modules/invoices/route-constants';

export interface AlertConfig {
  label: string;
  urlLabel: string;
  urlAddress: string;
  severity: 'error' | 'info';
}

const FAILED_INVOICES: AlertConfig = {
  label: 'Your payment has failed.',
  urlLabel: 'Update payment now.',
  urlAddress: `/${INVOICES_DOMAIN}${INVOICES_PATHS.list}`,
  severity: 'error',
};

const OVERDUE_INVOICES: AlertConfig = {
  label: 'Your account is overdue.',
  urlLabel: 'Make payment now.',
  urlAddress: `/${INVOICES_DOMAIN}${INVOICES_PATHS.list}`,
  severity: 'error',
};

const PAYMENT_AUTHORIZATION_REQUESTS: AlertConfig = {
  label: 'Authorize payment for new recurring invoices',
  urlLabel: 'Make payment now.',
  urlAddress: `/${INVOICES_DOMAIN}${INVOICES_PATHS.list}?tab=recurring`,
  severity: 'info',
};

export const getAlertConfig: (props: {
  hasFailedInvoices: boolean;
  hasOverdueInvoices: boolean;
  hasPaymentAuthorizationRequests: boolean;
  isAccountOwner: boolean;
}) => AlertConfig | null = ({
  hasFailedInvoices,
  hasOverdueInvoices,
  hasPaymentAuthorizationRequests,
  isAccountOwner,
}) => {
  // Tests are sorted according to business priorities
  if (hasFailedInvoices) {
    return FAILED_INVOICES;
  }
  if (hasOverdueInvoices) {
    return OVERDUE_INVOICES;
  }
  if (hasPaymentAuthorizationRequests && isAccountOwner) {
    return PAYMENT_AUTHORIZATION_REQUESTS;
  }

  return null;
};
