import React, { useMemo } from 'react';

import { SxProps } from '@mui/material';
import { useTheme, Tabs as TabsComponent } from '@liscio/ui';

import { TabType } from '../../Messages';

export interface MessagesTabsProps {
  inboxNumberOfMessages?: number;
  sentNumberOfMessages?: number;
  draftsNumberOfMessages?: number;
  archivedNumberOfMessages?: number;
  pinnedNumberOfMessages?: number;
  allNumberOfMessages?: number;
  selectedOptionValue?: TabType;
  onNavigationClick(key?: TabType): void;
  sx: SxProps;
}

export const MessagesTabs: React.FC<MessagesTabsProps> = ({
  inboxNumberOfMessages,
  sentNumberOfMessages,
  draftsNumberOfMessages,
  pinnedNumberOfMessages,
  archivedNumberOfMessages,
  selectedOptionValue,
  onNavigationClick,
  sx,
}) => {
  const theme = useTheme();
  const options = useMemo(() => {
    const availableOptions = [
      {
        label: 'Inbox',
        value: 'inbox',
        count: inboxNumberOfMessages,
        isCountVisible: true,
        dataTestId: 'filter__inbox',
      },
      {
        label: 'Sent',
        value: 'sent',
        count: sentNumberOfMessages,
        isCountVisible: true,
        dataTestId: 'filter__sent',
      },
      {
        label: 'Drafts',
        value: 'draft',
        count: draftsNumberOfMessages,
        isCountVisible: true,
        dataTestId: 'filter__draft',
      },
      {
        label: 'Archived',
        value: 'archive',
        count: archivedNumberOfMessages,
        isCountVisible: true,
        dataTestId: 'filter__archive',
      },
      {
        label: 'Pinned',
        value: 'pinned',
        count: pinnedNumberOfMessages,
        isCountVisible: true,
        dataTestId: 'filter__pinned',
      },
    ];

    return availableOptions;
  }, [
    inboxNumberOfMessages,
    sentNumberOfMessages,
    draftsNumberOfMessages,
    archivedNumberOfMessages,
  ]);
  return (
    <TabsComponent
      sx={sx}
      options={options}
      selectedOptionValue={
        selectedOptionValue ? String(selectedOptionValue) : undefined
      }
      onNavigationClick={onNavigationClick}
      singleTabSx={{ ...theme.typography.body2 }}
    />
  );
};

export default MessagesTabs;
