import React from 'react';

import { styled, useTheme, Stack } from '@liscio/ui';

const Wrapper = styled(Stack)(() => ({
  marginBottom: '35px',
}));

const ImageWrapper = styled(Stack)({
  paddingTop: '24px',
  paddingBottom: '24px',
  maxWidth: '260px',
  img: {
    width: '100%',
    objectFit: 'contain',
  },
});

export const LoginLogoDesktop = () => {
  const theme = useTheme();
  return (
    <Wrapper flexDirection="row" justifyContent="center" alignItems="center">
      <ImageWrapper>
        <img src={theme?.assets?.logoSplash} alt="logo" />
      </ImageWrapper>
    </Wrapper>
  );
};

export default LoginLogoDesktop;
