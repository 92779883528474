import { makeRouteParamsFn } from '@liscio/utils';

import {
  ACCOUNTS_DOMAIN,
  ACCOUNTS_PATHS,
} from './modules/accounts/route-constants';
import {
  AUTHENTICATE_DOMAIN,
  AUTHENTICATE_PATHS,
} from './modules/authenticate/route-constants';
import {
  DASHBOARD_DOMAIN,
  DASHBOARD_PATHS,
} from './modules/dashboard/route-constants';
import { FILES_DOMAIN, FILES_PATHS } from './modules/files/route-constants';
import {
  INVOICES_DOMAIN,
  INVOICES_PATHS,
} from './modules/invoices/route-constants';
import {
  MESSAGES_DOMAIN,
  MESSAGES_PATHS,
} from './modules/messages/route-constants';
import {
  NOTIFICATIONS_DOMAIN,
  NOTIFICATIONS_PATHS,
} from './modules/notifications/route-constants';
import {
  REQUESTS_PATHS,
  REQUESTS_DOMAIN,
} from './modules/requests/route-constants';
import { TASKS_DOMAIN, TASKS_PATHS } from './modules/tasks/route-constants';
import { PROFILE_DOMAIN, PROFILE_PATHS } from 'modules/profile/route-constants';

// TODO: Centralize all our app route paths here (rather than in our modules) for easier global access
export const ROUTE_PATHS = {
  INVITATION: '/i/:token',
  DASHBOARD: `/${DASHBOARD_DOMAIN}${DASHBOARD_PATHS.home}`,
  MESSAGES_INBOX: `/${MESSAGES_DOMAIN}${MESSAGES_PATHS.list}`,
  TASKS_LIST: `/${TASKS_DOMAIN}${TASKS_PATHS.list}`,
  REQUESTS_LIST_PROTECTED: `/${REQUESTS_DOMAIN}${REQUESTS_PATHS.list}`,
  REQUEST_DETAIL: `/${REQUESTS_DOMAIN}/:id`,
  REQUEST_DETAIL_SECTION: `/${REQUESTS_DOMAIN}/:id/sections/:sectionId`,
  REQUEST_DETAIL_QUESTION: `/${REQUESTS_DOMAIN}/:id/sections/:sectionId/questions/:questionId`,
  ACCOUNTS_LIST: `/${ACCOUNTS_DOMAIN}${ACCOUNTS_PATHS.list}`,
  FILES_LIST: `/${FILES_DOMAIN}${FILES_PATHS.list}`,
  INVOICES_LIST: `/${INVOICES_DOMAIN}${INVOICES_PATHS.list}`,
  NOTIFICATIONS_LIST: `/${NOTIFICATIONS_DOMAIN}${NOTIFICATIONS_PATHS.list}`,
  FILES_NEW: `/${FILES_DOMAIN}${FILES_PATHS.new}`,
  LOGIN: `/${AUTHENTICATE_DOMAIN}${AUTHENTICATE_PATHS.login}`,
  FORGOT_PASSWORD_FORM: `/${AUTHENTICATE_DOMAIN}${AUTHENTICATE_PATHS.forgotPassword}`,
  PROFILE_DETAILS: `/${PROFILE_DOMAIN}${PROFILE_PATHS.details}`,
};

/**
 * Centralized list of route paths with link generators
 * for consistency throughout the app.
 *
 * NOTE: This file is a first step towards flattening our routing
 * structures and removing the hierarchical nature of our routes
 * for flexibility and maintainability moving forward.
 */
export const routes = {
  // Accept invitation route
  invitation: {
    path: ROUTE_PATHS.INVITATION,
    link: makeRouteParamsFn(ROUTE_PATHS.INVITATION, 'token'),
  },
  dashboard: {
    path: ROUTE_PATHS.DASHBOARD,
    link: () => ROUTE_PATHS.DASHBOARD,
  },
  messagesInbox: {
    path: ROUTE_PATHS.MESSAGES_INBOX,
    link: () => ROUTE_PATHS.MESSAGES_INBOX,
  },
  tasksList: {
    path: ROUTE_PATHS.TASKS_LIST,
    link: () => ROUTE_PATHS.TASKS_LIST,
  },
  requestsListProtected: {
    path: ROUTE_PATHS.REQUESTS_LIST_PROTECTED,
    link: () => ROUTE_PATHS.REQUESTS_LIST_PROTECTED,
  },
  requestDetail: {
    path: ROUTE_PATHS.REQUEST_DETAIL,
    link: makeRouteParamsFn(ROUTE_PATHS.REQUEST_DETAIL, 'id'),
  },
  requestDetailSection: {
    path: ROUTE_PATHS.REQUEST_DETAIL_SECTION,
    link: makeRouteParamsFn(
      ROUTE_PATHS.REQUEST_DETAIL_SECTION,
      'id',
      'sectionId'
    ),
  },
  requestDetailQuestion: {
    path: ROUTE_PATHS.REQUEST_DETAIL_QUESTION,
    link: makeRouteParamsFn(
      ROUTE_PATHS.REQUEST_DETAIL_QUESTION,
      'id',
      'sectionId',
      'questionId'
    ),
  },
  accountsList: {
    path: ROUTE_PATHS.ACCOUNTS_LIST,
    link: () => ROUTE_PATHS.ACCOUNTS_LIST,
  },
  filesList: {
    path: ROUTE_PATHS.FILES_LIST,
    link: () => ROUTE_PATHS.FILES_LIST,
  },
  filesNew: {
    path: ROUTE_PATHS.FILES_NEW,
    link: () => ROUTE_PATHS.FILES_NEW,
  },
  invoicesList: {
    path: ROUTE_PATHS.INVOICES_LIST,
    link: () => ROUTE_PATHS.INVOICES_LIST,
  },
  notiicationsList: {
    path: ROUTE_PATHS.NOTIFICATIONS_LIST,
    link: () => ROUTE_PATHS.NOTIFICATIONS_LIST,
  },
  login: {
    path: ROUTE_PATHS.LOGIN,
    link: () => ROUTE_PATHS.LOGIN,
  },
  forgotPasswordForm: {
    path: ROUTE_PATHS.FORGOT_PASSWORD_FORM,
    link: () => ROUTE_PATHS.FORGOT_PASSWORD_FORM,
  },
  profileDetails: {
    path: ROUTE_PATHS.PROFILE_DETAILS,
    link: () => ROUTE_PATHS.PROFILE_DETAILS,
  },
  // TODO: Expand with all other app route paths/links
};
