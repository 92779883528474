import React from 'react';

import { FormControlLabel, TextField } from '@mui/material';
import { styled } from '@liscio/ui';
import { FieldError, useFormContext } from 'react-hook-form';

import { UploadFormType } from '@liscio/api';

import useUploadFormUtils from '../useUploadFormUtils';
import { Select } from 'components';

interface CoreUploadFormSectionProps {
  type: UploadFormType;
  disabled: boolean;
}

const StyledLabel = styled(FormControlLabel)({
  alignItems: 'center',
});

export default function CoreUploadFormSection({
  type,
  disabled,
}: CoreUploadFormSectionProps) {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const {
    handleTagsChange,
    removeTagByIndex,
    setMonthsFilter,
    setYearsFilter,
    setTagsFilter,
    monthsFilter,
    yearsFilter,
    tagsFilter,
    monthsOptions,
    yearsOptions,
    tagsOptions,
  } = useUploadFormUtils();

  const fileName = watch('file_name');
  const isEditView = type === UploadFormType.Edit;
  if (type === UploadFormType.Workflow) {
    return null;
  }

  return (
    <>
      {isEditView && (
        <StyledLabel
          control={
            <TextField
              {...register('file_name')}
              disabled={disabled}
              error={Boolean(errors?.file_name)}
              helperText={errors?.file_name?.message as string}
            />
          }
          label="Filename"
          labelPlacement="top"
          value={fileName}
        />
      )}
      <StyledLabel
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
        control={
          <Select
            disabled={disabled}
            error={errors?.year as FieldError}
            title="Year"
            data={yearsOptions}
            onSearchChange={setYearsFilter}
            onChange={(item) => setValue('year', item?.value || '')}
            searchString={yearsFilter}
          />
        }
        label="Year"
        labelPlacement="top"
        value={watch('year') ? { label: watch('year') } : ''}
      />
      <StyledLabel
        control={
          <Select
            disabled={disabled}
            error={errors?.month as FieldError}
            title="Month"
            data={monthsOptions}
            onSearchChange={setMonthsFilter}
            onChange={(item) => setValue('month', item?.value || '')}
            searchString={monthsFilter}
          />
        }
        label="Month"
        labelPlacement="top"
        value={watch('month') ? { label: watch('month') } : ''}
      />
      {!isEditView && (
        <StyledLabel
          control={
            <Select
              disabled={disabled}
              title="Tags"
              data={tagsOptions}
              error={errors?.tags as FieldError}
              onSearchChange={setTagsFilter}
              onChange={handleTagsChange}
              handleChipClick={removeTagByIndex}
              searchString={tagsFilter}
            />
          }
          label="Tags"
          labelPlacement="top"
          value={watch('tags') || []}
        />
      )}
    </>
  );
}
