import { useFileUploadsAndShare } from '../useFileUploadsAndShare';
import { FileUploadsProgress } from 'components';

/**
 * File progress for uploadAndShare user workflow
 */
export const GlobalUploadAndShareFileProgress = () => {
  const { uploadBatchKey } = useFileUploadsAndShare();
  return <FileUploadsProgress uploadBatchKey={uploadBatchKey} />;
};
