import React, { useState } from 'react';

import { Drawer } from '@mui/material';
import {
  Box,
  Button,
  Divider,
  Icon,
  GlobalLoader,
  IconButton,
  Stack,
  styled,
  Typography,
  useTheme,
} from '@liscio/ui';
import { Link } from 'react-router-dom';

import { DocumentDetail, UploadFormType } from '@liscio/api';
import { downloadDocumentDetailFile, Overlay } from '@liscio/common';

import { FileDetailRecipient } from './components';
import { Loading } from 'components';
import ArchiveFileConfirmationDialog from 'components/ArchiveFileConfirmationDialog/ArchiveFileConfirmationDialog';
import { Label, Row } from 'components/detailedInformation';
import TagList from 'components/TagList/TagList';
import { useProfileData } from 'fetch-utils/users/user-hooks';
import { makeFileSourceLink } from 'modules/files/views/ResponsiveFileDetailView/FileDetailsViewDesktop/helpers/make-file-source-link';
import { makeFormValuesFromDocumentDetails } from 'modules/forms/documents-form/components/UploadForm/uploadDocumentFormUtils';
import UploadDocumentFormView from 'modules/forms/documents-form/UploadDocumentFormViewMobile';
import { fileStatusToasts } from 'utils/toasts';

export interface DetailsDrawerProps {
  file?: DocumentDetail | void;
  open: boolean;
  onClose: () => void;
  closeFilePreviewFunction: () => void;
}

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  zIndex: theme.zIndex.modal + 1,
  '& .MuiDrawer-paper': {
    padding: '16px 12px',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[800],
  },
}));

export const DetailsDrawer: React.FC<DetailsDrawerProps> = ({
  file,
  open,
  onClose,
  closeFilePreviewFunction,
}) => {
  const theme = useTheme();
  const [archiveDialogIsOpen, setArchiveDialogIsOpen] = useState(false);
  const [showUploadForm, setShowUploadForm] = useState(false);
  const [isDownloadingFile, setIsDownloadingFile] = useState(false);
  const { isLoading: isProfileLoading } = useProfileData();

  async function handleDownloadFile() {
    const { onSuccess, onError } = fileStatusToasts(
      file?.doc_name || 'Your file'
    );

    setIsDownloadingFile(true);
    if (file)
      await downloadDocumentDetailFile(file).then(onSuccess).catch(onError);

    setIsDownloadingFile(false);
  }

  const isFileArchived = file?.is_file_archived_by_contact;

  const sourceLink = makeFileSourceLink(file);

  return (
    <>
      <StyledDrawer anchor="bottom" open={open} onClose={onClose}>
        {!file && <Loading />}
        {Boolean(file) && (
          <Stack gap="8px">
            <Stack direction="row" justifyContent="space-between">
              <Row>
                <Icon icon="copy" htmlColor={theme.palette.grey[500]} />
                <Box>
                  <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                    {file?.doc_name}
                  </Typography>
                  <Typography variant="caption">
                    by {file?.uploader?.name} • {file?.date_created}
                  </Typography>
                </Box>
              </Row>
              <IconButton onClick={handleDownloadFile}>
                <Icon icon="download" />
              </IconButton>
            </Stack>
            <Divider />
            <Row>
              <Label>ASSOCIATED WITH</Label>
              <Box>
                {file?.recipient?.map((recipient) => (
                  <FileDetailRecipient
                    key={recipient.name}
                    recipient={recipient}
                  />
                ))}
              </Box>
            </Row>
            <Divider />
            <Row>
              <Label>SOURCE</Label>
              <Typography>
                {file?.link ? (
                  <Link to={sourceLink}>{file?.source}</Link>
                ) : (
                  <Typography>{file?.source}</Typography>
                )}
              </Typography>
            </Row>
            <Row>
              <Label>DATE ADDED</Label>
              <Typography>{file?.date_created}</Typography>
            </Row>
            <Row>
              <Label>YEAR</Label>
              <Typography>{file?.upload_file_year}</Typography>
            </Row>
            <Row>
              <Label>MONTH</Label>
              <Typography>{file?.upload_file_month}</Typography>
            </Row>
            <Row>
              <Label>TAGS</Label>
              <Box>
                <TagList tags={file?.tags || []} />
              </Box>
            </Row>
            <Divider />
            <Box>
              <Button
                startIcon={<Icon icon="edit" />}
                onClick={() => {
                  onClose();
                  setShowUploadForm(true);
                }}
              >
                Edit File Details (Tags, File Name, etc.)
              </Button>
              <Button
                startIcon={<Icon icon="archive" />}
                onClick={() => {
                  onClose();
                  setArchiveDialogIsOpen(true);
                }}
              >
                {isFileArchived ? 'Unarchive File' : 'Archive File'}
              </Button>
              <Button
                startIcon={<Icon icon="download" />}
                onClick={handleDownloadFile}
              >
                Download
              </Button>
            </Box>
          </Stack>
        )}
      </StyledDrawer>
      <ArchiveFileConfirmationDialog
        id={file?.id!}
        open={archiveDialogIsOpen}
        closeFunction={() => setArchiveDialogIsOpen(false)}
        onSuccess={closeFilePreviewFunction}
        isFileArchived={isFileArchived}
      />
      <Overlay open={showUploadForm}>
        <UploadDocumentFormView
          fileId={file?.id ? String(file?.id) : undefined}
          formData={makeFormValuesFromDocumentDetails(file?.id!, file!)}
          goBack={() => {
            setShowUploadForm(false);
          }}
          type={UploadFormType.Edit}
        />
      </Overlay>
      <GlobalLoader open={isProfileLoading || isDownloadingFile} />
    </>
  );
};

export default DetailsDrawer;
