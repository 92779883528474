import { getYear } from 'date-fns';

import makeMidnightTheFifteenth from './make-midnight-the-fifteenth';

const CURRENT_YEAR = getYear(Date.now());

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const MONTH_OPTIONS = MONTHS.map((monthName, monthIndex) => ({
  label: monthName,
  value: makeMidnightTheFifteenth(monthIndex, CURRENT_YEAR),
}));

export default MONTH_OPTIONS;
