import React from 'react';

import { Helmet } from 'react-helmet-async';
import { Route, Routes } from 'react-router-dom';

import { MESSAGES_PAGE_TITLES, MESSAGES_PATHS } from './route-constants';
import {
  ResponsiveThreadDetailsView,
  NewMessageView,
  ResponsiveMessageListView,
} from './views';
import { CatchAllRedirect } from 'modules/CatchAllRedirect/CatchAllRedirect';

export const ModuleRoutes = () => {
  return (
    <>
      <Helmet title={MESSAGES_PAGE_TITLES.list} />
      <Routes>
        <Route
          path={MESSAGES_PATHS.list}
          element={<ResponsiveMessageListView />}
        />
        <Route
          path={`${MESSAGES_PATHS.details}/:id`}
          element={<ResponsiveThreadDetailsView />}
        />
        <Route path={MESSAGES_PATHS.new} element={<NewMessageView />} />
        <Route path="*" element={<CatchAllRedirect />} />
      </Routes>
    </>
  );
};

export default ModuleRoutes;
