import { TodoFeedsQuery } from '@liscio/api/graphql';

import {
  INVOICES_DOMAIN,
  INVOICES_PATHS,
} from 'modules/invoices/route-constants';
import { REQUESTS_DOMAIN } from 'modules/requests/route-constants';
import { TASKS_DOMAIN, TASKS_PATHS } from 'modules/tasks/route-constants';
import { Unpacked } from 'types/utilityTypes';

export const getRedirectUrl: (
  todoFeed: Unpacked<TodoFeedsQuery['todoFeeds']>
) => string | null = (todoFeed) => {
  if (todoFeed?.item?.__typename === 'WorkflowRequest') {
    return `/${REQUESTS_DOMAIN}/${encodeURIComponent(todoFeed.item.id)}`;
  }

  if (todoFeed?.item?.__typename === 'Task') {
    return `/${TASKS_DOMAIN}${TASKS_PATHS.details}/${encodeURIComponent(
      todoFeed.item.legacyId || ''
    )}`;
  }

  if (todoFeed?.item?.__typename === 'Invoice') {
    return `/${INVOICES_DOMAIN}${INVOICES_PATHS.details}/${encodeURIComponent(
      todoFeed.item.legacyId || ''
    )}`;
  }

  return null;
};

export default getRedirectUrl;
