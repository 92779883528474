import { useViewportSize } from '@liscio/ui';
import { useBeforeUnload } from '@liscio/utils';

import { DesktopFileUploadsProgress } from './DesktopFileUploadsProgress';
import { MobileFileUploadsProgressOverlay } from './MobileFileUploadsProgressOverlay';
import { useFileUploads } from 'stores/fileUploads/useFileUploads';

export type FileUploadsProgressProps = {
  /**
   * Pass uploadBatchKey to filter uploads by
   */
  uploadBatchKey?: string;
};

export const FileUploadsProgress = ({
  uploadBatchKey,
}: FileUploadsProgressProps) => {
  const { isMobile } = useViewportSize();
  const { uploadInProgress } = useFileUploads({ uploadBatchKey });

  // Prevent user from accidentally leaving page when uploads in progress
  useBeforeUnload(uploadInProgress);

  if (isMobile) {
    return <MobileFileUploadsProgressOverlay uploadBatchKey={uploadBatchKey} />;
  }

  return <DesktopFileUploadsProgress uploadBatchKey={uploadBatchKey} />;
};
