import React from 'react';

import { Route, Routes } from 'react-router-dom';

import { useDeepLinkNavigation, useLiveUpdates } from '@liscio/common';

import { ModuleRoutes as AccountsModuleRoutes } from './modules/accounts/ModuleRoutes';
import { ModuleRoutes as LoginModuleRoutes } from './modules/authenticate/ModuleRoutes';
import { ModuleRoutes as DashboardModuleRoutes } from './modules/dashboard/ModuleRoutes';
import { ModuleRoutes as FilesModuleRoutes } from './modules/files/ModuleRoutes';
import { ModuleRoutes as InvoicesModuleRoutes } from './modules/invoices/ModuleRoutes';
import { ModuleRoutes as MessagesModuleRoutes } from './modules/messages/ModuleRoutes';
import { ModuleRoutes as NotificationsModulesRoutes } from './modules/notifications/ModuleRoutes';
import { ModuleRoutes as ProfileModulesRoutes } from './modules/profile/ModuleRoutes';
import { DeprecatedWorkflowsToRequestsRedirect } from './modules/requests/DeprecatedWorkflowsToRequestsRedirect';
import { ModuleRoutes as TasksModuleRoutes } from './modules/tasks/ModuleRoutes';
import {
  AuthRoute,
  CpaRoute,
  AcceptedTermsAndConditionsRoute,
  AuthenticatedRedirectRoute,
  LazyWrapper,
} from 'components';
import { AuthUserDispacther } from 'components/AuthUserDispatcher/AuthUserDispatcher';
import { config } from 'config';
import { AppLayout, ResponsiveAuthenticateLayout } from 'layouts';
import { ACCOUNTS_DOMAIN_URL } from 'modules/accounts/route-constants';
import { AUTHENTICATE_DOMAIN_URL } from 'modules/authenticate/route-constants';
import { CatchAllRedirect } from 'modules/CatchAllRedirect/CatchAllRedirect';
import { DASHBOARD_DOMAIN_URL } from 'modules/dashboard/route-constants';
import { ERRORS_DOMAIN, ERRORS_PATHS } from 'modules/errors/route-constants';
import { InternetConnectionProblem } from 'modules/errors/views';
import { FILES_DOMAIN_URL } from 'modules/files/route-constants';
import { INVOICES_DOMAIN_URL } from 'modules/invoices/route-constants';
import { MESSAGES_DOMAIN_URL } from 'modules/messages/route-constants';
import { NOTIFICATIONS_DOMAIN_URL } from 'modules/notifications/route-constants';
import { PROFILE_DOMAIN_URL } from 'modules/profile/route-constants';
import RequestModuleRoutes from 'modules/requests/RequestModuleRoutes';
import {
  REQUESTS_DOMAIN_URL,
  DEPRECATED_WORKFLOWS_DOMAIN,
} from 'modules/requests/route-constants';
import { TASKS_DOMAIN_URL } from 'modules/tasks/route-constants';
import { routes } from 'routes';
import { useCaptureCompanyName } from 'stores/cpa';
import { useClearFileUploadsOnRouteChange } from 'stores/fileUploads';

const AcceptInvitationView = React.lazy(
  () =>
    import('./modules/registration/AcceptInvitationView/AcceptInvitationView')
);

const AppRoutes = () => {
  // Register native app behaviors
  useDeepLinkNavigation({ captureCompany: true });
  useLiveUpdates(config.dev.disableNativeLiveUpdates);

  useClearFileUploadsOnRouteChange([
    routes.filesNew.path,
    routes.requestDetailQuestion.path,
  ]);

  // Capture company_name param from legacy redirect
  useCaptureCompanyName();

  return (
    <Routes>
      <Route path="/authenticate" element={<ResponsiveAuthenticateLayout />}>
        <Route path={AUTHENTICATE_DOMAIN_URL} element={<LoginModuleRoutes />} />
      </Route>
      {/* Require Auth */}
      <Route
        element={<AuthRoute unauthenticatedRedirect="/authenticate/login" />}
      >
        {/* Require accepted terms & conditions */}
        <Route element={<AcceptedTermsAndConditionsRoute />}>
          {/* Require Selected Cpa */}
          <Route element={<CpaRoute />}>
            <Route element={<AuthUserDispacther />}>
              <Route element={<AuthenticatedRedirectRoute />}>
                <Route element={<AppLayout />}>
                  <Route
                    path={DASHBOARD_DOMAIN_URL}
                    element={<DashboardModuleRoutes />}
                  />
                  <Route
                    path={MESSAGES_DOMAIN_URL}
                    element={<MessagesModuleRoutes />}
                  />
                  <Route
                    path={ACCOUNTS_DOMAIN_URL}
                    element={<AccountsModuleRoutes />}
                  />
                  <Route
                    path={REQUESTS_DOMAIN_URL}
                    element={<RequestModuleRoutes />}
                  />
                  <Route
                    path={FILES_DOMAIN_URL}
                    element={<FilesModuleRoutes />}
                  />
                  <Route
                    path={TASKS_DOMAIN_URL}
                    element={<TasksModuleRoutes />}
                  />
                  <Route
                    path={INVOICES_DOMAIN_URL}
                    element={<InvoicesModuleRoutes />}
                  />
                  <Route
                    path={PROFILE_DOMAIN_URL}
                    element={<ProfileModulesRoutes />}
                  />
                  <Route
                    path={NOTIFICATIONS_DOMAIN_URL}
                    element={<NotificationsModulesRoutes />}
                  />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
      {/* Public Routes */}
      <Route
        path={`/${DEPRECATED_WORKFLOWS_DOMAIN}/*`}
        element={<DeprecatedWorkflowsToRequestsRedirect />}
      />
      <Route
        path={`/${ERRORS_DOMAIN}${ERRORS_PATHS.internetConnection}`}
        element={<InternetConnectionProblem />}
      />
      <Route
        path={routes.invitation.path}
        element={
          <LazyWrapper>
            <AcceptInvitationView />
          </LazyWrapper>
        }
      />
      <Route path="*" element={<CatchAllRedirect />} />
    </Routes>
  );
};

export default AppRoutes;
