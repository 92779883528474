import React, { ReactNode } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { Button, ButtonProps, GlobalLoader } from '@liscio/ui';
import { preventBubbling } from '@liscio/utils';

export interface ConfirmationDialogProps {
  open: boolean;
  title: ReactNode;
  content: string;
  confirmLabel?: string;
  cancelLabel?: string;
  disabled?: boolean;
  onConfirm(): void;
  onCancel?(): void;
  isLoading?: boolean;
  confirmBtnProps?: ButtonProps;
  cancelBtnProps?: ButtonProps;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  title,
  content,
  confirmLabel = 'OK',
  cancelLabel = 'Cancel',
  disabled,
  onConfirm,
  onCancel,
  isLoading,
  confirmBtnProps = {},
  cancelBtnProps = {},
}) => (
  <Dialog
    onClick={preventBubbling}
    open={open}
    onClose={onCancel}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {content}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      {onCancel && (
        <Button {...cancelBtnProps} onClick={onCancel} disabled={disabled}>
          {cancelLabel}
        </Button>
      )}
      <Button
        autoFocus
        disabled={disabled}
        data-testid="dialog-confirm"
        variant="contained"
        {...confirmBtnProps}
        onClick={onConfirm}
      >
        {confirmLabel}
      </Button>
    </DialogActions>
    <GlobalLoader open={Boolean(isLoading)} />
  </Dialog>
);

export default ConfirmationDialog;
