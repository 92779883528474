import {
  Icon,
  styled,
  Stack,
  Typography,
  useTheme,
  SwipeableDrawer,
} from '@liscio/ui';

import {
  useFileUploaderDropZone,
  useFileUploaderScanner,
} from 'stores/fileUploads';

type MobileFileUploadDrawerProps = {
  open: boolean;
  handleClose: () => void;
  url?: string;
};

const StyledDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  zIndex: theme.zIndex.modal + 1,
  '& .MuiDrawer-paper': {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.neutrals[500],
    height: '33%',
  },
}));

export function MobileFileUploadDrawer({
  open,
  handleClose,
  url,
}: MobileFileUploadDrawerProps) {
  const theme = useTheme();
  const { getInputProps, open: openFileBrowser } = useFileUploaderDropZone({
    url,
  });
  const { openScanner } = useFileUploaderScanner({
    url,
  });

  const handleScanDocumentClick = () => {
    openScanner();
    handleClose();
  };

  const handleBrowseFilesClick = () => {
    openFileBrowser();
    handleClose();
  };

  return (
    <StyledDrawer
      anchor="bottom"
      open={open}
      onClose={handleClose}
      onOpen={() => {}}
    >
      <Stack p={2}>
        <Typography>Upload Files</Typography>
      </Stack>
      <Stack
        p={2}
        gap={3}
        sx={{
          backgroundColor: theme.palette.common.tertiary[500],
          color: theme.palette.common.neutrals[100],
          height: '100%',
        }}
      >
        <Stack direction="row" onClick={handleScanDocumentClick}>
          <Icon icon="camera" />
          <Typography ml={4}>Scan Document</Typography>
        </Stack>
        <Stack direction="row" onClick={handleBrowseFilesClick}>
          <Icon icon="uploadFile" />
          <Typography ml={4}>Browse Files</Typography>
          <input data-testid="upload-file-input" {...getInputProps()} />
        </Stack>
      </Stack>
    </StyledDrawer>
  );
}
