import { CircularProgress, Toolbar } from '@mui/material';
import toast from 'react-hot-toast';

import { WorkflowRequestStatus } from '@liscio/api/graphql';
import { AppBar, Overlay } from '@liscio/common';
import { Box, Button, Stack } from '@liscio/ui';

import {
  CompletedMessage,
  IncompletedMessage,
} from '../ResponseSubmitDialog/Messages';
import {
  useGetCompletionValues,
  useUpdateWorkflowRequest,
} from 'fetch-utils/workflow/workflow-hooks';
import { MessageState } from 'modules/messages/views/ThreadDetailsViewMobile/components';
import { useTaxDeliveryStore } from 'stores/workflow/useTaxDeliveryStore';

type Props = {
  open: boolean;
  onCancel: () => void;
  onSubmitted?: () => void;
};

export const ResponseSubmitOverlay = ({
  open,
  onCancel,
  onSubmitted,
}: Props) => {
  const {
    taxDeliveryData: { workflowRequestId },
    allStepsComplete,
  } = useTaxDeliveryStore();
  const { mutate: workflowRequestMutation } = useUpdateWorkflowRequest();

  const {
    data: completionValues,
    isLoading,
    remove,
  } = useGetCompletionValues(workflowRequestId || '', open);

  const anyCompletion =
    allStepsComplete ||
    (completionValues && completionValues.total === completionValues.completed);

  const submitRequest = async () => {
    workflowRequestMutation(
      {
        workflowRequestId,
        status: WorkflowRequestStatus.Submitted,
      },
      {
        onSettled: (response) => {
          if (response?.updateWorkflowRequest?.errors?.length) {
            toast.error('Error submitting request');
            return;
          } else {
            toast.success('Request submitted');
            onSubmitted?.();
          }
        },
      }
    );
  };

  const handleOnCancel = () => {
    remove();
    onCancel();
  };

  let dialogContent = null;
  if (isLoading) {
    dialogContent = (
      <Stack mt={2} direction="row" justifyContent="center">
        <CircularProgress color="primary" />
      </Stack>
    );
  } else if (anyCompletion) {
    dialogContent = <CompletedMessage />;
  } else {
    dialogContent = <IncompletedMessage />;
  }

  return (
    <Overlay open={open}>
      <AppBar
        onNavigationClick={handleOnCancel}
        title="Confirm submission"
        hideSecondaryActionButton
        DefaultStateElement={MessageState}
      />
      <Box padding="1.5rem">{dialogContent} </Box>
      <Box
        position="fixed"
        display="flex"
        justifyContent="end"
        sx={{
          top: 'auto',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid rgba(0, 0, 0, 0.12);',
        }}
      >
        <Toolbar>
          <Stack direction="row" gap={2}>
            <Button variant="outlined" onClick={handleOnCancel}>
              Cancel
            </Button>
            <Button
              disabled={!anyCompletion}
              variant="contained"
              onClick={submitRequest}
            >
              Submit
            </Button>
          </Stack>
        </Toolbar>
      </Box>
    </Overlay>
  );
};
