import { Capacitor } from '@capacitor/core';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';

interface StatusToasts {
  onSuccess: () => void;
  onError: (err: AxiosError) => void;
}

export const fileStatusToasts = (fileName: string): StatusToasts => {
  const platform = Capacitor.getPlatform();
  let extraInstructions = '';
  if (platform === 'ios') {
    extraInstructions =
      'You can find your file in the  "Files" app. Either search for it by name or open the Liscio folder in the "On my iPhone" section.';
  } else if (platform === 'android') {
    extraInstructions =
      'You can find your file in the  "My Files" app in the "Recent Downloads" section.';
  }

  return {
    onSuccess: () =>
      toast.success(`${fileName} downloaded. ${extraInstructions}`),
    onError: (err) =>
      toast.error(`There was a problem downloading ${fileName}.`),
  };
};

export function launchInvalidFileToast(invalidFile: File) {
  toast.error(
    `Could not upload ${invalidFile.name} because ${invalidFile.type} is not a supported filetype.`
  );
}
