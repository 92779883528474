import React from 'react';

import { useParams } from 'react-router-dom';

import BankAccountFormMobile from './BankAccountFormMobile';
import { useSendUpdateNotification } from 'fetch-utils/accounts/accounts-hooks';
import { useSubmitCreateAccountSingleBankAccount } from 'modules/accounts/hooks';

interface CreateBankAccountFormProps {
  goBack: () => void;
}

export default function CreateBankAccountForm({
  goBack,
}: CreateBankAccountFormProps) {
  const { id: accountId } = useParams();
  const { submitHandler, isLoading: isBankAccountCreating } =
    useSubmitCreateAccountSingleBankAccount({
      onSuccess: () => {
        sendUpdateNotification(accountId!);
        goBack();
      },
      accountId,
    });
  const { mutateAsync: sendUpdateNotification } = useSendUpdateNotification();

  return (
    <BankAccountFormMobile
      goBack={goBack}
      isProcessing={isBankAccountCreating}
      onSubmit={submitHandler}
      title="Create New Bank Account"
      buttonText="Create"
    />
  );
}
