import React from 'react';

import { useBankAccountOverlays } from '../../../../view-hooks';
import { SpeedDial as SpeedDialComponent } from 'components';

export interface SpeedDialProps {}

const options = [
  {
    label: 'Add',
    value: 'add',
  },
];

export const SpeedDial: React.FC<SpeedDialProps> = () => {
  const setShowCreateOverlay = useBankAccountOverlays(
    (state) => state.setShowCreateOverlay
  );

  return (
    <SpeedDialComponent
      options={options}
      onNavigationClick={() => setShowCreateOverlay(true)}
    />
  );
};

export default SpeedDial;
