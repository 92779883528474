import { CSTAccount, CstMemberEntity } from '@liscio/api';

import { RecipientAccount } from 'components/ContactSelector/ContactSelector';



// - If the client has more than one account, the form should hydrate nothing
// - If the client has exactly one account, then
//      - If the account has a CST, populate the contact selector with that CST
//      - If the account has no CST, populate the contact selector with the firm's Default Message Recipient
const makeDefaultParticipants = ({
  accounts,
  defaultRecipient,
}: {
  accounts?: Array<CSTAccount>;
  defaultRecipient?: CstMemberEntity;
}): RecipientAccount[] => {
  if (!accounts) return [];

  if (accounts?.length === 1) {
    const theClientsOnlyAccount = accounts[0];
    const clientServiceTeam = theClientsOnlyAccount.cst_member;

    if (clientServiceTeam.length > 0) {
      const mungedCST = clientServiceTeam.map((cstMember) => ({
        recipient: cstMember,
        account: theClientsOnlyAccount,
      }));

      return mungedCST;
    } else if (defaultRecipient) {
      return [
        {
          recipient: defaultRecipient,
          account: theClientsOnlyAccount,
        },
      ];
    }
  }

  return [];
};

export default makeDefaultParticipants;
