import { useNavigate } from 'react-router-dom';

import { AppBar, DetailsState } from '@liscio/common';
import { Divider, Stack, Typography, useViewportSize } from '@liscio/ui';

import { UploadFilesFormAndShare } from './UploadFilesFormAndShare';
import { useFileUploadsAndShare } from './useFileUploadsAndShare';
import { ScreenWrapper } from 'components/screenComposition';
import { routes } from 'routes';
import { FileUploadsDropWrapper } from 'stores/fileUploads';

export const UploadFilesPage = () => {
  const { isMobile } = useViewportSize();
  const { referer } = useFileUploadsAndShare();
  const navigate = useNavigate();
  const goBack = () => navigate(referer || routes.filesList.link());

  return (
    <ScreenWrapper overflow="auto">
      <FileUploadsDropWrapper url={routes.filesNew.link()}>
        <Stack flex={1} minWidth={0}>
          {isMobile ? (
            <AppBar
              onNavigationClick={goBack}
              title="Upload Files"
              DefaultStateElement={DetailsState}
              hideSecondaryActionButton
            />
          ) : (
            <>
              <Typography variant="h1">Upload Files</Typography>
              <Divider />
            </>
          )}
          <Stack p={[2, 4]} flex={1}>
            <UploadFilesFormAndShare />
          </Stack>
        </Stack>
      </FileUploadsDropWrapper>
    </ScreenWrapper>
  );
};
