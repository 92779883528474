import { createPortal } from 'react-dom';

import { Button } from '@liscio/ui';

import { useTaxDeliveryStore } from 'stores/workflow/useTaxDeliveryStore';

type ReviewableDocumentActionButtonProps = {
  disabled?: boolean;
  onClick: () => void;
};

export const ReviewableDocumentActionButton = ({
  disabled = false,
  onClick,
}: ReviewableDocumentActionButtonProps) => {
  const { actionButtonPortalRef } = useTaxDeliveryStore();

  const handleOnClick = () => {
    onClick();
  };

  if (actionButtonPortalRef && actionButtonPortalRef.current) {
    return createPortal(
      <Button
        disabled={disabled}
        variant="contained"
        fullWidth
        onClick={handleOnClick}
      >
        Approve
      </Button>,
      actionButtonPortalRef.current as Element
    );
  }
};
