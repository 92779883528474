import React, { useMemo } from 'react';

import { FormControlLabel } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { Select } from 'components';
import UPLOADER_TYPE_OPTIONS from 'constants/uploaderTypeOptions';

export interface UploaderTypeFormProps {}

export const UploaderTypeForm: React.FC<UploaderTypeFormProps> = () => {
  const { setValue, watch } = useFormContext();

  const value = watch('file_uploaded_by');
  const selectedOption = useMemo(
    () => UPLOADER_TYPE_OPTIONS.find((item) => item.value === value),
    [value]
  );

  return (
    <>
      <FormControlLabel
        control={
          <Select
            title="File Uploaded By"
            data={UPLOADER_TYPE_OPTIONS}
            onSearchChange={() => {}}
            onChange={(item) => setValue('file_uploaded_by', item?.value)}
          />
        }
        label=""
        labelPlacement="top"
        value={selectedOption}
      />
    </>
  );
};

export default UploaderTypeForm;
