import { useState } from 'react';

import {
  Accordion,
  Box,
  Button,
  Card,
  Icon,
  ProgressBarWithLabel,
  Stack,
  styled,
} from '@liscio/ui';

import { FileUploadsProgressBatchMeta } from './FileUploadsProgressBatchMeta';
import { FileUploadsProgressFilesList } from './FileUploadsProgressFilesList';
import { FileUploadsProgressHeading } from './FileUploadsProgressHeading';
import { useFileUploads } from 'stores/fileUploads/useFileUploads';

const StyledPositioningBox = styled(Stack)({
  position: 'fixed',
  right: 32,
  bottom: 32,
  width: 320,
});

export type DesktopFileUploadsProgressProps = {
  /**
   * Pass uploadBatchKey to filter uploads by
   */
  uploadBatchKey?: string;
};

/**
 * NOTE: This is a temporary component that will likely undergo
 * additional style and UX changes once designs are completed.
 */
export const DesktopFileUploadsProgress = ({
  uploadBatchKey,
}: DesktopFileUploadsProgressProps) => {
  const [expanded, setExpanded] = useState<number | undefined>(undefined);
  const { uploads, deleteUploadBatch, retryUpload, cancelAndDeleteUpload } =
    useFileUploads({ uploadBatchKey });

  const toggleExpanded = (batchId: number) => {
    if (expanded === batchId) {
      setExpanded(undefined);
    } else {
      setExpanded(batchId);
    }
  };

  return (
    <StyledPositioningBox gap={2}>
      {uploads.map((uploadBatch) => (
        <Card key={uploadBatch.id} elevation={3}>
          <Box>
            <Accordion
              expanded={expanded === uploadBatch.id}
              onClick={() => toggleExpanded(uploadBatch.id)}
              summary={
                <Box px={2} pt={1} pb={0}>
                  <FileUploadsProgressHeading uploadBatch={uploadBatch} />
                  <FileUploadsProgressBatchMeta uploadBatch={uploadBatch} />
                </Box>
              }
              square={false}
              summaryProps={{
                sx: {
                  border: 'none',
                  pr: 2,
                  '& .MuiAccordionSummary-content': { margin: 0 },
                },
              }}
              detailsProps={{
                sx: (theme) => ({
                  maxHeight: `calc(100vh - ${theme.spacing(24)})`,
                  overflow: 'auto',
                  border: 'none',
                }),
              }}
            >
              <FileUploadsProgressFilesList files={uploadBatch.files} />
            </Accordion>
            {/* Display overall progress when collapsed */}
            {expanded !== uploadBatch.id && (
              <Box p={2} pt={0} pb={1}>
                <ProgressBarWithLabel
                  value={Math.round(uploadBatch.progress * 100)}
                />
              </Box>
            )}
            {/* Success on complete */}
            {uploadBatch.isSuccess && (
              <Box px={2} pb={1}>
                <Button
                  onClick={() => deleteUploadBatch(uploadBatch.id)}
                  variant="outlined"
                  fullWidth
                >
                  Close
                </Button>
              </Box>
            )}
            {/* Cancel/Retry on Failure */}
            {uploadBatch.error && (
              <Stack px={2} pb={1} direction="row" gap={2}>
                <Box flex={1}>
                  <Button
                    variant="outlined"
                    fullWidth
                    size="small"
                    onClick={() => cancelAndDeleteUpload(uploadBatch.id)}
                  >
                    Cancel
                  </Button>
                </Box>
                <Box flex={1}>
                  <Button
                    variant="contained"
                    fullWidth
                    startIcon={<Icon icon="retry" />}
                    size="small"
                    color="error"
                    onClick={() => retryUpload(uploadBatch.id)}
                  >
                    Retry
                  </Button>
                </Box>
              </Stack>
            )}
          </Box>
        </Card>
      ))}
    </StyledPositioningBox>
  );
};
