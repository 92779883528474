import React from 'react';

import { ResponsiveView } from '@liscio/ui';

const NewMessageViewMobile = React.lazy(
  () => import('./NewMessageViewMobileWrapper')
);
// Lazy load desktop view
const NewMessageViewDesktop = React.lazy(
  () => import('./NewMessageViewDesktop')
);

export const NewMessageView = () => {
  return (
    <ResponsiveView
      SmallView={<NewMessageViewMobile />}
      LargeView={<NewMessageViewDesktop />}
    />
  );
};

export default NewMessageView;
