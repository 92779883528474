import React, { useState } from 'react';

import { Icon, IconButton, styled, Stack, Typography } from '@liscio/ui';
import { useLocation, useNavigate } from 'react-router-dom';

import { FilePreview } from 'components/FilePreview';
import { useIsDesktopView } from 'custom-hooks';
import { FILES_DOMAIN, FILES_PATHS } from 'modules/files/route-constants';

export interface ResponsivePreviewFileButtonProps {
  selectedFileId: string;
  label?: string;
}

const Wrapper = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer',
});

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
  textDecoration: 'none',
}));

const PaddingText = styled(Typography)({
  padding: '0px 0px 5px 30px',
});

export const ResponsivePreviewFileButton: React.FC<
  ResponsivePreviewFileButtonProps
> = ({ selectedFileId, label = 'Preview File' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const isDesktopView = useIsDesktopView();
  const location = useLocation();

  const handleClick = () => {
    if (isDesktopView) {
      navigate(`/${FILES_DOMAIN}${FILES_PATHS.details}/${selectedFileId}`, {
        state: {
          goBackUrl: location.pathname,
        },
      });
    } else {
      setIsOpen(true);
    }
  };
  return (
    <>
      <Wrapper role="button" onClick={handleClick}>
        <IconButton>
          <GreyText>
            <Icon icon="preview" />
          </GreyText>
        </IconButton>
        <PaddingText>{label}</PaddingText>
      </Wrapper>
      <FilePreview
        documentId={selectedFileId || ''}
        closeFunction={() => setIsOpen(false)}
        open={isOpen}
      />
    </>
  );
};

export default ResponsivePreviewFileButton;
