import { useMemo } from 'react';

import { useController } from 'react-hook-form';

import { AutocompleteField, AutocompleteFieldProps } from '@liscio/ui';

import { useHomeData } from 'fetch-utils/users/user-hooks';

export type DocumentMonthFieldProps = Omit<
  AutocompleteFieldProps<string, false>,
  'multiple' | 'options' | 'loading'
> & {
  placeholder?: string;
};

/**
 * Upload Document Month Select Field
 */
export const DocumentMonthField = ({
  label = 'Month',
  placeholder,
  ...rest
}: DocumentMonthFieldProps) => {
  const { isLoading, data } = useHomeData();
  const monthOptions = useMemo(() => {
    if (data?.data?.pref_type_options?.upload_file_month) {
      return data.data.pref_type_options.upload_file_month.map((opt) =>
        String(opt.value)
      );
    }
    return [];
  }, [data?.data?.pref_type_options?.upload_file_month]);

  return (
    <AutocompleteField
      placeholder={placeholder}
      label={label}
      loading={isLoading}
      options={monthOptions}
      {...rest}
    />
  );
};

export type FormDocumentMonthFieldProps = Omit<
  DocumentMonthFieldProps,
  'onChange' | 'name'
> & {
  name: string;
};

export const FormDocumentMonthField = (props: FormDocumentMonthFieldProps) => {
  const { field, fieldState } = useController({ name: props.name });

  return (
    <DocumentMonthField
      {...props}
      value={field.value || null}
      onChange={(_e, value) => field.onChange(value)}
      error={fieldState.error?.message}
    />
  );
};
