import React, { useState, useRef } from 'react';

import { Dialog } from '@mui/material';

import { ClosePaymentDialogReasons, PaymentMethods } from '@liscio/api';
import { errorHandledByMutation, makeFormattedToday } from '@liscio/utils';

import {
  InitialLoadingState,
  SelectPaymentMethodState,
  SelectBankAccountState,
  PlaidPaymentProcessingState,
  StripePaymentProcessingState,
  OpenStripeState,
  StripeCardRegistrationState,
  PlaidAddingBankAccountState,
} from './components';
import { getSuccessUrl } from './helpers';
import {
  useProcessPlaidPayment,
  useRegisterPaymentSourceSession,
  useRegisterStripePaymentSession,
  useAddCustomerBankAccount,
} from 'fetch-utils/invoices/invoices-hooks';
import { useTaskDetails } from 'fetch-utils/tasks/tasks-hooks';
import {
  INVOICES_DOMAIN,
  INVOICES_PATHS,
} from 'modules/invoices/route-constants';
import { useRudderTrack } from 'utils/analytics';

export enum PayInvoiceHandlerStates {
  INITIAL_LOADING = 'INITIAL_LOADING',
  SELECT_PAYMENT_METHOD = 'SELECT_PAYMENT_METHOD',
  SELECT_BANK_ACCOUNT_ACH_METHOD = 'SELECT_BANK_ACCOUNT_ACH_METHOD',
  STRIPE_PAYMENT_PROCESSING = 'STRIPE_PAYMENT_PROCESSING',
  STRIPE_PAYMENT_PROCESSED = 'STRIPE_PAYMENT_PROCESSED',
  STRIPE_CARD_REGISTRATION_PROCESSING = 'STRIPE_CARD_REGISTRATION_PROCESSING',
  STRIPE_CARD_REGISTRATION_PROCESSED = 'STRIPE_CARD_REGISTRATION_PROCESSED',
  PLAID_PAYMENT_PROCESSING = 'PLAID_PAYMENT_PROCESSING',
  PLAID_ADDING_BANK_ACCOUNT = 'PLAID_ADDING_BANK_ACCOUNT',
  CLOSED = 'CLOSED',
}

export interface StateData {
  selectedBankAccountIdToPay: string | null;
}

export interface PayInvoiceHandlerV2Props {
  invoiceId: string;
  open?: boolean;
  closeFunction(closeReason: ClosePaymentDialogReasons): void;
  redirectUrl?: string;
}

export const PayInvoiceHandlerV2: React.FC<PayInvoiceHandlerV2Props> = ({
  invoiceId,
  closeFunction,
  redirectUrl,
}) => {
  /**
   * Component State
   */
  const rudderTrack = useRudderTrack();
  const [currentState, setCurrentState] = useState<PayInvoiceHandlerStates>(
    PayInvoiceHandlerStates.INITIAL_LOADING
  );
  const [stateData, setStateData] = useState<StateData>({
    selectedBankAccountIdToPay: null,
  });
  const hasAlreadySentPlaidPaymentRef = useRef(false);
  const hasAlreadyStripePaymentRegisteredRef = useRef(false);
  const hasAlreadyStipeCardRegisteredRef = useRef(false);
  const hasAlreadyBankAccountRegisteredRef = useRef(false);

  /**
   * Queries
   */
  const { data: invoice } = useTaskDetails(invoiceId);

  /**
   * Mutations
   */
  const { mutateAsync: processPlaidPayment, error: processPlaidPaymentError } =
    useProcessPlaidPayment({
      onSuccess: () => {
        closeFunction(ClosePaymentDialogReasons.PAYMENT_PROCEEDED);
      },
    });
  const {
    mutateAsync: registerStripePaymentSession,
    data: stripeRegisteredPaymentSession,
    error: registerStripePaymentSessionError,
  } = useRegisterStripePaymentSession();
  const {
    mutateAsync: registerPaymentSourceSession,
    data: registeredPaymentSourceSession,
    error: registerPaymentSourceSessionError,
  } = useRegisterPaymentSourceSession();
  const { mutateAsync: addCustomerBankAccount } = useAddCustomerBankAccount();

  /**
   * Render current state
   */
  if (currentState === PayInvoiceHandlerStates.INITIAL_LOADING) {
    return (
      <InitialLoadingState
        invoice={invoice}
        setCurrentState={setCurrentState}
      />
    );
  }

  if (currentState === PayInvoiceHandlerStates.PLAID_PAYMENT_PROCESSING) {
    if (!hasAlreadySentPlaidPaymentRef.current) {
      hasAlreadySentPlaidPaymentRef.current = true;

      const parentInvoiceId = invoice?.parent_task_id;
      const invoiceId = invoice?.id;
      const selectedBankAccountIdToPay = stateData.selectedBankAccountIdToPay;
      if ((parentInvoiceId || invoiceId) && selectedBankAccountIdToPay) {
        hasAlreadySentPlaidPaymentRef.current = true;
        rudderTrack('Paying invoice', {
          invoice_amount: invoice?.invoice_amount,
          invoice_status: invoice?.status,
          invoice_due_date: invoice?.due_date,
          invoice_sent_date: invoice?.invoice_date,
          invoice_completed_date: makeFormattedToday(),
        });

        processPlaidPayment({
          invoiceId: parentInvoiceId || invoiceId || '',
          sourceId: selectedBankAccountIdToPay,
        }).catch(errorHandledByMutation);
      }
    }
    return (
      <PlaidPaymentProcessingState
        processPlaidPaymentError={processPlaidPaymentError as Error}
        closeFunction={closeFunction}
      />
    );
  }

  if (currentState === PayInvoiceHandlerStates.STRIPE_PAYMENT_PROCESSING) {
    if (!hasAlreadyStripePaymentRegisteredRef.current) {
      hasAlreadyStripePaymentRegisteredRef.current = true;
      rudderTrack('Paying invoice', {
        invoice_amount: invoice?.invoice_amount,
        invoice_status: invoice?.status,
        invoice_due_date: invoice?.due_date,
        invoice_sent_date: invoice?.invoice_date,
        invoice_completed_date: makeFormattedToday(),
      });

      registerStripePaymentSession({
        invoiceId,
        successUrl: getSuccessUrl(
          `${INVOICES_DOMAIN}${INVOICES_PATHS.paymentProcessing}`,
          invoiceId,
          redirectUrl
        ),
      }).catch(errorHandledByMutation);
    }

    return (
      <StripePaymentProcessingState
        closeFunction={closeFunction}
        setCurrentState={setCurrentState}
        registerStripePaymentSessionError={
          registerStripePaymentSessionError as Error
        }
        stripeRegisteredPaymentSession={stripeRegisteredPaymentSession}
      />
    );
  }

  if (
    currentState === PayInvoiceHandlerStates.STRIPE_CARD_REGISTRATION_PROCESSING
  ) {
    if (!hasAlreadyStipeCardRegisteredRef.current) {
      hasAlreadyStipeCardRegisteredRef.current = true;
      rudderTrack('Paying invoice', {
        invoice_amount: invoice?.invoice_amount,
        invoice_status: invoice?.status,
        invoice_due_date: invoice?.due_date,
        invoice_sent_date: invoice?.invoice_date,
        invoice_completed_date: makeFormattedToday(),
      });

      const rootInvoiceId = invoice?.is_parent_task
        ? invoice?.id
        : invoice?.parent_task_id;

      registerPaymentSourceSession({
        accountId: invoice?.account_id || '',
        paymentMethod: PaymentMethods.CREDIT_CARD,
        successUrl: getSuccessUrl(
          `${INVOICES_DOMAIN}${INVOICES_PATHS.paymentSourceAdded}`,
          rootInvoiceId?.toString() || '',
          redirectUrl
        ),
      }).catch(errorHandledByMutation);
    }

    return (
      <StripeCardRegistrationState
        closeFunction={closeFunction}
        setCurrentState={setCurrentState}
        registerStripePaymentSessionError={
          registerPaymentSourceSessionError as Error
        }
        stripeRegisteredPaymentSourceSession={registeredPaymentSourceSession}
      />
    );
  }

  if (currentState === PayInvoiceHandlerStates.PLAID_ADDING_BANK_ACCOUNT) {
    if (!hasAlreadyBankAccountRegisteredRef.current) {
      hasAlreadyBankAccountRegisteredRef.current = true;

      registerPaymentSourceSession({
        accountId: invoice?.account_id || '',
        paymentMethod: PaymentMethods.ACH,
        successUrl: window.location.href,
      }).catch(errorHandledByMutation);
    }

    return (
      <PlaidAddingBankAccountState
        invoice={invoice}
        addCustomerBankAccount={addCustomerBankAccount}
        closeFunction={closeFunction}
        plaidRegisteredPaymentSourceSession={registeredPaymentSourceSession}
        registerPlaidPaymentSessionError={
          registerPaymentSourceSessionError as Error
        }
      />
    );
  }

  return (
    <Dialog
      open
      onClose={() => closeFunction(ClosePaymentDialogReasons.CANCEL)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      {currentState === PayInvoiceHandlerStates.SELECT_PAYMENT_METHOD && (
        <SelectPaymentMethodState
          invoice={invoice}
          setCurrentState={setCurrentState}
          closeFunction={closeFunction}
        />
      )}
      {currentState ===
        PayInvoiceHandlerStates.SELECT_BANK_ACCOUNT_ACH_METHOD && (
        <SelectBankAccountState
          invoice={invoice}
          closeFunction={closeFunction}
          setCurrentState={setCurrentState}
          setStateData={setStateData}
        />
      )}
      {currentState === PayInvoiceHandlerStates.STRIPE_PAYMENT_PROCESSED && (
        <OpenStripeState
          closeFunction={closeFunction}
          stripeRedirectUrl={stripeRegisteredPaymentSession?.data.url}
        />
      )}
      {currentState ===
        PayInvoiceHandlerStates.STRIPE_CARD_REGISTRATION_PROCESSED && (
        <OpenStripeState
          closeFunction={closeFunction}
          stripeRedirectUrl={registeredPaymentSourceSession?.session_id?.url}
        />
      )}
    </Dialog>
  );
};

export default PayInvoiceHandlerV2;
