import {
  ACCOUNTS_DOMAIN,
  ACCOUNTS_PATHS,
} from 'modules/accounts/route-constants';
import {
  AUTHENTICATE_DOMAIN,
  AUTHENTICATE_PATHS,
} from 'modules/authenticate/route-constants';
import {
  DASHBOARD_DOMAIN,
  DASHBOARD_PATHS,
} from 'modules/dashboard/route-constants';
import { ERRORS_DOMAIN } from 'modules/errors/route-constants';
import { FILES_DOMAIN, FILES_PATHS } from 'modules/files/route-constants';
import {
  INVOICES_DOMAIN,
  INVOICES_PATHS,
} from 'modules/invoices/route-constants';
import {
  MESSAGES_DOMAIN,
  MESSAGES_PATHS,
} from 'modules/messages/route-constants';
import {
  NOTIFICATIONS_DOMAIN,
  NOTIFICATIONS_PATHS,
} from 'modules/notifications/route-constants';
import { PROFILE_DOMAIN, PROFILE_PATHS } from 'modules/profile/route-constants';
import {
  REQUESTS_PATHS,
  REQUESTS_DOMAIN,
} from 'modules/requests/route-constants';
import { TASKS_DOMAIN, TASKS_PATHS } from 'modules/tasks/route-constants';

/**
 * A list of sensible default paths to fall back to if a URL doesn't exist in a given domain
 */
export const domainDefaultPaths: Record<string, { redirect: string }> = {
  [AUTHENTICATE_DOMAIN]: {
    redirect: `/${AUTHENTICATE_DOMAIN}${AUTHENTICATE_PATHS.login}`,
  },
  [DASHBOARD_DOMAIN]: {
    redirect: `/${DASHBOARD_DOMAIN}${DASHBOARD_PATHS.home}`,
  },
  [MESSAGES_DOMAIN]: {
    redirect: `/${MESSAGES_DOMAIN}${MESSAGES_PATHS.list}`,
  },
  [ACCOUNTS_DOMAIN]: {
    redirect: `/${ACCOUNTS_DOMAIN}${ACCOUNTS_PATHS.list}`,
  },
  [PROFILE_DOMAIN]: {
    redirect: `/${PROFILE_DOMAIN}${PROFILE_PATHS.details}`,
  },
  [FILES_DOMAIN]: {
    redirect: `/${FILES_DOMAIN}${FILES_PATHS.list}`,
  },
  [TASKS_DOMAIN]: {
    redirect: `/${TASKS_DOMAIN}${TASKS_PATHS.list}`,
  },
  [REQUESTS_DOMAIN]: {
    redirect: `/${REQUESTS_DOMAIN}${REQUESTS_PATHS.list}`,
  },
  [ERRORS_DOMAIN]: {
    redirect: `/${AUTHENTICATE_DOMAIN}${AUTHENTICATE_PATHS.login}`,
  },
  [INVOICES_DOMAIN]: { redirect: `/${INVOICES_DOMAIN}${INVOICES_PATHS.list}` },
  [NOTIFICATIONS_DOMAIN]: {
    redirect: `/${NOTIFICATIONS_DOMAIN}${NOTIFICATIONS_PATHS.list}`,
  },
};
