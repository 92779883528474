import React, { useCallback, useMemo } from 'react';

import { List as MUIList, ListItem } from '@mui/material';
import {
  styled,
  Stack,
  Typography,
  StaticTable,
  UnreadIndicator,
} from '@liscio/ui';
import { createColumnHelper, getCoreRowModel } from '@tanstack/react-table';
import { useLocation, useNavigate } from 'react-router-dom';

import { Message } from '@liscio/api';
import { MessageSnippet } from '@liscio/common';

import { MessagesEllipsisMenu } from './components';
import { MESSAGES_PER_PAGE, TabType } from '../../Messages';
import { getParticipants } from 'components/MessagesList/components/Primary/helpers';
import {
  MESSAGES_DOMAIN,
  MESSAGES_PATHS,
} from 'modules/messages/route-constants';
import AttachmentsCell from './components/AttachmentsCell/AttachmentsCell';

export interface MessagesTableProps {
  messages: Message[];
  goBackUrl?: string;
  totalPages: number;
  page: string;
  enableRowSelect: boolean;
  isMessagesLoading: boolean;
  onPageChange: (page: number) => void;
  handleRowSelect: (rows: string[]) => void;
  tab: TabType;
}

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: '16px 0px 16px 0px',
  borderBottom: `solid 1px ${theme.palette.common.neutrals[200]}`,
  alignItems: 'center',
}));

const columnHelper = createColumnHelper<Message>();

export const MessagesTable: React.FC<MessagesTableProps> = ({
  messages,
  totalPages,
  enableRowSelect,
  onPageChange,
  handleRowSelect,
  isMessagesLoading,
  page,
  tab,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const shouldStyleUnreadMessages = (['inbox', 'pinned'] as TabType[]).includes(
    tab
  );

  const onRowClick = useCallback(
    (message: Message) => {
      navigate(
        `/${MESSAGES_DOMAIN}${MESSAGES_PATHS.details}/${encodeURIComponent(
          message.thread_id
        )}?tab=${tab}`,
        {
          state: {
            goBackUrl: `${location.pathname}${location.search}`,
          },
        }
      );
    },
    [navigate, location, tab]
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor('read', {
        id: 'is-unread',
        header: '',
        cell: (info) =>
          shouldStyleUnreadMessages && (
            <Stack alignItems="center">
              <UnreadIndicator isUnread={!info.getValue()} />
            </Stack>
          ),
        size: 2.5,
      }),
      columnHelper.accessor(getParticipants, {
        id: 'senders',
        header: 'Senders',
        size: 20,
      }),
      columnHelper.accessor((message) => message, {
        id: 'message-details',
        header: 'Message Details',
        size: 45,
        cell: (data) => (
          <MessageSnippet
            isUnread={shouldStyleUnreadMessages && !data?.getValue()?.read}
            title={data?.getValue()?.title}
            snippet={data?.getValue()?.content_limit}
            unreadRepliesCount={data?.getValue()?.unread_count}
          />
        ),
      }),
      columnHelper.accessor('documents', {
        id: 'documents',
        header: 'Attachments',
        size: 15,
        cell: (data) => <AttachmentsCell documents={data.getValue() || []} />,
      }),
      columnHelper.accessor('tym', {
        id: 'last-activity',
        header: 'Last Activity At',
        size: 15,
      }),
      columnHelper.accessor((message) => message, {
        id: 'mark-as-unread',
        header: '',
        cell: (data) => {
          const message = data.getValue();
          return message.read && shouldStyleUnreadMessages ? (
            <MessagesEllipsisMenu message={message} />
          ) : null;
        },
        minSize: 5,
        size: 5,
      }),
    ],
    [shouldStyleUnreadMessages]
  );
  return (
    <Stack>
      <StaticTable
        data={messages}
        columns={columns}
        getCoreRowModel={getCoreRowModel()}
        // TODO: refactor with navigateTo, with location state baked in
        onRowClick={onRowClick}
        onRowSelect={handleRowSelect}
        pageCount={totalPages}
        pageSize={MESSAGES_PER_PAGE}
        onPageChange={onPageChange}
        isLoading={isMessagesLoading}
        checkIsUnread={(message) => shouldStyleUnreadMessages && !message.read}
        getRowId={(row) => row.thread_id || row.id}
        enableRowSelect={enableRowSelect}
        page={Number(page)}
      />
      {!isMessagesLoading && messages?.length === 0 && (
        <MUIList>
          <StyledListItem>
            <Typography width="100%" textAlign="center">
              The list is empty
            </Typography>
          </StyledListItem>
        </MUIList>
      )}
    </Stack>
  );
};

export default MessagesTable;
