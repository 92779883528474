import {
  InvoiceStatus,
  TaskStatus,
  WorkflowRequestStatus,
} from '@liscio/api/graphql';
import { useTheme, Chip } from '@liscio/ui';

const invoiceStatusMap = {
  AUTHORIZED: 'Authorized',
  CLOSED: 'Closed',
  FAILED: 'Failed',
  OTHER: 'Other',
  OVERDUE: 'Overdue',
  PAID: 'Paid',
  PENDING: 'Pending',
  PROCESSING: 'Processing',
  RE_AUTHORIZE: 'Reauthorize',
  UNPAID: 'Unpaid',
  VOID: 'Void',
};

const taskStatusMap = {
  ARCHIVED: 'Archived',
  CLOSED: 'Closed',
  DRAFT: 'Draft',
  OPEN: 'Open',
  OTHER: 'Other',
  REVIEW: 'Review',
};

const workflowStatusMap = {
  ARCHIVED: 'Archived',
  DELETED: 'Deleted',
  DRAFT: 'Draft',
  NEW_WORKFLOW: 'NewWorkflow',
  PERSONAL: 'Personal',
  PUBLISHED: 'Published',
  SYSTEM: 'System',
};

interface StatusCellProps {
  status: TaskStatus | WorkflowRequestStatus | InvoiceStatus | '';
}

const ERROR_STATUSES = [invoiceStatusMap.FAILED, invoiceStatusMap.OVERDUE];

const statuses = {
  ...workflowStatusMap,
  ...invoiceStatusMap,
  ...taskStatusMap,
};

type statusKey = keyof typeof statuses;

export default function StatusCell({ status }: StatusCellProps) {
  const theme = useTheme();

  const color = ERROR_STATUSES.includes(status as statusKey)
    ? theme.palette.error.main
    : theme.palette.primary.main;

  const finalStatus = statuses[status as statusKey] || 'N/A';

  return (
    <Chip
      sx={{ color, borderColor: color }}
      label={finalStatus}
      variant="outlined"
    />
  );
}
