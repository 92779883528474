import { List, styled } from '@liscio/ui';

import { FileUploadsProgressFileListItem } from './FileUploadsProgressFilesListItem';
import { FileUploadItem } from 'stores/fileUploads';

export const StyledList = styled(List)({ maxHeight: '100%', overflow: 'auto' });

export type FileUploadsProgressFilesListProps = {
  files: FileUploadItem[];
};

export const FileUploadsProgressFilesList = ({
  files,
}: FileUploadsProgressFilesListProps) => {
  return (
    <StyledList>
      {files.map((fileItem, idx) => (
        <FileUploadsProgressFileListItem
          key={`${idx}-${fileItem.file.name}`}
          fileItem={fileItem}
        />
      ))}
    </StyledList>
  );
};
