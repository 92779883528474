import { Accordion, Icon, styled } from '@liscio/ui';

import { UploadFilesFormAccordionContent } from './components';

const StyledIcon = styled(Icon)(({ theme }) => ({
  fontSize: theme.typography.caption.fontSize,
}));

export default function UploadFilesFormAccordion() {
  return (
    <Accordion
      summary={{
        expanded: 'Hide details',
        collapsed: 'Show details',
      }}
      expandIcon={<StyledIcon icon="chevronDown" />}
      detailsProps={{
        sx: { padding: 0 },
      }}
      summaryProps={{
        sx: {
          display: 'flex',
          flexDirection: 'row-reverse',
          justifyContent: 'flex-start',
          '& .MuiAccordionSummary-content': {
            flexGrow: 0,
            marginLeft: '8px',
          },
          fontWeight: 400,
          borderBottom: 'none',
        },
      }}
    >
      <UploadFilesFormAccordionContent />
    </Accordion>
  );
}
