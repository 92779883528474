import React from 'react';

import { Stack, Typography } from '@liscio/ui';

export interface NoPreviewProps {
  fileType: string;
}

export const NoPreview = ({ fileType }: NoPreviewProps) => {
  return (
    <Stack
      sx={{ height: '100%', position: 'relative' }}
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="body2" sx={{ width: '75%', textAlign: 'center' }}>
        Preview is not available for files of type "{fileType}"
      </Typography>
    </Stack>
  );
};

export default NoPreview;
