import React from 'react';

import { Alert } from '@mui/material';
import { FormProvider, SubmitHandler } from 'react-hook-form';

import { AppBar, DetailsState } from '@liscio/common';
import {
  Button,
  FormCheckboxField,
  FormTextField,
  GlobalLoader,
  Stack,
  styled,
  Typography,
} from '@liscio/ui';

import useBankAccountFormMobile from './helpers/useBankAccountFormMobile';
import { EncryptedField } from 'components';
import {
  ScreenWrapper,
  BodyWrapper,
  FooterWrapper,
} from 'components/screenComposition';
import { MAX_TEXT_AREA_ROWS } from 'constants/settings';
import { AccountBankAccountSingleFormData } from 'modules/accounts/types/AccountBankAccountsFormData';

export interface BankAccountFormMobileProps {
  defaultValues?: AccountBankAccountSingleFormData;
  goBack: () => void;
  isProcessing: boolean;
  buttonText: string;
  title: string;
  onSubmit: SubmitHandler<AccountBankAccountSingleFormData>;
}

const CheckboxLabel = styled('label')({
  display: 'flex',
  alignItems: 'center',
});

export default function BankAccountFormMobile({
  defaultValues,
  goBack,
  isProcessing,
  onSubmit,
  buttonText,
  title,
}: BankAccountFormMobileProps) {
  const formCtx = useBankAccountFormMobile(defaultValues);
  const {
    handleSubmit,
    formState: { errors },
  } = formCtx;

  const globalValidationError = errors['globalError']?.message;
  return (
    <FormProvider {...formCtx}>
      <form
        onSubmit={handleSubmit(
          onSubmit as SubmitHandler<Partial<AccountBankAccountSingleFormData>>
        )}
      >
        <ScreenWrapper>
          <AppBar
            onNavigationClick={goBack}
            DefaultStateElement={DetailsState}
            title={title}
            hideSecondaryActionButton
          />
          <BodyWrapper pl={2} pr={2}>
            <Stack direction="column" gap="8px" mt="16px">
              {globalValidationError && (
                <Alert severity="error">{globalValidationError}</Alert>
              )}
              <FormTextField
                name="bank_name"
                label="Bank Name"
                placeholder="Bank Name"
              />
              <FormTextField
                name="routing_number"
                label="Routing Number"
                placeholder="Routing Number"
              />
              <EncryptedField
                label="Account Number"
                recordType="BankAccount"
                fieldName="account_number"
                formFieldName="encryptedAccountNumber"
                recordId={
                  defaultValues?.encrypted_account_number?.record_id || ''
                }
                hasEncryptedValue={
                  defaultValues?.encrypted_account_number?.populated
                }
                inputProps={{
                  sx: {
                    padding: '100px',
                  },
                }}
              />
              <FormTextField
                name="account_type"
                label="Account Type"
                placeholder="Account Type"
              />
              <FormTextField
                name="web_site"
                label="Website"
                placeholder="Website"
              />
              <FormTextField
                name="notes"
                minRows={5}
                label="Notes"
                placeholder="Notes"
              />
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <CheckboxLabel>
                  <FormCheckboxField name="is_ach_autopay" />
                  <Typography sx={{ textAlign: 'right' }}>
                    Use this account for monthly ACH auto payments?
                  </Typography>
                </CheckboxLabel>
              </Stack>
            </Stack>
          </BodyWrapper>
          <FooterWrapper>
            <Button
              variant="contained"
              disabled={false}
              type="submit"
              fullWidth
            >
              {buttonText}
            </Button>
          </FooterWrapper>
          <GlobalLoader open={isProcessing} />
        </ScreenWrapper>
      </form>
    </FormProvider>
  );
}
