import { matchRoutes } from 'react-router-dom';

import { legacyPathConfig, legacyPaths } from './legacyPathConfig';

/**
 * Get the configured legacy site redirect uri for a given location
 */
export const resolveLegacyRedirect = ({ pathname }: { pathname: string }) => {
  const [match] = matchRoutes(legacyPaths, pathname) || [];

  if (match) {
    const routePath = match.route.path || '';
    let redirectPath = legacyPathConfig[routePath].redirect;

    if (redirectPath) {
      // Resolve redirect path params
      Object.keys(match.params).forEach((param) => {
        const paramValue = match.params[param];
        if (paramValue && redirectPath) {
          redirectPath = redirectPath.replaceAll(
            `:${param}`,
            encodeURIComponent(paramValue)
          );
        }
      });

      return redirectPath;
    }
  }

  return undefined;
};
