import React from 'react';

import { Navigate, useParams } from 'react-router-dom';

import { ResponsiveView } from '@liscio/ui';

import {
  ACCOUNTS_DOMAIN,
  ACCOUNTS_PATHS,
  AccountParamsType,
} from '../../route-constants';

const AccountLoginListMobileView = React.lazy(
  () => import('./AccountLoginListMobileView/AccountLoginListMobileView')
);

export const AccountLoginListView = () => {
  const { id: accountId } = useParams<AccountParamsType>();
  return (
    <ResponsiveView
      SmallView={<AccountLoginListMobileView />}
      LargeView={
        <Navigate
          to={`/${ACCOUNTS_DOMAIN}${ACCOUNTS_PATHS.details.root}/${accountId}/${ACCOUNTS_PATHS.details.tabs.overview}`}
        />
      }
    />
  );
};

export default AccountLoginListView;
