import { useCallback } from 'react';

import { Control, UseFormReset, UseFormGetValues } from 'react-hook-form';

import { Box, Button, Stack } from '@liscio/ui';

import { BiometricsToggle } from '../Biometrics';
import { LoginForm } from '../SignInForm';
import { Link } from 'components';
import { useForgotPassword } from 'fetch-utils/users/auth-hooks';
import { RememberMeToggle } from 'modules/authenticate/views/LoginViewDesktop/components/SignInForm/RememberMe';

type BottomSignInFormProps = {
  control: Control<LoginForm>;
  reset: UseFormReset<LoginForm>;
  getValues: UseFormGetValues<LoginForm>;
  isLoggingIn: boolean;
};

export default function BottomSignInForm({
  control,
  reset,
  getValues,
  isLoggingIn,
}: BottomSignInFormProps) {
  const { setForgotPasswordEmail } = useForgotPassword();
  // Forgot Password Capture
  const captureForgotPasswordEmail = useCallback(() => {
    const email = getValues('email');
    setForgotPasswordEmail(email);
  }, [getValues, setForgotPasswordEmail]);

  // Update/reset email field when remember me loads
  const updateRememberMeEmailValue = useCallback(
    (email: string) => reset({ email }),
    [reset]
  );
  return (
    <>
      <Stack
        direction="row"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box width="150px">
          <RememberMeToggle
            name="enableRememberMe"
            resetEmailField={updateRememberMeEmailValue}
            control={control}
          />
        </Box>

        <Link
          onClick={captureForgotPasswordEmail}
          to="/authenticate/forgot-password"
          underline="none"
          variant="caption"
        >
          Forgot your password?
        </Link>
        <BiometricsToggle name="enableBiometrics" control={control} />
      </Stack>
      <Button
        variant="contained"
        type="submit"
        disabled={isLoggingIn}
        size="large"
        sx={{ borderRadius: '8px' }}
      >
        Login
      </Button>
    </>
  );
}
