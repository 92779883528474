import { useMemo } from 'react';

import { useController } from 'react-hook-form';

import { AutocompleteField, AutocompleteFieldProps } from '@liscio/ui';

import { useHomeData } from 'fetch-utils/users/user-hooks';

export type DocumentTagsFieldProps = Omit<
  AutocompleteFieldProps<string, true>,
  'multiple' | 'options' | 'loading'
>;

/**
 * Upload Document Tags Field
 */
export const DocumentTagsField = ({
  label = 'Tags',
  ...rest
}: DocumentTagsFieldProps) => {
  const { isLoading, data } = useHomeData();
  const yearOptions = useMemo(() => {
    if (data?.data?.pref_type_options?.upload_tags) {
      return data.data.pref_type_options.upload_tags.map((opt) =>
        String(opt.value)
      );
    }
    return [];
  }, [data?.data?.pref_type_options?.upload_tags]);

  return (
    <AutocompleteField
      multiple
      label={label}
      loading={isLoading}
      options={yearOptions}
      {...rest}
    />
  );
};

export type FormDocumentTagsFieldProps = Omit<
  DocumentTagsFieldProps,
  'name' | 'onChange'
> & {
  name: string;
};

export const FormDocumentTagsField = (props: FormDocumentTagsFieldProps) => {
  const { field, fieldState } = useController({ name: props.name });
  return (
    <DocumentTagsField
      {...props}
      value={field.value || []}
      onChange={(_e, value) => field.onChange(value)}
      error={fieldState.error?.message}
    />
  );
};
