// This is a copy from react-hook-form documentation.
// Please see: https://react-hook-form.com/advanced-usage
// @todo remove any types

import { useCallback } from 'react';

import * as yup from 'yup';

export const yupValidationResolver =
  (validationSchema: yup.AnySchema) => async (data: any) => {
    try {
      const values = await validationSchema.validate(data, {
        abortEarly: false,
      });

      return {
        values,
        errors: {},
      };
    } catch (errors: any) {
      return {
        values: {},
        errors: errors?.inner?.reduce(
          (allErrors: any, currentError: any) => ({
            ...allErrors,
            [currentError.path]: {
              type: currentError.type ?? 'validation',
              message: currentError.message,
            },
          }),
          {}
        ),
      };
    }
  };

export const useYupValidationResolver = (validationSchema: yup.AnySchema) =>
  useCallback(
    async (data: any) => yupValidationResolver(validationSchema)(data),
    [validationSchema]
  );

export default useYupValidationResolver;
