import {
  Box,
  FormRichTextEditor,
  FormTextareaField,
  Grid,
  Stack,
  useViewportSize,
} from '@liscio/ui';

import {
  FormDocumentMonthField,
  FormDocumentTagsField,
  FormDocumentYearField,
} from 'components';

export default function UploadFilesFormAccordionContent() {
  const { isMobile } = useViewportSize();

  return (
    <Stack gap={2}>
      {isMobile ? (
        <FormTextareaField
          label="Message"
          placeholder="Write message"
          name="message"
        />
      ) : (
        <FormRichTextEditor label="Message" name="message" />
      )}
      <Grid container columns={12} spacing={2}>
        <Grid item xs={6}>
          <FormDocumentYearField name="year" placeholder="Select Year" />
        </Grid>
        <Grid item xs={6}>
          <FormDocumentMonthField name="month" placeholder="Select Month" />
        </Grid>
      </Grid>
      <Box>
        <FormDocumentTagsField name="tags" />
      </Box>
    </Stack>
  );
}
