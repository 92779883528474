import React from 'react';

import { Button, Icon, Stack, Typography } from '@liscio/ui';

export interface BankAccountProps {
  id: string;
  bankName: string;
  last4: string;
  status: string;
  isRecurring?: boolean;
  onPayClick: (sourceId: string) => void;
}

export const BankAccount: React.FC<BankAccountProps> = ({
  id,
  bankName,
  last4,
  isRecurring,
  onPayClick,
}) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      gap="8px"
    >
      <Stack direction="row" gap="8px" alignItems="center">
        <Icon icon="bank" />
        <Stack>
          <Typography>{bankName}</Typography>
          <Typography>{`****_****_****_${last4}`}</Typography>
        </Stack>
      </Stack>
      <Button variant="contained" onClick={() => onPayClick(id)}>
        {isRecurring ? 'Authorize' : 'Pay'}
      </Button>
    </Stack>
  );
};

export default BankAccount;
