import React from 'react';

import { Link } from 'react-router-dom';

import { styled, Box, Typography } from '@liscio/ui';

import { config } from 'config';
import { DESKTOP_COPYRIGHT_TEXT } from 'constants/settings';

export const FOOTER_HEIGHT = '53px';

const Wrapper = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  display: 'flex',
  position: 'sticky',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.common.neutrals[100],
  height: FOOTER_HEIGHT,
  borderTop: `solid 1px ${theme.palette.common.neutrals[200]}`,
}));

export const CopyrightFooter = () => (
  <Wrapper gap={1}>
    <Typography variant="caption">{DESKTOP_COPYRIGHT_TEXT}</Typography>
    <Typography variant="caption">•</Typography>
    <Typography variant="caption">
      <Link
        style={{ textDecoration: 'none', color: 'inherit' }}
        target="_blank"
        to="https://www.liscio.me/privacy-policy"
      >
        Privacy Policy
      </Link>
    </Typography>
    <Typography variant="caption">•</Typography>
    <Typography variant="caption">{config.version}</Typography>
  </Wrapper>
);

export default CopyrightFooter;
