import { createPortal } from 'react-dom';

import { Button, Stack } from '@liscio/ui';

import { useTaxDeliveryStore } from 'stores/workflow/useTaxDeliveryStore';

type PayableItemActionButtonsProps = {
  disabled?: boolean;
  printUrl: string;
  payOnlineURL?: string | null;
  onPayOnlineClick: () => void;
  onSubmit: () => void;
};

export const PayableItemActionButtons = ({
  disabled = false,
  printUrl,
  onSubmit,
  onPayOnlineClick,
}: PayableItemActionButtonsProps) => {
  const { actionButtonPortalRef, lastStepActive, checkAllItemsComplete } =
    useTaxDeliveryStore();

  const allItemsComplete = checkAllItemsComplete();

  const handlePayOnlineClick = () => {
    onPayOnlineClick();
  };

  const handlePrintClick = () => {
    window.open(printUrl, '_blank');
  };

  if (actionButtonPortalRef && actionButtonPortalRef.current) {
    return createPortal(
      <Stack width="100%" spacing={2}>
        <Stack direction="row" spacing={2}>
          <Button
            disabled={disabled}
            variant="outlined"
            fullWidth
            onClick={handlePrintClick}
          >
            Print
          </Button>
          <Button
            disabled={disabled}
            variant="contained"
            fullWidth
            onClick={handlePayOnlineClick}
          >
            Pay online
          </Button>
        </Stack>
        {lastStepActive && (
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              fullWidth
              onClick={onSubmit}
              disabled={!allItemsComplete}
            >
              Submit
            </Button>
          </Stack>
        )}
      </Stack>,
      actionButtonPortalRef.current as Element
    );
  }
};
