import { Divider, Box, Stack, Typography } from '@liscio/ui';

import { Loading } from 'components';
import { useTodoFeedsQuery } from 'fetch-utils/todo/todo-hooks';
import {
  DASHBOARD_DOMAIN,
  DASHBOARD_PATHS,
} from 'modules/dashboard/route-constants';
import { TodoTable } from 'modules/dashboard/views/ResponsiveDashboardView/components/DashboardViewDesktop/components/ToDoList/components';

/**
 * This component is responsible for rendering the
 * full todo table in desktop mode.
 */
export default function TodosListView() {
  const { data: todoFeedsResponse, isLoading } = useTodoFeedsQuery();
  const todoFeeds = todoFeedsResponse?.todoFeeds;

  return (
    <Stack>
      <Typography variant="h1" p="14px">
        My To-Do List
      </Typography>
      <Divider />
      {isLoading && <Loading mt={0} />}
      {!isLoading && todoFeeds && (
        <Box>
          <TodoTable todoFeeds={todoFeeds} goBackUrl={-1} fullTable />
        </Box>
      )}
    </Stack>
  );
}
