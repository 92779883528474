import React from 'react';

import { ListItem, ListItemText } from '@mui/material';
import { styled, Icon, Stack, Typography } from '@liscio/ui';

import { AccountNewData } from '@liscio/api';

import { ListItemButton } from 'components';

interface PrimaryProps {
  accountInfo: AccountNewData;
  onClick: (id: number) => void;
}

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.blueGray[500],
}));

export default function Primary({ accountInfo, onClick }: PrimaryProps) {
  const {
    account: { id, name, entity_type },
  } = accountInfo;
  return (
    <ListItem sx={{ padding: '0px' }}>
      <ListItemButton
        data-testid={`account_button_${id}`}
        onClick={() => onClick(id)}
      >
        <ListItemText>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="column" justifyContent="space-between">
              <Typography variant="body1">{name}</Typography>
              <GreyText variant="body2">{entity_type}</GreyText>
            </Stack>
            <Stack direction="row" alignItems="center">
              <GreyText>
                <Icon
                  icon="chevronRight"
                  fontSize="large"
                  sx={{
                    position: 'relative',
                    top: '3px',
                  }}
                />
              </GreyText>
            </Stack>
          </Stack>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
}
