import { useCallback, useMemo } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { DetailsState, DetailsStateProps } from '@liscio/common';
import { Icon, IconButton } from '@liscio/ui';

import { useAccountOverview } from 'fetch-utils/accounts/accounts-hooks';
import {
  ACCOUNTS_DOMAIN,
  ACCOUNTS_PATHS,
} from 'modules/accounts/route-constants';

export interface AccountDetailsAppBarStateProps extends DetailsStateProps {}

export const AccountDetailsStateElement = (
  props: AccountDetailsAppBarStateProps
) => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const tab = location.pathname.split('/').pop();

  // conditional secondary action
  const goToEditPayroll = useCallback(() => {
    navigate(
      `/${ACCOUNTS_DOMAIN}${ACCOUNTS_PATHS.details.root}/${id}/${ACCOUNTS_PATHS.details.payrollForm}`
    );
  }, [id, navigate]);
  const goToEditOverview = useCallback(() => {
    navigate(
      `/${ACCOUNTS_DOMAIN}${ACCOUNTS_PATHS.details.root}/${id}/${ACCOUNTS_PATHS.details.edit}`
    );
  }, [id, navigate]);

  const { data: accountData } = useAccountOverview(id!);

  const secondaryAction = useMemo(() => {
    const { payroll, overview } = ACCOUNTS_PATHS.details.tabs;
    const tabsWithEdit = [payroll, overview];

    if (accountData?.is_account_owner && tabsWithEdit.includes(tab || '')) {
      const handleClick = tab === payroll ? goToEditPayroll : goToEditOverview;
      return (
        <IconButton aria-label="Navigation" size="large" onClick={handleClick}>
          <Icon icon="edit" fontSize="small" />
        </IconButton>
      );
    }
    return null;
  }, [tab, goToEditPayroll, goToEditOverview, accountData]);

  return <DetailsState {...props} secondaryActionChildren={secondaryAction} />;
};
