import { AccountBankAccountEntity } from '@liscio/api';
import { DetailsState } from '@liscio/common';

import { AccountBankAccountEllipsesMenu } from './components';

interface EllipsesStateProps {
  bankAccount: Partial<AccountBankAccountEntity>;
}

export default function EllipsesState({
  bankAccount,
  ...rest
}: EllipsesStateProps) {
  return (
    <DetailsState
      {...rest}
      secondaryActionChildren={
        <AccountBankAccountEllipsesMenu bankAccount={bankAccount} />
      }
    />
  );
}
