import React from 'react';

import { BasicTask } from '@liscio/api';
import { styled, UnreadIndicator, Box, Stack, Typography } from '@liscio/ui';

import { CommentsCount } from './components';

export interface PrimaryProps {
  task: BasicTask;
  highlightUnreadComments?: boolean;
}

const Date = styled(Typography)(({ theme }) => ({
  flexShrink: 0,
  color: theme.palette.common.blueGray[500],
}));

const AccountName = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.blueGray[500],
}));

export const Primary: React.FC<PrimaryProps> = ({
  task,
  highlightUnreadComments = false,
}) => {
  const shouldDisplayAsUnread =
    task.comment_count > 0 && highlightUnreadComments;

  return (
    <Stack direction="row" alignItems="center" width="100%" spacing={2}>
      <UnreadIndicator isUnread={shouldDisplayAsUnread} />
      <Stack width="100%" spacing={1}>
        <Stack direction="row" justifyContent="space-between" width="100%">
          <AccountName>
            {task.account_name || task.todo_account_name}
          </AccountName>
          <Date variant="caption">{task.updated_at}</Date>
        </Stack>
        <Box>
          <Typography variant="label">{task.subject}</Typography>
        </Box>
        {task.comment_count > 0 && (
          <CommentsCount numberOfComments={task.comment_count} />
        )}
      </Stack>
    </Stack>
  );
};

export default Primary;
