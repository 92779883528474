import React, { useCallback } from 'react';

import { List, ListItem, ListItemText } from '@mui/material';
import { styled, Box, UnreadIndicator } from '@liscio/ui';
import { useNavigate } from 'react-router-dom';

import { BasicMessage } from '@liscio/api';
import { getScrollValue } from '@liscio/utils';

import { Primary } from './components/Primary/Primary';
import { ListItemButton, Avatar, ListItemAvatar } from 'components';
import {
  MESSAGES_DOMAIN,
  MESSAGES_PATHS,
} from 'modules/messages/route-constants';

export interface MessagesListProps {
  messages: BasicMessage[];
  // Optional state to pass to navigate options
  navigateState?: Record<string, any>;
  // Whether or not to display unread messages dot
  highlightUnreadComments?: boolean;
}

const IndicatorWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  width: '22px',
  minHeight: '70px',
});

export const MessagesList = ({
  messages,
  highlightUnreadComments = false,
  navigateState = {},
}: MessagesListProps) => {
  const navigate = useNavigate();
  const onMessageClick = useCallback(
    (message: BasicMessage) => {
      // Default navigate to thread detail w/ passed state
      navigate(
        `/${MESSAGES_DOMAIN}${MESSAGES_PATHS.details}/${encodeURIComponent(
          message.thread_id
        )}`,
        {
          state: {
            scroll: getScrollValue(),
            ...navigateState,
          },
        }
      );
    },
    [navigate, navigateState]
  );
  return (
    <List>
      {messages.map((message) => {
        const shouldDisplayAsUnread = highlightUnreadComments && !message.read;

        return (
          <ListItem key={message.thread_id} disablePadding>
            <ListItemButton
              data-testid={`message_button_${message.thread_id}`}
              onClick={() => onMessageClick(message)}
            >
              <IndicatorWrapper>
                <UnreadIndicator isUnread={shouldDisplayAsUnread} />
              </IndicatorWrapper>
              <ListItemAvatar>
                <Avatar src={message.avatar} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Primary
                    message={message}
                    shouldDisplayAsUnread={shouldDisplayAsUnread}
                  />
                }
              />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};

export default MessagesList;
