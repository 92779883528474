export const DEPRECATED_WORKFLOWS_DOMAIN = 'workflows';
export const REQUESTS_DOMAIN = 'requests';
export const REQUESTS_DOMAIN_URL = `/${REQUESTS_DOMAIN}/*`;

export const REQUESTS_PATHS = {
  list: '/list',
  todos: '/todos',
};

export const REQUESTS_PAGE_TITLES = {
  list: 'Requests',
};
