import * as yup from 'yup';

import { RecipientAccount } from 'components/ContactSelector/ContactSelector';
import { yupValidationResolver } from 'custom-hooks/useYupValidationResolver';
import { FileUploadItem } from 'stores/fileUploads';

export type UploadFilesFormType = {
  // Note: Files are stored in form ctx for validation only
  files: FileUploadItem[];
  participants: RecipientAccount[];
  year?: string;
  month?: string;
  tags?: string[];
  message?: string;
  // NOTE: We need to revisit this "feature" as it's kind of terrible...
  isMessageAltered?: boolean;
};

export const validateUploadFilesForm = yupValidationResolver(
  yup.object().shape({
    participants: yup.array().min(1, 'At least one participant required'),
    files: yup.array().min(1, 'No file selected'),
    year: yup.string().nullable(),
    month: yup.string().nullable(),
    tags: yup.array(),
    message: yup.string(),
  })
);
