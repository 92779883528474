import { PROFILE_PATHS } from 'modules/profile/route-constants';

const DEFAULT_TAB = 'user_information';
const SECURITY_SETTINGS_TAB = 'security_settings';

export const getDefaultTab = () => {
  if (window.location.pathname.includes(PROFILE_PATHS.securitySettings)) {
    return SECURITY_SETTINGS_TAB;
  }

  return DEFAULT_TAB;
};

export default getDefaultTab;
