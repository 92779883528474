import React from 'react';

import { AccountFileFiltersSecondaryNavigation } from './AccountFileFiltersSecondaryNavigation';

export interface AccountDetailsSecondaryNavigationprops {
  tab?: string;
}

export const AccountDetailsSecondaryNavigation = ({
  tab,
}: AccountDetailsSecondaryNavigationprops) => {
  if (tab === 'files') {
    return <AccountFileFiltersSecondaryNavigation />;
  }

  return null;
};
