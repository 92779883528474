import React from 'react';

import { ResponsiveView } from '@liscio/ui';

const RequestsListViewMobile = React.lazy(
  () => import('./RequestsListViewMobile/RequestsListViewMobile')
);
const RequestsListViewDesktop = React.lazy(
  () => import('./RequestsListViewDesktop/RequestsListViewDesktop')
);

export default function ResponsiveRequestsListView() {
  return (
    <ResponsiveView
      SmallView={<RequestsListViewMobile />}
      LargeView={<RequestsListViewDesktop />}
    />
  );
}
