import { PaymentEstimateObject } from '@liscio/api';
import {
  List,
  ListItem,
  ListItemText,
  Box,
  Typography,
  Button,
  Stack,
} from '@liscio/ui';
import { formatStringDate, parseCentsToDollars } from '@liscio/utils';

type TaxDetailsTableProps = {
  data: PaymentEstimateObject[];
};

export const TaxDetailsTableMobile = ({ data = [] }: TaxDetailsTableProps) => {
  const SecondaryListItemText = ({
    quarter,
    dueDate,
    paymentEstimate,
  }: {
    quarter: string;
    dueDate: string | null | undefined;
    paymentEstimate: number | null | undefined;
  }) => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
      >
        <Typography variant="h6" color="GrayText" p={2}>
          {quarter} - Due Date{' '}
          {formatStringDate({
            dateString: dueDate,
            defaultPlaceholder: '-',
          })}
        </Typography>
        <Typography variant="h6" color="GrayText" p={2}>
          Amount {parseCentsToDollars(paymentEstimate, '-')}
        </Typography>
      </Stack>
    );
  };

  const handlePaymentButtonClick = (paymentUrl: string | null | undefined) => {
    if (!paymentUrl) {
      return;
    }
    window.open(paymentUrl, '_blank');
  };

  return (
    <Box height="100%" overflow={'auto'}>
      <List
        sx={{ width: '100%', maxWidth: '100vw', bgcolor: 'background.paper' }}
      >
        {data.map((paymentEstimateRow) => (
          <>
            <ListItem key={paymentEstimateRow.uuid} disableGutters>
              <ListItemText
                disableTypography
                primary={
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    flexWrap="wrap"
                  >
                    <Typography variant="h6" p={1} component="div">
                      Jurisdiction {paymentEstimateRow.jurisdiction}{' '}
                    </Typography>
                    <Typography variant="h6" p={1} component="div">
                      Tax Year {paymentEstimateRow.tax_year}
                    </Typography>
                  </Stack>
                }
                secondary={
                  <>
                    <SecondaryListItemText
                      quarter="Q1"
                      dueDate={paymentEstimateRow?.q1_due_date}
                      paymentEstimate={paymentEstimateRow?.q1_payment_estimate}
                    />
                    <SecondaryListItemText
                      quarter="Q2"
                      dueDate={paymentEstimateRow?.q2_due_date}
                      paymentEstimate={paymentEstimateRow?.q2_payment_estimate}
                    />
                    <SecondaryListItemText
                      quarter="Q3"
                      dueDate={paymentEstimateRow?.q3_due_date}
                      paymentEstimate={paymentEstimateRow?.q3_payment_estimate}
                    />
                    <SecondaryListItemText
                      quarter="Q4"
                      dueDate={paymentEstimateRow?.q4_due_date}
                      paymentEstimate={paymentEstimateRow?.q4_payment_estimate}
                    />
                    {paymentEstimateRow.payment_url && (
                      <Box p={1}>
                        <Button
                          variant="outlined"
                          color="info"
                          size="small"
                          fullWidth
                          onClick={() => {
                            handlePaymentButtonClick(
                              paymentEstimateRow?.payment_url
                            );
                          }}
                        >
                          Go to {paymentEstimateRow.jurisdiction} Payment Portal
                        </Button>
                      </Box>
                    )}
                  </>
                }
              />
            </ListItem>
          </>
        ))}
      </List>
    </Box>
  );
};
