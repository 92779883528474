import React from 'react';

import { ResponsiveView } from '@liscio/ui';

const InvoicesListDesktopView = React.lazy(
  () => import('./InvoicesListDesktopView/InvoicesListDesktopView')
);
const InvoicesListMobileView = React.lazy(
  () => import('./InvoicesListMobileView/InvoicesListMobileView')
);

export const InvoicesListView = () => {
  return (
    <ResponsiveView
      SmallView={<InvoicesListMobileView />}
      LargeView={<InvoicesListDesktopView />}
    />
  );
};

export default InvoicesListView;
