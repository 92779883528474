import React, { useEffect } from 'react';

import {
  ClosePaymentDialogReasons,
  RegisteredPaymentSessionResponse,
} from '@liscio/api';
import { GlobalLoader } from '@liscio/ui';

import { parseError } from '../../helpers';
import { ErrorDialog } from 'components';
import { PayInvoiceHandlerStates } from 'modules/invoices/components/PayInvoiceHandlerV2/PayInvoiceHandlerV2';

export interface StripePaymentProcessingStateProps {
  closeFunction(closeReason: ClosePaymentDialogReasons): void;
  registerStripePaymentSessionError?: Error | null;
  stripeRegisteredPaymentSession?: RegisteredPaymentSessionResponse;
  setCurrentState: React.Dispatch<
    React.SetStateAction<PayInvoiceHandlerStates>
  >;
}

export const StripePaymentProcessingState: React.FC<
  StripePaymentProcessingStateProps
> = ({
  closeFunction,
  registerStripePaymentSessionError,
  stripeRegisteredPaymentSession,
  setCurrentState,
}) => {
  useEffect(() => {
    const redirectUrl = stripeRegisteredPaymentSession?.data?.url;
    if (redirectUrl) {
      setCurrentState(PayInvoiceHandlerStates.STRIPE_PAYMENT_PROCESSED);
    }
  }, [setCurrentState, stripeRegisteredPaymentSession]);

  return (
    <>
      <GlobalLoader open={!registerStripePaymentSessionError} />
      <ErrorDialog
        parseError={parseError}
        error={registerStripePaymentSessionError}
        onClose={() => closeFunction(ClosePaymentDialogReasons.VOID)}
      />
    </>
  );
};

export default StripePaymentProcessingState;
