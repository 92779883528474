import React, { useCallback } from 'react';

import { useNavigate } from 'react-router-dom';

import { Stack, Typography } from '@liscio/ui';

import {
  SignInForm,
  ConfirmMfaForm,
  HeroSplashMobile,
  OAuthButtonsMobile,
  LoginLogoMobile,
} from './components';
import { CopyrightFooter } from '../../components';
import PoweredByLiscio from '../LoginViewDesktop/components/PoweredByLiscio/PoweredByLiscio';
import { useAuth } from 'fetch-utils/users/use-auth';
import { AUTHENTICATE_PATHS } from 'modules/authenticate/route-constants';

const ClientLoginView = () => {
  // Single hook instance for maintaining local auth state across flows
  const auth = useAuth();
  const navigate = useNavigate();

  const handleBackToLogin = useCallback(() => {
    navigate(AUTHENTICATE_PATHS.login);
    auth.clearMfaCredentials();
  }, [navigate, auth]);

  return (
    <>
      <HeroSplashMobile />
      <Stack m="32px">
        <LoginLogoMobile />
        <Stack m="16px 0px">
          <Typography textAlign="center" variant="h3">
            Welcome back
          </Typography>
          <Typography
            color={(theme) => theme.palette.text.secondary}
            textAlign="center"
            variant="caption"
          >
            Login to your account with
          </Typography>
        </Stack>
        <OAuthButtonsMobile />
        <Stack>
          {auth.mfaRequired && auth.mfaEmail ? (
            <ConfirmMfaForm
              email={auth.mfaEmail}
              loginWithPasscode={auth.loginWithMfaCode}
              onBackToLogin={handleBackToLogin}
              error={auth.loginError}
            />
          ) : (
            <SignInForm auth={auth} />
          )}
        </Stack>
        <PoweredByLiscio />
      </Stack>
      <CopyrightFooter />
    </>
  );
};

export default ClientLoginView;
