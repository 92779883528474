import { useState, useCallback, useMemo } from 'react';

import { useFormContext } from 'react-hook-form';

import { Typography, Stack, Button, Box, Link } from '@liscio/ui';

import { RequestEmailedCode } from './components';
import { ErrorDialog } from 'components/ErrorDialog';
import { AuthTextField } from 'modules/authenticate/components/FormFields';

export interface MfaFormProps {
  backToLogin: () => void;
  isLoading?: boolean;
}

export const MfaForm = ({ backToLogin, isLoading = false }: MfaFormProps) => {
  const {
    register,
    formState: { errors, isSubmitting },
    getValues,
  } = useFormContext();

  // Request mfa code emailed
  const [needsHelp, setNeedsHelp] = useState(false);
  const openNeedsHelp = useCallback(() => setNeedsHelp(true), [setNeedsHelp]);
  const closeNeedsHelp = useCallback(() => setNeedsHelp(false), [setNeedsHelp]);

  const email = useMemo(() => {
    return getValues('email');
  }, [getValues]);

  if (needsHelp) {
    return <RequestEmailedCode onClose={closeNeedsHelp} email={email} />;
  }

  return (
    <>
      <Typography variant="h3" textAlign="center" mb={4}>
        Enter Passcode
      </Typography>
      <Typography variant="body1" textAlign="center" mb={4}>
        Enter the code on your mobile passcode application
      </Typography>
      <Stack gap={2}>
        <AuthTextField
          {...register('twoFactorCode')}
          fullWidth
          placeholder="Enter Passcode"
          error={Boolean(errors?.twoFactorCode)}
          helperText={errors?.twoFactorCode?.message as string}
        />
        <Button
          variant="contained"
          type="submit"
          disabled={isSubmitting || isLoading}
          size="large"
        >
          Verify Code
        </Button>
      </Stack>
      {email && (
        <Box mt={8}>
          <Typography variant="body1" textAlign="center" mb={4}>
            <Link sx={{ cursor: 'pointer' }} onClick={openNeedsHelp}>
              Need help logging in?
            </Link>
          </Typography>
        </Box>
      )}
      <Box mt={4}>
        <Typography variant="body1" textAlign="center" mb={4}>
          Back to{' '}
          <Link sx={{ cursor: 'pointer' }} onClick={backToLogin}>
            Login
          </Link>
        </Typography>
      </Box>
      <ErrorDialog
        title="Verification Error"
        error={errors.root?.serverError}
      />
    </>
  );
};

export default MfaForm;
