import { useEffect, useMemo } from 'react';

import { Capacitor } from '@capacitor/core';
import toast from 'react-hot-toast';
import { Outlet, useNavigate } from 'react-router-dom';

import { GlobalLoader } from '@liscio/ui';

import { config } from 'config';
import { useHomeData } from 'fetch-utils/users/user-hooks';
import {
  AUTHENTICATE_DOMAIN,
  AUTHENTICATE_PATHS,
} from 'modules/authenticate/route-constants';
import { useCpa } from 'stores/cpa';
import { getCpaSiteUrl } from 'stores/cpa/cpa-utils';

/**
 * Route wrapper that checks user role and selected cpa ONLY after
 * cpa selection has occurred and redirects users as necessary.
 *
 * - firm users redirected to firm in web
 * - firm users logged out and shown error in native app
 * - cpa users on wrong cpa domain redirected to correct cpa
 */
export const AuthUserDispacther = () => {
  const { data: homeData, isLoading } = useHomeData();
  const { cpa } = useCpa();
  const navigate = useNavigate();
  const siteCpa = getCpaSiteUrl();

  const isFirmUser = useMemo(() => homeData?.data.is_employee, [homeData]);
  const externalRedirect = useMemo(() => {
    if (!isLoading && cpa && homeData) {
      // Handle firm user redirect
      if (isFirmUser) {
        return config.auth.firmRedirect;
      }
      // Handle invalid client site redirect
      if (
        !Capacitor.isNativePlatform() &&
        cpa.custom_liscio_url &&
        cpa.custom_liscio_url !== siteCpa
      ) {
        return cpa.custom_liscio_url;
      }
    }
  }, [isLoading, cpa, homeData, isFirmUser, siteCpa]);

  // Handle external redirect
  useEffect(() => {
    if (externalRedirect) {
      // Logout and display error for client native mobile app
      if (Capacitor.isNativePlatform() && isFirmUser) {
        toast.error(
          'The client app is only available for clients. Use FirmDash instead.'
        );
        navigate(`/${AUTHENTICATE_DOMAIN}${AUTHENTICATE_PATHS.logout}`);
      } else {
        // Do redirect
        window.location.replace(externalRedirect);
      }
    }
  }, [externalRedirect, isFirmUser, navigate]);

  if (isLoading || externalRedirect) {
    return <GlobalLoader open />;
  }

  return <Outlet />;
};
