import React from 'react';

import { CircularProgress } from '@mui/material';
import { createPortal } from 'react-dom';

import { Box, Stack, Typography } from '@liscio/ui';

import taxDeliveryImageSrc from '../../../../assets/tax-delivery-image.svg';
import { HelperContentStyled } from '../HelperContent/HelperContent';
import { useTaxDeliveryStore } from 'stores/workflow/useTaxDeliveryStore';

export interface ESignLoadingMessageProps {
  open: boolean;
}

export const comfortingEsignMessages = [
  'Analyzing tax return for signature forms',
  'Extracting forms for electronic signature',
  'Identifying signatures required for filing',
  'Assembling taxpayer data for signing',
  'Creating file containing signable forms',
  'Retrieving data for identity verification',
  'Securely contacting signature provider',
  'Readying form for viewing and signing',
  'Confirming validity of signature forms',
  'Validating user is a designated signer',
  'Verifying signature link is ready & valid',
  'Tidying up a bit behind the scenes...',
  'Taking a little longer than expected...',
  'Apologies for the delay, please continue to wait...',
  'Retrying document creation process...',
];

export const ESignLoadingMessage: React.FC<ESignLoadingMessageProps> = ({
  open,
}) => {
  const topMessage = `Please wait`;
  const [messageIndex, setMessageIndex] = React.useState(0);
  const { mainContentPortalRef } = useTaxDeliveryStore();

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (!open) {
        clearInterval(interval);
      }
      if (messageIndex === comfortingEsignMessages.length - 1) {
        setMessageIndex(0);
        return;
      }
      setMessageIndex((prev) => prev + 1);
    }, 5000);

    return () => clearInterval(interval);
  });

  const content = (
    <HelperContentStyled>
      <img
        src={taxDeliveryImageSrc}
        alt="Tax delivery image"
        style={{ width: '70%', maxHeight: '400px', marginBottom: '32px' }}
      />
      <Box textAlign="center">
        <Typography variant="h3">
          {
            comfortingEsignMessages[
              messageIndex % comfortingEsignMessages.length
            ]
          }
        </Typography>
        <Typography>{topMessage}</Typography>
        <Stack mt={2} mb={2} direction="row" justifyContent="center">
          <CircularProgress color="primary" />
        </Stack>
      </Box>
    </HelperContentStyled>
  );

  if (open && mainContentPortalRef && mainContentPortalRef.current) {
    return createPortal(content, mainContentPortalRef.current as Element);
  } else {
    return null;
  }
};

export default ESignLoadingMessage;
