import React, { useEffect } from 'react';

import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { Avatar, TextField, Button } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { Stack } from '@liscio/ui';

import { MAX_TEXT_AREA_ROWS } from 'constants/settings';
import { useProfileData } from 'fetch-utils/users/user-hooks';

export interface MessageFormProps {
  isLoading?: boolean;
}

export const MessageForm: React.FC<MessageFormProps> = ({ isLoading }) => {
  const { avatar } = useProfileData();
  const {
    register,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    // fixes scrolling issue with virtual keyboard in ios native
    if (Capacitor.getPlatform() === 'ios') {
      Keyboard.setScroll({ isDisabled: true });
    }
  }, []);

  return (
    <Stack alignItems="center" direction="row" gap={2}>
      <Avatar src={avatar || ''} sx={{ width: 24, height: 24 }} />
      <TextField
        {...register('content')}
        fullWidth
        maxRows={MAX_TEXT_AREA_ROWS}
        placeholder="Reply..."
        multiline
        error={Boolean(errors?.message)}
        helperText={(errors?.message?.message as string) || ''}
        disabled={isLoading}
        autoComplete="off"
      />
      <Button variant="contained" type="submit" disabled={isLoading}>
        Send
      </Button>
    </Stack>
  );
};

export default MessageForm;
