import React from 'react';

import { ConfirmationDialog } from 'components';
import { useArchiveDocument } from 'fetch-utils/documents/documents-hooks';

export interface ConfirmationDialogProps {
  id: number;
  open: boolean;
  tab?: string;
  closeFunction(): void;
  onSuccess?: (id: number) => void;
  isFileArchived?: boolean;
}

export const ArchiveFileConfirmationDialog: React.FC<
  ConfirmationDialogProps
> = ({ open, tab, id, onSuccess, closeFunction, isFileArchived }) => {
  const title = isFileArchived
    ? 'Confirm unarchive this file'
    : 'Confirm archive this file';
  const content = isFileArchived
    ? 'Are you sure you want to unarchive this file?'
    : 'Are you sure you want to archive this file?';
  const confirmLabel = isFileArchived ? 'Unarchive' : 'Archive';

  const { mutateAsync: archiveDocument, isLoading: isDocumentArchiving } =
    useArchiveDocument({
      onSuccess,
    });

  const onConfirm = async () => {
    await archiveDocument({
      documentId: id,
      mode: isFileArchived ? 'UNARCHIVE' : 'ARCHIVE',
    });
    closeFunction();
  };

  return (
    <>
      <ConfirmationDialog
        open={open}
        onConfirm={onConfirm}
        onCancel={closeFunction}
        title={title}
        content={content}
        confirmLabel={confirmLabel}
        isLoading={isDocumentArchiving}
      />
    </>
  );
};

export default ArchiveFileConfirmationDialog;
