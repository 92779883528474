import React from 'react';

import { Navigate, useParams } from 'react-router-dom';

import { ResponsiveView } from '@liscio/ui';

import {
  ACCOUNTS_DOMAIN,
  ACCOUNTS_PATHS,
} from 'modules/accounts/route-constants';

export interface AccountBankAccountFormViewProps {}

const AccountBankAccountFormDesktopView = React.lazy(
  () =>
    import(
      './AccountBankAccountFormDesktopView/AccountBankAccountFormDesktopView'
    )
);

export const AccountBankAccountFormView: React.FC<
  AccountBankAccountFormViewProps
> = () => {
  const { id: accountId } = useParams();
  return (
    <ResponsiveView
      // There is no 'multiple bank accounts' form in the mobile view, so fallback to bank account list view.
      SmallView={
        <Navigate
          to={`/${ACCOUNTS_DOMAIN}${ACCOUNTS_PATHS.details.root}/${accountId}/${ACCOUNTS_PATHS.details.tabs.bankAccounts}`}
        />
      }
      LargeView={<AccountBankAccountFormDesktopView />}
    />
  );
};

export default AccountBankAccountFormView;
