import React from 'react';

import { styled, Box, Typography } from '@liscio/ui';

export interface EmptyListPlaceholderProps {
  label: string;
}

const Wrapper = styled(Box)({
  position: 'absolute',
  width: '100%',
  height: '70%',
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'center',
  justifyContent: 'center',
});

export const EmptyListPlaceholder: React.FC<EmptyListPlaceholderProps> = ({
  label,
}) => {
  return (
    <Wrapper>
      <Typography>{label}</Typography>
    </Wrapper>
  );
};

export default EmptyListPlaceholder;
