import React from 'react';

import { ErrorDialog } from './ErrorDialog';

export interface NetworkErrorDialogProps {
  hasError: boolean;
}

export const NetworkErrorDialog: React.FC<NetworkErrorDialogProps> = ({
  hasError,
}) => {
  return (
    <ErrorDialog
      title="Network problem"
      error={hasError} // we don't want to show ugly network connection message
      defaultMessage="Please check your internet connection"
      onClose={() => window.location.reload()}
      closeLabel="Reload"
    />
  );
};

export default NetworkErrorDialog;
