import { DEFAULT_ERROR_MESSAGE } from '@liscio/utils';

export const parseError = (error: any) => {
  if (error) {
    if (typeof error?.response?.data?.message === 'string')
      return error?.response?.data?.message;

    return DEFAULT_ERROR_MESSAGE;
  }
};
