import { formatRelative, parse } from 'date-fns';

import { TodoFeedsQuery } from '@liscio/api/graphql';
import { locale } from '@liscio/utils';

import { Unpacked } from 'types/utilityTypes';

export const getDueDate: (
  todoFeed: Unpacked<TodoFeedsQuery['todoFeeds']>
) => string = (todoFeed) => {
  if (
    ['WorkflowRequest', 'Invoice', 'Task'].includes(
      String(todoFeed?.item?.__typename)
    ) &&
    todoFeed?.item?.dueByDate
  ) {
    const dueDate = parse(todoFeed.item.dueByDate, 'yyyy-MM-dd', new Date());
    return formatRelative(dueDate, new Date(), { locale });
  } else {
    return '-';
  }
};

export default getDueDate;
