import React, { useCallback, useMemo } from 'react';

import { useTheme, Box, Stack, Typography, Button } from '@liscio/ui';
import { useForm, SubmitHandler } from 'react-hook-form';

import EmailAndPasswordFields from './EmailAndPasswordFields/EmailAndPasswordFields';
import { getValidationSchema } from './helpers';
import OAuthButtonsDesktop from './OAuthButtonsDesktop/OAuthButtonsDesktop';
import { RememberMeToggle, useRememberMe } from './RememberMe';
import { Link } from 'components';
import { ErrorDialog } from 'components/ErrorDialog';
import { useYupValidationResolver } from 'custom-hooks';
import { useForgotPassword } from 'fetch-utils/users/auth-hooks';
import { UseAuthPayload } from 'fetch-utils/users/use-auth';

export interface LoginForm {
  email: string;
  password: string;
  // Controls enabling remember me
  enableRememberMe: boolean;
}

export interface SignInFormProps {
  auth: UseAuthPayload;
}

export const SignInForm = ({ auth }: SignInFormProps) => {
  const theme = useTheme();
  const { login, isLoggingIn, loginError } = auth;
  const { email: rememberMeEmail, rememberEmail } = useRememberMe();
  const { setForgotPasswordEmail } = useForgotPassword();
  const validationSchema = useMemo(getValidationSchema, []);
  const resolver = useYupValidationResolver(validationSchema);
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm<LoginForm>({
    defaultValues: {
      email: '',
      password: '',
      enableRememberMe: false,
    },
    resolver,
  });

  // Update/reset email field when remember me loads
  const updateRememberMeEmailValue = useCallback(
    (email: string) => reset({ email }),
    [reset]
  );

  // Form submit login
  const onSubmit: SubmitHandler<LoginForm> = async ({
    email,
    password,
    enableRememberMe,
  }) => {
    await login({
      email,
      password,
      time_zone: new Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

    // Remember me
    if (enableRememberMe || rememberMeEmail) {
      await rememberEmail(email);
    }
  };

  // Forgot Password Capture
  const captureForgotPasswordEmail = useCallback(() => {
    const email = getValues('email');
    setForgotPasswordEmail(email);
  }, [getValues, setForgotPasswordEmail]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={3} useFlexGap>
          <OAuthButtonsDesktop />
          <Stack gap={5} useFlexGap>
            <EmailAndPasswordFields
              register={register}
              errors={errors}
              isLoggingIn={isLoggingIn}
            />
            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr"
              alignItems="center"
            >
              <RememberMeToggle
                name="enableRememberMe"
                resetEmailField={updateRememberMeEmailValue}
                control={control}
              />
              <Link
                onClick={captureForgotPasswordEmail}
                to="/authenticate/forgot-password"
                underline="none"
                color={theme.palette.primary.main}
                textAlign="right"
              >
                <Typography>Forgot your password?</Typography>
              </Link>
            </Box>
            <Button
              variant="contained"
              type="submit"
              disabled={isLoggingIn}
              size="large"
            >
              Login
            </Button>
          </Stack>
        </Stack>
      </form>
      <ErrorDialog title="Sign In Error" error={loginError} />
    </>
  );
};
