import React from 'react';

import { Helmet } from 'react-helmet-async';
import { Route, Routes } from 'react-router-dom';

import { REQUESTS_PAGE_TITLES, REQUESTS_PATHS } from './route-constants';
import { WorkflowView } from './views';
import ResponsiveRequestsListView from './views/RequestsListView/ResponsiveRequestsListView';
import TodosListView from './views/TodosListView/TodosListView';
import { CatchAllRedirect } from 'modules/CatchAllRedirect/CatchAllRedirect';

export const RequestModuleRoutes = () => (
  <>
    <Helmet title={REQUESTS_PAGE_TITLES.list} />
    <Routes>
      <Route
        path={REQUESTS_PATHS.list}
        element={<ResponsiveRequestsListView />}
      />
      <Route path={REQUESTS_PATHS.todos} element={<TodosListView />} />
      <Route path={'/:id/*'} element={<WorkflowView />} />
      <Route path="*" element={<CatchAllRedirect />} />
    </Routes>
  </>
);

export default RequestModuleRoutes;
