import React from 'react';

import { GoogleGIcon } from '@liscio/ui';

import { StyledOAuthButton } from './StyledOAuthButton';
import { useOAuth } from './useOauth';

export const GoogleOAuthLoginDesktop = () => {
  const { initiateOAuth } = useOAuth('google');

  return (
    <StyledOAuthButton
      type="button"
      onClick={initiateOAuth}
      variant="contained"
      startIcon={<GoogleGIcon fontSize="small" />}
      size="large"
    >
      Google
    </StyledOAuthButton>
  );
};
