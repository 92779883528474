import { Dispatch, SetStateAction } from 'react';

import { Interweave } from 'interweave';

import { Dialog, Loading } from '@liscio/ui';

import { useTermsAndConditions } from 'fetch-utils/users/user-hooks';

type TermsAndConditionsDialogProps = {
  open: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export default function TermsAndConditionsDialog({
  open,
  setIsOpen,
}: TermsAndConditionsDialogProps) {
  const { data: termsAndConditions, isLoading } = useTermsAndConditions();

  return (
    <Dialog onClose={() => setIsOpen(false)} open={open}>
      {isLoading ? (
        <Loading />
      ) : (
        <Interweave content={termsAndConditions?.data?.content || ''} />
      )}
    </Dialog>
  );
}
