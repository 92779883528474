import { useQuery } from '@tanstack/react-query';

import { CpaCustomThemeRequest } from '@liscio/api';

import apiClient from 'fetch-utils/api-client';



export const useGetCpaTheme = (params?: CpaCustomThemeRequest) =>
  useQuery({
    queryFn: () => apiClient.cpa.getCpaTheme(params!),
    queryKey: ['getCpaTheme', params],
    enabled: Boolean(
      (params as any)?.cpa_id || (params as any)?.custom_liscio_url
    ),
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
