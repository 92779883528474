import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import { TaskContact, TaskType } from '@liscio/api';

import apiClient from 'fetch-utils/api-client';



const { getInitialTaskContacts, searchTaskContacts } = apiClient.contacts;
export const useInitialToDoTaskEmployees = () =>
  useQuery({
    queryKey: ['initialToDoTaskEmployees'],
    queryFn: async () => getInitialTaskContacts(TaskType.ToDo),
  });
export const useSearchToDoTaskEmployees = (keyword: string) =>
  useQuery({
    queryKey: ['searchToDoTaskEmployees', keyword],
    queryFn: async () => searchTaskContacts(TaskType.ToDo, keyword),
    enabled: Boolean(keyword.trim()),
  });

/**
 * Custom hook for handling initial and search filtered employees for ToDo task types
 */
export interface UseToDoTaskEmployeesHookPayload {
  employees: TaskContact[];
  isLoading: boolean;
  error?: any;
}
export const useToDoTaskEmployees = (keyword = '') => {
  const initialEmployees = useInitialToDoTaskEmployees();
  const searchEmployees = useSearchToDoTaskEmployees(keyword);

  const employeesData = useMemo(() => {
    const data: UseToDoTaskEmployeesHookPayload = {
      employees: [],
      isLoading: true,
    };

    if (keyword.trim()) {
      data.employees = searchEmployees.data || [];
      data.isLoading = searchEmployees.isLoading;
      data.error = searchEmployees.error;
    } else {
      data.employees = initialEmployees.data || [];
      data.isLoading = initialEmployees.isLoading;
      data.error = initialEmployees.error;
    }

    return data;
  }, [keyword, initialEmployees, searchEmployees]);

  return employeesData;
};
