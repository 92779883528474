import { PayInvoiceActionTypes } from 'modules/invoices/helpers/getPayInvoiceActionType';

export enum PayButtonLabels {
  AUTHORIZE = 'Authorize Payment',
  UPDATE = 'Update Payment',
  PAY = 'Pay Invoice',
}

const ActionTypeToLabelMapper = {
  [PayInvoiceActionTypes.AUTHORIZE]: 'Authorize Payment',
  [PayInvoiceActionTypes.UPDATE]: 'Update Payment',
  [PayInvoiceActionTypes.PAY]: 'Pay Invoice',
};

export const getPayButtonLabel: (
  payInvoiceActionType: PayInvoiceActionTypes
) => string = (payInvoiceActionType) => {
  return ActionTypeToLabelMapper[payInvoiceActionType];
};

export default getPayButtonLabel;
