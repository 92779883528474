import { useEffect, useRef } from 'react';

import { useLocation, matchPath } from 'react-router-dom';

import { useFileUploadsStore } from './useFileUploadsStore';

/**
 * Simple utility hook to handle clearing global file uploads on location change.
 * Not ideal, but that's what you get with global state...
 */
export const useClearFileUploadsOnRouteChange = (
  /**
   * List of paths that should not invoke clearing upload files
   */
  paths: string[] = []
) => {
  const location = useLocation();
  const clearFiles = useFileUploadsStore((store) => store.clearFiles);
  /**
   * Store previous location for comparison
   */
  const prevLocationRef = useRef<string>();

  useEffect(() => {
    const isCurrentLocationFileUploadPath = paths.some((path) =>
      matchPath(path, location.pathname)
    );
    const isPreviousLocationFileUploadsPath = prevLocationRef.current
      ? paths.some((path) => matchPath(path, prevLocationRef.current || ''))
      : false;
    if (
      location.pathname !== prevLocationRef.current &&
      isPreviousLocationFileUploadsPath &&
      !isCurrentLocationFileUploadPath
    ) {
      clearFiles();
    }
    prevLocationRef.current = location.pathname;
  }, [location.pathname, clearFiles, paths]);
};
