import React, { useEffect } from 'react';

import {
  ClosePaymentDialogReasons,
  RegisteredAddingCardSessionResponse,
} from '@liscio/api';
import { GlobalLoader } from '@liscio/ui';

import { ErrorDialog } from 'components';
import { PayInvoiceHandlerStates } from 'modules/invoices/components/PayInvoiceHandlerV2/PayInvoiceHandlerV2';

export interface StripeCardRegistrationStateProps {
  closeFunction(closeReason: ClosePaymentDialogReasons): void;
  registerStripePaymentSessionError?: Error | null;
  stripeRegisteredPaymentSourceSession?: RegisteredAddingCardSessionResponse;
  setCurrentState: React.Dispatch<
    React.SetStateAction<PayInvoiceHandlerStates>
  >;
}

export const StripeCardRegistrationState: React.FC<
  StripeCardRegistrationStateProps
> = ({
  closeFunction,
  registerStripePaymentSessionError,
  stripeRegisteredPaymentSourceSession,
  setCurrentState,
}) => {
  useEffect(() => {
    const redirectUrl = stripeRegisteredPaymentSourceSession?.session_id?.url;
    if (redirectUrl) {
      setCurrentState(
        PayInvoiceHandlerStates.STRIPE_CARD_REGISTRATION_PROCESSED
      );
    }
  }, [setCurrentState, stripeRegisteredPaymentSourceSession]);

  return (
    <>
      <GlobalLoader open={!registerStripePaymentSessionError} />
      <ErrorDialog
        error={registerStripePaymentSessionError}
        onClose={() => closeFunction(ClosePaymentDialogReasons.VOID)}
      />
    </>
  );
};

export default StripeCardRegistrationState;
