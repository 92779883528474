import { useState } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { NavMenuItem } from '@liscio/common';
import { Icon } from '@liscio/ui';

import { MobileFileUploadDrawer } from 'components';
import { routes } from 'routes';

export const UploadFileNavigationMenuItemMobile = () => {
  const { clientFileUploadRedesign } = useFlags();
  const [showUploadDialog, setShowUploadDialog] = useState(false);

  if (clientFileUploadRedesign) {
    return (
      <>
        <MobileFileUploadDrawer
          open={showUploadDialog}
          handleClose={() => setShowUploadDialog(false)}
          url={routes.filesNew.link()}
        />
        <NavMenuItem
          data-testid="sidebar__upload_file"
          icon={<Icon icon="uploadFile" />}
          label="Upload File"
          onClick={() => setShowUploadDialog(true)}
        />
      </>
    );
  }

  return (
    <NavMenuItem
      data-testid="sidebar__upload_file"
      icon={<Icon icon="uploadFile" />}
      label="Upload File"
      to={routes.filesNew.link()}
    />
  );
};
