import React from 'react';

import { ResponsiveView } from '@liscio/ui';

import ListViewDesktop from './ListViewDesktop/ListViewDesktop';
import ListViewMobile from './ListViewMobile/ListViewMobile';

export interface ResponsiveMessageListViewProps {}

export const ResponsiveMessageListView: React.FC<
  ResponsiveMessageListViewProps
> = () => {
  return (
    <ResponsiveView
      SmallView={<ListViewMobile />}
      LargeView={<ListViewDesktop />}
    />
  );
};

export default ResponsiveMessageListView;
