import { useFileReader } from '@liscio/common';
import { Box, BoxProps, ProgressBar } from '@liscio/ui';

import { GenericFilePreview } from './GenericFilePreview';

export type FormFilePreviewProps = BoxProps & {
  // The file object to preview
  file: File;
};

/**
 * Basic component for loading and redering GenericFilePreview
 * using form file objects and FileReader
 */
export const FormFilePreview = ({
  file,
  ...boxProps
}: FormFilePreviewProps) => {
  const { isReading, dataURL } = useFileReader(file);

  return (
    <Box {...boxProps}>
      {isReading && <ProgressBar variant="indeterminate" />}
      {dataURL && <GenericFilePreview fileSrc={dataURL} mimeType={file.type} />}
    </Box>
  );
};
