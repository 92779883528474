import { Box, styled, Typography } from '@liscio/ui';

import taxDeliveryImageSrc from '../../../../assets/tax-delivery-image.svg';

type HelperContentProps = {
  type:
    | 'LOADING'
    | 'SELECT-DOCUMENT'
    | 'COMPLETED'
    | 'ERROR'
    | 'ADDITIONAL-SIGNER'
    | undefined;
};

export const HelperContentStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: 'calc(100vh - 200px)',
});

export const HelperContent = ({ type }: HelperContentProps) => {
  // Content logic TBD
  return (
    <HelperContentStyled>
      <img
        src={taxDeliveryImageSrc}
        alt="Tax delivery image"
        style={{ width: '70%', maxHeight: '400px', marginBottom: '32px' }}
      />
      {type === 'LOADING' && (
        <Box textAlign="center">
          <Typography variant="h3">Loading...</Typography>
          <Typography variant="body1">
            Please wait while we load your request.
          </Typography>
        </Box>
      )}
      {type === 'SELECT-DOCUMENT' && (
        <Box textAlign="center">
          <Typography variant="h3">Select document</Typography>
          <Typography variant="body1">
            Select a document on the right side pane to be reviewed.
          </Typography>
        </Box>
      )}
      {type === 'ERROR' && (
        <Box textAlign="center">
          <Typography variant="h3">Sorry! We encountered an error.</Typography>
          <Typography variant="body1">
            Please try again or contact support if the problem persists.
          </Typography>
        </Box>
      )}
      {type === 'COMPLETED' && (
        <Box textAlign="center">
          <Typography variant="h3">Thank you!</Typography>
          <Typography variant="body1">
            Your responses were submitted successfully.
          </Typography>
        </Box>
      )}
      {type === 'ADDITIONAL-SIGNER' && (
        <Box textAlign="center">
          <Typography variant="h3">Additional signer required</Typography>
          <Typography variant="body1">
            The document you just signed requires an additional signer. Please
            provide the next signer with access to your device, then click eSign
            to proceed with signing.
          </Typography>
        </Box>
      )}
    </HelperContentStyled>
  );
};
