import React from 'react';

import { ResponsiveView } from '@liscio/ui';

const FileDetailsViewMobile = React.lazy(
  () => import('./FileDetailsViewMobile/FileDetailsViewMobile')
);
const FileDetailsViewDesktop = React.lazy(
  () => import('./FileDetailsViewDesktop/FileDetailsViewDesktop')
);

export interface ResponsiveDashboardViewProps {}

export const ResponsiveDashboardView: React.FC<
  ResponsiveDashboardViewProps
> = () => {
  return (
    <ResponsiveView
      SmallView={<FileDetailsViewMobile />}
      LargeView={<FileDetailsViewDesktop />}
    />
  );
};

export default ResponsiveDashboardView;
