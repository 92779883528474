import React from 'react';

import { SxProps } from '@mui/material';

import { styled, useTheme, Stack } from '@liscio/ui';

type LoginLogoMobileProps = {
  sx?: SxProps;
};

const Wrapper = styled(Stack)(() => ({
  img: {
    width: '33%',
    maxWidth: '265px',
  },
})); 

export const LoginLogoMobile = ({ sx }: LoginLogoMobileProps) => {
  const theme = useTheme();
  return (
    <Wrapper justifyContent="center" alignItems="center" sx={sx}>
      <img src={theme?.assets?.logoSplash} alt="logo" />
    </Wrapper>
  );
};

export default LoginLogoMobile;
