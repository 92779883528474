import { TaskFieldsFragment, TodoFeedsQuery } from '@liscio/api/graphql';

import { Unpacked } from 'types/utilityTypes';

const taskTypeMap = {
  ADD_USER: 'Add User',
  BILL: 'Bill',
  EDOC: 'eDoc',
  GET_SIGNATURE: 'Get Signature',
  GET_SIGNATURE_CONSENT: 'Get Signature Consent',
  MANAGE_TO_GO_ITEMS: 'Manage To-Go Items',
  MANAGE_TO_GO_ITEMS_PICKUP: 'Manage To-Go Items Pickup',
  ONBOARD_ACCOUNT: 'Onboard Account',
  OTHER: 'Other',
  PAYROLL_CHANGE: 'Payroll Change',
  REMOVE_USER: 'Remove User',
  REQUEST_INFO: 'Request Info',
  SEND_MESSAGE: 'Send Message',
  TO_DO: 'To Do',
  VIRTUAL_MEETING: 'Virtual Meeting',
  WorkflowRequest: 'Workflow Request',
};

const itemTypeMap = {
  Invoice: 'Invoice',
  WorkflowRequest: 'Workflow Request',
};

export default function getType(data: Unpacked<TodoFeedsQuery['todoFeeds']>) {
  const itemType = data?.item?.__typename;

  const readableItemType = itemType
    ? itemTypeMap[itemType as keyof typeof itemTypeMap]
    : '';

  if (readableItemType) return readableItemType;

  const taskTypeKey = (data?.item as TaskFieldsFragment)?.taskType;

  if (taskTypeKey) {
    return taskTypeMap[taskTypeKey as keyof typeof taskTypeMap];
  }

  return itemType || '';
}
