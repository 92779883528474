import React from 'react';

import { Checkbox as MUICheckbox, CheckboxProps } from '@mui/material';

import { styled, Typography } from '@liscio/ui';

export interface InlineCheckboxProps extends CheckboxProps {
  label: React.ReactNode;
}

const StyledLabel = styled('label')({
  display: 'inline-flex',
  alignItems: 'center',
});

export const InlineCheckbox: React.FC<InlineCheckboxProps> = ({
  label,
  ...rest
}) => {
  return (
    <StyledLabel>
      <MUICheckbox {...rest} />
      <Typography>{label}</Typography>
    </StyledLabel>
  );
};

export default InlineCheckbox;
