import React from 'react';

import { ConfirmationDialog } from 'components';

export interface ConfirmationDialogProps {
  open: boolean;
  tab?: string;
  onCancel(): void;
  onConfirm(): void;
  isFileArchived?: boolean;
}

export const ReviewedConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  onCancel,
  onConfirm,
}) => {
  const title = 'Confirm Document Review';
  const content =
    'I have reviewed this document in detail and confirm its accuracy to the best of my knowledge.';
  const confirmLabel = 'Agree';
  const cancelLabel = 'Cancel';

  return (
    <>
      <ConfirmationDialog
        open={open}
        onConfirm={onConfirm}
        onCancel={onCancel}
        title={title}
        content={content}
        confirmLabel={confirmLabel}
        cancelLabel={cancelLabel}
      />
    </>
  );
};

export default ReviewedConfirmationDialog;
