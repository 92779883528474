import { useMemo } from 'react';

import { Box, Typography } from '@liscio/ui';
import { readableBytes } from '@liscio/utils';

import { FileUploadsBatchType } from 'stores/fileUploads';

export type FileUploadsProgressBatchMetaProps = {
  uploadBatch: FileUploadsBatchType;
};

/**
 * Meta data for upload batch
 */
export const FileUploadsProgressBatchMeta = ({
  uploadBatch,
}: FileUploadsProgressBatchMetaProps) => {
  const totalComplete = useMemo(
    () =>
      uploadBatch.files.reduce((sum, item) => {
        if (item.progress === 1) {
          return sum + 1;
        }
        return sum;
      }, 0),
    [uploadBatch.files]
  );
  const totalFiles = uploadBatch.files.length;

  return (
    <Box>
      <Typography variant="body1">
        {totalComplete}/{totalFiles} : {readableBytes(uploadBatch.batchSize)}
      </Typography>
    </Box>
  );
};
