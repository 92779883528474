import { TodoFeedsQuery } from '@liscio/api/graphql';

import { Unpacked } from 'types/utilityTypes';

export const getLabel: (
  todoFeed: Unpacked<TodoFeedsQuery['todoFeeds']>
) => string = (todoFeed) => {
  if (todoFeed?.item?.__typename === 'WorkflowRequest') {
    return todoFeed.item.title || '';
  } else {
    return todoFeed?.item?.subject || '';
  }
};

export default getLabel;
