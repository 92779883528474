/**
 * Legacy path redirect source/targets
 * NOTE: These paths correspond to legacy application paths
 * that have different path signatures in this new client app
 */
export const legacyPathConfig: Record<string, { redirect: string }> = {
  '/magicLink/:id': { redirect: '/authenticate/ml/:id' },
  '/ml/:id': { redirect: '/authenticate/ml/:id' },
  '/login': { redirect: '/authenticate/login' },
  '/cp/:id': { redirect: '/authenticate/cp/:id' },
  '/dashboard': { redirect: '/dashboard/home' },
  '/all_messages': { redirect: '/messages/list' },
  '/inbox/:id': { redirect: '/messages/details/:id' },
  '/alert/:id': { redirect: '/messages/details/:id' },
  '/sent/:id': { redirect: '/messages/details/:id' },
  '/draft/:id': { redirect: '/messages/details/:id' },
  '/archive/:id': { redirect: '/messages/details/:id' },
  '/pinned/:id': { redirect: '/messages/details/:id' },
  '/all_messages/:id': { redirect: '/messages/details/:id' },
  '/fileupload/new': { redirect: '/files/new' },
  '/message/new': { redirect: '/messages/new' },
  '/accounts': { redirect: '/accounts/list' },
  '/accounts_beta': { redirect: '/accounts/list' },
  '/accountdetails/message/:id': { redirect: '/accounts/list' },
  '/account/new': { redirect: '/accounts/list' },
  '/account/edit/:id': { redirect: '/accounts/details/:id/overview' },
  '/accountdetails/:id': { redirect: '/accounts/details/:id/overview' },
  '/accountdetails/:id/details': { redirect: '/accounts/details/:id/overview' },
  '/accountdetails/:id/relationships': {
    redirect: '/accounts/details/:id/overview',
  },
  '/accountdetails/:id/tasks': { redirect: '/accounts/details/:id/overview' },
  '/accountdetails/:id/messages': {
    redirect: '/accounts/details/:id/overview',
  },
  '/accountdetails/:id/notes': { redirect: '/accounts/details/:id/overview' },
  '/accountdetails/:id/payroll': { redirect: '/accounts/details/:id/overview' },
  '/accountdetails/:id/activity': {
    redirect: '/accounts/details/:id/overview',
  },
  '/accountdetails/:id/logins': { redirect: '/accounts/details/:id/overview' },
  '/accountdetails/:id/emails': { redirect: '/accounts/details/:id/overview' },
  '/accountdetails/:id/files': { redirect: '/accounts/details/:id/files' },
  '/accountdetails/:id/billing/invoices': {
    redirect: '/accounts/details/:id/overview',
  },
  '/accountdetails/:id/inFocus': { redirect: '/accounts/details/:id/overview' },
  '/accountdetails/:id/billing/recurring': {
    redirect: '/accounts/details/:id/overview',
  },
  '/accountdetails/:id/emails/:email_id': { redirect: '/accounts/details/:id' },
  '/profile_settings': { redirect: '/profile/details' },
  '/fileupload/:id': { redirect: '/files/new' },
  '/files': { redirect: '/files/list' },
  '/files/new': { redirect: '/files/new' },
  all_tasks: { redirect: '/tasks/list' },
  pending_review: { redirect: '/tasks/list' },
  archived: { redirect: '/tasks/list' },
  draft: { redirect: '/tasks/list' },
  '/all_tasks': { redirect: '/tasks/list' },
  '/tasks/:id': { redirect: '/tasks/details/:id' },
  '/task/detail/:id': { redirect: '/tasks/details/:id' },
  '/requests/:id': { redirect: '/requests/:id' },
  '/message/new/modal': { redirect: '/messages/new' },
  '/billing/invoices': { redirect: '/invoices/list' },
  '/billing/recurring': { redirect: '/invoices/list' },
  '/billing/invoices/:id': { redirect: '/invoices/details/:id' },
  '/billing/recurring/:id': { redirect: '/invoices/details/:id' },
};
export const legacyPaths = Object.keys(legacyPathConfig).map((path) => ({
  path,
}));
