import React from 'react';

import { FileTabOptions } from '@liscio/api';

import { Tabs as TabsComponent, Box } from '@liscio/ui';

export interface FilesListTabsProps {
  selectedOptionValue: FileTabOptions;
  onNavigationClick(value?: FileTabOptions): void;
  activeNumberOfFiles?: number | null;
  archivedNumberOfFiles?: number | null;
  isCountVisible?: boolean;
}

export const FilesListTabs: React.FC<FilesListTabsProps> = ({
  selectedOptionValue,
  onNavigationClick,
  activeNumberOfFiles,
  archivedNumberOfFiles,
  isCountVisible = true,
}) => {
  const options = [
    {
      label: 'Active',
      value: 'active',
      count: activeNumberOfFiles,
      isCountVisible,
    },
    {
      label: 'Archived',
      value: 'archived',
      count: archivedNumberOfFiles,
      isCountVisible,
    },
  ];

  return (
    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
      <TabsComponent
        options={options}
        selectedOptionValue={selectedOptionValue}
        onNavigationClick={onNavigationClick}
      />
    </Box>
  );
};
