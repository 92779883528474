import React from 'react';

import { ResponsiveView } from '@liscio/ui';

import AuthenticateLayoutDesktop from './AuthenticateLayoutDesktop';
import AuthenticateLayoutMobile from './AuthenticateLayoutMobile';

export interface ResponsiveLayoutProps {}

export const ResponsiveLayout: React.FC<ResponsiveLayoutProps> = () => {
  return (
    <ResponsiveView
      LargeView={<AuthenticateLayoutDesktop />}
      SmallView={<AuthenticateLayoutMobile />}
    />
  );
};

export default ResponsiveLayout;
