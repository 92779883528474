import { createPortal } from 'react-dom';

import { PaymentEstimateObject } from '@liscio/api';
import { Loading, useViewportSize } from '@liscio/ui';

import { TaxDetailsTable } from 'modules/accounts/views/AccountOverviewView/AccountOverviewDesktopView/components/TaxDetailsAccordion/TaxDetailsTable';
import { TaxDetailsTableMobile } from 'modules/accounts/views/AccountOverviewView/AccountOverviewDesktopView/components/TaxDetailsAccordion/TaxDetailsTableMobile';
import { useTaxDeliveryStore } from 'stores/workflow/useTaxDeliveryStore';

type Props = {
  data: PaymentEstimateObject[] | undefined;
  isLoading: boolean;
};

export const TaxDeliveryEstimateTable = ({ data, isLoading }: Props) => {
  const { mainContentPortalRef } = useTaxDeliveryStore();
  const { isMobile } = useViewportSize();

  if (mainContentPortalRef && mainContentPortalRef.current) {
    return createPortal(
      isLoading && !data ? (
        <Loading numberOfElements={5} mt={1} />
      ) : isMobile ? (
        <TaxDetailsTableMobile data={data || []} />
      ) : (
        <TaxDetailsTable data={data || []} />
      ),
      mainContentPortalRef.current as Element
    );
  } else {
    return null;
  }
};
