// Component inspired/copied from: https://mui.com/material-ui/react-tabs/#basic-tabs

import React from 'react';

import { Box } from '@liscio/ui';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number | string;
  value: number | string;
}

export const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
  ...other
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

export default TabPanel;
