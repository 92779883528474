import React, { useState } from 'react';

import { FileFiltersType } from '@liscio/api';
import { Overlay } from '@liscio/common';

import { FileFiltersForm } from './components/FileFiltersForm';
import { FiltersButton } from 'components';

export interface FileFiltersProps {
  filters?: FileFiltersType;
  onChange?: (filters: FileFiltersType) => void;
  hasFiltersSelected?: boolean;
  onClear?: () => void;
}

export const FileFilters = ({
  filters,
  onChange,
  hasFiltersSelected,
  onClear,
}: FileFiltersProps) => {
  const [showFiltersOverlay, setShowFiltersOverlay] = useState(false);

  return (
    <>
      <FiltersButton
        onClick={() => setShowFiltersOverlay(true)}
        hasFiltersSelected={hasFiltersSelected}
        onClear={onClear}
      />
      <Overlay open={showFiltersOverlay}>
        <FileFiltersForm
          filters={filters}
          onClose={() => setShowFiltersOverlay(false)}
          onFilter={onChange}
        />
      </Overlay>
    </>
  );
};
