import React from 'react';

import {
  Switch as MUISwitch,
  SwitchProps,
  FormControlLabel,
  SxProps,
} from '@mui/material';

import { styled } from '@liscio/ui';

export interface DefaultSwitchProps extends SwitchProps {
  label: React.ReactNode;
  labelStyles?: SxProps;
}

const LabelWrapper = styled(FormControlLabel)({
  display: 'flex',
});

export const DefaultSwitch: React.FC<DefaultSwitchProps> = ({
  label,
  labelStyles,
  ...rest
}) => {
  return (
    <LabelWrapper
      control={<MUISwitch {...rest} />}
      label={label}
      sx={{
        ...labelStyles,
        // Gross, yes, but the padding on switches is weird and I expect our design system
        // will want toggles to hug the left side of their container flush.
        // Manipulating the padding directly in the MUI switch uglifies the component in other ways.
        position: 'relative',
        right: '8px',
      }}
    />
  );
};

export default DefaultSwitch;
