import React from 'react';

import { useController, Control } from 'react-hook-form';

import Switch, { DefaultSwitchProps } from './Switch';

export interface RHFSwitchProps extends DefaultSwitchProps {
  name: string;
  control: Control<any>;
}

export const RHFSwitch: React.FC<RHFSwitchProps> = ({
  control,
  name,
  ...rest
}) => {
  const { field } = useController({ name, control });

  return (
    <Switch
      {...rest}
      value={field.value || false}
      checked={field.value || false}
      onChange={field.onChange}
    />
  );
};

export default RHFSwitch;
