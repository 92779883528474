import React from 'react';

import { Skeleton } from '@mui/material';
import { useTheme, styled, Icon, Stack, Typography, Chip } from '@liscio/ui';

import { statusColorMapper } from 'modules/invoices/helpers';

export interface StatusBarProps {
  isRecurring?: boolean;
  frequency?: string;
  status?: string;
  isLoading?: boolean;
}

const StyledStack = styled(Stack)({
  padding: '0 16px',
  height: '38px',
});

const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
}));

export const StatusBar: React.FC<StatusBarProps> = ({
  isRecurring = false,
  frequency,
  status,
  isLoading,
}) => {
  const theme = useTheme();
  const statusColor =
    statusColorMapper[status || 'default'] || statusColorMapper['default'];
  return (
    <StyledStack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <>
        <Stack direction="row" gap="6px" alignItems="center" minHeight="29px">
          <Label variant="button">Status:</Label>
          {isLoading && <Skeleton width="50px" />}
          {!isLoading && (
            <Chip
              sx={{
                color: statusColor,
                borderColor: statusColor,
                textTransform: 'capitalize',
              }}
              label={status}
              variant="outlined"
            />
          )}
        </Stack>
        {isLoading && <Skeleton width="100px" />}
        {!isLoading && (
          <Stack direction="row" gap="6px" alignItems="center">
            {isRecurring && (
              <Icon
                icon="recurringCharge"
                htmlColor={theme.palette.primary.main}
              />
            )}
            <Label variant="button">
              {isRecurring && `Recurring ${frequency}`}
              {!isRecurring && 'One-Time'}
            </Label>
          </Stack>
        )}
      </>
    </StyledStack>
  );
};

export default StatusBar;
