export const PROFILE_DOMAIN = 'profile';
export const PROFILE_DOMAIN_URL = `/${PROFILE_DOMAIN}/*`;

export const PROFILE_PATHS = {
  details: '/details',
  privacyPolicy: '/privacy-policy',
  termsAndConditions: '/terms-and-conditions',
  termsAndConditionsAcceptance: '/terms-and-conditions-acceptance',
  changePassword: '/change-password',
  editProfile: '/edit-profile',
  securitySettings: '/security-settings',
};

export const PROFILE_PAGE_TITLES = {
  details: 'Profile Details',
  privacyPolicy: 'Privacy Policy',
  termsAndConditions: 'Terms and Conditions',
  termsAndConditionsAcceptance: 'Accept Terms and Conditions',
  changePassword: 'Change Password',
  editProfile: 'Edit Profile',
};
