import React from 'react';

import { ResponsiveView } from '@liscio/ui';

const AccountOverviewMobileView = React.lazy(
  () => import('./AccountOverviewMobileView/AccountOverviewMobileView')
);
const AccountOverviewDesktopView = React.lazy(
  () => import('./AccountOverviewDesktopView/AccountOverviewDesktopView')
);

export interface AccountOverviewViewProps {}

export const AccountOverviewView: React.FC<AccountOverviewViewProps> = () => {
  return (
    <ResponsiveView
      SmallView={<AccountOverviewMobileView />}
      LargeView={<AccountOverviewDesktopView />}
    />
  );
};

export default AccountOverviewView;
