import React, { useEffect } from 'react';

import { Capacitor } from '@capacitor/core';
import { DialogContent, DialogTitle } from '@mui/material';

import { ClosePaymentDialogReasons } from '@liscio/api';
import { Platform } from '@liscio/common';
import { Button, Stack } from '@liscio/ui';

export interface OpenStripeStateProps {
  closeFunction(closeReason: ClosePaymentDialogReasons): void;
  stripeRedirectUrl?: string;
}

export const OpenStripeState = ({
  closeFunction,
  stripeRedirectUrl,
}: OpenStripeStateProps) => {
  const platform = Capacitor.getPlatform();

  useEffect(() => {
    if (stripeRedirectUrl) {
      if (platform === Platform.ANDROID) {
        window.open(stripeRedirectUrl);
        closeFunction(ClosePaymentDialogReasons.VOID);
      }
      if (platform === Platform.WEB) {
        window.location.replace(stripeRedirectUrl);
        closeFunction(ClosePaymentDialogReasons.VOID);
      }
    }
  }, [closeFunction, platform, stripeRedirectUrl]);

  if (platform !== Platform.IOS) {
    return null;
  }

  return (
    <>
      <DialogTitle id="alert-dialog-title">
        Redirecting to Stripe for payment processing:
      </DialogTitle>
      <DialogContent>
        <Stack alignItems="center" justifyContent="center">
          <Button
            variant="contained"
            onClick={() => {
              window.open(stripeRedirectUrl);
              closeFunction(ClosePaymentDialogReasons.VOID);
            }}
          >
            Click to Continue
          </Button>
        </Stack>
      </DialogContent>
    </>
  );
};

export default OpenStripeState;
