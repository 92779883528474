import React, { SyntheticEvent, useCallback, useState } from 'react';

import { Divider, Box, Button, Icon, Stack } from '@liscio/ui';
import { useNavigate } from 'react-router-dom';

import { Message, MessageResponseBody, BasicAccount } from '@liscio/api';

import {
  MessagesTable,
  MessagesTabs,
  ArchiveMessageThreadsDialog,
} from './components';
import AccountsAutocomplete from './components/AccountsAutocomplete/AccountsAutocomplete';
import { MessageSearchURLParams } from 'fetch-utils/messages/messages-filters';
import {
  useBulkArchiveMessageThreads,
  useMessages,
} from 'fetch-utils/messages/messages-hooks';
import {
  MESSAGES_DOMAIN,
  MESSAGES_PATHS,
} from 'modules/messages/route-constants';

export const MESSAGES_PER_PAGE = 50;

type CountType = keyof Omit<
  MessageResponseBody,
  'inbox' | 'sent' | 'draft' | 'archive'
>;

export type TabType = string &
  keyof Omit<
    MessageResponseBody,
    | 'inbox_count'
    | 'sent_count'
    | 'pinned_count'
    | 'draft_count'
    | 'archive_count'
    | 'all_messages'
    | 'unread_thread_count'
  >;

export interface MessagesProps {
  filters: MessageSearchURLParams;
  setFilters: (filters: MessageSearchURLParams) => void;
}

export const Messages: React.FC<MessagesProps> = ({ filters, setFilters }) => {
  const navigate = useNavigate();
  const [showArchiveDialog, setShowArchiveDialog] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const { data: allMessages, isLoading: isMessagesLoading } = useMessages({
    type: filters.type || 'inbox',
    pageParam: Number(filters.pageParam || 1),
    searchString: filters.searchString,
    perPage: Number(filters.perPage || 50),
    accountId: Number(filters.accountId),
  });

  const { mutateAsync: archiveThreads } = useBulkArchiveMessageThreads();

  const tab = filters.type as TabType;
  const messages: Message[] = allMessages?.[tab] || [];
  const count: number =
    (allMessages?.[`${tab}_count` as CountType] as number) || 0;
  const totalPages = Math.ceil(count / MESSAGES_PER_PAGE) || 0;

  const handleTabChange = useCallback(
    (newTab: TabType) => {
      setFilters({
        type: newTab! as TabType,
        pageParam: '1',
      });
    },
    [setFilters]
  );

  const handleAccountFilterChange = useCallback(
    (event: SyntheticEvent, account: BasicAccount | null) => {
      setFilters({
        accountId: String(account?.value),
        pageParam: '1',
      });
    },
    [setFilters]
  );

  function handleRowSelect(rows: string[]) {
    setSelectedRows(rows);
  }

  const handleArchiveThreads = useCallback(async () => {
    await archiveThreads(selectedRows);
    setFilters({ pageParam: '1' });
    setShowArchiveDialog(false);
  }, [setFilters, archiveThreads, selectedRows]);

  const enableRowSelect = tab === 'inbox';
  const threadCount = selectedRows.length;
  return (
    <>
      <Box pl="24px" pr="24px">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <MessagesTabs
            inboxNumberOfMessages={allMessages?.inbox_count}
            sentNumberOfMessages={allMessages?.sent_count}
            draftsNumberOfMessages={allMessages?.draft_count}
            archivedNumberOfMessages={allMessages?.archive_count}
            pinnedNumberOfMessages={allMessages?.pinned_count}
            selectedOptionValue={tab}
            onNavigationClick={handleTabChange}
            sx={{ height: '72px' }}
          />
          <Button
            sx={{ marginRight: '24px' }}
            onClick={() => navigate(`/${MESSAGES_DOMAIN}${MESSAGES_PATHS.new}`)}
            variant="contained"
            startIcon={<Icon icon="add" />}
            data-testid="new_message"
          >
            Message
          </Button>
        </Stack>
        <Divider />
        <Stack
          direction="row"
          justifyContent="space-between"
          p="24px 24px 24px 0px"
        >
          <AccountsAutocomplete onChange={handleAccountFilterChange} />
          <Button
            onClick={() => setShowArchiveDialog(true)}
            variant="outlined"
            disabled={!enableRowSelect || !selectedRows || threadCount === 0}
            startIcon={<Icon icon="archive" />}
            data-testid="archive"
          >
            Archive
          </Button>
        </Stack>
        {messages && (
          <MessagesTable
            totalPages={totalPages}
            page={filters.pageParam!}
            messages={messages}
            isMessagesLoading={isMessagesLoading}
            onPageChange={(newPage: number) =>
              setFilters({ pageParam: String(newPage || 1) })
            }
            enableRowSelect={enableRowSelect}
            handleRowSelect={handleRowSelect}
            tab={tab}
          />
        )}
      </Box>
      {showArchiveDialog && (
        <ArchiveMessageThreadsDialog
          open={showArchiveDialog}
          onClose={() => setShowArchiveDialog(false)}
          submitLabel="Ok"
          onSubmit={handleArchiveThreads}
          threadCount={threadCount}
        />
      )}
    </>
  );
};

export default Messages;
