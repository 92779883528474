import { DocumentsTypes } from '@liscio/api';

import {
  INVOICES_DOMAIN,
  INVOICES_PATHS,
} from 'modules/invoices/route-constants';
import {
  MESSAGES_DOMAIN,
  MESSAGES_PATHS,
} from 'modules/messages/route-constants';
import { TASKS_DOMAIN, TASKS_PATHS } from 'modules/tasks/route-constants';

export function makeFileSourceLink(
  file?: Partial<DocumentsTypes.DocumentDetail> | void
) {
  if (file?.source === 'Message') {
    return `/${MESSAGES_DOMAIN}${MESSAGES_PATHS.details}/${file?.link}`;
  }

  if (file?.source === 'Task') {
    return `/${TASKS_DOMAIN}${TASKS_PATHS.details}/${encodeURIComponent(
      String(file?.link)
    )}`;
  }

  if (file?.source === 'Bill') {
    return `/${INVOICES_DOMAIN}${INVOICES_PATHS.details}/${file?.link}`;
  }

  return '';
}
