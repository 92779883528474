import { TaskDetails, PaymentMethods } from '@liscio/api';

import { PayInvoiceHandlerStates } from 'modules/invoices/components/PayInvoiceHandlerV2/PayInvoiceHandlerV2';



export const calculateSelectPaymentMethodState = ({
  invoice,
  choice,
}: {
  invoice: void | TaskDetails | undefined;
  choice: PaymentMethods | null;
}) => {
  if (!invoice || !choice) {
    return PayInvoiceHandlerStates.SELECT_PAYMENT_METHOD;
  }
  const isRecurring = invoice.is_recurring;

  if (choice === PaymentMethods.ACH) {
    return PayInvoiceHandlerStates.SELECT_BANK_ACCOUNT_ACH_METHOD;
  }

  if (choice === PaymentMethods.CREDIT_CARD) {
    if (isRecurring) {
      return PayInvoiceHandlerStates.STRIPE_CARD_REGISTRATION_PROCESSING;
    } else {
      return PayInvoiceHandlerStates.STRIPE_PAYMENT_PROCESSING;
    }
  }

  return PayInvoiceHandlerStates.SELECT_PAYMENT_METHOD;
};

export default calculateSelectPaymentMethodState;
