import { domainDefaultPaths } from './domainDefaultPaths';

export const resolveRedirectByDomain = ({
  pathname = '',
}: {
  pathname: string;
}) => {
  const domain = pathname.split('/')[1];

  return domainDefaultPaths[domain]?.redirect || undefined;
};
