import React from 'react';

import { ResponsiveView } from '@liscio/ui';

const AccountPayrollFormMobileView = React.lazy(
  () => import('./AccountPayrollFormMobileView/AccountPayrollFormMobileView')
);
const AccountPayrollFormDesktopView = React.lazy(
  () => import('./AccountPayrollFormDesktopView/AccountPayrollFormDesktopView')
);

export const AccountPayrollFormView = () => {
  return (
    <ResponsiveView
      SmallView={<AccountPayrollFormMobileView />}
      LargeView={<AccountPayrollFormDesktopView />}
    />
  );
};

export default AccountPayrollFormView;
