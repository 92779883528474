export const statusColorMapper: { [key: string]: string } = {
  paid: '#62deb4',
  unpaid: '#d9c06d',
  pending: '#d9c06d',
  authorized: '#d9c06d',
  closed: '#d9c06d',
  failed: '#ff7094',
  overdue: '#ff7094',
  default: 'black',
};

export default statusColorMapper;
