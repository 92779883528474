import {
  ContactsAndEmployeeEntity,
  ContactsAndEmployeesResponse,
} from '@liscio/api';

import { Account } from 'components/ContactSelector/ContactSelector';



interface Args {
  selectedAccount?: Account | null;
  employeesAndContacts?: void | ContactsAndEmployeesResponse;
  relContactsAndCstOfAccount?: void | ContactsAndEmployeesResponse;
  searchedContacts?: void | ContactsAndEmployeesResponse;
  employeesAndContactsOfAccount?: void | Array<ContactsAndEmployeeEntity>;
  recipientSearchString: string;
}

const createRecipientFilter =
  (searchString: string) => (recipient: { label: string }) => {
    const normalizedLabel = recipient.label.toLowerCase();
    const normalizedSearchString = searchString.toLowerCase();
    return normalizedLabel.includes(normalizedSearchString);
  };

export const mungeRecipients: (args: Args) => ContactsAndEmployeesResponse = ({
  selectedAccount,
  employeesAndContacts,
  relContactsAndCstOfAccount,
  recipientSearchString = '',
}) => {
  if (selectedAccount) {
    return recipientSearchString
      ? relContactsAndCstOfAccount?.filter(
          createRecipientFilter(recipientSearchString)
        ) || []
      : relContactsAndCstOfAccount || [];
  }

  return recipientSearchString
    ? employeesAndContacts?.filter(
        createRecipientFilter(recipientSearchString)
      ) || []
    : employeesAndContacts || [];
};

export default mungeRecipients;
