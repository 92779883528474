import { UploadType } from '@liscio/api';

import { UploadFilesFormType } from './UploadFilesForm.types';
import apiClient from 'fetch-utils/api-client';
import { mungeToRecipientTuples } from 'fetch-utils/documents/documentsCallsUtils';
import { useFileUploads } from 'stores/fileUploads/useFileUploads';

export const useFileUploadsAndShare = () => {
  return useFileUploads<UploadFilesFormType>({
    uploadBatchKey: 'useFileUploadsAndShare',
    uploadType: UploadType.NewFile,
    onDocumentsUploaded: async ({ documents, batch, abortController }) => {
      await apiClient.documents.shareDocumentsByDocId(
        {
          recipients: mungeToRecipientTuples(batch.data.participants),
          doc_ids: documents.map(({ id }) => id),
          year: batch.data.year,
          month: batch.data.month,
          tags: batch.data.tags || [],
          message: batch.data.message || '',
          is_msg_alter: batch.data.isMessageAltered,
        },
        { signal: abortController.signal }
      );
    },
  });
};
