import { useCallback } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { convertImagesToPDF, useScanner } from '@liscio/common';

import { useFileUploadsStore } from './useFileUploadsStore';

export type UseFileUploaderScannerProps = {
  /**
   * URL to navigate to on file drop
   */
  url?: string;
};

/**
 * Helper hook for staging files into our global file uploads store
 * via mobile scanner. Pass an optional url prop to navigate to that
 * url on file scan.
 */
export const useFileUploaderScanner = ({
  url,
}: UseFileUploaderScannerProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const addFiles = useFileUploadsStore((store) => store.addFiles);
  const referer = location.pathname !== url ? location.pathname : undefined;
  const { open: openScanner } = useScanner({
    onScan: (scannedPagesUri) => {
      convertImagesToPDF(scannedPagesUri).then((PDFFile) => {
        addScannedFiles([PDFFile]);
      });
    },
  });

  const addScannedFiles = useCallback(
    (files: File[]) => {
      addFiles(files, referer);

      if (url && location.pathname !== url) {
        navigate(url);
      }
    },
    [addFiles, referer, location.pathname, navigate, url]
  );

  return {
    openScanner,
  };
};
