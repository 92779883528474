import { Capacitor } from '@capacitor/core';
import { Theme } from '@mui/material/styles';

import { Stack, Box, Grid, styled } from '@liscio/ui';

export const FullScreenStack = styled(Stack)({
  overflow: 'hidden',
  // height: '100%',
});

export const DesktopHeader = styled(Box)(({ theme }: { theme: Theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
  height: '73px',
  borderBottom: `solid 1px ${theme.palette.divider}`,
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const MobileHeader = styled(Box)(({ theme }: { theme: Theme }) => ({
  height: '62px',
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'block',
    height: Capacitor.getPlatform() === 'ios' ? '102px' : '62px',
  },
}));

export const DesktopMainContentGridContainer = styled(Grid)({
  height: 'calc(100vh - 243px)',
});

export const DesktopMainContentGridItem = styled(Grid)({
  height: 'inherit',
});

export const SidebarGridItem = styled(Grid)(({ theme }: { theme: Theme }) => ({
  height: 'calc(100vh - 243px)',
  [theme.breakpoints.down('md')]: {
    height:
      Capacitor.getPlatform() === 'ios'
        ? 'calc(100vh - 182px)'
        : 'calc(100vh - 142px)',
  },
}));

export const StepperContainer = styled(Box)(({ theme }: { theme: Theme }) => ({
  height: '120px',
  [theme.breakpoints.down('md')]: {
    height: '80px',
  },
  padding: 0,
  borderBottom: `solid 1px ${theme.palette.divider}`,
  backgroundColor: theme.palette.grey[50],
}));

export const MobileIFrameContainer = styled(Box)`
  display: block;
  z-index: 1;
  ${({ theme }: { theme: Theme }) => ({
    position: 'fixed',
    top: Capacitor.getPlatform() === 'ios' ? 102 : 62,
    bottom: 80,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: theme.zIndex.modal,
    padding: 0,
    overscrollBehavior: 'none',
    overflow: 'hidden',
  })}
`;

export const MobileActionButtonContainer = styled(Box)(
  ({ theme }: { theme: Theme }) => ({
    position: 'fixed',
    height: 80,
    bottom: 0,
    left: 0,
    right: 0,
    padding: theme.spacing(2),
    borderTop: `solid 1px ${theme.palette.divider}`,
  })
);
