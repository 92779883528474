import { FormProvider } from 'react-hook-form';

import { useAuthV5Process, AuthV5ProcessProvider } from '@liscio/common';
import { Stack, Typography } from '@liscio/ui';

import { LoginLogoMobile, HeroSplashMobile } from './components';
import { CopyrightFooter } from '../../../components';
import { SignInForm, MfaForm, PoweredByLiscio } from '../components';
import { config } from 'config';
import { CpaLoginV5Selector } from 'modules/authenticate/components/CpaLoginV5Selector/CpaLoginV5Selector';
import { useSetLoggedInUserSession } from 'modules/authenticate/hooks';
import { getCpaSiteUrl } from 'stores/cpa/cpa-utils'; // we should move it to common

export const LoginV5Mobile = () => {
  const setLoggedUserSession = useSetLoggedInUserSession();

  const authV5ProcessState = useAuthV5Process({
    onProcessFinished: async (loggedInUser) => {
      await setLoggedUserSession(loggedInUser);
    },
    desiredCpaUrl: getCpaSiteUrl(),
    webAppUrl: config.webApp.url,
  });

  const {
    processState,
    signInCpas,
    resetProcess,
    loginFormContext,
    submitLoginFormHandler,
    selectCpa,
    isLoading,
  } = authV5ProcessState;

  if (processState === 'cpaSelector') {
    return (
      <CpaLoginV5Selector
        cpas={signInCpas}
        onCancel={resetProcess}
        onCpaSelected={selectCpa}
      />
    );
  }

  return (
    <>
      <HeroSplashMobile />
      <Stack m="32px">
        <AuthV5ProcessProvider authV5ProcessState={authV5ProcessState}>
          <FormProvider {...loginFormContext}>
            <form onSubmit={submitLoginFormHandler}>
              <LoginLogoMobile />
              <Stack m="16px 0px">
                <Typography textAlign="center" variant="h3">
                  Welcome back
                </Typography>
              </Stack>
              <Stack>
                {processState === 'loginForm' && (
                  <SignInForm isLoading={isLoading} />
                )}
                {processState === 'mfaCode' && (
                  <MfaForm backToLogin={resetProcess} />
                )}
                <PoweredByLiscio />
              </Stack>
            </form>
          </FormProvider>
        </AuthV5ProcessProvider>
      </Stack>
      <CopyrightFooter />
    </>
  );
};

export default LoginV5Mobile;
