import { useFlags } from 'launchdarkly-react-client-sdk';

import { NavMenuItem } from '@liscio/common';
import { Icon } from '@liscio/ui';

import { routes } from 'routes';
import { useFileUploaderDropZone } from 'stores/fileUploads';

export const UploadFileNavigationMenuItemDesktop = () => {
  const { clientFileUploadRedesign } = useFlags();
  const { getInputProps, open: openFileBrowser } = useFileUploaderDropZone({
    url: routes.filesNew.link(),
  });

  if (clientFileUploadRedesign) {
    return (
      <>
        <NavMenuItem
          data-testid="sidebar__upload_file"
          icon={<Icon icon="uploadFile" />}
          label="Upload File"
          onClick={openFileBrowser}
        />
        <input data-testid="sidebar-upload-file-input" {...getInputProps()} />
      </>
    );
  }

  return (
    <NavMenuItem
      data-testid="sidebar__upload_file"
      icon={<Icon icon="uploadFile" />}
      label="Upload File"
      to={routes.filesNew.link()}
    />
  );
};
