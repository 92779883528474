import { useMemo } from 'react';

import { ThemeProvider } from '@mui/material';

import { GlobalLoader, createTheme } from '@liscio/ui';

import { useCpaCustomBranding } from 'stores/cpa/useCpaCustomBranding';

// Generate liscio base theme
const liscioTheme = createTheme();

export type CustomThemeProviderProps = {
  /**
   * Child component(s) for rendering.
   * NOTE: When theming is enabled a GlobalLoader will display until the
   * request for the custom theme has been resolved.
   */
  children?: React.ReactNode;
  /**
   *  Manual override to disable custom branding
   */
  disableCustomBranding?: boolean;
};

/**
 * App custom theme provider.
 * Loads and resolves firm custom theme when available.
 * Custom theming is currenly cobntrolled by a feature flag
 * NOTE: We really need to merge our mobile/desktop themes and remove the media query toggling
 */
export const CustomThemeProvider = ({
  disableCustomBranding = false,
  children,
}: CustomThemeProviderProps) => {
  // Resolve theme
  const {
    isEnabled: isBrandingEnabled,
    isLoading,
    data: customBranding,
  } = useCpaCustomBranding();
  const isEnabled = isBrandingEnabled && !disableCustomBranding;
  const theme = useMemo(() => {
    if (isEnabled && customBranding) {
      return createTheme(customBranding);
    }
    return liscioTheme;
  }, [isEnabled, customBranding]);

  return (
    <ThemeProvider theme={theme}>
      {isEnabled && isLoading ? <GlobalLoader open /> : children}
    </ThemeProvider>
  );
};
