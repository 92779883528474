import { FileUploadItem, FileUploadsBatchType } from './fileUploads.types';

/**
 * Calculate total files list size
 */
export const calculateUploadBatchSize = (files: FileUploadItem[]) =>
  files.reduce((sum, itm) => sum + itm.file.size, 0);

/**
 * Calculate total upload batch progress percent
 * sendPercent: The total percent to allocate to the "send" request process
 */
export const calculateUploadBatchProgress = (
  files: FileUploadItem[],
  { sendPercent = 0.15 }: { sendPercent?: number } = {}
) => {
  const totalFileProgress = files.reduce(
    (sum, itm) => sum + itm.file.size * itm.progress,
    0
  );
  const totalSize = calculateUploadBatchSize(files);
  const fileProgressPercent = totalFileProgress / totalSize;
  const batchPercent = fileProgressPercent / (1 / (1 - sendPercent));
  return batchPercent;
};

/**
 * Immutable helper utils
 */
export function updateUploadItemInUploadsList(
  batchId: number,
  uploads: FileUploadsBatchType[],
  updateUploadBatchHandler: (
    upload: FileUploadsBatchType
  ) => FileUploadsBatchType
) {
  return uploads.map((upload) => {
    if (upload.id === batchId) {
      return {
        ...updateUploadBatchHandler(upload),
        id: upload.id,
      };
    }
    return upload;
  });
}

export function updateFileItemInUploadsList(
  batchId: number,
  fileIdx: number,
  uploads: FileUploadsBatchType[],
  updateFileHander: (fileItem: FileUploadItem) => FileUploadItem
) {
  return updateUploadItemInUploadsList(batchId, uploads, (upload) => ({
    ...upload,
    files: upload.files.map((fileItm, idx) => {
      if (fileIdx === idx) {
        return updateFileHander(fileItm);
      }
      return fileItm;
    }),
  }));
}
