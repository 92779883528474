import React from 'react';

import { Typography } from '@liscio/ui';

import { AppHeaderWrapper } from './AppHeaderWrapper';

export interface AppHeaderProps {
  title: string;
  hideBorderBottom?: boolean;
}

export const AppHeader: React.FC<AppHeaderProps> = ({
  title,
  hideBorderBottom,
}) => {
  return (
    <AppHeaderWrapper hideBorderBottom={hideBorderBottom}>
      <Typography variant="body2">{title}</Typography>
    </AppHeaderWrapper>
  );
};

export default AppHeader;
