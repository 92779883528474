import { TaskDetails } from '@liscio/api';

import { PayInvoiceHandlerStates } from 'modules/invoices/components/PayInvoiceHandlerV2/PayInvoiceHandlerV2';



export const calculateInitialLoadingState = ({
  invoice,
}: {
  invoice: void | TaskDetails | undefined;
}) => {
  if (!invoice) {
    return PayInvoiceHandlerStates.INITIAL_LOADING;
  }

  const isAchPaymentAllowed = invoice?.is_ach_payment_allowed;
  const isCcPaymentAllowed = invoice?.is_cc_payment_allowed;
  const areMultipleMethodsAllowed = isAchPaymentAllowed && isCcPaymentAllowed;
  const isRecurringInvoice = invoice?.is_recurring;

  if (areMultipleMethodsAllowed) {
    return PayInvoiceHandlerStates.SELECT_PAYMENT_METHOD;
  }

  if (isCcPaymentAllowed) {
    if (isRecurringInvoice) {
      return PayInvoiceHandlerStates.STRIPE_CARD_REGISTRATION_PROCESSING;
    } else {
      return PayInvoiceHandlerStates.STRIPE_PAYMENT_PROCESSING;
    }
  }

  if (isAchPaymentAllowed) {
    return PayInvoiceHandlerStates.SELECT_BANK_ACCOUNT_ACH_METHOD;
  }

  return PayInvoiceHandlerStates.INITIAL_LOADING;
};

export default calculateInitialLoadingState;
