import { Avatar, AvatarProps } from '@liscio/ui';

import { useProfileData } from 'fetch-utils/users/user-hooks';

/**
 * Utility component to display current user avatar
 */
export const CurrentUserAvatar = (props: AvatarProps) => {
  const { avatar, name, first_name, last_name } = useProfileData();
  const initials = `${first_name?.[0]}${last_name?.[0]}`.toUpperCase();

  return (
    <Avatar {...props} alt={name} src={avatar}>
      {initials}
    </Avatar>
  );
};
