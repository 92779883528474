import { useForm } from 'react-hook-form';

import editBankAccountValidationSchema from './editBankAccountValidationSchema';
import { useYupValidationResolver } from 'custom-hooks';
import { AccountBankAccountSingleFormData } from 'modules/accounts/types/AccountBankAccountsFormData';

interface FormValues extends AccountBankAccountSingleFormData {
  globalError: unknown; // type to have an access in FieldErrors paths
}

export default function useBankAccountForm(
  defaultValues: AccountBankAccountSingleFormData | void
) {
  const resolver = useYupValidationResolver(editBankAccountValidationSchema);
  return useForm<Partial<FormValues>>({
    defaultValues: defaultValues || {
      bank_name: '',
      routing_number: '',
      encryptedAccountNumber: '',
      account_type: '',
      web_site: '',
      notes: '',
      is_ach_autopay: false,
    },
    resolver,
  });
}
