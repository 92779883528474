import { ContactsAndEmployeeEntity } from '@liscio/api';

import { mungeRecipients, mungeAccounts } from './helpers';
import { Recipient, Account } from '../ContactSelector';
import {
  useAccountsOfContact,
  useAccountsOfContactOnTheBasisOfUserId,
} from 'fetch-utils/accounts/accounts-hooks';
import {
  useEmployeesAndContacts,
  useEmployeesAndContactsOfAccount,
  useRelContactsAndCstOfAccounts,
  useSearchContacts,
} from 'fetch-utils/contacts/contacts-hooks';
import { useHomeData, useProfileData } from 'fetch-utils/users/user-hooks';


interface HookProps {
  fixedToContactId?: string;
  selectedRecipient?: Recipient | null;
  selectedAccount?: Account | null;
  recipientSearchString: string;
  accountSearchString: string;
}
export const useRecipientAccountData = ({
  fixedToContactId,
  selectedRecipient,
  selectedAccount,
  recipientSearchString,
  accountSearchString,
}: HookProps) => {
  const { isLoading: isProfileLoading } = useProfileData();

  const { data: homeData } = useHomeData();
  const { data: employeesAndContacts, isLoading: employeesAndContactsLoading } =
    useEmployeesAndContacts();
  const {
    data: employeesAndContactsOfAccount,
    isLoading: _employeesAndContactsOfAccountLoading,
  } = useEmployeesAndContactsOfAccount(selectedAccount?.value || ''); //maybe add isCustomer here?
  const {
    data: relContactsAndCstOfAccount,
    isLoading: _relContactsAndCstOfAccountLoading,
  } = useRelContactsAndCstOfAccounts(selectedAccount?.value || '');
  const { data: searchedContacts, isLoading: _searchedContactsLoading } =
    useSearchContacts(recipientSearchString);
  const { data: loggedUserAccounts, isLoading: loggedUserAccountsLoading } =
    useAccountsOfContactOnTheBasisOfUserId();
  const {
    data: contactAccountsWithCST,
    isLoading: contactAccountsWithCSTIsLoading,
  } = useAccountsOfContact(String(homeData?.data?.contact_id || ''));

  // Use isLoading param only if query is enabled
  const employeesAndContactsOfAccountLoading = selectedAccount
    ? _employeesAndContactsOfAccountLoading
    : false;
  const relContactsAndCstOfAccountLoading = selectedAccount
    ? _relContactsAndCstOfAccountLoading
    : false;
  const searchedContactsLoading = recipientSearchString
    ? _searchedContactsLoading
    : false;

  const recipients = mungeRecipients({
    relContactsAndCstOfAccount,
    selectedAccount,
    recipientSearchString,
    employeesAndContacts,
    searchedContacts,
    employeesAndContactsOfAccount,
  });

  const accounts = mungeAccounts({
    fixedToContactId,
    selectedRecipient,
    accountSearchString,
    loggedUserAccounts,
  });

  const { recipientContacts, recipientEmployees } = recipients.reduce(
    (
      acc: {
        recipientContacts: Array<ContactsAndEmployeeEntity>;
        recipientEmployees: Array<ContactsAndEmployeeEntity>;
      },
      person
    ) => {
      if (person.type === 'contact') acc.recipientContacts.push(person);
      if (person.type === 'emp') acc.recipientEmployees.push(person);

      return acc;
    },
    { recipientContacts: [], recipientEmployees: [] }
  );

  return {
    recipientContacts,
    recipientEmployees,
    loggedUserAccounts,
    contactAccountsWithCST,
    accounts,
    isLoading:
      employeesAndContactsLoading ||
      searchedContactsLoading ||
      employeesAndContactsOfAccountLoading ||
      loggedUserAccountsLoading ||
      relContactsAndCstOfAccountLoading ||
      isProfileLoading ||
      contactAccountsWithCSTIsLoading,
  };
};

export default useRecipientAccountData;
