import React from 'react';

import { Button, styled, MicrosoftIcon } from '@liscio/ui';

import { useOAuth } from './useOauth';

const OutlookButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.common.neutrals[50],
  height: '50px',
  flex: '1',
  borderRadius: '8px',
  border: `solid 1px ${theme.palette.common.neutrals[200]}`,
  color: theme.palette.common.neutrals[900],
  margin: '0px 0px 0px 8px',
  padding: '0px',
  textWrap: 'nowrap',
}));

export const OutlookOAuthLoginMobile = () => {
  const { initiateOAuth } = useOAuth('outlook365');

  return (
    <OutlookButton
      type="button"
      onClick={initiateOAuth}
      variant="contained"
      color="secondary"
      startIcon={<MicrosoftIcon fontSize="large" />}
      size="large"
    >
      Office 365
    </OutlookButton>
  );
};
