import React, { useCallback, useMemo } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
  usePostApiV5AuthMagicLinks,
  usePostApiV5AuthPasswordResets,
} from '@liscio/api';
import { Button, GlobalLoader, Stack, TextField } from '@liscio/ui';

import { validationSchema } from './validationSchema';
import { ConfirmationDialog, ErrorDialog } from 'components';
import { useYupValidationResolver } from 'custom-hooks';
import { useForgotPassword } from 'fetch-utils/users/auth-hooks';

export interface ForgotPasswordFormData {
  email: string;
}

export const ForgotPasswordForm = () => {
  const { v5Login } = useFlags();

  // Forgot Password
  const { forgotPasswordEmail, requestForgotPassword, requestMagicLink } =
    useForgotPassword();
  const {
    mutateAsync: requestForgotPasswordV5,
    error: requestForgotPasswordV5Error,
    data: requestForgotPasswordV5Data,
  } = usePostApiV5AuthPasswordResets();

  const {
    mutateAsync: requestMagicLinkV5,
    data: requestMagicLinkV5Data,
    isLoading: requestMagicLinkV5Loading,
    error: requestMagicLinkV5Error,
  } = usePostApiV5AuthMagicLinks();

  // Form
  const validateResolver = useYupValidationResolver(validationSchema);
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm<ForgotPasswordFormData>({
    resolver: validateResolver,
    defaultValues: {
      email: forgotPasswordEmail,
    },
  });
  const onSubmitForgotPassword: SubmitHandler<ForgotPasswordFormData> =
    useCallback(
      async (data) => {
        if (v5Login) {
          return requestForgotPasswordV5({
            data: { email: data.email, app_type: 'client' },
          });
        } else {
          return requestForgotPassword.mutateAsync(data);
        }
      },
      [requestForgotPassword, requestForgotPasswordV5, v5Login]
    );
  const onSubmitMagicLink: SubmitHandler<ForgotPasswordFormData> = useCallback(
    async (data) => {
      if (v5Login) {
        return requestMagicLinkV5({
          data: { email: data.email, app_type: 'client' },
        });
      } else {
        return requestMagicLink.mutateAsync(data);
      }
    },
    [requestMagicLink, requestMagicLinkV5, v5Login]
  );
  const isLoading =
    requestForgotPassword.isLoading ||
    requestMagicLink.isLoading ||
    requestMagicLinkV5Loading;
  const error =
    requestForgotPassword.error ||
    requestMagicLink.error ||
    requestForgotPasswordV5Error ||
    requestMagicLinkV5Error;
  const data =
    requestForgotPassword.data ||
    requestMagicLink.data ||
    requestForgotPasswordV5Data ||
    requestMagicLinkV5Data;

  // Back to Login
  const navigate = useNavigate();
  const backToLogin = useCallback(
    () => navigate('/authenticate/login'),
    [navigate]
  );

  const confirmationDialogContent = useMemo(() => {
    const { email } = getValues();
    return data?.message?.replace('#{email}', email) || '';
  }, [data?.message, getValues]);

  return (
    <>
      <form>
        <Stack gap={2}>
          <TextField
            {...register('email')}
            fullWidth
            type="email"
            placeholder="Email"
            error={Boolean(errors?.email)}
            helperText={errors?.email?.message}
            disabled={isLoading}
          />
          <Stack mt={2} gap={2}>
            <Button
              onClick={handleSubmit(onSubmitForgotPassword)}
              variant="contained"
              type="submit"
              disabled={isLoading}
              size="large"
            >
              Submit
            </Button>
            <Button
              onClick={handleSubmit(onSubmitMagicLink)}
              variant="outlined"
              type="submit"
              disabled={isLoading}
              size="large"
            >
              Send Magic Link
            </Button>
          </Stack>
        </Stack>
      </form>
      <GlobalLoader open={isLoading} />
      <ConfirmationDialog
        open={Boolean(data)}
        title="Password Reset Requested"
        content={confirmationDialogContent}
        onConfirm={backToLogin}
        confirmLabel="Okay"
      />
      <ErrorDialog title="Forgot Password Error" error={error} />
    </>
  );
};
