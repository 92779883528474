import React from 'react';

import { Dialog, DialogProps, Typography } from '@liscio/ui';
import { pluralLabel } from '@liscio/utils';

interface ArchiveMessageThreadsDialogProps extends DialogProps {
  threadCount: number;
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  submitLabel: string;
}

export default function ArchiveMessageThreadsDialog({
  open,
  onClose,
  onSubmit,
  threadCount,
}: ArchiveMessageThreadsDialogProps) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Archive message threads"
      submitLabel="Confirm"
      onSubmit={onSubmit}
    >
      <Typography variant="body1">
        Are you sure you want to archive the selected{' '}
        {pluralLabel(threadCount, '', String(threadCount))} message{' '}
        {pluralLabel(threadCount, 'thread', 'threads')}?
      </Typography>
    </Dialog>
  );
}
