import {
  useInfiniteQuery,
  useQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import { PaymentMethods } from '@liscio/api';

import apiClient from 'fetch-utils/api-client';

const {
  getInvoices,
  registerStripePaymentSession,
  registerPaymentSourceSession,
  processPlaidPayment,
  addCustomerBankAccount,
  getUnpaidInvoicesAndUnauthorizedParents,
  addCard,
} = apiClient.invoices;

export function useInvoices({
  page,
  sortBy,
  sortOrder,
  statuses,
  parentTaskId,
  searchString,
  isParentTask,
  accountId,
  ownerId,
  invoiceType,
  search,
}: {
  page: number;
  sortBy: string;
  sortOrder: string;
  statuses: string;
  parentTaskId?: string;
  searchString?: string;
  isParentTask?: boolean;
  accountId?: string;
  ownerId?: string;
  invoiceType?: string;
  search?: string;
}) {
  const queryKey = [
    'invoices',
    sortBy,
    sortOrder,
    statuses,
    parentTaskId || '',
    searchString || '',
    isParentTask ? 'true' : '',
    accountId || '',
    ownerId || '',
    invoiceType || '',
    search || '',
    String(page),
  ];
  return useQuery({
    queryKey,
    queryFn: () =>
      getInvoices({
        pageParam: page,
        queryKey,
      }),
  });
}

export function useInfinitiveInvoices(
  sortBy: string,
  sortOrder: string,
  statuses: Array<string> = [],
  parentTaskId?: string,
  searchString?: string,
  isParentTask?: boolean
) {
  return useInfiniteQuery({
    queryKey: [
      'invoices',
      sortBy,
      sortOrder,
      statuses?.join(','),
      parentTaskId || '',
      searchString || '',
      isParentTask ? 'true' : '',
    ],
    queryFn: getInvoices,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage?.stats?.page === lastPage?.stats?.pages) {
        return undefined;
      }
      return allPages.length + 1;
    },
    getPreviousPageParam: (firstPage, allPages) => {
      if (firstPage?.stats?.page === 1) {
        return undefined;
      }
      return allPages.length - 1;
    },
  });
}

export function useInvoicesHistory(parentTaskId: string) {
  return useQuery({
    queryKey: ['invoicesHistory', 'last_activity', 'desc', '', parentTaskId],
    queryFn: getInvoices,
  });
}

export function useRegisterStripePaymentSession(
  props?: DefaultMutationHookInterface
) {
  return useMutation({
    mutationFn: (params: { invoiceId: string | number; successUrl: string }) =>
      registerStripePaymentSession(params),
    onSuccess: () => {
      props?.onSuccess && props.onSuccess();
    },
  });
}
export function useRegisterPaymentSourceSession(
  props?: DefaultMutationHookInterface
) {
  return useMutation({
    mutationFn: (params: {
      accountId: string | number;
      successUrl: string;
      paymentMethod: PaymentMethods;
    }) => registerPaymentSourceSession(params),
    onSuccess: () => {
      props?.onSuccess && props.onSuccess();
    },
  });
}

export function useProcessPlaidPayment(props?: DefaultMutationHookInterface) {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (params: { invoiceId: string | number; sourceId: string }) =>
      processPlaidPayment(params),
    onSuccess: (_, { invoiceId }) => {
      client.refetchQueries(['taskDetails', String(invoiceId)]);
      client.invalidateQueries(['invoices']);
      props?.onSuccess && props.onSuccess();
    },
  });
}

export function useAddCustomerBankAccount(
  props?: DefaultMutationHookInterface
) {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (params: {
      accountId: string | number;
      bankAccountId: string;
      publicToken: string;
      invoiceId: number | string;
    }) => addCustomerBankAccount(params),
    onSuccess: (_, { accountId }) => {
      client.invalidateQueries(['customer', accountId]);
      props?.onSuccess && props.onSuccess();
    },
  });
}

export function useAddCard(props?: DefaultMutationHookInterface) {
  return useMutation({
    mutationFn: (params: { invoiceId: string | number; sessionId: string }) =>
      addCard(params),
    onSuccess: () => {
      props?.onSuccess && props.onSuccess();
    },
  });
}

export const useGetUnpaidInvoicesAndUnauthorizedParents = () => {
  return useQuery({
    queryKey: ['getUnpaidInvoicesAndUnauthorizedParents'],
    queryFn: getUnpaidInvoicesAndUnauthorizedParents,
  });
};
