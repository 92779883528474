import React, { useState } from 'react';

import { styled, Box, Stack, Tabs } from '@liscio/ui';

import { TaskDetails } from '@liscio/api';

import { Activity } from './components';
import { TabPanel, Comment } from 'components';

export interface AdditionalInformationProps {
  comments?: TaskDetails['comments'];
  activity?: TaskDetails['my_activity'];
  documents?: TaskDetails['documents'];
}

const tabs = [
  { label: 'All Activity', value: 'all_activity' },
  { label: 'Comments', value: 'comments' },
];

const DEFAULT_TAB = 'all_activity';

const TabsWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    padding: '0 16px',
  },
}));

export const AdditionalInformation: React.FC<AdditionalInformationProps> = ({
  comments = [],
  activity = [],
  documents = [],
}) => {
  const [tab, setTab] = useState<string>(DEFAULT_TAB);
  return (
    <>
      <TabsWrapper>
        <Tabs
          options={tabs}
          selectedOptionValue={tab}
          onNavigationClick={(newValue) => setTab(newValue || DEFAULT_TAB)}
        />
      </TabsWrapper>
      <TabPanel value={tab} index="comments">
        <Stack direction="column" gap="16px">
          {comments.map((comment) => (
            <Comment
              key={comment.id}
              avatarUrl={comment.avatar}
              commentBody={comment.comment_for_web_display}
              commentBy={comment.comment_by}
              tym={comment.tym}
            />
          ))}
        </Stack>
      </TabPanel>
      <TabPanel value={tab} index="all_activity">
        <Stack direction="column" gap="0px">
          {activity.map((activityElem, index) => (
            <Activity
              key={activityElem.id}
              activity={activityElem}
              isLastElement={index === activity.length - 1}
              documents={documents}
            />
          ))}
        </Stack>
      </TabPanel>
    </>
  );
};

export default AdditionalInformation;
