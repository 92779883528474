import React from 'react';

import { useParams } from 'react-router-dom';

import BankAccountFormMobile from './BankAccountFormMobile';
import { getDefaultValues } from './helpers';
import { IBankAccountOverlays } from '../../../../view-hooks/useBankAccountOverlays';
import { useSendUpdateNotification } from 'fetch-utils/accounts/accounts-hooks';
import { useSubmitEditAccountSingleBankAccount } from 'modules/accounts/hooks';
import { AccountBankAccountSingleFormData } from 'modules/accounts/types/AccountBankAccountsFormData';

interface EditBankAccountFormProps {
  goBack: () => void;
  // @TODO Refactor this component to fetch bank accounts individually
  // and not use the bankAccount prop
  // https://liscio.atlassian.net/browse/DEV-3639
  bankAccount: AccountBankAccountSingleFormData;
  setSelectedBankAccount: IBankAccountOverlays['setSelectedBankAccount'];
}

export default function EditBankAccountForm({
  goBack,
  bankAccount,
  setSelectedBankAccount,
}: EditBankAccountFormProps) {
  const { id } = useParams();
  const { mutateAsync: sendUpdateNotification } = useSendUpdateNotification();
  const { submitHandler, isLoading } = useSubmitEditAccountSingleBankAccount({
    onSuccess,
  });

  async function onSuccess(
    updatedBankAccount: Partial<AccountBankAccountSingleFormData>
  ) {
    try {
      sendUpdateNotification(id!);
      setSelectedBankAccount({
        ...bankAccount,
        ...updatedBankAccount,
      });
      goBack();
    } catch {
      // error handled by mutation hook
    }
  }

  return (
    <BankAccountFormMobile
      isProcessing={isLoading}
      onSubmit={submitHandler}
      defaultValues={getDefaultValues(bankAccount)}
      title={`Edit ${bankAccount!.bank_name}`}
      buttonText="Edit bank account"
      goBack={goBack}
    />
  );
}
