import React from 'react';

import { ResponsiveView } from '@liscio/ui';

export interface AccountEditViewProps {}

const AccountEditDesktopView = React.lazy(
  () => import('./AccountEditDesktopView/AccountEditDesktopView')
);
const AccountEditMobileView = React.lazy(
  () => import('./AccountEditMobileView/AccountEditMobileView')
);

export const AccountEditView: React.FC<AccountEditViewProps> = () => {
  return (
    <ResponsiveView
      SmallView={<AccountEditMobileView />}
      LargeView={<AccountEditDesktopView />}
    />
  );
};

export default AccountEditView;
