import React from 'react';

import { TextField } from '@mui/material';
import { useTheme } from '@liscio/ui';
import { FieldErrors, UseFormRegister } from 'react-hook-form';

import { LoginForm } from '../SignInForm';
import { PasswordInput } from 'components';

interface EmailAndPasswordFieldsProps {
  register: UseFormRegister<LoginForm>;
  errors: FieldErrors<LoginForm>;
  isLoggingIn: boolean;
}

export default function EmailAndPasswordFields({
  register,
  errors,
  isLoggingIn,
}: EmailAndPasswordFieldsProps) {
  const theme = useTheme();
  return (
    <>
      <TextField
        {...register('email')}
        fullWidth
        type="email"
        placeholder="Email"
        error={Boolean(errors?.email)}
        helperText={errors?.email?.message}
        disabled={isLoggingIn}
      />
      <PasswordInput
        {...register('password')}
        fullWidth
        placeholder="Password"
        error={Boolean(errors?.password)}
        helperText={errors?.password?.message}
        iconStyles={{ color: theme.palette.common.neutrals[500] }}
        disabled={isLoggingIn}
      />
    </>
  );
}
