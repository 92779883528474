import { useEffect, useRef, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { AppBar, DetailsState, ModalState } from '@liscio/common';
import { Box, Grid, Stepper, Stack, useTheme } from '@liscio/ui';

import { ESignItem } from './components/ESignItem/ESignItem';
import { HelperContent } from './components/HelperContent/HelperContent';
import { PayableItem } from './components/PayableItem/PayableItem';
import { ResponseSubmitOverlay } from './components/ResponseSubmitOverlay/ResponseSubmitOverlay';
import { ReviewableDocumentItem } from './components/ReviewableDocument/ReviewableDocumentItem';
import { TaxDeliveryEstimate } from './components/TaxDeliveryEstimate/TaxDeliveryEstimate';
import {
  FullScreenStack,
  MobileHeader,
  StepperContainer,
  SidebarGridItem,
  MobileIFrameContainer,
  MobileActionButtonContainer,
} from './TaxDeliveryView.styles';
import {
  DASHBOARD_DOMAIN,
  DASHBOARD_PATHS,
} from 'modules/dashboard/route-constants';
import { useTaxDeliveryStore } from 'stores/workflow/useTaxDeliveryStore';

/* The overall concept here is that each workflow item controls it's own state and actions 
and uses the portal containers provided by this view to render it's specific content and actions.
*/

export const TaxDeliveryMobileView = () => {
  const mainContentPortalContainerRef = useRef(null);
  const actionButtonPortalContainerRef = useRef(null);
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [submissionOverlayOpen, setSubmissionOverlayOpen] = useState(false);

  const {
    activeStep,
    activeItem,
    allStepsComplete,
    taxDeliveryData,
    lastStepActive,
    submitted,
    setActiveStep,
    setActiveItem,
    setAllStepsComplete,
    setActiveItemComplete,
    goToNextItem,
    setMainContentPortalRef,
    setActionButtonPortalRef,
  } = useTaxDeliveryStore();

  useEffect(() => {
    setMainContentPortalRef(mainContentPortalContainerRef);
    setActionButtonPortalRef(actionButtonPortalContainerRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainContentPortalContainerRef, actionButtonPortalContainerRef]);

  const [showMobileIFrame, setShowMobileIFrame] = useState(false);

  const activeRequestItem =
    taxDeliveryData.steps?.[activeStep]?.items?.[activeItem as number];

  // used to clear the IFrame when working with additional signers
  const clearIFrameForAdditionalSigner = () => setShowMobileIFrame(false);

  const handleStepperClick = (index: number) => {
    setActiveStep(index);
    setActiveItem(0);
    // This just removes the "thank you" message so we can see the items again
    if (allStepsComplete) {
      setActiveItem(null);
      setAllStepsComplete(false);
    }
  };

  const handleOnItemComplete = () => {
    setActiveItemComplete();
    goToNextItem();
    if (lastStepActive) {
      setSubmissionOverlayOpen(true);
    }
    setShowMobileIFrame(false);
  };

  const handleCancelSubmission = () => {
    setActiveStep(0);
    setSubmissionOverlayOpen(false);
  };

  const handleItemClick = (i: number) => {
    setShowMobileIFrame(true);
    setActiveItem(i);
  };

  return (
    <FullScreenStack direction="column">
      <MobileHeader theme={theme}>
        <AppBar
          onNavigationClick={() => {
            if (showMobileIFrame) {
              setShowMobileIFrame(false);
            } else {
              navigate(
                location.state.goBackUrl ||
                  `/${DASHBOARD_DOMAIN}${DASHBOARD_PATHS.home}`
              );
            }
          }}
          DefaultStateElement={showMobileIFrame ? ModalState : DetailsState}
          hideSecondaryActionButton
          title={activeRequestItem?.prompt || taxDeliveryData.title || ''}
          position="relative"
        />
      </MobileHeader>
      <StepperContainer theme={theme}>
        <Stepper
          activeStep={activeStep}
          steps={taxDeliveryData.steps || [{ label: '' }]}
          onClick={handleStepperClick}
        />
      </StepperContainer>
      <Grid container>
        <SidebarGridItem item xs={12}>
          <Stack direction="column" height="100%">
            <MobileIFrameContainer
              theme={theme}
              style={{ display: showMobileIFrame ? 'block' : 'none' }}
            >
              {/* (Mobile) Main Content Portal Container */}
              <Box
                ref={mainContentPortalContainerRef}
                width="100%"
                height="100%"
              ></Box>
              {/* END Main Content Portal Container */}
            </MobileIFrameContainer>
            <Box flex={1} overflow={'hidden auto'} p={2}>
              {/* mobile thank you/complete step */}
              {submitted && <HelperContent type={'COMPLETED'} />}
              {/* items in sections */}
              {taxDeliveryData.steps?.[activeStep]?.items?.map((item, i) => {
                if (item.type === 'WfiReviewableDocument') {
                  return (
                    <ReviewableDocumentItem
                      key={item.id}
                      onItemClick={() => {
                        handleItemClick(i);
                      }}
                      onComplete={handleOnItemComplete}
                      itemData={taxDeliveryData.steps?.[activeStep]?.items?.[i]}
                    />
                  );
                }
                if (item.type === 'WfiDynamicItem') {
                  return (
                    <TaxDeliveryEstimate
                      key={item.id}
                      assignedAccount={taxDeliveryData.assignedAccount}
                      onItemClick={() => handleItemClick(i)}
                      onComplete={handleOnItemComplete}
                      itemData={taxDeliveryData.steps?.[activeStep]?.items?.[i]}
                    />
                  );
                }
                if (item.type === 'WfiSignableDocument') {
                  return (
                    <ESignItem
                      key={item.id}
                      onItemClick={() => {
                        handleItemClick(i);
                      }}
                      onComplete={handleOnItemComplete}
                      itemData={taxDeliveryData.steps?.[activeStep]?.items?.[i]}
                      clearIFrameForAdditionalSigner={
                        clearIFrameForAdditionalSigner
                      }
                    />
                  );
                }
                if (item.type === 'WfiPayable') {
                  return (
                    <PayableItem
                      key={item.id}
                      onItemClick={() => {
                        handleItemClick(i);
                      }}
                      onComplete={handleOnItemComplete}
                      itemData={taxDeliveryData.steps?.[activeStep]?.items?.[i]}
                    />
                  );
                }
              })}
            </Box>
            {/* Action Button Portal Container */}
            <MobileActionButtonContainer>
              <Stack
                direction="row"
                spacing={2}
                ref={actionButtonPortalContainerRef}
              ></Stack>
            </MobileActionButtonContainer>
            {/* END Action Button Portal Container */}
            <ResponseSubmitOverlay
              open={submissionOverlayOpen}
              onCancel={handleCancelSubmission}
            ></ResponseSubmitOverlay>
          </Stack>
        </SidebarGridItem>
      </Grid>
    </FullScreenStack>
  );
};
