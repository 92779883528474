import React from 'react';

import { Helmet } from 'react-helmet-async';
import { Route, Routes, Navigate } from 'react-router-dom';

import { ResponsiveView } from '@liscio/ui';

import {
  NOTIFICATIONS_PAGE_TITLES,
  NOTIFICATIONS_PATHS,
} from './route-constants';
import { CatchAllRedirect } from 'modules/CatchAllRedirect/CatchAllRedirect';
import {
  DASHBOARD_DOMAIN,
  DASHBOARD_PATHS,
} from 'modules/dashboard/route-constants';

const ListViewDesktop = React.lazy(
  () => import('./views/ListViewDesktop/ListViewDesktop')
);

export const ModuleRoutes = () => (
  <>
    <Helmet title={NOTIFICATIONS_PAGE_TITLES.list} />
    <Routes>
      <Route
        path={NOTIFICATIONS_PATHS.list}
        element={
          <ResponsiveView
            SmallView={
              <Navigate to={`/${DASHBOARD_DOMAIN}${DASHBOARD_PATHS.home}`} />
            }
            LargeView={<ListViewDesktop />}
          />
        }
      />
      <Route path="*" element={<CatchAllRedirect />} />
    </Routes>
  </>
);

export default ModuleRoutes;
