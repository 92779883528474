import toast from 'react-hot-toast';

import { DocumentUploadResponseArray } from '@liscio/api';

export function parseBrokenDocumentsToast(
  uploadedDocsResponse: DocumentUploadResponseArray | void,
  files: File[]
): number[] | null {
  if (!uploadedDocsResponse) return [];

  const brokenIndicies = uploadedDocsResponse!.reduce<number[]>(
    (acc, response, index) => {
      const uploadDetails = response?.data;
      if (uploadDetails?.status === 'unprocessable_entity') {
        if (uploadDetails?.message) {
          toast.error(uploadDetails?.message);
        } else {
          toast.error(`Could not upload ${files[index].name}.`);
        }
        return [...acc, index];
      }

      return acc;
    },
    []
  );

  return brokenIndicies;
}
