import React, { useState, useEffect } from 'react';

import { InView } from 'react-intersection-observer';
import { useLocation } from 'react-router-dom';

import { AppBar, useMobileDrawer } from '@liscio/common';
import { setScrollValue } from '@liscio/utils';

import { Tabs } from './components';
import { extractValues } from './helpers';
import {
  Loading,
  TopLoader,
  MessagesList,
  NewMessageSpeedDial,
  NetworkErrorDialog,
} from 'components';
import { ScreenWrapper, BodyWrapper } from 'components/screenComposition';
import { useReloadFunction } from 'custom-hooks';
import { useInfinitiveMessages } from 'fetch-utils/messages/messages-hooks';
import {
  MESSAGES_DOMAIN,
  MESSAGES_PATHS,
} from 'modules/messages/route-constants';

const DEFAULT_TAB = 'inbox';

export interface ListViewMobileProps {}

export const ListViewMobile: React.FC<ListViewMobileProps> = () => {
  const { setIsDrawerOpen } = useMobileDrawer();
  const location = useLocation();
  const [searchString, setSearchString] = useState('');
  const [tab, setTab] = useState(location?.state?.tab || DEFAULT_TAB);

  const {
    data,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    isRefetching,
    error,
  } = useInfinitiveMessages(tab, searchString);
  const hasError = Boolean(error);

  const {
    messages,
    inboxNumberOfMessages,
    sentNumberOfMessages,
    draftsNumberOfMessages,
    archivedNumberOfMessages,
    allNumberOfMessages,
  } = extractValues(data, tab, error);

  useEffect(() => {
    const scrollValue = location?.state?.scroll;
    if (scrollValue) {
      setScrollValue(scrollValue);
    }
  }, [location?.state?.scroll]);

  const reloadFunction = useReloadFunction([['messages']]);

  return (
    <ScreenWrapper>
      <TopLoader open={isRefetching} />
      <AppBar
        onNavigationClick={() => {
          setIsDrawerOpen((currentState: boolean) => !currentState);
        }}
        title="Messages"
        onSearchChange={setSearchString}
      >
        <Tabs
          selectedOptionValue={tab}
          onNavigationClick={(key) => {
            setTab(key || DEFAULT_TAB);
            setScrollValue(0);
          }}
          inboxNumberOfMessages={inboxNumberOfMessages}
          sentNumberOfMessages={sentNumberOfMessages}
          draftsNumberOfMessages={draftsNumberOfMessages}
          archivedNumberOfMessages={archivedNumberOfMessages}
          allNumberOfMessages={allNumberOfMessages}
        />
      </AppBar>
      <BodyWrapper reloadFunction={reloadFunction}>
        {isLoading && <Loading />}
        {!isLoading && messages.length > 0 && !hasError && (
          <MessagesList
            messages={messages}
            highlightUnreadComments={!['sent', 'draft'].includes(tab)}
            navigateState={{
              tab,
              hideSecondaryActionButton: tab === 'archive',
              goBackUrl: `/${MESSAGES_DOMAIN}${MESSAGES_PATHS.list}`,
            }}
          />
        )}
        {isFetchingNextPage && <Loading numberOfElements={1} mt={1} />}
        {!isLoading && !isFetchingNextPage && !hasError && (
          <InView onChange={(inView) => inView && fetchNextPage()} />
        )}
        <NewMessageSpeedDial navigateState={{ tab }} />
        <NetworkErrorDialog hasError={hasError} />
      </BodyWrapper>
    </ScreenWrapper>
  );
};

export default ListViewMobile;
