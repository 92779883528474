import { useCallback } from 'react';

import { NavMenuItem } from '@liscio/common';
import {
  Typography,
  useViewportSize,
  Menu,
  MenuItem,
  styled,
  useMenuAnchor,
  Stack,
  Icon,
} from '@liscio/ui';

import { CurrentUserAvatar, CurrentUserName } from 'components';

const StyledMenu = styled(Menu)({
  [`.MuiMenu-paper`]: {
    minWidth: '200px',
  },
  [`.MuiMenuItem-root`]: {
    padding: '16px',
  },
});
const StyledUsername = styled(Typography)(({ theme }) => ({
  overflow: 'hidden',
  lineHeight: 1.25,
  maxHeight: '48px',
  textOverflow: 'ellipsis',
  [theme.breakpoints.down('md')]: {
    width: '152px',
  },
}));
const StyledCurrentUserAvatar = styled(CurrentUserAvatar)({
  width: '40px',
  height: '40px',
  border: 'none',
});
const StyledCurrentUserAvatarSmall = styled(CurrentUserAvatar)({
  width: '24px',
  height: '24px',
  fontSize: 12,
  border: 'none',
});

/**
 * User Profile secondary nav item.
 * Provides client user with secondary nav menu with the following items in desktop:
 * - Profile
 * - Logout
 * Direct link to profile page in mobile
 */
export const UserProfileNavMenuItem = () => {
  const { isMobile, isTablet } = useViewportSize();
  const { anchorEl, open, onOpen, onClose } = useMenuAnchor();
  const onMenuClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (!isMobile) {
        // Only open the menu on non-mobile viewports
        onOpen(event);
      }
    },
    [isMobile, onOpen]
  );

  return (
    <>
      <NavMenuItem
        id="user-menu-button"
        onClick={onMenuClick}
        icon={isTablet ? <StyledCurrentUserAvatar /> : false}
        to={isMobile ? '/profile/details' : undefined}
        label={
          <Stack direction="row" gap={1} alignItems="center">
            <CurrentUserAvatar
              sx={{ width: '40px', height: '40px', border: 'none' }}
            />
            <StyledUsername variant="body1" flex={1}>
              <CurrentUserName />
            </StyledUsername>
          </Stack>
        }
      />
      <StyledMenu
        id="user-menu"
        aria-labelledby="user-menu-button"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem
          to="/profile/details"
          selected={false}
          onClick={onClose}
          iconBefore={<StyledCurrentUserAvatarSmall />}
          label="Profile"
        />
        <MenuItem
          to="/authenticate/logout"
          iconBefore={<Icon icon="logout" />}
          selected={false}
          onClick={onClose}
          label="Logout"
        />
      </StyledMenu>
    </>
  );
};
