import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import {
  updateResponseItemDocUploadDocument,
  updateResponseItemTextDocument,
  updateResponseItemBoolDocument,
  updateWorkflowRequestMutationDocument,
  workflowRequestsQueryDocument,
  workflowRequestsCountQueryDocument,
  workflowRequestDocument,
} from '@liscio/api';
import {
  QueryWorkflowRequestsArgs,
  WorkflowRequestsCountQueryVariables,
} from '@liscio/api/graphql';
import { useGraphQLClient } from '@liscio/common';

import { getWorkflowRequests } from './workflow-calls';
import apiClient from 'fetch-utils/api-client';

export function useUpdateResponseItemDocUpload() {
  return useGraphQLClient().useMutationGraphQL(
    updateResponseItemDocUploadDocument
  );
}

export function useUpdateResponseItemBool() {
  return useGraphQLClient().useMutationGraphQL(updateResponseItemBoolDocument);
}

export function useUpdateResponseItemText() {
  return useGraphQLClient().useMutationGraphQL(updateResponseItemTextDocument);
}

export function useUpdateWorkflowRequest() {
  return useGraphQLClient().useMutationGraphQL(
    updateWorkflowRequestMutationDocument
  );
}

export function useWorkflowRequestsCountQuery(
  variables?: WorkflowRequestsCountQueryVariables,
  options?: { enabled?: boolean; staleTime?: number }
) {
  return useGraphQLClient().useGraphQLWithOptions(
    workflowRequestsCountQueryDocument,
    options || {},
    variables || {}
  );
}

export function useWorkflowRequestsQuery(
  variables: QueryWorkflowRequestsArgs,
  options?: { enabled: boolean }
) {
  return useGraphQLClient().useGraphQLWithOptions(
    workflowRequestsQueryDocument,
    { retry: false, ...options },
    variables
  );
}

export const REQUESTS_MOBILE_QUERY_KEY = 'requestWorkflows';

export function useInfinitiveRequests({
  statuses,
  perPage = 50,
  totalPages,
}: QueryWorkflowRequestsArgs & { totalPages: number }) {
  return useInfiniteQuery({
    queryKey: [REQUESTS_MOBILE_QUERY_KEY, statuses, perPage],
    queryFn: ({ pageParam = 1 }) =>
      getWorkflowRequests({
        statuses,
        page: pageParam,
        perPage,
      }),
    getNextPageParam: (lastPage, allPages) => {
      if (allPages.length < totalPages) return allPages.length + 1;

      return undefined;
    },
    getPreviousPageParam: (firstPage, allPages) => {
      return allPages.length - 1;
    },
  });
}

export function useGetCompletionValues(uuid: string, enabled: boolean = false) {
  return useQuery({
    queryKey: ['request-completion-values', uuid],
    queryFn: () => apiClient.workflows.getCompletionValues(uuid),
    enabled,
  });
}

export function useCreateESignAgreement(url: string, enabled: boolean = false) {
  return useQuery({
    queryKey: ['create-esign-url', url],
    queryFn: () => apiClient.workflows.createESignAgreement(url),
    enabled,
  });
}

export function useGetESignDocumentURL(url: string, enabled: boolean = false) {
  return useQuery({
    queryKey: ['request-esign-document-url', url],
    queryFn: () => apiClient.workflows.getESignDocumentURL(url),
    enabled,
    refetchInterval: 2000,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: false,
  });
}

export const useGetWorkflowRequest = (
  id?: string,
  options?: { cacheTime?: number; staleTime?: number }
) => {
  const gqlClient = useGraphQLClient();
  return gqlClient.useGraphQLWithOptions(
    workflowRequestDocument,
    {
      enabled: !!id,
      ...options,
    },
    {
      id,
    }
  );
};
