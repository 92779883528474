import React from 'react';

import { Helmet } from 'react-helmet-async';
import { Route, Routes } from 'react-router-dom';

import { ResponsiveView } from '@liscio/ui';

import { AccountViewLayout } from './components/AccountViewLayout/AccountViewLayout';
import {
  ACCOUNTS_PAGE_TITLES,
  ACCOUNTS_PATHS,
  ACCOUNT_PARAMS,
} from './route-constants';
import {
  AccountContactsView,
  AccountOverviewView,
  AccountEditView,
  AccountLoginListView,
  AccountLoginFormView,
  AccountBankAccountFormView,
  AccountPayrollFormView,
} from './views';
import AccountBankAccounts from './views/AccountDetailsMobileView/AccountDetailsMobileView/components/AccountBankAccountsMobile/AccountBankAccounts';
import { LazyWrapper } from 'components';
import { CatchAllRedirect } from 'modules/CatchAllRedirect/CatchAllRedirect';
import { useRudderStackTrackAccountTabs } from 'utils/analytics';

const AccountsListViewMobile = React.lazy(
  () =>
    import(
      './views/AccountsListView/AccountsListViewMobile/AccountsListViewMobile'
    )
);
const AccountsListViewDesktop = React.lazy(
  () =>
    import(
      './views/AccountsListView/AccountsListViewDesktop/AccountsListViewDesktop'
    )
);
const AccountPayrollView = React.lazy(
  () => import('./views/AccountPayrollView/AccountPayrollView')
);
const AccountFilesView = React.lazy(
  () => import('./views/AccountFilesView/AccountFilesView')
);

export const ModuleRoutes = () => {
  useRudderStackTrackAccountTabs();

  return (
    <>
      <Helmet title={ACCOUNTS_PAGE_TITLES.list} />
      <Routes>
        <Route
          path={`${ACCOUNTS_PATHS.details.root}/${ACCOUNT_PARAMS.id}`}
          element={<AccountViewLayout />}
        >
          <Route
            path={ACCOUNTS_PATHS.details.tabs.overview}
            element={<AccountOverviewView />}
          />
          <Route
            path={ACCOUNTS_PATHS.details.tabs.bankAccounts}
            element={<AccountBankAccounts />}
          />
          <Route
            path={ACCOUNTS_PATHS.details.tabs.contacts}
            element={<AccountContactsView />}
          />
          <Route
            path={ACCOUNTS_PATHS.details.tabs.logins}
            element={<AccountLoginListView />}
          />
          <Route
            path={ACCOUNTS_PATHS.details.tabs.payroll}
            element={
              <LazyWrapper>
                <AccountPayrollView />
              </LazyWrapper>
            }
          />
          <Route
            path={ACCOUNTS_PATHS.details.tabs.files}
            element={
              <LazyWrapper>
                <AccountFilesView />
              </LazyWrapper>
            }
          />
        </Route>
        <Route
          path={`${ACCOUNTS_PATHS.details.root}/${ACCOUNT_PARAMS.id}/${ACCOUNTS_PATHS.details.edit}`}
          element={<AccountEditView />}
        />
        <Route
          path={`${ACCOUNTS_PATHS.details.root}/${ACCOUNT_PARAMS.id}/${ACCOUNTS_PATHS.details.loginForm}`}
          element={<AccountLoginFormView />}
        />
        <Route
          path={`${ACCOUNTS_PATHS.details.root}/${ACCOUNT_PARAMS.id}/${ACCOUNTS_PATHS.details.payrollForm}`}
          element={<AccountPayrollFormView />}
        />
        <Route
          path={`${ACCOUNTS_PATHS.details.root}/${ACCOUNT_PARAMS.id}/${ACCOUNTS_PATHS.details.bankAccountsForm}`}
          element={<AccountBankAccountFormView />}
        />
        <Route
          path={ACCOUNTS_PATHS.list}
          element={
            <ResponsiveView
              SmallView={<AccountsListViewMobile />}
              LargeView={<AccountsListViewDesktop />}
            />
          }
        />
        <Route path="*" element={<CatchAllRedirect />} />
      </Routes>
    </>
  );
};

export default ModuleRoutes;
