import { useEffect, useState } from 'react';

import { useLocation } from 'react-router';
import { Userpilot as UserpilotSystem } from 'userpilot';

import { config } from 'config';
import { useAuth } from 'fetch-utils/users/use-auth';
import { useHomeData } from 'fetch-utils/users/user-hooks';

if (config.userpilot.isEnabled) {
  UserpilotSystem.initialize(config.userpilot.appToken);
}

export const Userpilot = () => {
  const { isLoggedIn } = useAuth();
  const location = useLocation();
  const [registeredUniqueId, setRegisteredUniqueId] = useState<string | null>();
  const { data } = useHomeData();

  /**
   * Identify users to Userpilot
   */
  useEffect(() => {
    if (isLoggedIn && data) {
      const userpilotData = {
        ...data?.data?.userpilot_data,
        name: '',
        is_new_web_app: true,
      };
      userpilotData.name = userpilotData.user_name;
      const uniqueId = `${userpilotData.unique_id}_${userpilotData.app_name}_${userpilotData.firm_name}`;

      if (registeredUniqueId !== uniqueId) {
        UserpilotSystem.identify(uniqueId, userpilotData);
        UserpilotSystem.reload();
        setRegisteredUniqueId(uniqueId);
      }
    } else {
      UserpilotSystem.anonymous();
      setRegisteredUniqueId(null);
    }
  }, [data, registeredUniqueId, isLoggedIn]);

  /**
   * Reload Userpilot on a Page Change
   */
  useEffect(() => {
    UserpilotSystem.reload();
  }, [location]);

  return null;
};

export default Userpilot;
