
import {
  BasicDocument,
  DocumentsResponseHash,
  FormDataUploadConfig,
  RecipientTuple,
  UploadType,
} from '@liscio/api';

import { RecipientAccount } from 'components/ContactSelector/ContactSelector';
import { MAX_FILE_SIZE_IN_BYTES } from 'constants/settings';


export function makeFormDataForS3({
  file,
  name,
  uploadType,
  is_draft = true,
  taskId,
}: FormDataUploadConfig) {
  const formData = new FormData();

  formData.append('aws_url', file, name);
  formData.append('is_draft', JSON.stringify(is_draft));

  if (
    (Boolean(taskId) && uploadType === UploadType.Task) ||
    uploadType === UploadType.Invoice
  ) {
    formData.append('task_id', taskId!);
  } else if (uploadType === UploadType.Message) {
    formData.append('doc_type', uploadType);
  } else if (uploadType === UploadType.NewFile) {
    formData.append('doc_type', 'Manual');
  }

  return formData;
}

export function checkFileSize(
  document: File,
  maxsizeInBytes = MAX_FILE_SIZE_IN_BYTES
) {
  if (document?.size > maxsizeInBytes) {
    return false;
  }

  return true;
}

export function calculateDocumentPageStats({
  off,
  offset,
  total_documents,
}: DocumentsResponseHash) {
  const perPage = off - offset + 1;

  return {
    totalPages: Math.ceil(total_documents / perPage) || 0,
    currentPage: Math.ceil(off / perPage) || 0,
  };
}

export function mungeTags(tags: RichOption[]) {
  return tags.filter(Boolean).map(({ value }) => (value ? String(value) : ''));
}

export function makeRecipientTuple({ recipient, account }: RecipientAccount) {
  return [recipient?.value, account?.value || '', 'user'];
}

export function mungeToRecipientTuples(participants: RecipientAccount[]) {
  return participants?.map(makeRecipientTuple) as RecipientTuple[];
}

export function makeFileId(file: File) {
  return `${file.name}-${file.lastModified}-${file.size}`;
}

// TODO: Refactor other extractValues callss
export const extractFilesFromPages = (
  pages: Array<{
    documents?: BasicDocument[];
    response_hash: DocumentsResponseHash;
  }> = []
) => {
  const files = pages.reduce(
    (acc, page) => [...acc, ...(page.documents || [])],
    [] as BasicDocument[]
  );
  const {
    response_hash: { total_active = undefined, total_archive = undefined } = {},
  } = pages[0] || {};

  return {
    files,
    total_active,
    total_archive,
  };
};
