import React from 'react';

import { Link } from '@mui/material';

import { Box, Button, GlobalLoader, Typography } from '@liscio/ui';

import { ErrorDialog } from 'components/ErrorDialog';
import { useRequestBackupMfaCodeMutation } from 'fetch-utils/users/auth-hooks';

export interface RequestEmailedCodeProps {
  email?: string;
  onClose: () => void;
}

/**
 * Un-paged view for requesting an mfa code be emailed
 * directly to the users email address on file.
 */
export const RequestEmailedCode = ({
  onClose,
  email,
}: RequestEmailedCodeProps) => {
  const {
    mutate: requestBackupCode,
    isLoading,
    error,
    isSuccess,
  } = useRequestBackupMfaCodeMutation();

  return (
    <Box display="flex" flexDirection="column">
      {isSuccess ? (
        <>
          <Typography mb={8} textAlign="center">
            Email has been sent to your registered email address.
          </Typography>
          <Button variant="contained" color="primary" onClick={onClose}>
            Ok
          </Button>
        </>
      ) : (
        <>
          <Typography mb={8} textAlign="center">
            If you have lost your device, Please{' '}
            <Link onClick={() => requestBackupCode(email)}>click here</Link> to
            receive one time recovery code. It will be sent to your registered
            email account.
          </Typography>
          <Button variant="contained" onClick={onClose}>
            Dismiss
          </Button>
        </>
      )}
      <ErrorDialog error={error} />
      <GlobalLoader open={isLoading} />
    </Box>
  );
};
