import React, { useMemo, useCallback } from 'react';

import { styled, StaticTable } from '@liscio/ui';
import { createColumnHelper, getCoreRowModel } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';

import { NewDataEntity } from '@liscio/api';

import { Check } from 'components/columnsRenderers';
import {
  ACCOUNTS_DOMAIN,
  ACCOUNTS_PATHS,
} from 'modules/accounts/route-constants';

export interface AccountsTableProps {
  accounts: Array<NewDataEntity>;
  isLoading: boolean;
}

interface MappedNewDataEntity extends NewDataEntity {
  id: number;
}

const CenterText = styled('span')({
  display: 'block',
  textAlign: 'center',
});

const columnHelper = createColumnHelper<MappedNewDataEntity>();

const columns = [
  columnHelper.accessor('account.name', {
    id: 'account_name',
    header: 'Account name',
    size: 35,
  }),
  columnHelper.accessor('account.entity_type', {
    id: 'entity_type',
    header: 'Entity type',
    size: 35,
  }),
  columnHelper.accessor('is_account_owner', {
    id: 'account_owner',
    header: () => <CenterText>Account owner</CenterText>,
    size: 15,
    cell: (props) => <Check isChecked={props.getValue()} />,
  }),
  columnHelper.accessor('is_billing_contact', {
    id: 'billing_contact',
    header: () => <CenterText>Billing contact</CenterText>,
    size: 15,
    cell: (props) => <Check isChecked={props.getValue()} />,
  }),
];

export const AccountsTable: React.FC<AccountsTableProps> = ({
  accounts,
  isLoading,
}) => {
  const navigate = useNavigate();

  const mappedAccounts = useMemo(() => {
    return accounts.map((account) => ({
      ...account,
      id: account.account.id,
    }));
  }, [accounts]);

  const onClick = useCallback(
    (element: MappedNewDataEntity) => {
      navigate(
        `/${ACCOUNTS_DOMAIN}${ACCOUNTS_PATHS.details.root}/${encodeURIComponent(
          element.id
        )}/overview`
      );
    },
    [navigate]
  );

  return (
    <StaticTable
      data={mappedAccounts}
      columns={columns}
      getCoreRowModel={getCoreRowModel()}
      onRowClick={onClick}
      isLoading={isLoading}
      getRowId={(row) => String(row.account.id)}
    />
  );
};

export default AccountsTable;
