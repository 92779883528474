import { ACCOUNTS_PATHS } from 'modules/accounts/route-constants';

export const NON_OWNER_ACCOUNT_TABS_OPTIONS = [
  { label: 'Overview', value: 'overview' },
];

export const ACCOUNT_OWNER_TABS_OPTIONS = [
  ...NON_OWNER_ACCOUNT_TABS_OPTIONS,
  // { label: 'Bank Accounts', value: 'bank-accounts' },
  { label: 'Contacts', value: 'contacts' },
  // { label: 'Logins', value: 'logins' },
  { label: 'Files', value: 'files' },
];

export const PAYROLL_TAB = { label: 'Payroll', value: 'payroll' };
export const LOGINS_TAB = { label: 'Logins', value: 'logins' };
export const BANK_ACCOUNT_TAB = {
  label: 'Bank Accounts',
  value: ACCOUNTS_PATHS.details.tabs.bankAccounts,
};
