import React from 'react';

import { Outlet } from 'react-router-dom';

import { styled, Container as BaseContainer, Stack } from '@liscio/ui';

const Container = styled(BaseContainer)(({ theme }) => ({
  height: '100vh',
  margin: 0,
  overflow: 'auto',
}));

const Wrapper = styled(Stack)(() => ({
  margin: 0,
  padding: 0,
}));

const AuthenticateLayout = () => (
  <Container disableGutters>
    <Wrapper>
      <Outlet />
    </Wrapper>
  </Container>
);

export default AuthenticateLayout;
