import React, { useMemo } from 'react';

import { Tabs as TabsComponent } from '@liscio/ui';

export interface TabsProps {
  inboxNumberOfMessages?: number;
  sentNumberOfMessages?: number;
  draftsNumberOfMessages?: number;
  archivedNumberOfMessages?: number;
  allNumberOfMessages?: number;
  selectedOptionValue: string;
  onNavigationClick(key?: string): void;
}

export const Tabs: React.FC<TabsProps> = ({
  inboxNumberOfMessages,
  sentNumberOfMessages,
  draftsNumberOfMessages,
  archivedNumberOfMessages,
  selectedOptionValue,
  onNavigationClick,
}) => {
  const options = useMemo(() => {
    const availableOptions = [
      {
        label: 'Inbox',
        value: 'inbox',
        count: inboxNumberOfMessages,
        isCountVisible: true,
      },
      {
        label: 'Sent',
        value: 'sent',
        count: sentNumberOfMessages,
        isCountVisible: true,
      },
      {
        label: 'Drafts',
        value: 'draft',
        count: draftsNumberOfMessages,
        isCountVisible: true,
      },
      {
        label: 'Archived',
        value: 'archive',
        count: archivedNumberOfMessages,
        isCountVisible: true,
      },
    ];

    return availableOptions;
  }, [
    inboxNumberOfMessages,
    sentNumberOfMessages,
    draftsNumberOfMessages,
    archivedNumberOfMessages,
  ]);
  return (
    <TabsComponent
      options={options}
      selectedOptionValue={selectedOptionValue}
      onNavigationClick={onNavigationClick}
    />
  );
};

export default Tabs;
