/* eslint-disable import/no-unresolved */
import { MouseEventHandler, useEffect, useState } from 'react';

import { Box, Button, Icon, Stack, Typography, useTheme } from '@liscio/ui';
import { usePrevious } from '@liscio/utils';

import ESignAdditionalSigner from './ESignAdditionalSigner';
import { ESignItemActionButton } from './ESignItemActionButton';
import ESignLoadingMessage from './ESignLoadingMessage';
import { useESignHook } from './useESignHook';
import { ReviewableDocumentFileViewer } from '../ReviewableDocument/ReviewableDocumentFileViewer';
import {
  ESignDependency,
  Item,
  useTaxDeliveryStore,
} from 'stores/workflow/useTaxDeliveryStore';

type ESignItemProps = {
  onItemClick: () => void;
  onComplete: () => void;
  clearIFrameForAdditionalSigner?: () => void;
  itemData: Item;
};

const getSignerFullName = (
  dependency: ESignDependency,
  nextSignerId: string | null
) => {
  let signer;

  if (nextSignerId !== null) {
    if (dependency.currentUserSignatory?.id === nextSignerId) {
      signer = dependency.currentUserSignatory;
    } else {
      signer = dependency.sameDeviceSignatories.find(
        (sameDevice) => sameDevice?.id === nextSignerId
      );
    }
    return `${signer?.firstName || ''} ${signer?.lastName || ''}`;
  }

  if (dependency) {
    if (dependency.currentUserSignatory?.status !== 'signed_agreement') {
      signer = dependency.currentUserSignatory;
    } else {
      signer = dependency.sameDeviceSignatories.find(
        (sameDevice) => sameDevice?.status !== 'signed_agreement'
      );
    }
    return `${signer?.firstName || ''} ${signer?.lastName || ''}`;
  }

  return '';
};

export const ESignItem = ({
  onItemClick,
  onComplete,
  clearIFrameForAdditionalSigner,
  itemData,
}: ESignItemProps) => {
  const { prompt, itemIndex, stepIndex, complete, dependency } = itemData;

  const { activeItem, activeStep, setActiveItemComplete } =
    useTaxDeliveryStore();
  const active = itemIndex === activeItem && stepIndex === activeStep;

  const theme = useTheme();
  const [open, setOpen] = useState(active);
  const [actionButtonDisabled, setActionButtonDisabled] = useState(false);
  const [additionalSigner, setAdditionalSigner] = useState(false);

  // ESign document creation and polling logic put into a custom hook
  const {
    ESignIframeURL,
    ESignDocumentCompleted,
    setESignAgreementURL,
    createAgreementIsLoading,
    createAgreementIsError,
    getESignDocumentIsLoading,
    getESignDocumentIsError,
    nextSignerId,
  } = useESignHook();

  const pESignDocumentCompleted = usePrevious(ESignDocumentCompleted);
  if (pESignDocumentCompleted === false && ESignDocumentCompleted === true) {
    setActiveItemComplete();
  }

  // set the open state when the active prop changes
  useEffect(() => {
    if (complete) {
      setOpen(false);
    } else {
      setOpen(active);
    }
  }, [active, complete]);

  // reset the action button disabled, etc state when the item is closed
  useEffect(() => {
    if (!open) {
      setActionButtonDisabled(false);
      setESignAgreementURL('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // used to present the additional signer
  useEffect(() => {
    if (nextSignerId) {
      setAdditionalSigner(true);
      setActionButtonDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextSignerId]);

  useEffect(() => {
    if (ESignDocumentCompleted && complete && clearIFrameForAdditionalSigner)
      clearIFrameForAdditionalSigner();
  }, [ESignDocumentCompleted, complete, clearIFrameForAdditionalSigner]);

  const handleESignButtonClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    setAdditionalSigner(false);
    setActionButtonDisabled(true);

    const createAgreementURL = (dependency as ESignDependency)?.startSigningUrl;

    setESignAgreementURL(createAgreementURL || '');

    onItemClick();
  };

  const handleActionOnClick = () => {
    if (additionalSigner) {
      if (clearIFrameForAdditionalSigner) clearIFrameForAdditionalSigner();
      setAdditionalSigner(false);
    }

    if (ESignDocumentCompleted || complete) {
      onComplete();
    }
  };

  const isComplete = complete ? 'done' : 'to-do';

  const fontColor = active
    ? theme.palette.text.primary
    : theme.palette.text.secondary;

  const labelFontColor = complete
    ? theme.palette.success.main
    : active
    ? theme.palette.primary.main
    : theme.palette.divider;

  return (
    <>
      <Box
        sx={{
          width: '100%',
          padding: '12px',
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: '7px',
          marginBottom: '16px',
          color: fontColor,
          cursor: 'pointer',
        }}
        onClick={() => setOpen(!open)}
      >
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Box role="button" aria-label="show more">
            <Stack direction="row" spacing={1} alignItems="center">
              {open ? (
                <Icon icon="chevronDown" />
              ) : (
                <Icon icon="chevronRight" />
              )}
              <Typography variant="body1" fontWeight={'normal'}>
                {prompt} -{' '}
                {getSignerFullName(dependency as ESignDependency, nextSignerId)}
              </Typography>
            </Stack>
          </Box>
          <Box
            sx={{
              borderRadius: '5px',
              border: `1px solid ${labelFontColor}`,
              padding: '2px 8px',
              color: labelFontColor,
              textTransform: 'uppercase',
            }}
          >
            <Typography variant="subtitle2" fontWeight={'bold'}>
              {isComplete}
            </Typography>
          </Box>
        </Stack>
        {open && (
          <Stack mt={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleESignButtonClick}
              disabled={actionButtonDisabled}
            >
              eSign
            </Button>
          </Stack>
        )}
      </Box>
      {/* component for main-content-portal-container */}
      {open && (
        <>
          <ReviewableDocumentFileViewer
            loading={createAgreementIsLoading}
            hasError={createAgreementIsError || getESignDocumentIsError}
            url={ESignIframeURL || ''}
            mode="signable"
          />
        </>
      )}
      {active && (
        <ESignItemActionButton
          onClick={handleActionOnClick}
          disabled={
            complete || (additionalSigner && clearIFrameForAdditionalSigner)
              ? false
              : !ESignDocumentCompleted
          }
        />
      )}

      {/* loading dialog for ESign document */}
      {getESignDocumentIsLoading && (
        <ESignLoadingMessage open={getESignDocumentIsLoading} />
      )}
      {/* helper content for the additional signer - both desktop and client */}
      {additionalSigner && <ESignAdditionalSigner open={additionalSigner} />}
    </>
  );
};
