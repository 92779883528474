import React, { MouseEventHandler, useState } from 'react';

import { IconButton, Menu, MenuItem } from '@mui/material';
import { styled, GlobalLoader, Icon } from '@liscio/ui';
import { useParams } from 'react-router-dom';

import { AccountBankAccountEntity } from '@liscio/api';

import { ConfirmationDialog } from 'components';
import { useToastError } from 'custom-hooks';
import {
  useAccountOverview,
  useDeleteBankAccount,
  useSendUpdateNotification,
} from 'fetch-utils/accounts/accounts-hooks';
import { useBankAccountOverlays } from 'modules/accounts/views/AccountDetailsMobileView/AccountDetailsMobileView/components/AccountBankAccountsMobile/view-hooks';

export interface AccountBankAccountEllipsesMenuProps {
  bankAccount?: Partial<AccountBankAccountEntity>;
}

const GreyDots = styled(Icon)(({ theme }) => ({
  color: theme.palette.grey[600],
}));

export default function AccountBankAccountEllipsesMenu({
  bankAccount,
}: AccountBankAccountEllipsesMenuProps) {
  const { id } = useParams();
  const { data: accountData } = useAccountOverview(id!);

  const [open, setOpen] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<
    null | (EventTarget & Element)
  >(null);
  const { setShowEditOverlay, setShowDetailOverlay, setSelectedBankAccount } =
    useBankAccountOverlays();
  const {
    mutateAsync: deleteBankAccount,
    isLoading: isBankDeleting,
    error,
  } = useDeleteBankAccount();
  const { mutateAsync: sendUpdateNotification } = useSendUpdateNotification();

  useToastError(error, 'Something went wrong with bank account update');
  if (!accountData?.is_account_owner) return null;

  function handleClose() {
    setOpen(false);
  }

  function handleEllipsesClick(event: React.MouseEvent) {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  }

  function handleEditSelect() {
    setShowEditOverlay(true);
    handleClose();
  }

  function handleDeleteSelect() {
    setShowDeleteDialog(true);
  }

  async function handleConfirmDelete() {
    try {
      await deleteBankAccount(String(bankAccount!.id));
      sendUpdateNotification(id!);
      setShowDetailOverlay(false);
      setSelectedBankAccount(undefined);
      setShowDeleteDialog(false);
      handleClose();
    } catch (e) {
      // error handled by react query
    }
  }

  return (
    <>
      <IconButton
        sx={{ padding: 0, margin: 0 }}
        aria-controls={open ? 'ellipse-menu-open' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleEllipsesClick as MouseEventHandler}
      >
        <GreyDots icon="ellipsisVertical" />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleEditSelect}>Edit</MenuItem>
        <MenuItem onClick={handleDeleteSelect}>Delete</MenuItem>
      </Menu>
      <ConfirmationDialog
        open={showDeleteDialog}
        onConfirm={handleConfirmDelete}
        onCancel={() => setShowDeleteDialog(false)}
        title="Delete Bank Account"
        content={`Are you sure you want to delete ${bankAccount?.bank_name}?`}
        confirmLabel="Delete"
      />
      <GlobalLoader open={isBankDeleting} />
    </>
  );
}
