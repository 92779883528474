import React from 'react';

import { styled, Stack, Typography, Icon } from '@liscio/ui';

import { parseAmount } from '@liscio/utils';

import { Avatar, Interweave } from 'components';
import { Label, Row } from 'components/detailedInformation';

export interface DetailsProps {
  forAccount?: string;
  assigned?: string;
  assignedAvatar?: string;
  invoiceNumber?: string;
  invoiceDate?: string;
  invoiceDueData?: string;
  invoiceAmount?: string;
  paymentItem?: string;
  message?: string;
}

const Wrapper = styled(Stack)({
  padding: '16px',
});

export const Details: React.FC<DetailsProps> = ({
  forAccount,
  assigned,
  assignedAvatar,
  invoiceNumber,
  invoiceDate,
  invoiceDueData,
  invoiceAmount,
  paymentItem,
  message,
}) => {
  return (
    <Wrapper gap="24px">
      <Row>
        <Label>FOR ACCOUNT:</Label>
        <Typography>{forAccount}</Typography>
      </Row>
      <Row>
        <Label>FOR CONTACT:</Label>
        <Avatar
          src={assignedAvatar || ''}
          sx={{ width: 24, height: 24, fontSize: '12px' }}
        />
        <Typography>{assigned}</Typography>
      </Row>
      {invoiceNumber && (
        <Row>
          <Label>INVOICE NUMBER:</Label>
          <Typography>{invoiceNumber}</Typography>
        </Row>
      )}
      <Row>
        <Label>INVOICE DATE:</Label>
        <Icon icon="calendarDay" />
        <Typography>{invoiceDate}</Typography>
      </Row>
      <Row>
        <Label>DUE DATE:</Label>
        <Icon icon="calendarDay" />
        <Typography>{invoiceDueData}</Typography>
      </Row>
      <Row>
        <Label>AMOUNT:</Label>
        <Typography>
          <strong>{parseAmount(invoiceAmount || '')}</strong>
        </Typography>
      </Row>
      <Row>
        <Label>ITEM:</Label>
        <Typography>{paymentItem}</Typography>
      </Row>
      {message && (
        <>
          <Row>
            <Label>MESSAGE:</Label>
          </Row>
          <Interweave content={message} />
        </>
      )}
    </Wrapper>
  );
};

export default Details;
