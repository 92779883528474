import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';

import { NavMenuItem } from '@liscio/common';
import { List, Icon, useViewportSize } from '@liscio/ui';

import { NotificationsNavMenuItem } from './NotificationsNavMenuItem';
import { ResponsiveUploadFileNavigationMenuItem } from './ResponsiveUploadFileNavigationMenuItem';
import { useMessages } from 'fetch-utils/messages/messages-hooks';
import { useProfileData } from 'fetch-utils/users/user-hooks';
import { routes } from 'routes';

const SUPPORT_PORTAL_URL =
  'https://support.liscio.me/portal/en/kb/liscio/client-end-user';

export const PrimaryNavigationMenu = () => {
  const { isMobile } = useViewportSize();
  const isNativePlatform = Capacitor.isNativePlatform();
  const { data: messagesData } = useMessages({ type: 'inbox' });
  const { hasInvoiceMenuElement } = useProfileData();

  return (
    <List>
      <ResponsiveUploadFileNavigationMenuItem />
      <NavMenuItem
        data-testid="sidebar__home"
        icon={<Icon icon="home" />}
        label="Home"
        to={routes.dashboard.link()}
      />
      <NavMenuItem
        data-testid="sidebar__inbox"
        icon={<Icon icon="inbox" />}
        badgeNumber={messagesData?.unread_thread_count || 0}
        label="Inbox"
        to={routes.messagesInbox.link()}
      />
      <NavMenuItem
        data-testid="sidebar__tasks"
        icon={<Icon icon="tasks" />}
        label="Tasks"
        to={routes.tasksList.link()}
      />
      <NavMenuItem
        data-testid="sidebar__requests"
        icon={<Icon icon="requests" />}
        label="Requests"
        to={routes.requestsListProtected.link()}
      />
      <NavMenuItem
        data-testid="sidebar__accounts"
        icon={<Icon icon="accounts" />}
        label="Accounts"
        to={routes.accountsList.link()}
      />
      <NavMenuItem
        data-testid="sidebar__files"
        icon={<Icon icon="files" />}
        label="Files"
        to={routes.filesList.link()}
      />
      {hasInvoiceMenuElement && (
        <NavMenuItem
          data-testid="sidebar__billing"
          icon={<Icon icon="billing" />}
          label="Billing"
          to={routes.invoicesList.link()}
        />
      )}
      {!isMobile && <NotificationsNavMenuItem />}
      <NavMenuItem
        data-testid="sidebar__need_help"
        icon={<Icon icon="help" />}
        onClick={
          isNativePlatform
            ? () => {
                Browser.open({
                  url: SUPPORT_PORTAL_URL,
                });
              }
            : undefined
        }
        href={isNativePlatform ? undefined : SUPPORT_PORTAL_URL}
        target={isNativePlatform ? undefined : '_blank'}
        label="Need Help?"
      />
    </List>
  );
};
