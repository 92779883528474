import {
  Autocomplete,
  AutocompleteProps,
  FormControl,
  TextField,
} from '@mui/material';

import { BasicAccount } from '@liscio/api';
import { Stack, Typography } from '@liscio/ui';

import { useAccountsOfContactOnTheBasisOfUserId } from 'fetch-utils/accounts/accounts-hooks';

interface AccountsAutocompleteProps
  extends Omit<
    AutocompleteProps<BasicAccount, undefined, undefined, undefined>,
    'renderInput' | 'options'
  > {}

export default function AccountsAutocomplete(props: AccountsAutocompleteProps) {
  const { data: loggedUserAccounts, isLoading: loggedUserAccountsLoading } =
    useAccountsOfContactOnTheBasisOfUserId();

  return (
    <Stack direction="row" alignItems="center">
      <Typography variant="body2" pr="10px">
        Filter By
      </Typography>
      <FormControl sx={{ width: 300, display: 'flex' }}>
        <Autocomplete
          renderInput={(params) => (
            <TextField
              {...params}
              data-testid="filter__account"
              label={
                loggedUserAccountsLoading
                  ? 'Loading Accounts . . .'
                  : 'Accounts'
              }
            />
          )}
          color="primary"
          options={loggedUserAccounts?.data! || []}
          {...props}
        />
      </FormControl>
    </Stack>
  );
}
