import React, { useState, useLayoutEffect, useMemo, useEffect } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { styled, Box, Button, Stack, Typography, Chip } from '@liscio/ui';

import { CstMemberEntity, Role } from '@liscio/api';

import { mapRecipientTypeToLabel } from './helpers';
import useRecipientAccountData from './hooks/useRecipientAccountData';
import { Select } from 'components';

export interface Recipient extends RichOption {
  status?: string | null;
  accounts?: Array<Account> | null;
  type?: Role;
}
export interface Account extends RichOption {
  account_type?: string;
  cpa_id?: number;
  entity_type?: string;
  cst_member?: Recipient[];
}

export interface RecipientAccount {
  recipient?: Recipient | null;
  account?: Account | null;
}

export interface ContactSelectorProps {
  open?: boolean;
  selectedRecipient?: Recipient | null;
  selectedAccount?: Account | null;
  onChange(newValue: RecipientAccount | RecipientAccount[]): void;
  onCancel(): void;
  fixedToAccountId?: string;
  fixedToContactId?: string;
  useOnlyEmployees?: boolean;
  accountIsRequired?: boolean;
  shouldAddCSTMembers?: boolean;
}

const LabelWrapper = styled(Box)(({ theme }) => ({
  width: '80px',
  [theme.breakpoints.down('md')]: {
    width: '64px',
  },
}));

export const ContactSelector: React.FC<ContactSelectorProps> = ({
  open = false,
  selectedRecipient: selectedRecipientProp,
  selectedAccount: selectedAccountProp,
  onChange,
  onCancel,
  fixedToAccountId,
  fixedToContactId,
  useOnlyEmployees,
  accountIsRequired,
  shouldAddCSTMembers = false,
}) => {
  const [selectedRecipient, setSelectedRecipient] = useState(
    selectedRecipientProp
  );
  const [selectedAccount, setSelectedAccount] = useState(selectedAccountProp);
  const [recipientSearchString, setRecipientSearchString] = useState('');
  const [accountSearchString, setAccountSearchString] = useState('');
  const {
    recipientContacts,
    recipientEmployees,
    accounts,
    isLoading,
    contactAccountsWithCST,
  } = useRecipientAccountData({
    selectedRecipient,
    selectedAccount,
    recipientSearchString,
    accountSearchString,
  });

  const recipientData = useMemo(
    () =>
      useOnlyEmployees
        ? recipientEmployees
        : [...recipientContacts, ...recipientEmployees],
    [recipientEmployees, recipientContacts, useOnlyEmployees]
  );

  const handleSubmit = () => {
    let CSTMembers: CstMemberEntity[] = [];

    if (shouldAddCSTMembers) {
      const accountWithCST = contactAccountsWithCST?.data?.find(
        (account: Account) => account.value === selectedAccount?.value
      );
      CSTMembers = accountWithCST?.cst_member || [];
    }

    // Because we must add all CST members if an account has CST members
    // the ContactSelector callback will supply a new recipient OR multiple new recipients
    if (CSTMembers?.length! > 0) {
      const recipients = [
        selectedRecipient,
        ...((CSTMembers as Array<Recipient>) || []),
      ];

      const recipientAccounts: RecipientAccount[] = recipients.map(
        (recipient) => ({
          recipient: recipient,
          account: selectedAccount,
        })
      );

      onChange(recipientAccounts);
    } else {
      onChange({
        recipient: selectedRecipient,
        account: selectedAccount,
      });
    }

    onCancel();
    setSelectedRecipient(null);
    setSelectedAccount(null);
    setRecipientSearchString('');
    setAccountSearchString('');
  };

  useLayoutEffect(() => {
    if (accounts?.length === 1) {
      setSelectedAccount(accounts[0]);
    }
  }, [accounts]);

  useLayoutEffect(() => {
    if (selectedRecipient && selectedRecipient.accounts?.length === 1) {
      setSelectedAccount(selectedRecipient.accounts[0]);
    }
  }, [selectedRecipient]);

  useEffect(() => {
    if (fixedToContactId) setSelectedRecipient(selectedRecipientProp);
  }, [
    fixedToContactId,
    setSelectedRecipient,
    selectedRecipientProp,
    selectedRecipient,
  ]);

  useEffect(() => {
    if (fixedToAccountId) setSelectedAccount(selectedAccountProp);
  }, [
    fixedToAccountId,
    setSelectedAccount,
    selectedAccountProp,
    selectedAccount,
  ]);

  const requiredAccountNotSelected = !selectedAccount && accountIsRequired;
  const submitDisabled = !selectedRecipient || requiredAccountNotSelected;
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      data-testid="contact-selector-container"
    >
      <DialogTitle id="alert-dialog-title">Select Contact</DialogTitle>
      <DialogContent>
        <Stack direction="row" alignItems="center">
          <LabelWrapper>
            <Typography>Recipient:</Typography>
          </LabelWrapper>
          <Select<Recipient | Account>
            data={recipientData}
            onChange={setSelectedRecipient}
            onSearchChange={setRecipientSearchString}
            value={selectedRecipient}
            title="Select Recipient"
            isLoading={isLoading}
            searchString={recipientSearchString}
            renderItem={(item) => (
              <Box
                display="flex"
                justifyContent="space-between"
                width="100%"
                pt={1}
                pb={1}
              >
                <Typography>{(item as Account | Recipient)?.label}</Typography>
                <Chip
                  label={mapRecipientTypeToLabel(
                    (item as Account | Recipient)?.type
                  )}
                  size="small"
                />
              </Box>
            )}
            disabled={Boolean(fixedToContactId)}
          />
        </Stack>
        <Stack direction="row" alignItems="center">
          <LabelWrapper>
            <Typography>Account:</Typography>
          </LabelWrapper>
          <Select
            data={
              fixedToContactId && selectedRecipient
                ? selectedRecipient.accounts
                : accounts
            }
            onChange={setSelectedAccount}
            onSearchChange={setAccountSearchString}
            value={selectedAccount}
            title="Select Account"
            isLoading={isLoading}
            searchString={accountSearchString}
            disabled={Boolean(fixedToAccountId)}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Close</Button>
        <Button
          onClick={handleSubmit}
          autoFocus
          variant="contained"
          disabled={submitDisabled}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContactSelector;
