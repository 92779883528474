import React from 'react';

import { Helmet } from 'react-helmet-async';
import { Route, Routes } from 'react-router-dom';

import { INVOICES_PAGE_TITLES, INVOICES_PATHS } from './route-constants';
import { InvoicesListView } from './views';
import { LazyWrapper } from 'components';
import { CatchAllRedirect } from 'modules/CatchAllRedirect/CatchAllRedirect';

const InvoiceDetailsView = React.lazy(
  () => import('./views/InvoiceDetailsView/InvoiceDetailsView')
);
const PaymentProcessingView = React.lazy(
  () => import('./views/PaymentProcessingView/PaymentProcessingView')
);
const PaymentSourceAddedView = React.lazy(
  () => import('./views/PaymentSourceAddedView/PaymentSourceAddedView')
);
const BillingHistory = React.lazy(
  () => import('./views/BillingHistory/BillingHistory')
);

export const ModuleRoutes = () => (
  <>
    <Helmet title={INVOICES_PAGE_TITLES.list} />
    <Routes>
      <Route path={INVOICES_PATHS.list} Component={InvoicesListView} />
      <Route
        path={`${INVOICES_PATHS.details}/:id`}
        element={
          <LazyWrapper>
            <InvoiceDetailsView />
          </LazyWrapper>
        }
      />
      <Route
        path={INVOICES_PATHS.paymentSourceAdded}
        element={
          <LazyWrapper>
            <PaymentSourceAddedView />
          </LazyWrapper>
        }
      />
      <Route
        path={INVOICES_PATHS.paymentProcessing}
        element={
          <LazyWrapper>
            <PaymentProcessingView />
          </LazyWrapper>
        }
      />
      <Route
        path={`${INVOICES_PATHS.billingHistory}/:parentInvoiceId`}
        element={
          <LazyWrapper>
            <BillingHistory />
          </LazyWrapper>
        }
      />
      <Route path="*" element={<CatchAllRedirect />} />
    </Routes>
  </>
);

export default ModuleRoutes;
