import React, { useCallback, useState } from 'react';

import { IconButton, Menu, MenuItem } from '@mui/material';
import toast from 'react-hot-toast';

import { Message } from '@liscio/api';
import { Icon } from '@liscio/ui';
import { preventBubbling } from '@liscio/utils';

import { useUpdateMessageUnreadStatus } from 'fetch-utils/messages/messages-hooks';
import { useProfileData } from 'fetch-utils/users/user-hooks';
import { MARK_UNREAD_TOASTS } from 'modules/messages/views/ThreadDetailsViewDesktop/components/ThreadDetailHeader/ThreadDetailHeader';

export type MessagesEllipsisMenuProps = {
  message: Message;
};

export default function MessagesEllipsisMenu({
  message,
}: MessagesEllipsisMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const { cpa_user_id } = useProfileData();
  function handleEllipsesClick(e: React.MouseEvent<HTMLButtonElement>) {
    preventBubbling(e);
    setAnchorEl(e.currentTarget);
    setOpen((isOpen) => !isOpen);
  }

  const { mutateAsync: markUnread } = useUpdateMessageUnreadStatus();

  const handleMarkAsUnread = useCallback(
    async (e: React.MouseEvent<HTMLLIElement>) => {
      preventBubbling(e);

      try {
        if (message && cpa_user_id) {
          await markUnread({
            cpa_user_id,
            message_id: message.id,
          });
          toast.success(MARK_UNREAD_TOASTS.success);
        } else {
          toast.error(MARK_UNREAD_TOASTS.error);
        }
      } catch (e) {
        toast.error(MARK_UNREAD_TOASTS.error);
      }

      setOpen(false);
    },
    [message, markUnread, cpa_user_id]
  );

  return (
    <>
      <IconButton
        sx={{ padding: 0, margin: 0 }}
        aria-controls={open ? 'ellipse-menu-open' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleEllipsesClick}
      >
        <Icon
          icon="ellipsis"
          sx={{ color: (theme) => theme.palette.grey[600] }}
        />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={() => setOpen(false)}>
        <MenuItem onClick={handleMarkAsUnread}>Mark as Unread</MenuItem>
      </Menu>
    </>
  );
}
