import { useMemo } from 'react';

import { useForm } from 'react-hook-form';

import { UploadFormType } from '@liscio/api';
import { Document } from '@liscio/api/graphql';
import { parseFileName } from '@liscio/utils';

import getValidationSchema from './getUploadValidationSchema';
import makeDefaultParticipants from './helpers/make-default-participants';
import { RecipientAccount } from 'components/ContactSelector/ContactSelector';
import { useYupValidationResolver } from 'custom-hooks';
import { useAccountsOfContact } from 'fetch-utils/accounts/accounts-hooks';
import { useHomeData } from 'fetch-utils/users/user-hooks';

export interface UploadDocumentFormData {
  year: string;
  month: string;
  tags: RichOption[];
  message: string;
  files: File[];
  file_name: string;
  file_extension?: string;
  doc_ids?: number[];
  doc_info?: Document[];
  participants?: RecipientAccount[];
}

export default function useSendDocumentForm(
  defaultForm?: Partial<UploadDocumentFormData>,
  type?: UploadFormType
) {
  const { data: homeData } = useHomeData();
  const { data: accounts } = useAccountsOfContact(
    String(homeData?.data?.contact_id || '')
  );
  const defaultValues = defaultForm || {};
  const userHasNoAccounts = !accounts?.data || accounts?.data.length === 0;

  const { baseName, extension } = useMemo(() => {
    return parseFileName(defaultValues?.file_name || '');
  }, [defaultValues?.file_name]);

  const validationSchema = useMemo(
    () => getValidationSchema(type, userHasNoAccounts),
    [type, userHasNoAccounts]
  );

  // - If the client has more than one account, the form should hydrate nothing
  // - If the client has exactly one account, then
  //      - If the account has a CST, populate the contact selector with that CST
  //      - If the account has no CST, populate the contact selector with the firm's Default Message Recipient
  const defaultParticipants: RecipientAccount | RecipientAccount[] =
    useMemo(() => {
      if (type !== UploadFormType.NewFile || !UploadFormType.Workflow)
        return [];

      return makeDefaultParticipants({
        accounts: accounts?.data,
        defaultRecipient: homeData?.data?.default_recipient,
      });
    }, [accounts?.data, homeData?.data, type]);

  const resolver = useYupValidationResolver(validationSchema);
  return useForm<UploadDocumentFormData>({
    defaultValues: {
      year: '',
      month: '',
      files: [],
      tags: [],
      participants: defaultParticipants,
      ...defaultValues,
      file_name: baseName,
      file_extension: extension,
    },
    resolver,
  });
}
