import React from 'react';

import { Checkbox as MUICheckbox, CheckboxProps } from '@mui/material';

import { styled, Typography } from '@liscio/ui';

export interface DefaultCheckboxProps extends CheckboxProps {
  label: React.ReactNode;
}

const StyledLabel = styled('label')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const DefaultCheckbox: React.FC<DefaultCheckboxProps> = ({
  label,
  ...rest
}) => {
  return (
    <StyledLabel>
      <Typography>{label}</Typography>
      <MUICheckbox {...rest} />
    </StyledLabel>
  );
};

export default DefaultCheckbox;
