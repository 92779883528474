import { useCallback, useState } from 'react';

import { Stack, GlobalLoader } from '@liscio/ui';
import { useScanner, convertImagesToPDF } from '@liscio/common';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';
import { DropzoneProps, useDropzone } from 'react-dropzone';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { defaultAllowedUploadMimetypes } from 'components/FileAttachmentsField/accepted-file-types';
import { launchInvalidFileToast } from 'utils/toasts';

interface ScannerAndInputProps {
  disabled: boolean;
}

const Wrapper = styled(Stack)(() => ({
  width: '50%',
  margin: '0 auto',
  textAlign: 'center',
}));

const ScannerAndInput: React.FC<ScannerAndInputProps> = ({ disabled }) => {
  const [isCloningFiles, setIsCloningFiles] = useState(false);
  const { setValue } = useFormContext();
  const { append: addFilesToForm } = useFieldArray({ name: 'files' });
  const { open: openScanner } = useScanner({
    onScan: (scannedPagesUri) => {
      convertImagesToPDF(scannedPagesUri).then((PDFFile) => {
        addFilesToForm(PDFFile);
      });
    },
  });

  const handleFileSelection = useCallback<Required<DropzoneProps>['onDrop']>(
    async (acceptedFiles, fileRejections) => {
      fileRejections.forEach((rejection) =>
        launchInvalidFileToast(rejection.file)
      );
      if (acceptedFiles.length) {
        setIsCloningFiles(true);
        // When using a file from google drive, it seems we must clone it _immediately_,
        // otherwise the delay between the user selection and upload
        // request disturbs chrome, per https://www.reddit.com/r/learnjavascript/ . . .
        // . . . comments/16hgnzq/neterr_upload_file_changed_even_after_recreating/
        const clonedFiles = acceptedFiles.map(async (file) => {
          if (typeof file.arrayBuffer === 'function') {
            const buffer = await file.arrayBuffer();
            const clone = new File([buffer], file.name, { type: file.type });

            return clone;
          }

          return file;
        });
        const clones = await Promise.all(clonedFiles);
        setIsCloningFiles(false);
        addFilesToForm([...clones]);
      }
    },
    [addFilesToForm]
  );

  function handleClearClick() {
    setValue('files', []);
  }

  const derivedDisabled = disabled;

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleFileSelection,
    accept: defaultAllowedUploadMimetypes,
  });

  return (
    <Wrapper>
      <GlobalLoader open={Boolean(isCloningFiles)} />
      <Button {...getRootProps()} variant="outlined">
        Browse Files for Upload
        <input
          data-testid="file-upload-input"
          {...getInputProps()}
          disabled={derivedDisabled}
        />
      </Button>
      <Button
        sx={{ margin: '16px 0px' }}
        variant="outlined"
        onClick={openScanner}
        disabled={disabled}
      >
        Open Document Scanner
      </Button>
      <Button onClick={handleClearClick}>Clear Files</Button>
    </Wrapper>
  );
};

export default ScannerAndInput;
