import React from 'react';

import { styled, Stack, Typography } from '@liscio/ui';

import LogoImage from './assets/liscio_logo.png';

const Wrapper = styled(Stack)(({ theme }) => ({
  marginTop: '30px',
  color: theme.palette.common.neutrals[400],
}));

export const PoweredByLiscio = () => (
  <Wrapper direction="row" justifyContent="center" alignItems="center">
    <Typography pr="10px" variant="body2">
      Powered by
    </Typography>
    <img src={LogoImage} alt="logo" width="66px" />
  </Wrapper>
);

export default PoweredByLiscio;
