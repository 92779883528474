import React from 'react';

import { ResponsiveView } from '@liscio/ui';

const AccountContactsMobileView = React.lazy(
  () => import('./AccountContactsMobileView/AccountContactsMobileView')
);
const AccountContactsDesktopView = React.lazy(
  () => import('./AccountContactsDesktopView/AccountContactsDesktopView')
);

export const AccountContactsView = () => {
  return (
    <ResponsiveView
      SmallView={<AccountContactsMobileView />}
      LargeView={<AccountContactsDesktopView />}
    />
  );
};

export default AccountContactsView;
