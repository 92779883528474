import React from 'react';

import { styled, useTheme, Typography, Box } from '@liscio/ui';

import { useIsDesktopView } from 'custom-hooks';

const WrapperStyled = styled(Box)({
  paddingTop: '56px',
  textAlign: 'center',
});

const ImageWrapperStyled = styled(Box)({
  marginBottom: '24px',
  img: {
    width: '100%',
    maxWidth: '260px',
    objectFit: 'contain',
  },
});

export const Greetings = () => {
  const theme = useTheme();
  const isDesktopView = useIsDesktopView();

  return (
    <WrapperStyled>
      <ImageWrapperStyled>
        <img src={theme?.assets?.logoSplash} alt="logo" />
      </ImageWrapperStyled>
      <Typography variant={isDesktopView ? 'h1' : 'h3'}>
        Welcome back
      </Typography>
    </WrapperStyled>
  );
};

export default Greetings;
