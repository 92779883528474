import React from 'react';

import { Icon, IconButton, styled, Stack, Typography } from '@liscio/ui';

export interface PreviewFileButtonProps {
  onClick: () => void;
  label?: string;
}

const Wrapper = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer',
});

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
  textDecoration: 'none',
}));

const PaddingText = styled(Typography)({
  wordWrap: 'break-word',
  maxWidth: '230px',
});

export const PreviewFileButton: React.FC<PreviewFileButtonProps> = ({
  onClick,
  label = 'Preview File',
}) => {
  return (
    <Wrapper role="button" onClick={onClick}>
      <IconButton>
        <GreyText>
          <Icon icon="preview" />
        </GreyText>
      </IconButton>
      <PaddingText>{label}</PaddingText>
    </Wrapper>
  );
};

export default PreviewFileButton;
