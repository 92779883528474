import React from 'react';

import { styled, useTheme, Box, Stack, Typography, Icon } from '@liscio/ui';

import { BasicDocument } from '@liscio/api';

import { FileListEllipsesMenu } from './components';
import TagList from 'components/TagList/TagList';

export interface FilePrimaryProps {
  file: BasicDocument;
  onClick: (e: React.MouseEvent) => void;
  onArchiveSuccess?: () => void;
}

const InfoStack = styled(Stack)({
  padding: '0px 0px 0px 10px',
  flexDirection: 'column',
  justifyContent: 'space-between',
  flex: 1,
  maxWidth: '90%',
});

const UploadedBy = styled(Typography)(({ theme }) => ({
  flexShrink: 0,
  color: theme.palette.grey[500],
}));

export const FilePrimary: React.FC<FilePrimaryProps> = ({
  file,
  onClick,
  onArchiveSuccess,
}) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      data-testid="file-list-primary-item"
    >
      <Box
        flex={1}
        maxWidth="90%"
        display="flex"
        alignItems="center"
        onClick={onClick}
      >
        <Icon icon="copy" htmlColor={theme.palette.grey[500]} />
        <InfoStack>
          <Stack direction="row" gap="8px" alignItems="center">
            <Typography
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {file.doc_name}
            </Typography>
            {file.is_private && (
              <Icon
                icon="lock"
                htmlColor={theme.palette.grey[500]}
                fontSize="small"
              />
            )}
          </Stack>
          <UploadedBy>
            by {file.uploaded_by} • {file.date_created}
          </UploadedBy>
          <Box>
            <TagList tags={file?.tags} />
          </Box>
        </InfoStack>
      </Box>
      <FileListEllipsesMenu file={file} onArchiveSuccess={onArchiveSuccess} />
    </Stack>
  );
};
