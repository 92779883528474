import React from 'react';

import { Box, Stack, Typography, Divider, styled } from '@liscio/ui';

import { AccountsTable } from './components';
import { useAccounts } from 'fetch-utils/accounts/accounts-hooks';

export interface AccountsListViewDesktopProps {}

const TableWrapper = styled(Box)({
  padding: '24px',
});

export const AccountsListViewDesktop: React.FC<
  AccountsListViewDesktopProps
> = () => {
  const { data, isLoading } = useAccounts();
  const accounts = data?.accounts || [];

  return (
    <Stack mb="16px">
      <Typography variant="h1" p="14px">
        Accounts
      </Typography>
      <Divider />
      <TableWrapper>
        <AccountsTable accounts={accounts} isLoading={isLoading} />
      </TableWrapper>
    </Stack>
  );
};

export default AccountsListViewDesktop;
