import React, { useCallback } from 'react';

import { LinearProgress } from '@mui/material';
import { createColumnHelper, getCoreRowModel } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';

import { TodoFeedsQuery } from '@liscio/api/graphql';
import {
  styled,
  Box,
  Typography,
  StaticTable,
  UnreadIndicator,
  List as MUIList,
  ListItem,
  HasId,
} from '@liscio/ui';
import { getScrollValue } from '@liscio/utils';

import { StatusCell } from './components';
import { getRedirectUrl, getLabel } from './helpers';
import getStatus from './helpers/getStatus';
import getType from './helpers/getType';
import { getDueDate } from 'modules/dashboard/helpers';
import { Unpacked } from 'types/utilityTypes';

export type TableTodo = Unpacked<TodoFeedsQuery['todoFeeds']> & HasId;
export interface TodoTableProps {
  todoFeeds: TableTodo[];
  goBackUrl?: string | number;
  fullTable?: boolean;
}

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: '16px 0px 16px 0px',
  borderBottom: `solid 1px ${theme.palette.common.neutrals[200]}`,
  alignItems: 'center',
}));

const columnHelper = createColumnHelper<TableTodo>();

const columns = [
  columnHelper.accessor('isRead', {
    id: 'is-unread',
    header: '',
    cell: (info) => (
      <Box
        sx={{
          display: 'grid',
          justifyContent: 'center',
        }}
      >
        <UnreadIndicator isUnread={!info.getValue()} />
      </Box>
    ),
    size: 2.5,
  }),
  columnHelper.accessor(getLabel, {
    id: 'name',
    header: () => <span>Name</span>,
    size: 52.5,
  }),
  columnHelper.accessor(getType, {
    header: 'Type',
    size: 20,
  }),
  columnHelper.accessor(getStatus, {
    id: 'status',
    header: 'Status',
    cell: (data) => {
      const status = data.getValue();

      return typeof status === 'number' ? (
        <LinearProgress
          sx={{ width: 'calc(100% - 1rem)' }}
          color="secondary"
          variant="determinate"
          value={status}
        />
      ) : (
        <StatusCell status={status} />
      );
    },
    size: 30,
  }),
  columnHelper.accessor(getDueDate, {
    id: 'duedate-header',
    header: 'Due Date',
    size: 20,
  }),
];

export const TodoTable = ({
  todoFeeds,
  goBackUrl,
  fullTable = false,
}: TodoTableProps) => {
  const navigate = useNavigate();
  const onRowClick = useCallback(
    (todoFeed: Unpacked<TodoFeedsQuery['todoFeeds']>) => {
      const redirectUrl = getRedirectUrl(todoFeed);

      if (redirectUrl) {
        navigate(redirectUrl, {
          state: {
            scroll: getScrollValue(),
            goBackUrl,
          },
        });
      }
    },
    [goBackUrl, navigate]
  );

  const filterRows = (todoFeed: TableTodo) =>
    Boolean(todoFeed?.item && Object.keys(todoFeed?.item).length !== 0);

  return (
    <>
      <StaticTable
        data={fullTable ? todoFeeds : todoFeeds.slice(0, 5)}
        columns={columns}
        getCoreRowModel={getCoreRowModel()}
        filterRows={filterRows}
        checkIsUnread={(todoFeed) => !todoFeed?.isRead}
        onRowClick={onRowClick}
        dataTestRowPrefix="table_todo_row"
      />
      {!todoFeeds ||
        (todoFeeds.length === 0 && (
          <MUIList>
            <StyledListItem>
              <Typography width="100%" textAlign="center">
                The list is empty
              </Typography>
            </StyledListItem>
          </MUIList>
        ))}
    </>
  );
};

export default TodoTable;
