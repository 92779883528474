import React, { useEffect, useMemo } from 'react';

import { SxProps } from '@mui/material';
import { useTheme } from '@liscio/ui';
import { Control } from 'react-hook-form';

import { useRememberMe } from './useRememberMe';
import { Switch } from 'components';
import { RHFSwitch } from 'components/Switch/RFHSwitch';

export interface RememberMeToggleProps {
  name: string;
  resetEmailField: (email: string) => void;
  control: Control<any>;
  labelStyles?: SxProps;
}

export const RememberMeToggle = ({
  name,
  resetEmailField,
  control,
  labelStyles,
}: RememberMeToggleProps) => {
  const { email, forgetEmail } = useRememberMe();
  const theme = useTheme();

  const finalLabelStyles = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      '.MuiFormControlLabel-label': {
        textTransform: 'none',
        ...theme.typography.caption,
        [theme.breakpoints.up('md')]: {
          ...theme.typography.body1,
        },
        ...labelStyles,
      },
    }),
    [labelStyles, theme]
  );
  // Reset form email value if loaded
  useEffect(() => {
    if (email) {
      resetEmailField(email);
    }
  }, [email, resetEmailField]);

  // Render controlled checkbox if remember email is set
  if (email) {
    return (
      <Switch
        onClick={forgetEmail}
        label="Remember me"
        checked
        labelStyles={finalLabelStyles}
      />
    );
  }

  return (
    <RHFSwitch
      name={name}
      control={control}
      label="Remember me"
      labelStyles={finalLabelStyles}
    />
  );
};
