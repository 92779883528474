import React, { useEffect, useState } from 'react';

import { FormHelperText, FormControl, TextField } from '@mui/material';
import { styled, useTheme, Box } from '@liscio/ui';
import { uniqBy } from 'lodash';

import ContactSelector, {
  RecipientAccount,
} from 'components/ContactSelector/ContactSelector';
import UserChip from 'components/UserChip/UserChip';
import { useAccountOverview } from 'fetch-utils/accounts/accounts-hooks';
import { useContactDetails } from 'fetch-utils/contacts/contacts-hooks';

export interface ContactsSelectorProps {
  placeholder?: string;
  value?: Array<RecipientAccount>;
  error?: boolean;
  helperText?: string;
  onAddElement(newValue: RecipientAccount | RecipientAccount[]): void;
  onRemoveElement(value: RecipientAccount, index: number): void;
  fixedToAccountId?: string;
  fixedToContactId?: string;
  accountIsRequired?: boolean;
  shouldAddCSTMembers?: boolean;
}

const Wrapper = styled(Box)(({ theme }) => ({
  borderStyle: 'solid',
  borderWidth: '1.5px',
  borderRadius: '8px',
  width: '100%',
  display: 'flex',
  rowGap: '4px',
  columnGap: '4px',
  padding: '14.5px 14px',
  minHeight: '54.56px', // strange...
  maxHeight: '100px',
  overflow: 'auto',
  color: theme.palette.common.neutrals[300],
  flexWrap: 'wrap',
  '&:hover': {
    borderColor: theme.palette.common.neutrals[800],
  },
  '&:focus': {
    borderColor: theme.palette.common.primary[500],
  },
}));
const StyledFormControl = styled(FormControl)(() => ({
  width: '100%',
}));
const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  color: theme.palette.error.main,
}));

export const ContactsSelector: React.FC<ContactsSelectorProps> = ({
  placeholder,
  value = [],
  onAddElement,
  onRemoveElement,
  error,
  helperText,
  fixedToAccountId,
  fixedToContactId,
  accountIsRequired,
  shouldAddCSTMembers,
}) => {
  const [isContactSelectorOpen, setIsContactSelectorOpen] = useState(false);
  const onContactSelectorCancel = () => setIsContactSelectorOpen(false);
  const [selectedRecipient, setSelectedRecipient] = useState<
    RichOption | undefined
  >();
  const [selectedAccount, setSelectedAccount] = useState<
    RichOption | undefined
  >();
  const { data: fixedContact } = useContactDetails(fixedToContactId);
  const { data: fixedAccount } = useAccountOverview(fixedToAccountId);
  const [dedupedValue, setDedupedValue] = useState(value);

  useEffect(() => {
    if (value.length !== dedupedValue.length) {
      const dedupedRecipientAccounts = uniqBy(
        value,
        (recipientAccount) =>
          `${recipientAccount?.account?.value}${recipientAccount?.recipient?.value}`
      );

      setDedupedValue(dedupedRecipientAccounts);
    }
  }, [value, dedupedValue.length]);

  useEffect(() => {
    if (fixedToContactId && !selectedRecipient && fixedContact) {
      const { contact, accounts } = fixedContact?.data[0] || {};

      setSelectedRecipient({
        value: contact.id,
        label: `${contact.first_name} ${contact.first_name}`,
        accounts: accounts,
      });
    }
  }, [fixedToContactId, selectedRecipient, fixedContact]);

  useEffect(() => {
    if (fixedToAccountId && !selectedAccount && fixedAccount) {
      setSelectedAccount({
        value: fixedAccount?.account.id,
        label: fixedAccount?.account.name,
      });
    }
  }, [fixedToAccountId, selectedAccount, fixedAccount]);

  return (
    <>
      <StyledFormControl>
        <Wrapper
          role="button"
          sx={{
            borderColor: (theme) =>
              error
                ? theme.palette.error.main
                : theme.palette.common.neutrals[400],
          }}
          tabIndex={0}
          onClick={() => setIsContactSelectorOpen(true)}
        >
          {dedupedValue.length === 0 && placeholder}
          {dedupedValue.map((recipientAccount, index) => (
            <UserChip
              key={`${recipientAccount?.recipient?.value}_${recipientAccount?.account?.value}`}
              onRemoveClick={() => onRemoveElement(recipientAccount, index)}
              userName={recipientAccount?.recipient?.label}
              accountName={recipientAccount?.account?.label}
            />
          ))}
        </Wrapper>
        {error && (
          <StyledFormHelperText variant="filled" data-testid="field-error">
            {helperText}
          </StyledFormHelperText>
        )}
      </StyledFormControl>
      <ContactSelector
        fixedToAccountId={fixedToAccountId}
        fixedToContactId={fixedToContactId}
        selectedAccount={selectedAccount || null}
        open={isContactSelectorOpen}
        selectedRecipient={selectedRecipient}
        onCancel={onContactSelectorCancel}
        onChange={onAddElement}
        accountIsRequired={accountIsRequired}
        shouldAddCSTMembers={shouldAddCSTMembers}
      />
    </>
  );
};

export default ContactsSelector;
