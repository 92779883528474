import React from 'react';

import { useNavigate } from 'react-router-dom';

import { UploadFormType } from '@liscio/api';
import { ResponsiveView } from '@liscio/ui';

import { FILES_DOMAIN, FILES_PATHS } from 'modules/files/route-constants';

const UploadDocumentFormViewMobile = React.lazy(
  () => import('modules/forms/documents-form/UploadDocumentFormViewMobile')
);
const SendDocumentFormViewDesktop = React.lazy(
  () => import('./SendDocumentFormViewDesktop/SendDocumentFormViewDesktop')
);
export interface ResponsiveUploadDocumentFormViewProps {}

export const ResponsiveUploadDocumentFormView: React.FC<
  ResponsiveUploadDocumentFormViewProps
> = () => {
  const navigate = useNavigate();

  function returnToList() {
    navigate(`/${FILES_DOMAIN}${FILES_PATHS.list}`);
  }

  return (
    <ResponsiveView
      SmallView={
        <UploadDocumentFormViewMobile
          type={UploadFormType.NewFile}
          goBack={returnToList}
          onFileUpload={returnToList}
        />
      }
      LargeView={
        <SendDocumentFormViewDesktop
          type={UploadFormType.NewFile}
          goBack={returnToList}
          onFileUpload={returnToList}
        />
      }
    />
  );
};

export default ResponsiveUploadDocumentFormView;
