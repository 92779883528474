import { FormProvider } from 'react-hook-form';

import { useAuthV5Process, AuthV5ProcessProvider } from '@liscio/common';
import { styled, Stack, Box } from '@liscio/ui';

import { CopyrightFooter } from './components';
import { Greetings, MfaForm, PoweredByLiscio, SignInForm } from '../components';
import { config } from 'config';
import { CpaLoginV5Selector } from 'modules/authenticate/components/CpaLoginV5Selector/CpaLoginV5Selector';
import { useSetLoggedInUserSession } from 'modules/authenticate/hooks';
import { getCpaSiteUrl } from 'stores/cpa/cpa-utils';

const SplashStyled = styled(Box)(({ theme }) => ({
  flex: 1,
  backgroundImage: `url(${theme?.assets?.loginHero})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
}));

const ProcessWrapperStyled = styled(Box)({
  flex: 1,
  padding: '16px',
  display: 'flex',
  justifyContent: 'center',
});

const ProcessInnerWrapperStyled = styled(Box)({
  width: '100%',
  maxWidth: '516px',
});

export const LoginV5Desktop = () => {
  const setLoggedUserSession = useSetLoggedInUserSession();

  const authV5ProcessState = useAuthV5Process({
    onProcessFinished: async (loggedInUser) => {
      await setLoggedUserSession(loggedInUser);
    },
    desiredCpaUrl: getCpaSiteUrl(),
    webAppUrl: config.webApp.url,
  });

  const {
    processState,
    signInCpas,
    resetProcess,
    loginFormContext,
    submitLoginFormHandler,
    selectCpa,
    isLoading,
  } = authV5ProcessState;

  if (processState === 'cpaSelector') {
    return (
      <CpaLoginV5Selector
        cpas={signInCpas}
        onCancel={resetProcess}
        onCpaSelected={selectCpa}
      />
    );
  }

  return (
    <AuthV5ProcessProvider authV5ProcessState={authV5ProcessState}>
      <FormProvider {...loginFormContext}>
        <form onSubmit={submitLoginFormHandler}>
          <Stack height="100vh">
            <Stack flex={1} direction="row">
              <ProcessWrapperStyled>
                <ProcessInnerWrapperStyled>
                  <Greetings />
                  {processState === 'loginForm' && (
                    <SignInForm isLoading={isLoading} />
                  )}
                  {processState === 'mfaCode' && (
                    <MfaForm backToLogin={resetProcess} isLoading={isLoading} />
                  )}
                  <PoweredByLiscio />
                </ProcessInnerWrapperStyled>
              </ProcessWrapperStyled>
              <SplashStyled />
            </Stack>
            <CopyrightFooter />
          </Stack>
        </form>
      </FormProvider>
    </AuthV5ProcessProvider>
  );
};

export default LoginV5Desktop;
