import { styled, GlobalLoader, Box } from '@liscio/ui';

import { PDFViewer, UnsupportedFileTypeViewer } from './components';
import { useDocumentDetails } from 'fetch-utils/documents/documents-hooks';

export interface DocumentPreviewProps {
  documentId: string | number;
}

const StyledPdfViewer = styled(PDFViewer)({
  // Needed for better rendering within scrollable containers
  '.pdf-preview-container': {
    overflow: 'none',
  },
});

/**
 * Simple component for fetching and rendering a document preview
 */
export const DocumentPreview = ({ documentId }: DocumentPreviewProps) => {
  const { data: file, isFetching } = useDocumentDetails(String(documentId));

  return (
    <Box>
      {file?.file_type === 'PDF' && !isFetching && (
        <Box width="100%" minWidth="70vw">
          <StyledPdfViewer
            url={file.file_download_url}
            maxHeight="calc(100vh - 150px)"
          />
        </Box>
      )}
      {file?.file_type !== 'PDF' && !isFetching && (
        <UnsupportedFileTypeViewer file={file} />
      )}
      <GlobalLoader open={isFetching} />
    </Box>
  );
};

export default DocumentPreview;
