export const NON_OWNER_ACCOUNT_TABS_OPTIONS = [
  { label: 'Details', value: 'overview' },
];

export const ACCOUNT_OWNER_TABS_OPTIONS = [
  ...NON_OWNER_ACCOUNT_TABS_OPTIONS,
  { label: 'Contacts', value: 'contacts' },
  // { label: 'Files', value: 'files' }, // Hide temporarily
];

export const BILLING_TAB = { label: 'Billing', value: 'billing' };
