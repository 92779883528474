import { Stack, Typography } from '@liscio/ui';

export const IncompletedMessage = () => (
  <Stack gap={2}>
    <Typography>
      You have not answered one or more questions in this request, please
      complete all questions before submitting.
    </Typography>
  </Stack>
);

export const CompletedMessage = () => (
  <Stack>
    <Typography>
      Are you sure you want to submit your response? You will{' '}
      <span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
        not
      </span>{' '}
      be able to make further edits after submission.
    </Typography>
  </Stack>
);
