import { parse, isAfter } from 'date-fns';

export enum PayInvoiceActionTypes {
  AUTHORIZE = 'authorize',
  UPDATE = 'update',
  PAY = 'pay',
}

export const getPayInvoiceActionType: (params: {
  isParentInvoice: boolean;
  isRecurring: boolean;
  hasPaymentAuthorizationId: boolean;
  /**
   * format: yyyy-MM-dd
   * link: https://date-fns.org/v2.29.3/docs/format
   * example: [2023-11-15];
   */
  recurringEndDateString?: string | null;
}) => PayInvoiceActionTypes = ({
  isParentInvoice,
  isRecurring,
  hasPaymentAuthorizationId,
  recurringEndDateString,
}) => {
  // Parent Invoice
  if (isParentInvoice) {
    if (!hasPaymentAuthorizationId) {
      return PayInvoiceActionTypes.AUTHORIZE;
    }

    if (hasPaymentAuthorizationId) {
      const recurringEndDate = recurringEndDateString
        ? parse(recurringEndDateString, 'yyyy-MM-dd', new Date())
        : new Date();
      const now = new Date();
      return isAfter(recurringEndDate, now)
        ? PayInvoiceActionTypes.AUTHORIZE
        : PayInvoiceActionTypes.UPDATE;
    }
  }

  // Child Invoice
  if (!isParentInvoice && isRecurring) {
    return hasPaymentAuthorizationId
      ? PayInvoiceActionTypes.UPDATE
      : PayInvoiceActionTypes.AUTHORIZE;
  }

  // One-time Invoice
  return PayInvoiceActionTypes.PAY;
};

export default getPayInvoiceActionType;
