import React from 'react';

import {
  styled,
  Box,
  Stack,
  Typography,
  CenteredLayout,
  ThemedLogo,
} from '@liscio/ui';
import { Helmet } from 'react-helmet-async';

import { ForgotPasswordForm } from './components';
import { Copyright } from '../LoginViewMobile/components';
import { CustomThemeProvider, Link } from 'components';
import { AUTHENTICATE_PAGE_TITLES } from 'modules/authenticate/route-constants';

const Wrapper = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  color: theme.palette.common.black,
  paddingTop: '48px',
  paddingBottom: '16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  maxWidth: theme.breakpoints.values.sm,

  [theme.breakpoints.up('md')]: {
    color: theme.palette.text.primary,
  },
}));

export const ForgotPasswordView = () => {
  return (
    <>
      <CustomThemeProvider>
        <Helmet title={AUTHENTICATE_PAGE_TITLES.forgotPassword} />
        <CenteredLayout>
          <Wrapper>
            <Stack alignItems="center" mb={5}>
              <ThemedLogo width={200} variant="logoSplash" />
            </Stack>
            <Box>
              <Typography mb={4} variant="h3" textAlign="center">
                Forgot Password?
              </Typography>
              <ForgotPasswordForm />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              mt={3}
            >
              <Link
                to="/authenticate/login"
                underline="none"
                color={(theme) => theme.palette.text.secondary}
              >
                Back to Log In
              </Link>
            </Box>
            <Copyright />
          </Wrapper>
        </CenteredLayout>
      </CustomThemeProvider>
    </>
  );
};
