import { useState } from 'react';

import { Stack, styled, Typography } from '@liscio/ui';

import { PrivacyPolicyDialog } from 'components';
import TermsAndConditionsDialog from 'components/TermsAndConditionsDialog/TermsAndConditionsDialog';
import { DESKTOP_COPYRIGHT_TEXT } from 'constants/settings';

const FooterWrapper = styled(Stack)(({ theme }) => ({
  height: '50px',
  backgroundColor: theme.palette.common.neutrals[100],
  borderTop: `solid 1px ${theme.palette.common.neutrals[200]}`,
}));

export const AppFooter = () => {
  const [termsModalIsOpen, setTermsModalIsOpen] = useState(false);
  const [privacyPolicyModalIsOpen, setPrivacyPolicyModalIsOpen] =
    useState(false);

  return (
    <>
      <TermsAndConditionsDialog
        open={termsModalIsOpen}
        setIsOpen={setTermsModalIsOpen}
      />
      <PrivacyPolicyDialog
        open={privacyPolicyModalIsOpen}
        setIsOpen={setPrivacyPolicyModalIsOpen}
      />
      <FooterWrapper
        alignItems="center"
        justifyContent="center"
        flexDirection="row"
        gap={1}
      >
        <Typography variant="caption">{DESKTOP_COPYRIGHT_TEXT}</Typography>
        <Typography variant="caption">•</Typography>
        <Typography
          variant="caption"
          sx={{ cursor: 'pointer' }}
          onClick={() => setPrivacyPolicyModalIsOpen((isOpen) => !isOpen)}
        >
          Privacy Policy
        </Typography>
        <Typography variant="caption">•</Typography>
        <Typography
          variant="caption"
          sx={{ cursor: 'pointer' }}
          onClick={() => setTermsModalIsOpen((isOpen) => !isOpen)}
        >
          Terms & Conditions
        </Typography>
      </FooterWrapper>
    </>
  );
};
