import React from 'react';

import { TextField } from '@mui/material';
import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form';

import { useTheme } from '@liscio/ui';

import { BiometricsAdornment } from '../BiometricsAdornment/BiometricsAdornment';
import { PasswordInput } from 'components';

interface EmailAndPasswordFieldsProps {
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
  isLoggingIn: boolean;
}

export default function EmailAndPasswordFields({
  register,
  errors,
  isLoggingIn,
}: EmailAndPasswordFieldsProps) {
  const theme = useTheme();
  return (
    <>
      <TextField
        {...register('email')}
        fullWidth
        type="email"
        placeholder="Email"
        error={Boolean(errors?.email)}
        helperText={errors?.email?.message as string}
        disabled={isLoggingIn}
        InputProps={{
          endAdornment: <BiometricsAdornment />,
        }}
      />
      <PasswordInput
        {...register('password')}
        fullWidth
        placeholder="Password"
        error={Boolean(errors?.password)}
        helperText={errors?.password?.message as string}
        iconStyles={{ color: theme.palette.common.neutrals[500] }}
        disabled={isLoggingIn}
      />
    </>
  );
}
