import React from 'react';

import { ResponsiveView } from '@liscio/ui';

const FilesListViewMobile = React.lazy(
  () => import('./FilesListViewMobile/FilesListViewMobile')
);
const FilesListViewDesktop = React.lazy(
  () => import('./FilesListViewDesktop/FilesListViewDesktop')
);

export interface ResponsiveDashboardViewProps {}

export const ResponsiveDashboardView: React.FC<
  ResponsiveDashboardViewProps
> = () => {
  return (
    <ResponsiveView
      SmallView={<FilesListViewMobile />}
      LargeView={<FilesListViewDesktop />}
    />
  );
};

export default ResponsiveDashboardView;
