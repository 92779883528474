import {
  useQuery,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import { TaskType } from '@liscio/api';

import apiClient from 'fetch-utils/api-client';

const {
  contacts: {
    getEmployeesAndContactsOfAccount,
    getEmployeesAndContacts,
    searchContacts,
    getAccountContacts,
    getRelContactsAndCstOfAccounts,
    getContacts,
    getContactDetails,
    getInitialTaskContacts,
    searchTaskContacts,
    searchToDoTaskContacts,
    createQuickContactAndOrAccount,
    updateContact,
  },
  tasks: { getToDoTaskData },
} = apiClient;

export function useInfinitiveContacts({
  searchString,
  sortField,
  sortOrder,
}: {
  searchString: string;
  sortOrder: string;
  sortField: string;
}) {
  return useInfiniteQuery({
    queryKey: ['contacts', searchString, sortOrder, sortField],
    queryFn: getContacts,
    getNextPageParam: (lastPage, allPages) => {
      const pageSize = 15;
      const allElementsCount = lastPage?.count_data?.all || 0;

      if (allPages.length * pageSize < allElementsCount) {
        return allPages.length + 1;
      } else {
        return undefined;
      }
    },
    getPreviousPageParam: (firstPage, allPages) => {
      return allPages.length - 1;
    },
  });
}

export function useContactDetails(contactId?: number | string) {
  return useQuery({
    queryKey: ['contact', String(contactId)],
    queryFn: getContactDetails,
    enabled: Boolean(contactId),
  });
}

export function useEmployeesAndContactsOfAccount(accountId: string | number) {
  return useQuery({
    queryKey: ['employees-and-contacts-of-account', String(accountId)],
    queryFn: getEmployeesAndContactsOfAccount,
    enabled: !!accountId,
  });
}

export function useRelContactsAndCstOfAccounts(accountId: string | number) {
  return useQuery({
    queryKey: ['contacts_and_cst_of_accounts', String(accountId)],
    queryFn: getRelContactsAndCstOfAccounts,
    enabled: Boolean(accountId),
  });
}

export function useEmployeesAndContacts() {
  return useQuery({
    queryKey: ['employees-and-contacts'],
    queryFn: getEmployeesAndContacts,
  });
}

export function useSearchContacts(contactSearchKeyword: string) {
  return useQuery({
    queryKey: ['searched-contacts', contactSearchKeyword],
    queryFn: searchContacts,
    enabled: !!contactSearchKeyword,
  });
}

export const useAccountContacts = (accountId: string | number = '') => {
  return useQuery({
    queryKey: ['account-contacts', accountId],
    queryFn: () => getAccountContacts(accountId),
    enabled: Boolean(accountId),
  });
};

// NOTE: TaskType.ToDo will return employees
export const useInitialTaskContacts = (taskType: TaskType) =>
  useQuery({
    queryKey: ['getInitialTaskContacts', taskType],
    queryFn: async () => {
      // to_do Task type has different initial data endpoint
      if (taskType === TaskType.ToDo) {
        const taskData = await getToDoTaskData();
        return taskData.contacts;
      }

      const contacts = await getInitialTaskContacts(taskType);
      return contacts;
    },
  });
// NOTE: TaskType.ToDo will return employees
export const useSearchTaskContacts = (taskType: TaskType, keyword: string) =>
  useQuery({
    queryKey: ['searchTaskContacts', taskType, keyword],
    queryFn: async () => {
      // to_do task type has different search endpoint
      if (taskType === TaskType.ToDo) {
        return searchToDoTaskContacts(keyword);
      }

      return searchTaskContacts(taskType, keyword);
    },
    enabled: Boolean(keyword.trim()),
  });

export const useQuickCreateContactAndOrAccount = (
  props?: DefaultMutationHookInterface
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createQuickContactAndOrAccount,
    onSuccess: () => {
      if (props?.onSuccess) props.onSuccess();
      queryClient.invalidateQueries(['sms-contacts']);
    },
  });
};

export const useUpdateContact = (props?: DefaultMutationHookInterface) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateContact,
    onSuccess: () => {
      if (props?.onSuccess) props.onSuccess();
      queryClient.invalidateQueries(['sms-contacts']);
    },
  });
};
