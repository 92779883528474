import React from 'react';

import {
  useTheme,
  styled,
  Box,
  Button,
  Icon,
  IconButton,
  Stack,
} from '@liscio/ui';

export interface FiltersProps {
  onClick: () => void;
  onClear?: () => void;
  hasFiltersSelected?: boolean;
}

const Wrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderTop: `solid 1px ${theme.palette.common.neutrals[200]}`,
  padding: '4px 12px',
  height: '42px',
}));

const ClearFiltersWrapper = styled(Box)(({ theme }) => ({
  minWidth: '50px',
}));

export const FiltersButton: React.FC<FiltersProps> = ({
  hasFiltersSelected = false,
  onClick,
  onClear,
}) => {
  const theme = useTheme();

  return (
    <Wrapper direction="row" alignItems="center" justifyContent="space-between">
      <ClearFiltersWrapper>
        {hasFiltersSelected && (
          <Button size="small" onClick={onClear}>
            Clear
          </Button>
        )}
      </ClearFiltersWrapper>
      <IconButton aria-label="filters" size="small" onClick={onClick}>
        <Icon
          icon="sliders"
          htmlColor={
            hasFiltersSelected ? theme.palette.primary.main : 'inherit'
          }
        />
      </IconButton>
    </Wrapper>
  );
};

export default FiltersButton;
