import React from 'react';

import {
  List as MUIList,
  ListItem,
  ListItemButton as MUIListItemButton,
  ListItemIcon as MUIListItemIcon,
  ListItemText,
  Skeleton,
} from '@mui/material';

import { styled } from '@liscio/ui';

import { DrawerProps } from '../../Drawer';

type DrawerMenuProps = Pick<
  DrawerProps,
  | 'options'
  | 'onNavigationClick'
  | 'selectedOptionValue'
  | 'isLoading'
  | 'hasLoadingElement'
>;

const List = styled(MUIList)(() => ({
  flexGrow: 1,
}));
const ListItemButton = styled(MUIListItemButton)(({ theme }) => ({
  paddingTop: '12px',
  paddingBottom: '12px',
  color: theme.palette.common.tertiaryContrast,
  '&.Mui-selected': {
    backgroundColor: theme.palette.common.tertiary[800],
  },
}));
const ListItemIcon = styled(MUIListItemIcon)(({ theme }) => ({
  color: theme.palette.common.tertiaryContrast,
}));

export const DrawerMenu: React.FC<DrawerMenuProps> = ({
  options,
  selectedOptionValue,
  onNavigationClick,
  isLoading,
  hasLoadingElement,
}) => (
  <List>
    {options.map((option) => (
      <ListItem key={option.value} disablePadding>
        <ListItemButton
          selected={selectedOptionValue === option.value}
          onClick={() => onNavigationClick && onNavigationClick(option.value)}
          disabled={isLoading}
        >
          <ListItemIcon>{option.Icon}</ListItemIcon>
          <ListItemText>{option.label}</ListItemText>
        </ListItemButton>
      </ListItem>
    ))}
    {hasLoadingElement && (
      <ListItem sx={{ justifyContent: 'center' }}>
        <Skeleton width="80%" />
      </ListItem>
    )}
  </List>
);

export default DrawerMenu;
