import React from 'react';

import { OAuthConfig as OAuthConfigInterface } from '@liscio/api';
import { useOAuthV5Process } from '@liscio/common';
import { GoogleGIcon } from '@liscio/ui';

import { StyledOAuthButton } from './StyledOAuthButton';
import { config } from 'config';

const PROVIDER = 'google';

export const GoogleOAuthLoginV5 = () => {
  const oAuthConfig: OAuthConfigInterface = config.oauth[PROVIDER];

  const { initiateOAuth } = useOAuthV5Process({
    provider: PROVIDER,
    oAuthConfig,
    shouldAddLegacyAppStateCookie: true,
  });

  return (
    <StyledOAuthButton
      type="button"
      onClick={initiateOAuth}
      variant="contained"
      startIcon={<GoogleGIcon fontSize="small" />}
      size="large"
    >
      Google
    </StyledOAuthButton>
  );
};
