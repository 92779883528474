import { Dispatch, SetStateAction } from 'react';

import { Interweave } from 'interweave';

import { Dialog, Loading } from '@liscio/ui';

import { useHomeData } from 'fetch-utils/users/user-hooks';

type PrivacyPolicyDialogDialogProps = {
  open: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export default function PrivacyPolicyDialogDialog({
  open,
  setIsOpen,
}: PrivacyPolicyDialogDialogProps) {
  const { data: homeData, isLoading } = useHomeData();

  return (
    <Dialog onClose={() => setIsOpen(false)} open={open}>
      {isLoading ? (
        <Loading />
      ) : (
        <Interweave content={homeData?.data?.privacy_content || ''} />
      )}
    </Dialog>
  );
}
