import React from 'react';

import { useInitializedFlags } from '@liscio/common';
import { ResponsiveView, GlobalLoader } from '@liscio/ui';

import LoginV5Desktop from './LoginV5View/LoginV5Desktop/LoginV5Desktop';
import LoginV5Mobile from './LoginV5View/LoginV5Mobile/LoginV5Mobile';
import LoginViewDesktop from './LoginViewDesktop/LoginViewDesktop';
import LoginViewMobile from './LoginViewMobile/LoginViewMobile';

export interface LoginFlaggedViewProps {}

export const LoginFlaggedView: React.FC<LoginFlaggedViewProps> = () => {
  const {
    isInitialized,
    flags: { v5Login },
  } = useInitializedFlags();

  if (!isInitialized) {
    return <GlobalLoader open />;
  }

  if (v5Login) {
    return (
      <ResponsiveView
        SmallView={<LoginV5Mobile />}
        LargeView={<LoginV5Desktop />}
      />
    );
  }

  return (
    <ResponsiveView
      SmallView={<LoginViewMobile />}
      LargeView={<LoginViewDesktop />}
    />
  );
};

export default LoginFlaggedView;
