import { useCallback } from 'react';

import { SubmitHandler } from 'react-hook-form';

import { AccountBankAccountEntity } from '@liscio/api';

import {
  AccountBankAccountSingleFormData,
  AccountBankAccountsFormData,
} from '../types/AccountBankAccountsFormData';
import { useToastError } from 'custom-hooks';
import {
  useCreateBankAccount,
  useEditBankAccount,
  useUpdateEncryptedField,
} from 'fetch-utils/accounts/accounts-hooks';

export const useSubmitAccountMultipleBankAccountsFormHandler = ({
  onSuccess,
  accountId,
}: {
  onSuccess?: () => void;
  accountId?: string;
  onError?: (e: Error) => void;
}) => {
  const { mutateAsync: editBankAccount, isLoading: isBankAccountUpdating } =
    useEditBankAccount();
  const { mutateAsync: createBankAccount, isLoading: isBankAccountCreating } =
    useCreateBankAccount();
  const { mutateAsync: updateEncryptedField, isLoading: isFieldEncrypting } =
    useUpdateEncryptedField();

  const submitHandler = useCallback<SubmitHandler<AccountBankAccountsFormData>>(
    async (data) => {
      try {
        const currentBankAccounts = data.accountBankAccounts;
        const formBankAccountsToAdd = currentBankAccounts.filter(
          (bankAccount) => !bankAccount.account_id
        );
        const formBankAccountsToUpdate = currentBankAccounts.filter(
          (bankAccount) => bankAccount.account_id
        );

        await Promise.all(
          formBankAccountsToUpdate.map((bankAccount) => {
            const {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              encryptedAccountNumber,
              ...prunedBankAccount
            } = bankAccount;
            return editBankAccount(prunedBankAccount);
          })
        );

        const rawCreatedBankAccounts = await Promise.all(
          formBankAccountsToAdd.map((bankAccount) => {
            const {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              encryptedAccountNumber,
              ...prunedBankAccount
            } = bankAccount;

            return createBankAccount({
              ...prunedBankAccount,
              account_id: Number(accountId),
            });
          })
        );

        const createdBankAccounts = rawCreatedBankAccounts.map(
          (bankAccount) => bankAccount.data
        );

        const COMMON_KEYS = {
          fieldName: 'account_number',
          recordType: 'BankAccount',
        };

        const encryptionPromisesForNewBankAccounts = createdBankAccounts.map(
          (bankAccount) => {
            return updateEncryptedField({
              ...COMMON_KEYS,
              recordId: bankAccount?.id || '',
              newValue: bankAccount?.account_number || '',
            });
          }
        );

        const encryptionPromisesForUpdatingBankAccounts =
          formBankAccountsToUpdate
            .filter((bankAccount) =>
              Boolean(bankAccount.encryptedAccountNumber)
            )
            .map((bankAccount) => {
              return updateEncryptedField({
                ...COMMON_KEYS,
                recordId:
                  bankAccount?.encrypted_account_number?.record_id || '',
                newValue: bankAccount?.encryptedAccountNumber || '',
              });
            });

        await Promise.all([
          ...encryptionPromisesForNewBankAccounts,
          ...encryptionPromisesForUpdatingBankAccounts,
        ]);

        if (onSuccess) {
          onSuccess();
        }
      } catch (e) {
        console.error(e);
        // error handled by react query
      }
    },
    [
      editBankAccount,
      onSuccess,
      createBankAccount,
      updateEncryptedField,
      accountId,
    ]
  );

  return {
    submitHandler,
    isLoading:
      isBankAccountCreating || isBankAccountUpdating || isFieldEncrypting,
  };
};

export const useSubmitCreateAccountSingleBankAccount = ({
  onSuccess,
  accountId,
}: {
  onSuccess?: (bankAccount: AccountBankAccountEntity) => void;
  accountId?: string;
  onError?: (e: Error) => void;
}) => {
  const {
    mutateAsync: createBankAccount,
    error: createBankAccountError,
    isLoading: isBankAccountCreating,
  } = useCreateBankAccount();
  const { mutateAsync: updateEncryptedField, isLoading: isFieldEncrypting } =
    useUpdateEncryptedField();

  useToastError(
    createBankAccountError,
    'Encountered problem with bank account creation.'
  );

  const submitHandler = useCallback<
    SubmitHandler<AccountBankAccountSingleFormData>
  >(
    async (data) => {
      try {
        const { data: newBankAccount } = await createBankAccount({
          ...data,
          account_id: Number(accountId),
        });

        if (data.encryptedAccountNumber) {
          await updateEncryptedField({
            fieldName: 'account_number',
            recordType: 'BankAccount',
            recordId: newBankAccount.id!,
            newValue: data.encryptedAccountNumber,
          });
        }

        if (onSuccess) {
          onSuccess(newBankAccount);
        }
      } catch (e) {
        console.error(e);
        // error handled by react query
      }
    },
    [onSuccess, createBankAccount, updateEncryptedField, accountId]
  );

  return {
    submitHandler,
    isLoading: isBankAccountCreating || isFieldEncrypting,
  };
};

export const useSubmitEditAccountSingleBankAccount = ({
  onSuccess,
}: {
  onSuccess?: (bankAccount: AccountBankAccountEntity) => void;
  onError?: (e: Error) => void;
}) => {
  const {
    mutateAsync: editBankAccount,
    error: editBankAccountError,
    isLoading: isBankAccountUpdating,
  } = useEditBankAccount();
  const { mutateAsync: updateEncryptedField, isLoading: isFieldEncrypting } =
    useUpdateEncryptedField();

  useToastError(
    editBankAccountError,
    'Encountered problem with bank account update.'
  );

  const submitHandler = useCallback<
    SubmitHandler<AccountBankAccountSingleFormData>
  >(
    async (data) => {
      try {
        const { data: updatedBankAccount } = await editBankAccount(data);

        if (data.encryptedAccountNumber) {
          await updateEncryptedField({
            fieldName: 'account_number',
            recordType: 'BankAccount',
            recordId: data.id!,
            newValue: data.encryptedAccountNumber,
          });
        }

        if (onSuccess) {
          onSuccess(updatedBankAccount);
        }
      } catch (e) {
        console.error(e);
        // error handled by react query
      }
    },

    [editBankAccount, onSuccess, updateEncryptedField]
  );

  return {
    submitHandler,
    isLoading: isBankAccountUpdating || isFieldEncrypting,
  };
};
