import React, { useEffect, useState } from 'react';

import { DetailsState, AppBar, Overlay } from '@liscio/common';

import { ImagePreview, NoPreview } from './components';
import { PDFViewer } from 'components/FilePreview/components';
import { ScreenWrapper } from 'components/screenComposition';
import { RichFile } from 'modules/messages/views/ThreadDetailsViewMobile/useThreadDetailBottomForm';

export interface FileInFormPreviewProps {
  file: RichFile;
  open: boolean;
  closeFunction: () => void;
}

export const FileInFormPreview: React.FC<FileInFormPreviewProps> = ({
  open,
  file,
  closeFunction,
}) => {
  const [fileURL, setFileURL] = useState('');

  useEffect(() => {
    if (!file) return;

    if (file.file_url) {
      return setFileURL(file.file_url);
    }

    const reader = new FileReader();

    reader.addEventListener(
      'load',
      () => {
        setFileURL(reader.result as string);
      },
      false
    );

    if (file instanceof File) reader.readAsDataURL(file);
  }, [file]);

  const isPdf = fileURL && file?.type === 'application/pdf';
  const isImage = fileURL && file?.type.includes('image/');
  return (
    <Overlay open={open}>
      <ScreenWrapper>
        <AppBar
          onNavigationClick={closeFunction}
          title={file?.name}
          DefaultStateElement={DetailsState}
          hideSecondaryActionButton
          position="relative"
        />
        {isPdf && <PDFViewer url={fileURL} />}
        {isImage && <ImagePreview src={fileURL} />}
        {!isImage && !isPdf && <NoPreview fileType={file.type} />}
      </ScreenWrapper>
    </Overlay>
  );
};

export default FileInFormPreview;
