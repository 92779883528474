import React from 'react';

import { Helmet } from 'react-helmet-async';
import { Route, Routes } from 'react-router-dom';

import { ProfileViewLayout } from './components';
import { PROFILE_PAGE_TITLES, PROFILE_PATHS } from './route-constants';
import { LazyWrapper } from 'components';
import { CatchAllRedirect } from 'modules/CatchAllRedirect/CatchAllRedirect';

const ProfileViewMobile = React.lazy(
  () => import('./views/ProfileView/ProfileViewMobile/ProfileViewMobile')
);
const ProfileViewDesktop = React.lazy(
  () => import('./views/ProfileView/ProfileViewDesktop/ProfileViewDesktop')
);
const EditProfileMobile = React.lazy(
  () => import('./views/EditProfile/EditProfileMobile/EditProfileMobile')
);
const EditProfileDesktop = React.lazy(
  () => import('./views/EditProfile/EditProfileDesktop/EditProfileDesktop')
);
const PrivacyPolicy = React.lazy(
  () => import('./views/PrivacyPolicy/PrivacyPolicy')
);
const ChangePassword = React.lazy(
  () => import('./views/ChangePassword/ChangePassword')
);
const TermsAndConditions = React.lazy(
  () => import('./views/TermsAndConditions/TermsAndConditions')
);
const TermsAndConditionsAcceptance = React.lazy(
  () =>
    import('./views/TermsAndConditionsAcceptance/TermsAndConditionsAcceptance')
);
const ProfileSecurityViewDesktop = React.lazy(
  () => import('./views/SecuritySettingsView/ProfileSecurityViewDesktop')
);
const ProfileSecurityViewMobile = React.lazy(
  () => import('./views/SecuritySettingsView/ProfileSecurityViewMobile')
);

export const ModuleRoutes = () => (
  <>
    <Helmet title={PROFILE_PAGE_TITLES.details} />
    <Routes>
      <Route
        path={PROFILE_PATHS.details}
        element={
          <ProfileViewLayout
            mobile={<ProfileViewMobile />}
            desktop={<ProfileViewDesktop />}
          />
        }
      />
      <Route
        path={PROFILE_PATHS.securitySettings}
        element={
          <ProfileViewLayout
            title="Manage Devices"
            mobile={<ProfileSecurityViewMobile />}
            desktop={<ProfileSecurityViewDesktop />}
          />
        }
      />
      <Route
        path={PROFILE_PATHS.privacyPolicy}
        element={
          <LazyWrapper>
            <PrivacyPolicy />
          </LazyWrapper>
        }
      />
      <Route
        path={PROFILE_PATHS.termsAndConditions}
        element={
          <LazyWrapper>
            <TermsAndConditions />
          </LazyWrapper>
        }
      />
      <Route
        path={PROFILE_PATHS.termsAndConditionsAcceptance}
        element={
          <LazyWrapper>
            <TermsAndConditionsAcceptance />
          </LazyWrapper>
        }
      />
      <Route path={PROFILE_PATHS.changePassword} element={<ChangePassword />} />
      <Route
        path={PROFILE_PATHS.editProfile}
        element={
          <ProfileViewLayout
            mobile={<EditProfileMobile />}
            desktop={<EditProfileDesktop />}
          />
        }
      />
      <Route path="*" element={<CatchAllRedirect />} />
    </Routes>
  </>
);

export default ModuleRoutes;
