import React from 'react';

import { Box, Typography } from '@liscio/ui';

import { PDFViewer } from './components';

export interface GenericFilePreviewProps {
  // File src to preview
  fileSrc: any;
  // Mime type of file to preview
  mimeType: string;
}

/**
 * Generic File preview component.
 * Renders preview for the following file types:
 * - image/*
 * - pdf
 * - Renders "Preview not available" fallback message for all other file types
 */
export const GenericFilePreview = ({
  fileSrc,
  mimeType,
}: GenericFilePreviewProps) => {
  // image/*
  if (/^image\//.test(mimeType)) {
    return <Box component="img" src={fileSrc} width="100%" />;
  }

  // application/pdf
  if (mimeType === 'application/pdf') {
    return (
      <Box width="100%" minWidth="70vw">
        <PDFViewer url={fileSrc} height="60vh" />
      </Box>
    );
  }

  // other
  return (
    <Box display="flex" width="100%" p={4}>
      <Typography>
        Preview is not available for files of type {mimeType}
      </Typography>
    </Box>
  );
};
