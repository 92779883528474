import { useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import { AppBar, getIsIndividualAccount } from '@liscio/common';

import { AccountDetailsSecondaryNavigation } from './components/AccountDetailsSecondaryNavigation/AccountDetailsSecondaryNavigation';
import { AccountDetailsStateElement } from './components/AccountDetailsStateElement';
import { AccountFileFiltersProvider } from '../../AccountFilesView/components/AccountFileFiltersProvider';
import { Tabs } from '@liscio/ui';
import { ScreenWrapper, BodyWrapper } from 'components/screenComposition';
import { useReloadFunction } from 'custom-hooks';
import { useAccountOverview } from 'fetch-utils/accounts/accounts-hooks';
import { useHomeData } from 'fetch-utils/users/user-hooks';
import {
  ACCOUNT_OWNER_TABS_OPTIONS,
  NON_OWNER_ACCOUNT_TABS_OPTIONS,
  PAYROLL_TAB,
  LOGINS_TAB,
  BANK_ACCOUNT_TAB,
} from 'modules/accounts/components/AccountViewLayout/constants/availableMobileTabs';
import {
  ACCOUNTS_DOMAIN,
  ACCOUNTS_PAGE_TITLES,
  ACCOUNTS_PATHS,
} from 'modules/accounts/route-constants';

export default function AccountDetailsMobileView() {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const tab = location.pathname.split('/').pop();
  const { data: accountData } = useAccountOverview(id!);
  const { data: homeData } = useHomeData();

  const isIndividualAccount = useMemo(
    () =>
      getIsIndividualAccount(
        accountData?.account.entity_type || '',
        homeData?.data.pref_type_options.individual_entity_type || []
      ),

    [
      accountData?.account.entity_type,
      homeData?.data.pref_type_options.individual_entity_type,
    ]
  );

  const onNavigationClick = useCallback(
    (keyOrTab: string) => {
      if (keyOrTab === 'go:back') {
        navigate(`/${ACCOUNTS_DOMAIN}${ACCOUNTS_PATHS.list}`);
      } else {
        navigate(
          `/${ACCOUNTS_DOMAIN}${ACCOUNTS_PATHS.details.root}/${id}/${keyOrTab}`
        );
      }
    },
    [navigate, id]
  );

  const availableUserTabs = useMemo(() => {
    // Clients
    if (accountData?.is_account_owner) {
      const tabs = [...ACCOUNT_OWNER_TABS_OPTIONS];
      if (!isIndividualAccount) {
        tabs.push(PAYROLL_TAB);
      }
      tabs.push(LOGINS_TAB);
      tabs.push(BANK_ACCOUNT_TAB);
      return tabs;
    } else {
      return NON_OWNER_ACCOUNT_TABS_OPTIONS;
    }
  }, [accountData?.is_account_owner, isIndividualAccount]);

  const selectedTab = useMemo(() => {
    if (tab && availableUserTabs.find((t) => t.value === tab)) {
      return tab;
    }
  }, [tab, availableUserTabs]);

  const reloadFunction = useReloadFunction([
    ['account-overview', id],
    ['account-bank-accounts', id],
    ['account-contacts', id],
    ['account-logins', id],
    ['files', id],
  ]);

  return (
    <>
      <Helmet title={ACCOUNTS_PAGE_TITLES.details} />
      <ScreenWrapper>
        <AccountFileFiltersProvider>
          <AppBar
            onNavigationClick={onNavigationClick}
            DefaultStateElement={AccountDetailsStateElement}
            title={accountData?.account?.name}
          >
            <Tabs
              options={availableUserTabs}
              onNavigationClick={onNavigationClick}
              selectedOptionValue={selectedTab}
            />
            <AccountDetailsSecondaryNavigation tab={tab} />
          </AppBar>
          <BodyWrapper reloadFunction={reloadFunction}>
            <Outlet />
          </BodyWrapper>
        </AccountFileFiltersProvider>
      </ScreenWrapper>
    </>
  );
}
