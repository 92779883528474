import React, { useState } from 'react';

import { Alert } from '@mui/material';
import { useFieldArray } from 'react-hook-form';

import { Button, Stack } from '@liscio/ui';

import { makeAccountFromRecipientAccount } from './helpers/make-account-from-recipient-account';
import { ContactSelector } from 'components';
import { RecipientAccount } from 'components/ContactSelector/ContactSelector';

export interface AdditionalParticipantFormProps {
  warningMessage: string;
}

export const AdditionalParticipantForm: React.FC<
  AdditionalParticipantFormProps
> = ({ warningMessage }) => {
  const [isContactSelectorOpen, setIsContactSelectorOpen] = useState(false);
  const { append } = useFieldArray({ name: 'participants' });

  return (
    <Stack gap={2}>
      <Alert severity="error">{warningMessage}</Alert>
      <Button
        onClick={() => setIsContactSelectorOpen(true)}
        variant="contained"
      >
        Add
      </Button>
      <ContactSelector
        open={isContactSelectorOpen}
        onCancel={() => setIsContactSelectorOpen(false)}
        onChange={(value: RecipientAccount | RecipientAccount[]) => {
          if (Array.isArray(value)) {
            value.map(makeAccountFromRecipientAccount);
          } else {
            append(makeAccountFromRecipientAccount(value));
          }
        }}
        useOnlyEmployees
      />
    </Stack>
  );
};

export default AdditionalParticipantForm;
