import { AccountBankAccountSingleFormData } from 'modules/accounts/types/AccountBankAccountsFormData';

export const getDefaultValues: (
  bankAccount: AccountBankAccountSingleFormData
) => AccountBankAccountSingleFormData = (bankAccount) => {
  return {
    ...bankAccount,
    bank_name: bankAccount.bank_name || '',
    routing_number: bankAccount.routing_number || '',
    account_type: bankAccount.account_type || '',
    web_site: bankAccount.web_site || '',
    notes: bankAccount.notes || '',
    is_ach_autopay: Boolean(bankAccount.is_ach_autopay),
  };
};

export default getDefaultValues;
