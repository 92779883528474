import React from 'react';

import { styled, Box, Typography } from '@liscio/ui';

import { COPYRIGHT_TEXT } from 'constants/settings';

const Wrapper = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.common.white,
  paddingBottom: '12px',
}));

export const Copyright = () => (
  <Wrapper>
    <Typography variant="caption">{COPYRIGHT_TEXT}</Typography>
  </Wrapper>
);

export default Copyright;
