/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-constant-condition */
import React, { useCallback } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';

import {
  parseApiErrorMessage,
  usePutApiV5AuthPasswordResetsId,
} from '@liscio/api';
import { useCpaStore } from '@liscio/common';
import { Button, GlobalLoader, Stack, ErrorAlert } from '@liscio/ui';
import { defaultErrorParser } from '@liscio/utils';

import { validationSchema } from './validationSchema';
import { useYupValidationResolver } from 'custom-hooks';
import { useForgotPassword } from 'fetch-utils/users/auth-hooks';
import { AuthPasswordInput } from 'modules/authenticate/components/FormFields';
import { getCpaSiteUrl } from 'stores/cpa/cpa-utils';

export interface ResetPasswordFormData {
  password: string;
  password_confirmation: string;
}

export const parseError = (error?: unknown) => {
  const apiErrorMessage = parseApiErrorMessage(error);

  return apiErrorMessage || defaultErrorParser(error);
};

export const ResetPasswordForm = () => {
  // Back to login
  const navigate = useNavigate();
  const backToLogin = useCallback(
    () => navigate('/authenticate/login', { replace: true }),
    [navigate]
  );

  // Form
  const validateResolver = useYupValidationResolver(validationSchema);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordFormData>({
    resolver: validateResolver,
  });

  const {
    changePassword: {
      mutateAsync: changePasswordV3,
      isLoading: isLoadingV3,
      error: errorV3,
    },
  } = useForgotPassword();
  const { v5Login } = useFlags();
  const {
    mutateAsync: changePasswordV5,
    isLoading: isLoadingV5,
    error: errorV5,
  } = usePutApiV5AuthPasswordResetsId();
  const { token } = useParams<{ token: string }>();
  const siteCompany = getCpaSiteUrl({ hostnameOnly: true });
  const queryCompany = useCpaStore(({ queryCompany }) => queryCompany);
  const companyName = siteCompany || queryCompany || '';
  const onSubmit: SubmitHandler<ResetPasswordFormData> = useCallback(
    async (data) => {
      try {
        if (v5Login) {
          await changePasswordV5({
            id: token || '',
            data,
          });
        } else {
          await changePasswordV3({
            ...data,
            token: token || '',
            company_name: companyName,
          });
        }
        backToLogin();
        toast.success('Password changed successfully', { duration: 3000 });
      } catch {}
    },
    [
      v5Login,
      changePasswordV3,
      changePasswordV5,
      token,
      companyName,
      backToLogin,
    ]
  );

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack mt={2} gap={2}>
          <ErrorAlert
            error={(errorV3 || errorV5) as Error}
            parseError={parseError}
          />
          <AuthPasswordInput
            {...register('password')}
            placeholder="New Password"
            error={Boolean(errors.password)}
            helperText={errors?.password?.message}
          />
          <AuthPasswordInput
            {...register('password_confirmation')}
            placeholder="Confirm Password"
            error={Boolean(errors.password_confirmation)}
            helperText={errors?.password_confirmation?.message}
          />
          <Stack mt={2} gap={2}>
            <Button variant="contained" type="submit" size="large">
              Submit
            </Button>
            <Button
              variant="outlined"
              type="button"
              size="large"
              onClick={backToLogin}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </form>
      <GlobalLoader open={isLoadingV3 || isLoadingV5} />
    </>
  );
};
