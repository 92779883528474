import React from 'react';

import { ConfirmationDialog } from 'components';

export interface ConfirmationDialogProps {
  open: boolean;
  tab?: string;
  onCancel(): void;
  onConfirm(): void;
  isFileArchived?: boolean;
  redirectUrl: string;
}

export const PayableItemConfirmRedirectDialog: React.FC<
  ConfirmationDialogProps
> = ({ open, onCancel, onConfirm, redirectUrl }) => {
  const title = '';
  const content = `You are about to leave Liscio to visit a third-party website at the following URL:
    ${redirectUrl}.
    Are you sure you want to proceed?`;
  const confirmLabel = 'Continue';
  const cancelLabel = 'Cancel';

  return (
    <>
      <ConfirmationDialog
        open={open}
        onConfirm={onConfirm}
        onCancel={onCancel}
        title={title}
        content={content}
        confirmLabel={confirmLabel}
        cancelLabel={cancelLabel}
      />
    </>
  );
};

export default PayableItemConfirmRedirectDialog;
