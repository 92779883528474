import React, { useCallback, useMemo } from 'react';

import { Avatar, TextField, Button } from '@mui/material';
import { styled, Stack, GlobalLoader } from '@liscio/ui';
import { useForm } from 'react-hook-form';

import { AddCommentResponse } from '@liscio/api';
import { newLinesHtml } from '@liscio/utils';

import { getValidationSchema } from './helpers';
import { MAX_TEXT_AREA_ROWS } from 'constants/settings';
import { useYupValidationResolver } from 'custom-hooks';
import { useAddComment } from 'fetch-utils/tasks/tasks-hooks';
import { useProfileData } from 'fetch-utils/users/user-hooks';

export interface CommentFormProps {
  taskId: string;
  onSuccess?: (addCommentResponse: AddCommentResponse) => void;
}

interface MessageForm {
  taskId: string;
  comment: string;
}

const StyledStack = styled(Stack)(({ theme }) => ({
  padding: '8px',
  width: '100%',
  minHeight: '72px',
  backgroundColor: theme.palette.background.paper,
}));

export const CommentForm: React.FC<CommentFormProps> = ({
  taskId,
  onSuccess,
}) => {
  const { avatar } = useProfileData();
  const validationSchema = useMemo(getValidationSchema, []);
  const resolver = useYupValidationResolver(validationSchema);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<MessageForm>({
    defaultValues: {
      taskId,
      comment: '',
    },
    resolver,
  });
  const handleAddCommentSuccess = useCallback(
    (payload: AddCommentResponse) => {
      if (onSuccess) {
        onSuccess(payload);
      }
      reset();
    },
    [onSuccess, reset]
  );
  const { mutateAsync: addComment, isLoading: isCommentAdding } = useAddComment(
    { onSuccess: handleAddCommentSuccess }
  );

  const onSubmit: (data: MessageForm) => void = (data) => {
    const { comment, taskId } = data;
    addComment({ comment: newLinesHtml(comment), taskId });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StyledStack direction="row" gap={2} alignItems="center">
        <Avatar src={avatar || ''} sx={{ width: 24, height: 24 }} />
        <TextField
          {...register('comment')}
          fullWidth
          multiline
          maxRows={MAX_TEXT_AREA_ROWS}
          placeholder="Write a comment..."
          error={Boolean(errors?.comment)}
          helperText={errors?.comment?.message}
          disabled={isCommentAdding}
          autoComplete="off"
        />
        <Button variant="contained" type="submit" disabled={isCommentAdding}>
          Send
        </Button>
      </StyledStack>
      <GlobalLoader open={isCommentAdding} />
    </form>
  );
};

export default CommentForm;
