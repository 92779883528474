import { createContext, useContext, useState } from 'react';

import { useFileFilters } from 'fetch-utils/documents/document-filters';

export type FileTab = 'active' | 'archived';
export interface AccountFileFiltersContextType
  extends ReturnType<typeof useFileFilters> {
  tab: FileTab;
  setTab: (tab: FileTab) => void;
}
const accountFileFiltersCtx = createContext<AccountFileFiltersContextType>({
  filters: {},
  hasFiltersSelected: false,
  setFilters: () => {},
  resetFilters: () => {},
  tab: 'active',
  setTab: () => {},
});

/**
 * Simple file filter context for account files to accoommodate
 * page layout structure to keep things decoupled and reusable
 */
export const AccountFileFiltersProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  // Maintain archive filter separately for tabs
  const [tab, setTab] = useState<FileTab>('active');
  const filterCtx = useFileFilters();
  const ctx = {
    tab,
    setTab,
    ...filterCtx,
    filters: {
      ...filterCtx.filters,
      archive: tab === 'archived',
    },
  };

  return (
    <accountFileFiltersCtx.Provider value={ctx}>
      {children}
    </accountFileFiltersCtx.Provider>
  );
};

export const useAccountFileFilters = () => {
  const ctx = useContext(accountFileFiltersCtx);
  return ctx;
};
