export const defaultAllowedUploadMimetypes = {
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
  'image/gif': ['.gif'],
  'image/tiff': ['.tif', '.tiff'],
  'application/postscript': ['.ps', '.eps', '.postscript', '.ai'],
  'application/pdf': ['.pdf'],
  'text/plain': [
    '.txt',
    '.text',
    '.qbb',
    '.qbm',
    '.qbw',
    '.qbx',
    '.qba',
    '.ptb',
    '.cab',
    '.qbo',
  ],
  'application/octect-stream': ['.quickenbackup'],
  'application/msword': ['.doc', '.dot'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
    '.docx',
  ],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template': [
    '.dotx',
  ],
  'application/vnd.ms-word.document.macroEnabled.12': ['.docm'],
  'application/vnd.ms-word.template.macroEnabled.12': ['.dotm'],
  'application/vnd.ms-excel.sheet.macroEnabled.12': ['.xlsm'],
  'application/vnd.ms-excel': ['.xls', '.xlt', '.xla'],
  'application/vnd.ms-excel.template.macroenabled.12': ['.xltm'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
  ],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template': [
    '.xltx',
  ],
  'application/vnd.ms-excel.sheet.binary.macroenabled.12': ['.xlsb'],
  'application/vnd.ms-excel.addin.macroenabled.12': ['.xlam'],
  'text/csv': ['.csv'],
  'text/tab-separated-values': ['.tsv'],
  'application/x-zip': ['.zip'],
  'audio/mpeg': ['.mp3'],
  'application/vnd.intu.qfx': ['.qfx'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': [
    '.pptx',
  ],
  'application/vnd.ms-powerpoint': ['.pot', '.ppa', '.pps', '.ppt', '.pwz'],
  'application/vnd.openxmlformats-officedocument.presentationml.template': [
    '.potx',
  ],
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow': [
    '.ppsx',
  ],
  'application/vnd.ms-powerpoint.presentation.macroenabled.12': ['.pptm'],
  'application/vnd.ms-powerpoint.template.macroenabled.12': ['.potm'],
  'application/vnd.ms-powerpoint.slideshow.macroenabled.12': ['.ppsm'],
  'application/vnd.ms-powerpoint.addin.macroenabled.12': ['.ppam'],
};
