import React from 'react';

import { Link } from '@mui/material';
import { useTheme, styled, Box, Stack, Typography } from '@liscio/ui';
import { Interweave } from 'interweave';

import { TaskActivity, TaskDetails } from '@liscio/api';

import { extractData } from './helpers';
import { Avatar } from 'components';

export interface ActivityProps {
  activity: TaskActivity;
  documents?: TaskDetails['documents'];
  isLastElement?: boolean;
}

const Wrapper = styled(Stack)({
  width: '100%',
});

const Date = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.blueGray[500],
  paddingBottom: '16px',
}));

const Content = styled(Box)(({ theme }) => ({
  color: theme.palette.common.blueGray[500],
}));

const AvatarBackground = styled(Box)(({ theme }) => ({
  backgroundSize: '1px 100%',
  backgroundPosition: '50% 0',
  backgroundRepeat: 'no-repeat',
}));

export const Activity: React.FC<ActivityProps> = ({
  activity,
  documents,
  isLastElement = false,
}) => {
  const theme = useTheme();
  const avatarBackgroundImage = isLastElement
    ? ''
    : `linear-gradient(${theme.palette.grey[200]}, ${theme.palette.grey[200]})`;

  const activityExtractedData = extractData(activity, documents);

  return (
    <Wrapper direction="row" gap="16px">
      <AvatarBackground sx={{ backgroundImage: avatarBackgroundImage }}>
        <Avatar
          src={activity.avatar || ''}
          alt={activity.comment_by}
          sx={{ width: 24, height: 24, fontSize: '12px' }}
        />
      </AvatarBackground>
      <Box flexGrow={1}>
        <Typography>{activityExtractedData.title}</Typography>
        <Content>
          {activityExtractedData.fileName && (
            <p>
              {activityExtractedData.fileUrl ? (
                <Link href={activityExtractedData.fileUrl} target="_blank">
                  {activityExtractedData.fileName}
                </Link>
              ) : (
                activityExtractedData.fileName
              )}
            </p>
          )}
          {!activityExtractedData.fileName && (
            <Interweave content={activityExtractedData.comment} />
          )}
        </Content>
        <Date>{activityExtractedData.date}</Date>
      </Box>
    </Wrapper>
  );
};

export default Activity;
