import React, { useState } from 'react';

import { styled, Box, Button } from '@liscio/ui';

import { getPayButtonLabel } from './helpers';
import { PayInvoiceHandlerV2 as PayInvoiceHandler } from 'modules/invoices/components';
import { getPayInvoiceActionType } from 'modules/invoices/helpers';
import {
  INVOICES_DOMAIN,
  INVOICES_PATHS,
} from 'modules/invoices/route-constants';

export interface ActionButtonProps {
  invoiceId: string;
  invoiceStatus?: string;
  isParentInvoice: boolean;
  isRecurring: boolean;
  hasPaymentAuthorizationId: boolean;
  recurringEndDateString?: string | null;
}

const Wrapper = styled(Box)({
  paddingLeft: '8px',
  paddingRight: '8px',
});

export const ActionButton: React.FC<ActionButtonProps> = ({
  invoiceId,
  invoiceStatus,
  isParentInvoice,
  isRecurring,
  hasPaymentAuthorizationId,
  recurringEndDateString,
}) => {
  const [isPayInvoiceHandlerOpen, setIsInvoiceHandlerOpen] = useState(false);

  const payInvoiceActionType = getPayInvoiceActionType({
    isParentInvoice,
    isRecurring,
    hasPaymentAuthorizationId,
    recurringEndDateString,
  });
  const label = getPayButtonLabel(payInvoiceActionType);

  if (invoiceStatus === 'paid' || invoiceStatus === 'processing') {
    return null;
  }

  return (
    <Wrapper>
      <Button
        variant="contained"
        fullWidth
        onClick={() => {
          setIsInvoiceHandlerOpen(true);
        }}
      >
        {label}
      </Button>
      {isPayInvoiceHandlerOpen && (
        <PayInvoiceHandler
          invoiceId={invoiceId}
          closeFunction={() => setIsInvoiceHandlerOpen(false)}
          redirectUrl={`/${INVOICES_DOMAIN}${INVOICES_PATHS.details}/${invoiceId}`}
        />
      )}
    </Wrapper>
  );
};

export default ActionButton;
