import React, { useCallback } from 'react';

import { useFormContext, useController } from 'react-hook-form';

import { ContactsSelector as ContactsSelectorComponent } from 'components';
import { RecipientAccount } from 'components/ContactSelector/ContactSelector';
import { useAccounts } from 'fetch-utils/accounts/accounts-hooks';

export interface ContactsSelectorProps {
  fixedToAccountId?: string;
  fixedToContactId?: string;
}

export const ContactsSelector: React.FC<ContactsSelectorProps> = ({
  fixedToAccountId,
  fixedToContactId,
}) => {
  const { data: accounts } = useAccounts();
  const userHasNoAccounts = !accounts?.count_data.all;

  const {
    formState: { errors },
  } = useFormContext();
  const { field } = useController({ name: 'participants' });
  const append = useCallback(
    (recipientOrRecipients: RecipientAccount | RecipientAccount[]) => {
      const fieldArray = field.value || [];
      const newRecipients = fieldArray.concat(recipientOrRecipients);

      field.onChange(newRecipients);
    },
    [field]
  );
  const remove = useCallback(
    (idx: number) => {
      field.onChange([
        ...(field.value || []).slice(0, idx),
        ...(field.value || []).slice(idx + 1),
      ]);
    },
    [field]
  );

  return (
    <ContactsSelectorComponent
      fixedToAccountId={fixedToAccountId}
      fixedToContactId={fixedToContactId}
      value={field.value}
      placeholder="Select recipients"
      onAddElement={append}
      onRemoveElement={(_, index) => {
        remove(index);
      }}
      error={Boolean(errors?.participants)}
      helperText={String(errors?.participants?.message)}
      accountIsRequired={!userHasNoAccounts}
      shouldAddCSTMembers
    />
  );
};

export default ContactsSelector;
