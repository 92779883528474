import React from 'react';

import { Avatar as MUIAvatar, Button } from '@mui/material';

import { ProfileResponseBody } from '@liscio/api';
import { styled, Stack, Typography, Icon } from '@liscio/ui';

export interface UserInfoHeaderProps {
  profile?: ProfileResponseBody | void;
  isEdit?: boolean;
  onEditClick: () => void;
}

const Avatar = styled(MUIAvatar)(({ theme }) => ({
  width: '120px',
  height: '120px',
  border: `solid 2.35px ${theme.palette.common.neutrals['A100']}`,
}));

export const UserInfoHeader: React.FC<UserInfoHeaderProps> = ({
  profile,
  isEdit,
  onEditClick,
}) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack direction="row" alignItems="center" gap="24px">
        <Avatar src={profile?.avatar} />
        <Typography variant="h2">{`${profile?.first_name || ''} ${
          profile?.middle_name || ''
        } ${profile?.last_name || ''}`}</Typography>
      </Stack>
      {!isEdit && (
        <Button
          onClick={onEditClick}
          variant="outlined"
          size="large"
          startIcon={<Icon icon="pen" />}
        >
          Edit Profile
        </Button>
      )}
    </Stack>
  );
};

export default UserInfoHeader;
