/* eslint-disable import/no-unresolved */
import { useCallback, useEffect, useState } from 'react';

import { SignatoryType } from '@liscio/api';

import {
  useCreateESignAgreement,
  useGetESignDocumentURL,
} from 'fetch-utils/workflow/workflow-hooks';

// https://opensource.adobe.com/acrobat-sign/developer_guide/events.html
type AdobeESignStatus = 'PAGE_LOAD' | 'ESIGN' | 'ERROR' | null;

/**
 *
 * Overall concept/flow
 * POST to Licsio BE to start the ESign agreement creation process
 * Poll GET to Liscio BE for the ESign signing (iframe) URL
 * wait for the Adobe ESign iframe to send a message "ESIGN" to the parent window when the document is signed
 * mark ESignDocumentCompleted as true
 */
export const useESignHook = () => {
  const [ESignAgreementURL, setESignAgreementURL] = useState<string>('');
  const [ESignIframeURL, setESignIframeURL] = useState<string>('');
  const [ESignDocumentCompleted, setESignDocumentCompleted] =
    useState<boolean>(false);

  const [adobeESignMessage, setAdobeESignMessage] =
    useState<AdobeESignStatus>(null);

  const [nextSignerId, setNextSignerId] = useState<string | null>(null);

  // first call POSTs to the BE to start the esign agreement creation process on the BE
  const createESignAgreement = useCreateESignAgreement(
    ESignAgreementURL,
    !!ESignAgreementURL
  );

  const createAgreementIsLoading =
    createESignAgreement.isLoading &&
    createESignAgreement.fetchStatus !== 'idle';

  const createAgreementIsError = createESignAgreement.isError;

  // second call polls using a GET to the BE to get document status and signing URL(for iframe)
  const getESignDocument = useGetESignDocumentURL(
    ESignAgreementURL,
    // poll if the all following conditions are met:
    // - * not used for polling how - adobeESignMessage is null meaning the iframe hasnt loaded yet
    // - ESignDocumentCompleted is false
    // - ESignAgreementURL is truthy
    // - createAgreementIsError is false
    // - createAgreementIsLoading is false
    // adobeESignMessage === null && // this is not needed if we rely on polling
    !ESignDocumentCompleted &&
      !!ESignAgreementURL &&
      !createAgreementIsError &&
      !createAgreementIsLoading
  );

  const signingUrl = getESignDocument?.data?.current_user_signatory.signing_url;

  // since this is a polling call we need to rely on the returned data to determine if the call is loading
  const getESignDocumentIsLoading =
    ESignAgreementURL !== '' &&
    !Boolean(getESignDocument.data?.current_user_signatory.signing_url);

  const getESignDocumentIsError = getESignDocument.isError;

  // set the ESignIframeURL state when the signing URL is available
  useEffect(() => {
    // if not 'sign on same device' and document is signed set the ESignDocumentCompleted to true
    if (
      getESignDocument?.data?.same_device_signatories &&
      getESignDocument?.data?.same_device_signatories.length < 1 &&
      getESignDocument?.data?.current_user_signatory.status ===
        'signed_agreement'
    ) {
      setESignDocumentCompleted(true);
      return;
    }

    if (Boolean(signingUrl)) {
      // first check that the current user has signed; if not present the IFRAME to sign
      if (
        getESignDocument?.data?.current_user_signatory.status !==
        'signed_agreement'
      ) {
        setESignIframeURL(
          getESignDocument?.data?.current_user_signatory?.signing_url || ''
        );
      } else {
        // if current has signed check for any unsigned same device signers
        if (
          getESignDocument.data.same_device_signatories &&
          getESignDocument.data.same_device_signatories.length > 0
        ) {
          const hasUnsignedSameDeviceSigners =
            getESignDocument.data.same_device_signatories.find(
              (signer: { status: string } | null) =>
                signer?.status !== 'signed_agreement'
            );

          if (!hasUnsignedSameDeviceSigners) {
            setESignDocumentCompleted(true);
            return;
          }

          setESignIframeURL(hasUnsignedSameDeviceSigners.signing_url);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getESignDocument?.data]);

  // helper method for determining whether there may be additional signers
  const getNextSameDeviceSigner = () => {
    if (
      getESignDocument?.data?.same_device_signatories &&
      getESignDocument?.data?.same_device_signatories.length > 0
    ) {
      const nextSigner = getESignDocument?.data?.same_device_signatories.find(
        (signer: SignatoryType) => signer?.status === 'waiting'
      );

      return nextSigner;
    }

    return null;
  };

  // set the ESignDocumentCompleted state when the adobeESignMessage is 'ESIGN'
  // and all documents have been signed
  useEffect(() => {
    const nextSigner = getNextSameDeviceSigner();

    // force the next document
    if (adobeESignMessage === 'ESIGN' && nextSigner) {
      // TODO: I need to kill the polling with the setESignAgreementURL
      // I need to change how I detect the allDocsSigned to look at the second waiting
      // I need change the getNextSignerId in a similar fashion
      // Push the nextSignerId to the ESignItem so it can present the name and enable
      // the ESign button which will restart the polling

      setNextSignerId(nextSigner.id);

      // this kills the poll for sign on same device!!!!
      setESignAgreementURL('');
      setESignIframeURL('');

      setAdobeESignMessage(null);
    }

    // call completed
    if (adobeESignMessage === 'ESIGN' && nextSigner === undefined) {
      setESignDocumentCompleted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adobeESignMessage]);

  const onRecievedMessage = useCallback(
    (event: MessageEvent) => {
      // check the message source origin as a security measure
      const regExGetHostName = /https:\/\/(?:[\w-]+\.)+[\w-]+/;
      const postMessageOrigin = ESignIframeURL.match(regExGetHostName)?.[0];
      if (event.origin !== postMessageOrigin) {
        return;
      }

      if (event.data) {
        const data = JSON.parse(event.data);
        setAdobeESignMessage(data.type);
      }
    },
    [ESignIframeURL]
  );

  useEffect(function () {
    window.addEventListener('message', onRecievedMessage);

    return function () {
      window.removeEventListener('message', onRecievedMessage);
    };
  });

  return {
    ESignIframeURL,
    ESignDocumentCompleted,
    setESignAgreementURL,
    createAgreementIsLoading,
    createAgreementIsError,
    getESignDocumentIsLoading,
    getESignDocumentIsError,
    nextSignerId,
    adobeESignMessage, // use this to set a loading state on the action button
  };
};
