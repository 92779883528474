import React from 'react';

import { Button, ButtonProps, Icon, styled } from '@liscio/ui';

const StyledButton = styled(Button)(({ theme }) => ({
  // NOTE: THis is bad practice. We need to style buttons via color
  // variants in our root theme, rather than applying one-off styles
  backgroundColor: theme.palette.grey[100],
  borderBottom: `solid 1px ${theme.palette.common.blueGray[200]}`,
}));

export const AddAttachmentButton: React.FC<ButtonProps> = (props) => {
  return (
    <StyledButton fullWidth {...props}>
      <Icon icon="attachFile" fontSize="small" />
      Add Attachment
    </StyledButton>
  );
};

export default AddAttachmentButton;
