import React from 'react';

import { MicrosoftIcon } from '@liscio/ui';

import { StyledOAuthButton } from './StyledOAuthButton';
import { useOAuth } from './useOauth';

export const OutlookOAuthLoginDesktop = () => {
  const { initiateOAuth } = useOAuth('outlook365');

  return (
    <StyledOAuthButton
      type="button"
      onClick={initiateOAuth}
      variant="contained"
      startIcon={<MicrosoftIcon />}
      size="large"
    >
      Office 365
    </StyledOAuthButton>
  );
};
