/* eslint-disable import/no-unresolved */
import { useEffect, useRef, useState } from 'react';

import {
  BackButton,
  Box,
  Icon,
  Stepper,
  Stack,
  Typography,
  useTheme,
} from '@liscio/ui';

import { ESignItem } from './components/ESignItem/ESignItem';
import { HelperContent } from './components/HelperContent/HelperContent';
import { PayableItem } from './components/PayableItem/PayableItem';
import ResponseSubmitDialog from './components/ResponseSubmitDialog/ResponseSubmitDialog';
import { ReviewableDocumentItem } from './components/ReviewableDocument/ReviewableDocumentItem';
import { TaxDeliveryEstimate } from './components/TaxDeliveryEstimate/TaxDeliveryEstimate';
import {
  FullScreenStack,
  DesktopHeader,
  StepperContainer,
  DesktopMainContentGridItem,
  SidebarGridItem,
  DesktopMainContentGridContainer,
} from './TaxDeliveryView.styles';
import {
  DASHBOARD_DOMAIN,
  DASHBOARD_PATHS,
} from 'modules/dashboard/route-constants';
import { useTaxDeliveryStore } from 'stores/workflow/useTaxDeliveryStore';

/* The overall concept here is that each workflow item controls it's own state and actions 
and uses the portal containers provided by this view to render it's specific content and actions.
*/

export const TaxDeliveryDesktopView = () => {
  const mainContentPortalContainerRef = useRef(null);
  const actionButtonPortalContainerRef = useRef(null);
  const [submissionDialogOpen, setSubmissionDialogOpen] = useState(false);
  const theme = useTheme();
  const borderColor = theme.palette.divider;

  const {
    activeStep,
    checkAllItemsComplete,
    submitted,
    lastStepActive,
    taxDeliveryData,
    setActiveStep,
    setActiveItem,
    setActiveItemComplete,
    goToNextItem,
    setMainContentPortalRef,
    setActionButtonPortalRef,
  } = useTaxDeliveryStore();

  useEffect(() => {
    setMainContentPortalRef(mainContentPortalContainerRef);
    setActionButtonPortalRef(actionButtonPortalContainerRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainContentPortalContainerRef, actionButtonPortalContainerRef]);

  const [showHelperContent, setShowHelperContent] = useState(true);

  // short circuit the approval if all Reviewable Items have been signed
  useEffect(() => {
    if (taxDeliveryData.steps?.[activeStep]?.items) {
      const reviewableItems = taxDeliveryData.steps?.[activeStep]?.items.filter(
        (item) => item.type === 'WfiReviewableDocument'
      );

      if (reviewableItems.every((item) => item.complete))
        setActiveItem(reviewableItems.length);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxDeliveryData.steps?.[activeStep]?.items]);

  const handleStepperClick = (index: number) => {
    setActiveStep(index);
    setActiveItem(0);
    setShowHelperContent(true);
  };

  const handleOnItemComplete = () => {
    setActiveItemComplete();
    goToNextItem();
    if (lastStepActive && checkAllItemsComplete()) {
      setSubmissionDialogOpen(true);
    }
    setShowHelperContent(true);
  };

  const handleItemClick = (i: number) => {
    setActiveItem(i);
    setShowHelperContent(false);
  };

  const helperContentType = () => {
    if (submitted) return 'COMPLETED';
    if (taxDeliveryData) return 'SELECT-DOCUMENT';
  };

  const handleCancelSubmission = () => {
    setShowHelperContent(false);
    setSubmissionDialogOpen(false);
  };

  return (
    <FullScreenStack direction="column">
      <DesktopHeader theme={theme}>
        <Stack direction="row" alignItems="center">
          <BackButton
            startIcon={<Icon icon="arrowLeft" />}
            fallback={`/${DASHBOARD_DOMAIN}${DASHBOARD_PATHS.home}`}
          >
            Back
          </BackButton>
          <Typography variant="h5" ml={2} fontWeight={600}>
            {taxDeliveryData.title || ''}
          </Typography>
        </Stack>
      </DesktopHeader>
      <StepperContainer theme={theme}>
        <Stepper
          activeStep={activeStep}
          steps={taxDeliveryData.steps || [{ label: '' }]}
          onClick={handleStepperClick}
        />
      </StepperContainer>
      <DesktopMainContentGridContainer container>
        <DesktopMainContentGridItem item md={8}>
          <Stack
            direction="column"
            height="100%"
            borderRight={`solid 1px ${borderColor}`}
            justifyContent="flex-start"
          >
            <Box flex={1} overflow={'hidden'}>
              {/* (Desktop) Main Content Portal Container */}
              <Box
                ref={mainContentPortalContainerRef}
                width="100%"
                height="100%"
              >
                {showHelperContent && (
                  <HelperContent type={helperContentType()} />
                )}
              </Box>
              {/* END Main Content Portal Container */}
            </Box>
          </Stack>
        </DesktopMainContentGridItem>
        <SidebarGridItem item md={4} theme={theme}>
          <Stack direction="column" height="inherit">
            <Box flex={1} overflow={'hidden auto'} p={2}>
              {/* items in sections */}
              {taxDeliveryData.steps?.[activeStep]?.items?.map((item, i) => {
                if (item.type === 'WfiReviewableDocument') {
                  return (
                    <ReviewableDocumentItem
                      key={item.id}
                      onItemClick={() => {
                        handleItemClick(i);
                      }}
                      onComplete={handleOnItemComplete}
                      itemData={taxDeliveryData.steps?.[activeStep]?.items?.[i]}
                    />
                  );
                }
                if (item.type === 'WfiDynamicItem') {
                  return (
                    <TaxDeliveryEstimate
                      key={item.id}
                      assignedAccount={taxDeliveryData.assignedAccount}
                      onItemClick={() => handleItemClick(i)}
                      onComplete={handleOnItemComplete}
                      itemData={taxDeliveryData.steps?.[activeStep]?.items?.[i]}
                    />
                  );
                }
                if (item.type === 'WfiSignableDocument') {
                  return (
                    <ESignItem
                      key={item.id}
                      onItemClick={() => {
                        handleItemClick(i);
                      }}
                      onComplete={handleOnItemComplete}
                      itemData={taxDeliveryData.steps?.[activeStep]?.items?.[i]}
                    />
                  );
                }
                if (item.type === 'WfiPayable') {
                  return (
                    <PayableItem
                      key={item.id}
                      onItemClick={() => {
                        handleItemClick(i);
                      }}
                      onComplete={handleOnItemComplete}
                      itemData={taxDeliveryData.steps?.[activeStep]?.items?.[i]}
                    />
                  );
                }
              })}
            </Box>
            {/* flex to grow the action button container vertically as needed */}
            <Box flex="0" p={2} borderTop={`solid 1px ${borderColor}`}>
              {/* Action Button Portal Container */}
              <Stack
                direction="row"
                spacing={2}
                ref={actionButtonPortalContainerRef}
              ></Stack>
              {/* END Action Button Portal Container */}
              <ResponseSubmitDialog
                open={submissionDialogOpen}
                onCancel={handleCancelSubmission}
                onSubmit={() => setSubmissionDialogOpen(false)}
              />
            </Box>
          </Stack>
        </SidebarGridItem>
      </DesktopMainContentGridContainer>
    </FullScreenStack>
  );
};
