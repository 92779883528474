import { useCallback, useState, useEffect, useRef } from 'react';

import { InputBaseComponentProps } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { FormPasswordField, FieldWrapper } from '@liscio/ui';

import { Mask } from './components';
import { useEncryptedField } from 'fetch-utils/accounts/accounts-hooks';

export interface EncryptedFieldFormProps {
  recordType: string;
  recordId: string | number;
  fieldName: string;
  formFieldName: string;
  label: string;
  hasEncryptedValue?: boolean;
  inputProps?: InputBaseComponentProps;
}

export const EncryptedField = ({
  recordType,
  recordId,
  fieldName,
  formFieldName,
  label,
  hasEncryptedValue,
  inputProps = {},
}: EncryptedFieldFormProps) => {
  const { formState, getValues, setValue } = useFormContext();
  const [shouldLoadAndShowData, setShouldLoadData] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const { data, isFetching } = useEncryptedField({
    recordType,
    recordId,
    fieldName,
    enabled: shouldLoadAndShowData,
  });
  const isFirstFocus = useRef(true);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setValue(formFieldName, data?.enc_value);
    inputRef?.current?.focus();
  }, [data?.enc_value, formFieldName, setValue, shouldLoadAndShowData]);

  const onFocus = useCallback(() => {
    const value = getValues(formFieldName);
    if (
      value === null ||
      value === undefined ||
      (Boolean(value) && isFirstFocus.current)
    ) {
      setShouldLoadData(true);
      isFirstFocus.current = false;
    }
  }, [getValues, formFieldName]);

  const shouldDisplayMask = hasEncryptedValue && !shouldLoadAndShowData;

  const disabled = isFetching || formState.isSubmitting;

  return (
    <FieldWrapper
      label={label}
      name={formFieldName}
      fullWidth
      disabled={disabled}
    >
      <>
        {shouldDisplayMask && (
          <Mask
            name={formFieldName}
            onFocus={onFocus}
            onVisible={(isVisible) => {
              setIsVisible(!isVisible);
            }}
            disabled={disabled}
          />
        )}
        {!shouldDisplayMask && (
          <FormPasswordField
            inputRef={inputRef}
            name={formFieldName}
            visible={isVisible}
            disabled={disabled}
            onVisible={(isVisible) => setIsVisible(!isVisible)}
            placeholder={isFetching ? 'Loading...' : undefined}
            inputProps={{
              autoComplete: 'new-password',
              ...inputProps,
            }}
          />
        )}
      </>
    </FieldWrapper>
  );
};

export default EncryptedField;
