import React from 'react';

import Checkbox from '@mui/material/Checkbox';

import { AccountBankAccountEntity } from '@liscio/api';
import { AppBar } from '@liscio/common';
import { Stack, Typography } from '@liscio/ui';

import { EllipsesState } from './components';
import { EncryptedFieldPreview } from 'components';
import { KeyValue } from 'components/KeyValueBox/components';
import { ScreenWrapper, BodyWrapper } from 'components/screenComposition';

interface AccountBankAccountDetailViewMobileProps {
  // @TODO Refactor this component to fetch bank accounts individually
  // and not use the bankAccount prop
  // https://liscio.atlassian.net/browse/DEV-3639
  bankAccount?: Partial<AccountBankAccountEntity>;
  goBack: () => void;
}
export default function AccountBankAccountDetailViewMobile({
  bankAccount,
  goBack,
}: AccountBankAccountDetailViewMobileProps) {
  if (!bankAccount) return null;

  const hasPopulatedAccountNumber =
    bankAccount?.encrypted_account_number?.populated;
  return (
    <ScreenWrapper>
      <AppBar
        onNavigationClick={goBack}
        DefaultStateElement={(props) => (
          <EllipsesState {...props} bankAccount={bankAccount} />
        )}
        title={bankAccount.bank_name}
      />
      <BodyWrapper>
        <Stack sx={{ padding: '20px' }} spacing={5}>
          <KeyValue itemKey="Bank Name" value={bankAccount.bank_name} />
          <KeyValue itemKey="Routing #" value={bankAccount.routing_number} />
          <KeyValue
            itemKey="Account #"
            value={
              <EncryptedFieldPreview
                recordId={bankAccount.id || ''}
                recordType="BankAccount"
                fieldName="account_number"
                hasEncryptedValue={hasPopulatedAccountNumber}
              />
            }
          />
          <KeyValue itemKey="Account Type" value={bankAccount.account_type} />
          <KeyValue itemKey="Website" value={bankAccount.web_site} />
          <KeyValue itemKey="Note" value={bankAccount.notes} />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Checkbox disableRipple checked={bankAccount.is_ach_autopay} />
            <Typography sx={{ textAlign: 'right' }}>
              Use this account for monthly ACH auto payments?
            </Typography>
          </Stack>
        </Stack>
      </BodyWrapper>
    </ScreenWrapper>
  );
}
