import React from 'react';

import { BasicMessage } from '@liscio/api';
import { MessageSnippet } from '@liscio/common';
import { styled, Box, Typography } from '@liscio/ui';

import { getParticipants } from './helpers';

export interface PrimaryProps {
  message: BasicMessage;
  shouldDisplayAsUnread: boolean;
}

const Date = styled(Typography)(({ theme }) => ({
  flexShrink: 0,
  color: theme.palette.common.blueGray[500],
}));

export const Primary: React.FC<PrimaryProps> = ({
  message,
  shouldDisplayAsUnread,
}) => {
  const participants = getParticipants(message);

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" textAlign="start">
        <Typography variant={shouldDisplayAsUnread ? 'body2' : 'body1'}>
          {participants}
        </Typography>
        <Date variant="caption">{message.tym}</Date>
      </Box>
      <MessageSnippet
        snippet={message?.content_limit}
        title={message.title}
        isUnread={shouldDisplayAsUnread}
      />
    </Box>
  );
};

export default Primary;
