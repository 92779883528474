import React from 'react';

import { Link } from 'react-router-dom';

import { styled, useTheme, Box, Typography } from '@liscio/ui';

import { getAlertConfig } from './helpers/getAlertConfig';

export interface AccountAlertProps {
  hasFailedInvoices: boolean;
  hasOverdueInvoices: boolean;
  hasPaymentAuthorizationRequests: boolean;
  isAccountOwner: boolean;
}

const Wrapper = styled(Box)(() => ({
  display: 'flex',
  gap: '4px',
  alignItems: 'center',
  justifyContent: 'center',
  borderStyle: 'solid',
  borderWidth: '5px 0 0 0',
  padding: '24px',
}));

const StyledLink = styled(Link)(({ color }) => ({
  color,
  '&:visited': {
    color,
  },
}));

export const AccountAlert = ({
  hasFailedInvoices,
  hasOverdueInvoices,
  hasPaymentAuthorizationRequests,
  isAccountOwner,
}: AccountAlertProps) => {
  const theme = useTheme();
  const alertConfig = getAlertConfig({
    hasFailedInvoices,
    hasOverdueInvoices,
    hasPaymentAuthorizationRequests,
    isAccountOwner,
  });

  if (!alertConfig) {
    return null;
  }

  const onColor =
    alertConfig?.severity === 'error'
      ? theme.brandColors.onErrorContainer
      : theme.brandColors.onSecondaryContainer;
  const color =
    alertConfig.severity === 'error'
      ? theme.brandColors.errorContainer
      : theme.brandColors.secondaryContainer;
  return (
    <Wrapper
      sx={{ borderColor: onColor, backgroundColor: color, color: onColor }}
    >
      <Typography variant="body1">{alertConfig.label}</Typography>
      <StyledLink to={alertConfig.urlAddress} color={onColor}>
        <Typography component="span" variant="body1">
          {alertConfig.urlLabel}
        </Typography>
      </StyledLink>
    </Wrapper>
  );
};

export default AccountAlert;
