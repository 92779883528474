import React from 'react';

import { Button, styled, GoogleGIcon } from '@liscio/ui';

import { useOAuth } from './useOauth';

const GoogleButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.common.neutrals[50],
  height: '50px',
  flex: '1',
  borderRadius: '8px',
  border: `solid 1px ${theme.palette.common.neutrals[200]}`,
  color: theme.palette.common.neutrals[900],
  margin: '0px 8px 0px 0px',
  padding: '0px',
}));

export const GoogleOAuthLoginMobile = () => {
  const { initiateOAuth } = useOAuth('google');

  return (
    <GoogleButton
      type="button"
      onClick={initiateOAuth}
      variant="contained"
      startIcon={<GoogleGIcon fontSize="medium" />}
      size="large"
    >
      Google
    </GoogleButton>
  );
};
