import React from 'react';

import { ResponsiveView } from '@liscio/ui';

import { ProfileViewDesktopLayout } from './components';

export interface ProfileViewLayoutProps {
  title?: React.ReactNode;
  mobile?: React.ReactNode;
  desktop?: React.ReactNode;
}

export const ProfileViewLayout = ({
  title,
  mobile,
  desktop,
}: ProfileViewLayoutProps) => (
  <ResponsiveView
    SmallView={<>{mobile}</>}
    LargeView={
      <ProfileViewDesktopLayout title={title}>
        {desktop}
      </ProfileViewDesktopLayout>
    }
  />
);

export default ProfileViewLayout;
