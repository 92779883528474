import { CircularProgress } from '@mui/material';
import toast from 'react-hot-toast';

import { WorkflowRequestStatus } from '@liscio/api/graphql';
import { Dialog, Stack, Typography } from '@liscio/ui';

import { CompletedMessage, IncompletedMessage } from './Messages';
import {
  useGetCompletionValues,
  useUpdateWorkflowRequest,
} from 'fetch-utils/workflow/workflow-hooks';
import { useTaxDeliveryStore } from 'stores/workflow/useTaxDeliveryStore';

export type ResponseSubmitDialogProps = {
  open: boolean;
  onCancel: () => void;
  onSubmit: () => void;
};

export const ResponseSubmitDialog = ({
  onCancel,
  open,
  onSubmit,
}: ResponseSubmitDialogProps) => {
  const {
    taxDeliveryData: { workflowRequestId },
    allStepsComplete,
    setSubmitted,
  } = useTaxDeliveryStore();
  const { mutate: workflowRequestMutation } = useUpdateWorkflowRequest();
  const {
    data: completionValues,
    isLoading,
    remove,
  } = useGetCompletionValues(workflowRequestId || '', open);

  const isCompleted =
    allStepsComplete ||
    (completionValues && completionValues.total === completionValues.completed);

  const handleOnSubmit = () => {
    submitRequest();
  };

  const handleOnCancel = () => {
    remove();
    onCancel();
  };

  const submitRequest = async () => {
    workflowRequestMutation(
      {
        workflowRequestId,
        status: WorkflowRequestStatus.Submitted,
      },
      {
        onSettled: (response) => {
          if (response?.updateWorkflowRequest?.errors?.length) {
            toast.error('Error submitting request');
            return;
          } else {
            toast.success('Request submitted');
            setSubmitted(true);
            onSubmit();
          }
        },
      }
    );
  };

  let dialogContent = null;
  if (isLoading) {
    dialogContent = (
      <Stack mt={2} direction="row" justifyContent="center">
        <CircularProgress color="primary" />
      </Stack>
    );
  } else if (isCompleted) {
    dialogContent = <CompletedMessage />;
  } else {
    dialogContent = <IncompletedMessage />;
  }

  return (
    <>
      <Dialog
        open={open}
        title={<Typography variant="h4">Confirm Submission</Typography>}
        onClose={handleOnCancel}
        cancelLabel="Cancel"
        cancelBtnProps={{ size: 'small' }}
        onCancel={handleOnCancel}
        submitLabel="Submit"
        submitBtnProps={{ size: 'small', disabled: !isCompleted }}
        onSubmit={handleOnSubmit}
        iconClose={false}
      >
        {dialogContent}
      </Dialog>
    </>
  );
};

export default ResponseSubmitDialog;
