import React from 'react';

import { ConfirmationDialog } from 'components';

export interface ConfirmationDialogProps {
  open: boolean;
  tab?: string;
  onCancel(): void;
  onConfirm(): void;
  isFileArchived?: boolean;
}

export const PayableItemConfirmPaymentDialog: React.FC<
  ConfirmationDialogProps
> = ({ open, onCancel, onConfirm }) => {
  const title = 'Confirm Payment';
  const content = `Click "Mark Paid" to indicate you have completed this payment and mark this item as Done.
    Click "Keep Open" if you intend to revisit this request to make this payment later.`;
  const confirmLabel = 'Mark Paid';
  const cancelLabel = 'Keep Open';

  return (
    <>
      <ConfirmationDialog
        open={open}
        onConfirm={onConfirm}
        onCancel={onCancel}
        title={title}
        content={content}
        confirmLabel={confirmLabel}
        cancelLabel={cancelLabel}
      />
    </>
  );
};

export default PayableItemConfirmPaymentDialog;
