import React, { useCallback } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import {
  CenteredLayout,
  GlobalLoader,
  Box,
  styled,
  ThemedLogo,
} from '@liscio/ui';

import { ConfirmMfaForm } from '../LoginViewMobile/components';
import { ErrorDialog } from 'components';
import { useMagicLinkLogin } from 'fetch-utils/users/auth-hooks';

const Wrapper = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  paddingTop: '48px',
  paddingBottom: '16px',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: theme.breakpoints.values.sm,
  [theme.breakpoints.up('md')]: {
    color: theme.palette.text.primary,
  },
}));

export const MagicLinkLoginView = () => {
  const navigate = useNavigate();
  const backToLogin = useCallback(
    () => navigate('/authenticate/login', { replace: true }),
    [navigate]
  );
  const { link } = useParams<{ link: string }>();
  const { isLoading, error, mfaRequired, email, loginWithPasscode } =
    useMagicLinkLogin({ link });

  return (
    <CenteredLayout>
      <Wrapper>
        <ThemedLogo width="240px" mb="48px" variant="logoSplash" />
        {mfaRequired && (
          <ConfirmMfaForm
            email={email}
            onBackToLogin={backToLogin}
            loginWithPasscode={loginWithPasscode}
            error={error}
          />
        )}
        <GlobalLoader open={isLoading} />
        {!mfaRequired && (
          <ErrorDialog
            title="Invalid Link"
            error={error}
            onClose={backToLogin}
          />
        )}
      </Wrapper>
    </CenteredLayout>
  );
};
