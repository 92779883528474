import React from 'react';

import {
  Avatar,
  List as MUIList,
  ListItem as MUIListItem,
  ListItemButton as MUIListItemButton,
  ListItemAvatar,
  ListItemIcon as MUIListItemIcon,
  ListItemText as MUIListItemText,
} from '@mui/material';

import { styled, Icon } from '@liscio/ui';

import { DrawerProps } from '../../Drawer';

type LoggedUserProps = Pick<
  DrawerProps,
  | 'onNavigationClick'
  | 'selectedOptionValue'
  | 'name'
  | 'avatarUrl'
  | 'isLoading'
>;

const List = styled(MUIList)(() => ({
  paddingBottom: '16px',
}));
const ListItem = styled(MUIListItem)(({ theme }) => ({
  color: theme.palette.common.tertiaryContrast,
}));
const ListItemButton = styled(MUIListItemButton)(({ theme }) => ({
  paddingTop: '12px',
  paddingBottom: '12px',
  '&.Mui-selected': {
    backgroundColor: theme.brandColors.dark,
  },
}));
const ListItemIcon = styled(MUIListItemIcon)(({ theme }) => ({
  color: theme.palette.common.tertiaryContrast,
}));
const ListItemText = MUIListItemText;

export const LoggedUser: React.FC<LoggedUserProps> = ({
  selectedOptionValue,
  onNavigationClick,
  name,
  avatarUrl,
  isLoading,
}) => (
  <List>
    <ListItem disablePadding>
      <ListItemButton
        selected={selectedOptionValue === 'profile'}
        onClick={() => onNavigationClick && onNavigationClick(`profile`)}
        disabled={isLoading}
      >
        <ListItemAvatar>
          <Avatar alt={name} src={avatarUrl} sx={{ width: 24, height: 24 }} />
        </ListItemAvatar>
        <ListItemText>{name}</ListItemText>
      </ListItemButton>
    </ListItem>
    <ListItem disablePadding>
      <ListItemButton
        onClick={() => onNavigationClick && onNavigationClick(`logout`)}
        disabled={isLoading}
      >
        <ListItemIcon>
          <Icon icon="logout" />
        </ListItemIcon>
        <ListItemText>Logout</ListItemText>
      </ListItemButton>
    </ListItem>
  </List>
);

export default LoggedUser;
