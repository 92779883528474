import React from 'react';

import { TaskDetails } from '@liscio/api';
import { Box, Icon, Link, Stack, Typography, styled } from '@liscio/ui';

import { Interweave, Avatar } from 'components';
import { Label, Row } from 'components/detailedInformation';
import { useProfileData } from 'fetch-utils/users/user-hooks';
import { TASKS_DOMAIN, TASKS_PATHS } from 'modules/tasks/route-constants';

export interface DetailsProps {
  task: TaskDetails;
}

const Wrapper = styled(Stack)({
  padding: '36px 16px 16px 16px',
});

export const Details: React.FC<DetailsProps> = ({ task }) => {
  const { cpa_user_id: cpaUserId } = useProfileData();
  const signEdocLinkDisabled =
    cpaUserId !== task?.assigne_cpa_user_id ||
    task?.edoc_status !== 'in_progress' ||
    task.is_edoc_signed;

  return (
    <Wrapper gap="24px">
      <Row>
        <Label>DUE DATE:</Label>
        <Icon icon="calendarDay" />
        <Typography>{task.due_date}</Typography>
      </Row>
      <Row>
        <Label>FOR ACCOUNT:</Label>
        <Typography>{task.account || task.todo_account_name}</Typography>
      </Row>
      {task.task_type_key === 'manage_to_go_items' && (
        <>
          <Row>
            <Label>TO-GO TYPE:</Label>
            <Typography>{task.manage_to_go_type}</Typography>
          </Row>
          <Row>
            <Label>DOCUMENT TYPE:</Label>
            <Typography>{task.to_go_document_type}</Typography>
          </Row>
        </>
      )}
      <Row>
        <Label>TASK OWNER:</Label>
        <Avatar
          src={task.owner_image || ''}
          alt={task.owner_initials}
          sx={{ width: 24, height: 24, fontSize: '12px' }}
        />
        <Typography>{task.owner}</Typography>
      </Row>
      {task.task_type_key === 'virtual_meeting' && (
        <Row>
          <Label>LOCATION:</Label>
          <Box sx={{ maxWidth: '55%' }}>
            {!task.zoom_meeting_link && <Typography>-</Typography>}
            {task.zoom_meeting_link && (
              <Link
                href={task.zoom_meeting_link}
                target="_blank"
                sx={{ wordBreak: 'break-all' }}
              >
                {task.zoom_meeting_link}
              </Link>
            )}
          </Box>
        </Row>
      )}
      <Row>
        <Label>DESCRIPTION:</Label>
        {/* eDoc Desktop Signing URL */}
        {task.task_type_key === 'edoc' &&
          task.signing_url &&
          !signEdocLinkDisabled && (
            <Box display={{ xs: 'none', md: 'block' }}>
              <Link
                to={`/${TASKS_DOMAIN}${TASKS_PATHS.eDoc}/${encodeURIComponent(
                  task.signing_url
                )}/${task.id}`}
                state={{
                  goBackUrl: `/${TASKS_DOMAIN}${TASKS_PATHS.details}/${task.id}`,
                }}
              >
                <b>Click here</b> to review and sign{' '}
                <b>{task.agreement_name || 'eDoc'}</b>
              </Link>
            </Box>
          )}
      </Row>
      <Interweave content={task.description || ''} />
    </Wrapper>
  );
};

export default Details;
