import { useCallback, useMemo, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import {
  Dialog,
  DragAndDrop,
  FieldWrapper,
  Typography,
  Button,
  styled,
} from '@liscio/ui';
import { preventBubbling, readableBytes } from '@liscio/utils';

import { FormFilePreview } from 'components/FilePreview';
import { routes } from 'routes';
import { useFileUploaderDropZone } from 'stores/fileUploads';
import { useFileUploads } from 'stores/fileUploads/useFileUploads';

export type FileUploadsListProps = {
  onBrowse?: () => void;
};

const StyledDialog = styled(Dialog)({
  '& .MuiTypography-h5': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

const StyledCloseButton = styled(Button)(({ theme }) => ({
  margin: '0 auto',
  width: '100%',
  backgroundColor: theme.palette.common.tertiary[400],
}));

export const FileUploadsList = ({
  onBrowse = () => {},
}: FileUploadsListProps) => {
  const { files, deleteFile } = useFileUploads();
  const { getInputProps } = useFileUploaderDropZone({
    url: routes.filesNew.link(),
  });
  const [idxToDelete, setIdxToDelete] = useState<number | undefined>();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const fileToDelete = useMemo(() => {
    if (idxToDelete !== undefined) {
      return files[idxToDelete];
    }
  }, [idxToDelete, files]);
  const cancelDelete = () => setShowDeleteConfirm(false);
  const promptDeleteConfirm = (idx: number) => {
    setIdxToDelete(idx);
    setShowDeleteConfirm(true);
  };
  const confirmDeleteFile = useCallback(() => {
    if (idxToDelete !== undefined) {
      setShowDeleteConfirm(false);
      setIdxToDelete(undefined);
      deleteFile(idxToDelete);
    }
  }, [idxToDelete, deleteFile]);

  const [idxToPreview, setIdxToPreview] = useState<number | undefined>();
  const [showPreview, setShowPreview] = useState(false);
  const previewFile = (idx: number) => {
    setIdxToPreview(idx);
    setShowPreview(true);
  };
  const cancelFilePreview = () => setShowPreview(false);
  const fileToPreview = useMemo(() => {
    if (idxToPreview !== undefined) {
      return files[idxToPreview];
    }
  }, [idxToPreview, files]);

  const {
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <FieldWrapper
        error={errors?.files?.message as string}
        fullWidth
        data-testid="upload-files-list"
        onClick={(e) => preventBubbling(e)}
      >
        <DragAndDrop
          onBrowse={onBrowse}
          attachments={files.map((file, idx) => ({
            filename: file.file.name,
            mimeType: file.file.type,
            size: readableBytes(file.file.size),
            onCancel: () => promptDeleteConfirm(idx),
            onPreview: () => previewFile(idx),
          }))}
        >
          <input data-testid="upload-files-input" {...getInputProps()} />
        </DragAndDrop>
      </FieldWrapper>
      {/* Delete Files Confirm Dialog */}
      <Dialog
        title="Delete Attachment"
        open={!!(fileToDelete && showDeleteConfirm)}
        onClose={cancelDelete}
        onCancel={cancelDelete}
        cancelLabel="No"
        onSubmit={confirmDeleteFile}
        submitLabel="Yes"
      >
        <Typography>
          You are about to delete the attached file{' '}
          <b>{fileToDelete?.file.name}</b>. This action cannot be undone.
          Proceed?
        </Typography>
      </Dialog>
      {/* File Preview Dialog */}
      <StyledDialog
        maxWidth={false}
        title={fileToPreview?.file?.name || 'File Preview'}
        open={!!(fileToPreview && showPreview)}
        onClose={cancelFilePreview}
      >
        {fileToPreview && <FormFilePreview file={fileToPreview.file} />}
        <StyledCloseButton onClick={cancelFilePreview} variant="contained">
          Close
        </StyledCloseButton>
      </StyledDialog>
    </>
  );
};
