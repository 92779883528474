import { styled, Box, GlobalLoader, Stack } from '@liscio/ui';
import Button from '@mui/material/Button';
import { FieldValues, SubmitHandler, useFormContext } from 'react-hook-form';

import { FileMessageFormSection, ScannerAndInput } from './components';
import CoreUploadFormSection from './components/CoreUploadFormSection';
import { FilesInForm } from './components/ScannerAndInput/components';
import useSubmitUploadForm from './useSubmitUploadForm';
import { UploadDocumentFormViewMobileProps } from '../../UploadDocumentFormViewMobile';
import { BodyWrapper, FooterWrapper } from 'components/screenComposition';

export const MarginBox = styled(Box)(() => ({
  margin: '25px 0px 0px 0px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

const HtmlForm = styled('form')({
  display: 'flex',
  flexGrow: '1',
  flexDirection: 'column',
  overflow: 'scroll',
});

export type UploadFormProps = UploadDocumentFormViewMobileProps & {
  fileId?: string;
  taskId?: string;
  fixedToAccountId?: string;
  fixedToContactId?: string;
  onNavigationClick: (actionKey: string) => void;
};

export default function UploadForm({
  fileId,
  onNavigationClick,
  type,
  onFileUpload,
  taskId,
  fixedToAccountId,
  fixedToContactId,
}: UploadFormProps) {
  const {
    disableSubmit,
    filesAreUploading,
    isEditView,
    fileUpdating,
    onSubmit,
    uploadProgressPercentMap,
    cancelUpload,
  } = useSubmitUploadForm({
    onNavigationClick,
    type,
    onFileUpload,
    fileId,
    taskId,
  });

  const {
    handleSubmit,
    formState: { errors },
  } = useFormContext();

  const percentValues = Object.values(uploadProgressPercentMap || {});
  const allUploadsComplete =
    percentValues.length > 0 &&
    percentValues.every((percent) => percent === 100);
  const showSpinner = allUploadsComplete || fileUpdating;

  return (
    <>
      <GlobalLoader open={showSpinner} />
      <HtmlForm onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}>
        <BodyWrapper
          sx={{ padding: '16px 8px 8px 8px' }}
          display="flex"
          flexDirection="column"
          id="body-wrapper-overlay"
        >
          <Stack gap="8px">
            <FileMessageFormSection
              type={type}
              disabled={filesAreUploading}
              fixedToAccountId={fixedToAccountId}
              fixedToContactId={fixedToContactId}
            />
            <CoreUploadFormSection type={type} disabled={filesAreUploading} />
            {!isEditView && <ScannerAndInput disabled={filesAreUploading} />}
            <MarginBox>
              <FilesInForm
                errors={errors}
                uploadProgressPercentMap={uploadProgressPercentMap}
                cancelUpload={cancelUpload}
              />
            </MarginBox>
          </Stack>
        </BodyWrapper>
        <FooterWrapper>
          <Button
            variant="contained"
            type="submit"
            fullWidth
            disabled={disableSubmit}
          >
            {isEditView ? 'Edit file' : 'Add attachment'}
          </Button>
        </FooterWrapper>
      </HtmlForm>
    </>
  );
}
