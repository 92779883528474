import React from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { Helmet } from 'react-helmet-async';
import { Route, Routes } from 'react-router-dom';

import { FILES_PAGE_TITLES, FILES_PATHS } from './route-constants';
import {
  ResponsiveFileListView,
  ResponsiveFileDetailView,
  ResponsiveSendDocumentFormView,
} from './views';
import { UploadFilesPage } from './views/UploadFilesPage/UploadFilesPage';
import { CatchAllRedirect } from 'modules/CatchAllRedirect/CatchAllRedirect';

export const ModuleRoutes = () => {
  const { clientFileUploadRedesign } = useFlags();

  return (
    <>
      <Helmet title={FILES_PAGE_TITLES.list} />
      <Routes>
        <Route
          path={`${FILES_PATHS.details}/:id`}
          Component={ResponsiveFileDetailView}
        />
        <Route path={FILES_PATHS.list} element={<ResponsiveFileListView />} />
        <Route
          path={FILES_PATHS.new}
          element={
            clientFileUploadRedesign ? (
              <UploadFilesPage />
            ) : (
              <ResponsiveSendDocumentFormView />
            )
          }
        />
        <Route path="*" element={<CatchAllRedirect />} />
      </Routes>
    </>
  );
};

export default ModuleRoutes;
