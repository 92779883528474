import { styled, Button } from '@liscio/ui';

export const StyledOAuthButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: '5px',
  color: theme.palette.common.neutrals[800],
  border: `1px solid ${theme.palette.common.neutrals[400]}`,
  width: '100%',
  paddingLeft: '8px',
  paddingRight: '8px',
  '&:hover': {
    backgroundColor: theme.palette.common.neutrals[200],
  },
}));
