import { useMemo } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { BasicDocument, UploadType } from '@liscio/api';

import {
  FileUploadsBatchType,
  OnDocumentsUploadedArgs,
} from './fileUploads.types';
import { useFileUploadsStore } from './useFileUploadsStore';
import apiClient from 'fetch-utils/api-client';

export type UseFileUploadOptions<T = unknown> = {
  /**
   * Upload type for file uploads
   */
  uploadType?: UploadType;
  /**
   * Optional user defined uploadBatchKey for encapsulating upload
   * batches to a specific usage context
   */
  uploadBatchKey?: string;
  /**
   * Optional callback to fire after all documents are uploaded successfully
   */
  onDocumentsUploaded?: (args: OnDocumentsUploadedArgs<T>) => Promise<void>;
};

/**
 * Helper hook for tapping into our global file uploads store.
 * Make sure to clear any staged uploads when no longer needed
 * if they haven't been uploaded.
 */
export const useFileUploads = <T = unknown>(
  options?: UseFileUploadOptions<T>
) => {
  const queryClient = useQueryClient();
  const {
    files,
    uploads: allUploads,
    referer,
    clearFiles,
    deleteFile,
    createBatchFromFiles,
    startUploadBatch,
    retryUploadBatch,
    fileUploadStart,
    fileUploadProgress,
    fileUploadComplete,
    fileUploadError,
    allFileUploadsComplete,
    sendFilesComplete,
    batchUploadError,
    deleteUploadBatch,
  } = useFileUploadsStore(
    ({
      files,
      uploads,
      referer,
      clearFiles,
      deleteFile,
      createBatchFromFiles,
      startUploadBatch,
      retryUploadBatch,
      fileUploadStart,
      fileUploadProgress,
      fileUploadComplete,
      fileUploadError,
      allFileUploadsComplete,
      sendFilesComplete,
      batchUploadError,
      deleteUploadBatch,
    }) => ({
      files,
      uploads,
      referer,
      clearFiles,
      deleteFile,
      createBatchFromFiles,
      startUploadBatch,
      retryUploadBatch,
      fileUploadStart,
      fileUploadProgress,
      fileUploadComplete,
      fileUploadError,
      allFileUploadsComplete,
      sendFilesComplete,
      batchUploadError,
      deleteUploadBatch,
    })
  );
  const uploads = useMemo(() => {
    if (options?.uploadBatchKey) {
      return allUploads.filter(
        (batch) => batch.uploadBatchKey === options.uploadBatchKey
      );
    }
    return allUploads;
  }, [allUploads, options?.uploadBatchKey]);

  const uploadInProgress = useMemo(
    () => uploads.find((upload) => upload.isUploadingBatch) !== undefined,
    [uploads]
  );

  const uploadFilesBatch = async (batch?: FileUploadsBatchType<T>) => {
    if (!batch || batch.isSuccess || batch.isUploadingBatch) {
      throw new Error('Invalid upload batch');
    }
    const batchId = batch.id;

    startUploadBatch(batchId);

    try {
      const documents = await Promise.all(
        batch.files.map(async (fileItem, idx) => {
          // File is already complete
          if (fileItem.document) {
            return fileItem.document;
          }

          // File is still in progress
          if (fileItem.isUploading && fileItem.uploadPromise) {
            const document = await fileItem.uploadPromise;
            return document;
          }

          try {
            const controller = new AbortController();
            const uploadPromise = new Promise<BasicDocument>(
              (resolve, reject) => {
                apiClient.documents
                  .uploadFile({
                    file: fileItem.file,
                    type: batch?.uploadType || UploadType.NewFile,
                    // Track upload progress
                    onUploadProgress: ({ progress }) => {
                      fileUploadProgress({
                        batchId,
                        idx,
                        progress: progress || 0,
                      });
                    },
                    // Abort controller
                    signal: controller.signal,
                  })
                  .then((result) => {
                    resolve(result.data.data);
                  })
                  .catch(reject);
              }
            );
            fileUploadStart({
              batchId,
              idx,
              controller,
              uploadPromise,
            });

            // Upload complete
            const document = await uploadPromise;
            fileUploadComplete({
              batchId,
              idx,
              document,
            });
            return document;
          } catch (error) {
            // NOTE: We may want to parse these errors for better visibility
            fileUploadError({
              batchId,
              idx,
              error: 'Error uploading document',
            });
            throw error;
          }
        })
      );

      // Handle uploads complete
      const abortController = new AbortController();
      allFileUploadsComplete(batchId, abortController);

      // "Send" files
      // NOTE: This could be abstracted into a `onFilesUploaded` handler for flexibility
      try {
        if (typeof options?.onDocumentsUploaded === 'function') {
          await options?.onDocumentsUploaded({
            documents,
            batch,
            abortController,
          });
        }

        sendFilesComplete(batchId);
      } catch (error) {
        //  Document callback error
        batchUploadError({
          batchId,
          error: 'Error Completing Upload',
        });
      }

      queryClient.invalidateQueries(['files']);
    } catch (err) {
      // File upload errors
      batchUploadError({
        batchId,
        error: 'Error uploading files',
      });
    }
  };

  /**
   * Start file upload batch from current list of files.
   * Pass additional data to uploadFiles that will get passed
   * on to your `onDocumentsUploaded` callback. This data will
   * be saved to the store with the batch for retry attempts.
   */
  const uploadFiles = async (data: T) => {
    const batch = createBatchFromFiles(data, {
      uploadType: options?.uploadType,
      uploadBatchKey: options?.uploadBatchKey,
    });
    await uploadFilesBatch(batch);
  };
  const retryUpload = async (batchId: number) => {
    const batch = uploads.find(({ id }) => id === batchId) as
      | FileUploadsBatchType<T>
      | undefined;
    retryUploadBatch(batchId);
    await uploadFilesBatch(batch);
  };

  const cancelAndDeleteUpload = async (batchId: number) => {
    const batch = uploads.find(({ id }) => id === batchId);

    // Fire abort controllers
    if (batch) {
      batch.controller?.abort();
      batch.files.forEach((itm) => {
        itm.controller?.abort('Cancelled by user');
      });
      deleteUploadBatch(batchId);
    }
  };

  return {
    // State
    files,
    uploads,
    referer,
    uploadInProgress,
    uploadBatchKey: options?.uploadBatchKey,
    // File Actions
    clearFiles,
    deleteFile,
    // Upload Batch Actions
    uploadFiles,
    retryUpload,
    deleteUploadBatch,
    cancelAndDeleteUpload,
  };
};
