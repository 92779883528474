import React, { useCallback, useEffect, useMemo } from 'react';

import { InputAdornment } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';

import { useBiometrics } from '@liscio/common';
import { Stack, TextField } from '@liscio/ui';

import { BiometricsLoginTrigger } from './Biometrics';
import BottomSignInForm from './BottomSignInForm/BottomSignInForm';
import { getValidationSchema } from './helpers';
import { PasswordInput } from 'components';
import { ErrorDialog } from 'components/ErrorDialog';
import { config } from 'config';
import { useYupValidationResolver } from 'custom-hooks';
import { UseAuthPayload } from 'fetch-utils/users/use-auth';
import { useRememberMe } from 'modules/authenticate/views/LoginViewDesktop/components/SignInForm/RememberMe';

export interface LoginForm {
  email: string;
  password: string;
  // Controls enabling remember me
  enableRememberMe: boolean;
  // Controls enabling biometrics in native
  enableBiometrics: boolean;
}

export interface SignInFormProps {
  auth: UseAuthPayload;
}

export const SignInForm = ({ auth }: SignInFormProps) => {
  const { login, isLoggingIn, loginError, explicitLogout } = auth;
  const {
    saveBiometricCredentials,
    performBiometricVerification,
    isBiometricLoginEnabled,
  } = useBiometrics({ webAppUrl: config.webApp.url });
  const { email: rememberMeEmail, rememberEmail } = useRememberMe();

  const validationSchema = useMemo(getValidationSchema, []);
  const resolver = useYupValidationResolver(validationSchema);
  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors },
  } = useForm<LoginForm>({
    defaultValues: {
      email: '',
      password: '',
      enableRememberMe: false,
      enableBiometrics: false,
    },
    resolver,
  });

  // Form submit login
  const onSubmit: SubmitHandler<LoginForm> = async ({
    email,
    password,
    enableRememberMe,
    enableBiometrics,
  }) => {
    await login({
      email,
      password,
      time_zone: new Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

    // Remember me
    if (enableRememberMe || rememberMeEmail) {
      await rememberEmail(email);
    }

    // Biometrics
    if (enableBiometrics || isBiometricLoginEnabled) {
      await saveBiometricCredentials({ email, password });
    }
  };

  // Biometric login
  const biometricLogin = useCallback(async () => {
    const credentials = await performBiometricVerification();

    if (credentials) {
      const { email, password } = credentials;
      await login({
        email,
        password,
        time_zone: new Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
    }
  }, [performBiometricVerification, login]);

  // Do biometric login on initial load
  useEffect(() => {
    // Don't do a biometric auto-login after explicit logout
    if (isBiometricLoginEnabled && !explicitLogout) {
      biometricLogin();
    }
  }, [isBiometricLoginEnabled, explicitLogout, biometricLogin]);

  return (
    <Stack>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={3}>
          <TextField
            {...register('email')}
            fullWidth
            type="email"
            placeholder="Email"
            error={Boolean(errors?.email)}
            helperText={errors?.email?.message}
            disabled={isLoggingIn}
            InputProps={{
              endAdornment: isBiometricLoginEnabled ? (
                <InputAdornment
                  position="end"
                  style={{ color: 'inherit', marginRight: 0 }}
                >
                  <BiometricsLoginTrigger biometricLogin={biometricLogin} />
                </InputAdornment>
              ) : undefined,
            }}
          />
          <PasswordInput
            {...register('password')}
            fullWidth
            placeholder="Password"
            error={Boolean(errors?.password)}
            helperText={errors?.password?.message}
            disabled={isLoggingIn}
          />
          <BottomSignInForm
            control={control}
            reset={reset}
            getValues={getValues}
            isLoggingIn={isLoggingIn}
          />
        </Stack>
      </form>

      <ErrorDialog title="Sign In Error" error={loginError} />
    </Stack>
  );
};
