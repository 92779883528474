import React from 'react';

import { FormProvider } from 'react-hook-form';

import { UploadFormType } from '@liscio/api';

import UploadForm from './components/UploadForm/UploadForm';
import WorkflowRequestUploadForm from './components/UploadForm/WorkflowRequestUploadForm';
import { UploadDocumentFormViewMobileProps } from './UploadDocumentFormViewMobile';
import useUploadDocumentForm from './useUploadDocumentForm';

type UploadDocumentFormProviderSwitchMobileProps =
  UploadDocumentFormViewMobileProps & {
    onNavigationClick: (key: string) => void;
  };

export default function UploadDocumentFormProviderSwitchMobile({
  formData,
  type,
  fixedToAccountId,
  fixedToContactId,
  taskId,
  fileId,
  onFileUpload,
  onNavigationClick,
}: UploadDocumentFormProviderSwitchMobileProps) {
  const methods = useUploadDocumentForm(formData, type);

  return (
    <FormProvider {...methods}>
      {type === UploadFormType.Workflow ? (
        <WorkflowRequestUploadForm
          fixedToAccountId={fixedToAccountId}
          taskId={taskId}
          fileId={fileId}
          onFileUpload={onFileUpload}
          onNavigationClick={onNavigationClick}
          type={type}
        />
      ) : (
        <UploadForm
          fixedToAccountId={fixedToAccountId}
          fixedToContactId={fixedToContactId}
          taskId={taskId}
          fileId={fileId}
          onFileUpload={onFileUpload}
          onNavigationClick={onNavigationClick}
          type={type}
        />
      )}
    </FormProvider>
  );
}
