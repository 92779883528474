import { AuthenticatedUserCpaDataItem } from '@liscio/api';
import {
  Box,
  Typography,
  CenteredLayout,
  styled,
  Button,
  GlobalLoader,
} from '@liscio/ui';

const Wrapper = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  color: theme.palette.common.white,
  paddingTop: '96px',
  maxWidth: theme.breakpoints.values.sm,

  [theme.breakpoints.up('md')]: {
    color: theme.palette.text.primary,
  },
}));

export interface CpaLoginV5SelectorProps {
  cpas?: AuthenticatedUserCpaDataItem[];
  onCancel: () => void;
  onCpaSelected: (cpa: AuthenticatedUserCpaDataItem) => void;
  isLoading?: boolean;
}

export const CpaLoginV5Selector = ({
  cpas = [],
  onCancel,
  onCpaSelected,
  isLoading,
}: CpaLoginV5SelectorProps) => {
  if (isLoading) {
    return <GlobalLoader open />;
  }

  return (
    <CenteredLayout>
      <Wrapper>
        <Typography variant="h3" textAlign="center" mb={4}>
          Select Workspace
        </Typography>
        {cpas.map((cpa) => (
          <Box key={cpa.cpa_uuid} display="flex" flexDirection="column" mt={3}>
            <Button
              onClick={() => onCpaSelected(cpa)}
              variant="contained"
              size="large"
              color="primary"
            >
              {cpa.cpa_name}
            </Button>
          </Box>
        ))}
        <Box display="flex" flexDirection="column" mt={6}>
          <Button
            onClick={onCancel}
            variant="contained"
            size="large"
            color="secondary"
          >
            Cancel
          </Button>
        </Box>
      </Wrapper>
    </CenteredLayout>
  );
};

export default CpaLoginV5Selector;
